import { useQuery } from 'react-query';
import { getHookState } from '@utils';
import { QueryNamesEnums, HookState, IProject } from '@interfaces';
import { getProject } from '@globalService';
import { useEffect } from 'react';

const requiredKeys = [
  'estimated_start_date',
  'start_date',
  'original_completion_date',
  'estimated_completion_date',
  'loan',
  'retainage_rate',
] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;

export interface ControllerInterface {
  state: HookState;
  project: ProjectData;
}

export const useProject = (projectId: string): ControllerInterface => {
  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  // after importing budget and invalidating cache, loan details isn't updated immediately,
  // so we need to refetch project on component mount to have updated data in loan details on url change
  useEffect(() => {
    projectQuery.refetch();
  }, []);

  return {
    project: projectQuery.data,
    state: getHookState(projectQuery),
  };
};
