import React, { useCallback, useEffect, useState, useContext } from 'react';
import { UNSAFE_NavigationContext, useNavigate } from 'react-router-dom';
import type { History, Transition } from 'history';

import { ConfirmationModal } from '@components';
import { PopupTypeEnum } from '@interfaces';

export const useLeavePageBlocker = ({
  currentPagePathname,
  confirmTitle,
  confirmText = 'If you exit, you will lose the data you entered.',
  isUpdated = false,
}: {
  currentPagePathname: string;
  confirmTitle?: string;
  confirmText?: string;
  isUpdated?: boolean;
}) => {
  const navigate = useNavigate();
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  const [triggerExit, setTriggerExit] = useState({
    isNavigationConfirmed: false,
    path: '',
  });
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const handleGoToIntendedPage = useCallback((location) => navigate(location), [history]);

  useEffect(() => {
    if (triggerExit.isNavigationConfirmed) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = navigator.block((tx: Transition) => {
      if (tx.location?.pathname !== currentPagePathname && isUpdated) {
        setLeaveModalOpen(true);
      }
      setTriggerExit({
        path: tx.location.pathname,
        isNavigationConfirmed: !isUpdated,
      });

      return triggerExit.isNavigationConfirmed;
    });

    return () => {
      unblock();
    };
  }, [handleGoToIntendedPage, triggerExit.isNavigationConfirmed, triggerExit.path, isUpdated]);

  const getLeavePageConfirmModal = useCallback(
    () => (
      <ConfirmationModal
        open={isLeaveModalOpen}
        title={confirmTitle}
        text={confirmText}
        onClose={() => setLeaveModalOpen(false)}
        confirmCallback={() => {
          setTriggerExit((obj) => ({
            ...obj,
            isNavigationConfirmed: true,
          }));
          setLeaveModalOpen(false);
        }}
        type={PopupTypeEnum.ERROR}
      />
    ),
    [isLeaveModalOpen],
  );

  return {
    isLeaveModalOpen,
    setLeaveModalOpen,
    getLeavePageConfirmModal,
    setTriggerExit,
  };
};
