import { useQuery } from 'react-query';
import { HookState, IProjectsPortfolioStats, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { getProjectsStats } from '@globalService';
import { getHookState, transformFiltersToQueryString } from '@utils';

export interface ControllerInterface {
  statsData: IProjectsPortfolioStats;
  state: HookState;
}

export const useProjectPortfolioStats = (filtering): ControllerInterface => {
  const stringQueryParams = transformFiltersToQueryString({
    filtering,
    tableKey: TableKeyEnum.RISK_RADAR,
  });

  const projectsStatsQuery = useQuery<IProjectsPortfolioStats, Error>(
    [QueryNamesEnums.GET_PROJECTS_STATS, { query: stringQueryParams }],
    getProjectsStats.bind(this, stringQueryParams),
  );

  return {
    state: getHookState(projectsStatsQuery),
    statsData: projectsStatsQuery.data,
  };
};
