import React, { FC } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { ColumnsFilterV2, MilestoneListOptionsMenu } from '@components';
import { useNavigationNetBlocker } from '@hooks';
import { useLaunchDarklyFlags } from '@context';
import { TableContext, useMilestoneList } from './controller';
import { MilestoneListInterface } from './interface';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { ListWrapper } from './columns/common';
import { RequestTableTabTypesEnum, RequestTableTabsEnum } from '@interfaces';
import ScrollPlayground from './test';

const MilestoneList: FC<MilestoneListInterface> = ({
  tableKey,
  milestones,
  initColumns,
  totals,
  withColumnIndication,
  headerLeftPart,
  headerRightPart,
  withProgress,
  patchMilestone,
  refetch,
  lockedColumns,
  resetMutation,
  requestStatus,
  deleteMilestone,
  openEditMilestoneModal,
  exportToCSV,
  contingencyMode,
  menuItems = [],
  onExpandTable,
  source,
  updateData,
  withCredit,
  groupByFilter,
}) => {
  useNavigationNetBlocker();
  const {
    sortedMilestones,
    tableContext,
    hiddenColumns,
    isColumnFilterUpdating,
    columns,
    changeFieldVisibility,
    tableRef,
    milestoneColumns,
    onPaste,
    keyDown,
    updateRightDrawer,
    showCreditButton,
    renderColumns,
  } = useMilestoneList({
    tableKey,
    milestones,
    initColumns,
    withProgress,
    patchMilestone,
    refetch,
    withColumnIndication,
    resetMutation,
    requestStatus,
    deleteMilestone,
    openEditMilestoneModal,
    contingencyMode,
    onExpandTable,
    source,
    updateData,
    withCredit,
    groupByFilter,
  });
  const flags = useLaunchDarklyFlags();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        {Boolean(headerLeftPart?.length) && (
          <Stack direction="row" alignItems="center">
            {headerLeftPart?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
        <Stack direction="row" alignItems="center" sx={{ ml: 'auto' }}>
          {headerRightPart?.map(({ Component }, index) => (
            <Box key={index.toString()}>{Component}</Box>
          ))}
          {showCreditButton && (
            <Button
              size="small"
              onClick={() =>
                flags?.['ENG_7938_breakdown_of_line_item']
                  ? updateRightDrawer({
                      milestoneName: 'Credit review',
                      tab: RequestTableTabsEnum.CREDIT,
                      type: RequestTableTabTypesEnum.APPROVE,
                    })()
                  : updateRightDrawer({
                      milestoneName: 'Credit review',
                      tab: RequestTableTabsEnum.CREDIT_APPROVE,
                    })()
              }
              data-cy={`${source}__review_credit__button`}
              sx={{ ml: 1 }}
            >
              Review credits
            </Button>
          )}
          {!lockedColumns && (
            <ColumnsFilterV2
              hiddenColumns={hiddenColumns}
              columns={columns}
              milestoneColumns={milestoneColumns}
              changeFieldVisibility={changeFieldVisibility}
              isUpdating={isColumnFilterUpdating}
              source={source.toString()}
            />
          )}
          <Box pr={1} />
          <MilestoneListOptionsMenu
            menuItems={menuItems}
            totals={totals}
            columns={columns}
            sortedMilestones={sortedMilestones}
            exportToCSV={exportToCSV}
          />
        </Stack>
      </Stack>
      <ListWrapper
        id="listWrapper"
        tabIndex={-1}
        ref={tableRef}
        onPaste={onPaste}
        onKeyDown={keyDown}
      >
        <div>
          <TableContext.Provider value={tableContext}>
            {flags?.['ENG_7895_table_v3'] &&
            sortedMilestones.filter((item) => item.id).length &&
            renderColumns.length > 0 ? (
              <ScrollPlayground
                sortedMilestones={sortedMilestones.filter((item) => item.id)}
                columns={renderColumns}
              />
            ) : null}
          </TableContext.Provider>
        </div>
      </ListWrapper>
    </>
  );
};

export default MilestoneList;
