import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import cloneDeep from 'lodash/cloneDeep';
import {
  BulkDrawRequestListItemUpdateParam,
  ErrorDual,
  IDrawRequest,
  IFilterOption,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  MutationKeyEnum,
  PatchDrawRequestProdBuildGroupParam,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestPayload,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteDrawRequest,
  getDrawRequest,
  getDrawRequestMilestones,
  getProject,
  patchDrawRequestProdBuildGroup,
  updateDrawRequestMilestones,
} from '@globalService';
import { useNavigate, useParams } from 'react-router-dom';
import { excludeCommentsQueryFields, LineItemFilterValues } from '@constants';
import {
  autofillCanBeShown,
  canDeleteRequest,
  checkIsCreator,
  checkIsCustomerSuccess,
  checkIsOwner,
  checkIsTableEdit,
  getItemLocalHighlight,
  getTeamRole,
  isDrawRequest,
  isReallocationEnabled,
  isRequestInReview,
  parsePathErrorDual,
  replaceMilestoneData,
} from '@utils';
import { useRequestTotals, useSafeSnackbar } from '@hooks';
import { AuthContext } from '@context';
import { useExpandCollapseTable, useLoadingSkeleton, usePHBFilters } from '../hooks';

export type ControllerInterface = {
  initColumns: string[];
  milestones: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  groupByOptions: { ids: string; filterValue: string }[];
  filterValue: string;
  handleShowFilterClick: (value: string) => void;
  groupByValue: string;
  handleGroupByFilterClick: (value: string) => void;
  patchMsGroup: (params) => void;
  isLoading: boolean;
  canDeleteRequest: boolean;
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  deleteRequest: () => Promise<Response>;
  isDeleting: boolean;
  showAutofillButton: boolean;
  drawRequest: IDrawRequest;
  handleAutofillLenderAllowance: (autofillValue: string) => void;
  isAutofillLoading: boolean;
  totals?: IMilestoneTotal;
  groupByKeys: string;
};

export const useRequestTable = (isSubmissionProcess = false): ControllerInterface => {
  const { projectId, requestId: drawRequestId, action } = useParams();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const navigate = useNavigate();
  const isEditTable = useMemo(() => checkIsTableEdit(action), [action]);

  const [listItems, setListItems] = useState<IPHBTableItem[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    //TODO use flag from the BE after it will be added
    localIsUserCreator: checkIsCreator(drawRequest, teamRole),
    isExpanded,
    isNested,
    index,
    project_milestone: { ...item.project_milestone, index },
  });

  const { onExpandClick, updateListItemsWithParentGroup, updateListItemsWithMsList } =
    useExpandCollapseTable({
      setListItems,
      createTableObject,
    });

  const {
    groupIds,
    filterValue,
    handleFilterClick,
    groupByValue,
    handleGroupByClick,
    filterOptions,
    groupByOptions,
    filterTotalKey,
    filterKey,
  } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
  });

  const query = excludeCommentsQueryFields;
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId, query: `{-comments_preview,totals{${filterTotalKey}}}` },
      ],
      queryFn: getDrawRequest.bind(this, {
        projectId,
        drawRequestId,
        query: `{-comments_preview,totals{${filterTotalKey}}}`,
      }),
      enabled: Boolean(drawRequestId),
    },
  ]);

  const deleteDrawRequestMutation = useMutation<Response, Error, RequestPayload>(
    deleteDrawRequest,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: async () => {
        enqueueSnackbar('Request deleted', { variant: 'success' });
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL,
          { projectId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          { projectId, query },
        ]);
        navigate(`/projects/${projectId}/overview`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const project = requestedDataQueries[0].data;
  const drawRequest = requestedDataQueries[1].data;

  const { refetchAndReplaceTotals } = useRequestTotals({
    projectId,
    drawRequestId,
    totalKey: filterTotalKey,
    filterKey,
    groupByKeys: groupIds,
  });

  const requestMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: groupIds,
        filterKey,
      },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      groupBy: groupIds,
      filterKey,
    }),
    { enabled: Boolean(drawRequestId && projectId && groupIds) },
  );
  const requestMilestones = requestMilestonesQuery.data;

  // bulk milestones update on autofill
  const bulkMilestoneMutation = useMutation<
    Response,
    ErrorDual,
    BulkDrawRequestListItemUpdateParam
  >(updateDrawRequestMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH_BULK,
    onSuccess: () => {
      requestMilestonesQuery.refetch().then((res) => {
        updateListItemsWithMsList(res.data?.results);
      });
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId: drawRequestId },
      ]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequest, project),
    [drawRequest, project],
  );

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'nameV2',
      'costType',
      ...(isSubmissionProcess
        ? ['prefundingCost', 'originalEstimate']
        : [
            'originalConstructionBudget',
            'prefundingCost',
            'originalEstimate',
            'revisedConstructionBudget',
          ]),
      'previousChanges',
      'previousChangesRate',
      'revisedEstimate',
      ...(isReallocationAllowed
        ? ['requestedAdjustments', 'requestedAdjustmentsRate', 'requestedRevisedEstimate']
        : []),
      ...(isReallocationAllowed && !isSubmissionProcess
        ? ['approvedAdjustments', 'adjustmentsRate']
        : []),
      ...(isDrawRequest(drawRequest) ? ['requestedAmount', 'requestedAmountRelative'] : []),
      ...(isSubmissionProcess
        ? []
        : [
            PermissionNamesEnums.PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE,
            PermissionNamesEnums.PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_RATE,
            PermissionNamesEnums.PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_INCREMENTAL,
            PermissionNamesEnums.PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_RATE_INCREMENTAL,
            'approvedAmountCumulative',
            'lenderAllowanceRate',
            ...(isDrawRequest(drawRequest) ? ['approvedAmount', 'approvedAmountRelative'] : []),
          ]),
      'previousApprovedAmountCumulative',
      'previousLenderAllowanceRate',
      'balanceToFinish',
      'balanceToFinishRate',
      ...(retainageRate ? ['retainageRateBudget'] : []),
      ...(isSubmissionProcess
        ? []
        : [PermissionNamesEnums.PROJECT__SOV__GAP, PermissionNamesEnums.PROJECT__SOV__GAP_RATE]),
      'documentsPhotosUploaderMenu',
      'documentsPhotosGalleryMenu',
      'comments',
    ],
    [isSubmissionProcess, retainageRate, isReallocationAllowed, drawRequest],
  );

  const activeToEdit = useMemo(
    () =>
      (drawRequest?.waits_current_user_approval && !checkIsOwner(teamRole)) ||
      (checkIsCustomerSuccess(teamRole) && isRequestInReview(drawRequest?.status)) ||
      isEditTable,
    [drawRequest, teamRole, isEditTable],
  );

  useEffect(() => {
    setListItems(null);
  }, [filterValue, groupByValue, drawRequestId]);

  useEffect(() => {
    if (!requestMilestones?.results || listItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(requestMilestones.results);
    setListItems(
      clonedMilestones.map((item: IMilestone, index: number) => createTableObject({ item, index })),
    );
  }, [requestMilestones?.results, drawRequest, listItems]);

  const patchDrawRequestProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestProdBuildGroupParam
  >(patchDrawRequestProdBuildGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId: drawRequestId },
      ]);
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
            {
              projectId,
              drawRequestId,
              query,
              groupBy: groupIds,
              filterKey,
            },
          ],
        },
        (old) =>
          replaceMilestoneData({
            milestones: old,
            milestoneId: data.id,
            json: data,
          }),
      );
      refetchAndReplaceTotals({ isMilestonesUpdate: false });
      updateListItemsWithParentGroup(data);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      if (isSubmissionProcess) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            filterKey: LineItemFilterValues.CURRENT_REQUEST_ONLY.filterKey,
            restQlParams: '{}',
            limit: '1',
          },
        ]);
      }
    },
  });

  const patchMsGroup = useCallback(
    (params) =>
      patchDrawRequestProdBuildGroupMutation.mutate({
        project: projectId,
        drawRequest: drawRequestId,
        group_by: groupIds,
        tags: params.milestoneTags,
        json: params.json,
        filterKey,
      }),
    [drawRequestId, projectId, groupIds, filterKey],
  );

  const deleteRequest = useCallback(
    () =>
      deleteDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: drawRequestId,
      }),
    [deleteDrawRequestMutation, projectId, drawRequestId],
  );

  const handleAutofillLenderAllowance = (autofillValue) => {
    const json = {
      autofill_key: autofillValue,
    };
    bulkMilestoneMutation.mutate({
      projectId,
      drawRequestId,
      json,
    });
  };

  const showAutofillButton = useMemo(
    () => autofillCanBeShown(drawRequest, teamRole),
    [drawRequest, teamRole],
  );

  return {
    initColumns,
    milestones: listItems,
    onExpandClick,
    groupByOptions,
    filterOptions,
    filterValue,
    handleShowFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    groupByValue,
    handleGroupByFilterClick: (value) => {
      handleGroupByClick(value);
      showTemporaryLoadingSkeleton();
    },
    patchMsGroup,
    isLoading:
      showLoadingSkeleton || requestMilestonesQuery.isLoading || requestMilestonesQuery.isIdle,
    canDeleteRequest: canDeleteRequest(drawRequest),
    showDeleteModal,
    setShowDeleteModal,
    deleteRequest,
    isDeleting: deleteDrawRequestMutation.isLoading,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading: bulkMilestoneMutation.isLoading,
    totals: drawRequest?.totals?.[filterTotalKey],
    groupByKeys: groupIds,
  };
};
