import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { getAppraisalDocuments, getHookState } from '@utils';
import {
  DeleteInspectionDocument,
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  HookState,
  IDocumentType,
  IInspection,
  IProjectDocument,
  ITransloaditRestrictions,
  ITransloaditSignature,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useFilesUploader,
  useSafeSnackbar,
} from '@hooks';
import {
  deleteInspectionDocument,
  getInspectionDocuments,
  getProjectDocuments,
  getProjectDocumentTypes,
  getProjectInspectionById,
} from '@globalService';
import { useParams } from 'react-router-dom';

export interface ControllerInterface {
  state: HookState;
  handleOpenDocumentUploader: ({ isProjectLevel }: { isProjectLevel: boolean }) => void;
  isFilesUploaderOpened: boolean;
  transloaditSignature: ITransloaditSignature;
  filesUploaderClose: () => void;
  refetchCallback: (refetch: () => Promise<UseQueryResult>) => void;
  documentTypes: IDocumentType[];
  refetchDocuments: () => Promise<UseQueryResult>;
  restrictions: ITransloaditRestrictions;
  documents: IProjectDocument[];
  isLoading: boolean;
  setIsAppraisal: Dispatch<SetStateAction<boolean>>;
  handleDelete: ({ documentId }: { documentId: string }) => void;
  deleteModal: ConfirmModalHookInterface;
  handleDeleteIconClick: ({
    fileName,
    documentId,
  }: {
    fileName?: string;
    documentId?: string;
  }) => void;
  selectedFileName: string;
}

export const useInspectionRelatedDocuments = ({
  drawRequestId,
  inspectionId,
  isInspectionReports,
}): ControllerInterface => {
  const { projectId } = useParams();
  const [isAppraisal, setIsAppraisal] = useState(false);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  const deleteModal = useConfirmationModal();

  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }],
    getProjectDocuments.bind(this, { projectId }),
    { enabled: Boolean(projectId) },
  );

  const inspectionDocumentsQuery = useQuery<{ results: IProjectDocument[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId, inspectionId }],
    getInspectionDocuments.bind(this, { projectId, inspectionId }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );

  const inspectionQuery = useQuery<IInspection, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
    getProjectInspectionById.bind(this, { projectId, inspectionId }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    uploadMedia,
    restrictions,
    refetchCallback,
  } = useFilesUploader();

  const handleOpenDocumentUploader = useCallback(
    ({ isProjectLevel }) => {
      const content_type = isProjectLevel
        ? DocumentContentTypeEnum.PROJECT
        : DocumentContentTypeEnum.INSPECTION;
      const fields = isInspectionReports
        ? { inspection_id: inspectionId }
        : { content_type, object_id: isProjectLevel ? projectId : inspectionId };
      const templateType = isInspectionReports
        ? TransloaditTemplateEnum.INSPECTION_DOCUMENTS
        : TransloaditTemplateEnum.DOCUMENTS;

      uploadMedia({
        fields,
        templateType,
      });
    },
    [projectId, drawRequestId, uploadMedia, inspectionId, isInspectionReports],
  );

  const refetchDocuments = useMemo(
    () => (isAppraisal ? projectDocumentsQuery.refetch : inspectionDocumentsQuery.refetch),
    [projectDocumentsQuery],
  );

  const documentTypes = useMemo(
    () =>
      projectDocumentTypesQuery.data?.filter((type) =>
        isAppraisal
          ? type.name === DocumentTypeEnum.APPRAISAL
          : type.name !== DocumentTypeEnum.APPRAISAL,
      ),
    [projectDocumentTypesQuery, isAppraisal],
  );

  const deleteInspectionDocumentMutation = useMutation<Response, Error, DeleteInspectionDocument>(
    deleteInspectionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
          { projectId, inspectionId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
          { projectId, inspectionId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleDelete = ({ documentId }) =>
    deleteInspectionDocumentMutation.mutateAsync({
      projectId,
      inspectionId,
      documentId,
    });

  const documents = useMemo(
    () =>
      isInspectionReports
        ? inspectionQuery.data?.reports
        : [
            ...getAppraisalDocuments(projectDocumentsQuery.data?.results),
            ...(inspectionDocumentsQuery.data?.results || []),
          ],
    [
      inspectionQuery.data,
      projectDocumentsQuery.data,
      inspectionDocumentsQuery.data,
      isInspectionReports,
    ],
  );

  const handleDeleteIconClick = ({ fileName = '', documentId = '' }) => {
    setSelectedFileName(fileName);
    deleteModal.askConfirm({ documentId });
  };

  return {
    state: getHookState(projectDocumentsQuery),
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    refetchCallback,
    refetchDocuments,
    documentTypes,
    restrictions,
    documents,
    isLoading: projectDocumentsQuery.isFetching,
    setIsAppraisal,
    handleDelete,
    deleteModal,
    handleDeleteIconClick,
    selectedFileName,
  };
};
