import { Popper } from '@mui/base';
import { autocompleteClasses, alpha, InputBase, ButtonBase, styled } from '@mui/material';
import { colors } from '@theme';
import React from 'react';

interface PopperComponentProps {
  anchorEl?: null | Element | ((element: Element) => Element);
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: colors.white,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  #eaecef'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

export function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export const StyledPopper = styled(Popper)(() => ({
  border: `1px solid ${colors.neutral.lighter}`,
  boxShadow: `0 8px 24px ${alpha(colors.secondary, 0.25)}`,
  borderRadius: 4,
  width: 360,
  zIndex: 1300,
  backgroundColor: colors.white,
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${colors.neutral.lighter}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: colors.white,
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${colors.neutral.lighter}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${alpha(theme.palette.secondary.main, 0.5)}`,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

export const Button = styled(ButtonBase)(() => ({
  width: '100%',
}));
