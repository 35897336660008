import React, { FC, useEffect, useState } from 'react';
import { MessagePanel, ProjectNameLabel, RequestChecklist } from '@components';
import { Stack, Tab, Tabs } from '@mui/material';
import { Documents } from '../../sections/project/components';
import * as Controller from './controller';

const TableItemRightDrawer: FC<{
  projectId: string;
  requestId?: string;
  activeTab?: string;
}> = ({ projectId, requestId, activeTab }) => {
  const { project } = Controller.useRequestQueueRightModal({ projectId });
  const TABS = {
    policies: { label: 'Policies', value: 'policies' },
    documents: { label: 'Documents', value: 'documents' },
    comments: { label: 'Comments', value: 'comments' },
  };

  const [tab, setTab] = useState(activeTab || TABS.comments.value);
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (activeTab) setTab(activeTab);
  }, [activeTab]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ mb: 1 }}>
        <ProjectNameLabel project={project} />
      </Stack>
      <Tabs
        TabIndicatorProps={{
          style: { transition: 'none' },
          children: <span />,
        }}
        value={tab}
        onChange={handleChange}
        sx={{ mb: 4 }}
      >
        {requestId && (
          <Tab
            label={TABS.policies.label}
            value={TABS.policies.value}
            sx={{ textTransform: 'none' }}
          />
        )}
        <Tab
          label={TABS.documents.label}
          value={TABS.documents.value}
          sx={{ textTransform: 'none' }}
        />
        <Tab
          label={TABS.comments.label}
          value={TABS.comments.value}
          sx={{ textTransform: 'none' }}
        />
      </Tabs>

      {tab === TABS.policies.value && (
        <RequestChecklist projectId={projectId} requestId={requestId} />
      )}
      {tab === TABS.documents.value && (
        <Documents
          prId={projectId}
          requestId={requestId}
          requestDocuments
          source="requests_queue__right_drawer__documents"
        />
      )}
      {tab === TABS.comments.value && (
        <MessagePanel
          projectId={projectId}
          requestId={requestId}
          source="requests_queue__right_drawer__comments"
        />
      )}
    </>
  );
};

export default TableItemRightDrawer;
