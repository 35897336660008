import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import {
  IDocument,
  IHandleRightDrawerOpenerParams,
  IRightMenu,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequestDocumentsList } from '@globalService';
import { useRightMenu } from '@hooks';

export interface ControllerInterface {
  drawRequestDocuments: IDocument[];
  docSummaryMenu: IRightMenu;
  docSummaryParams: { projectId: string };
  handleSmartSummaryOpenerClick: (params: IHandleRightDrawerOpenerParams) => void;
  setDocSummaryParams: Dispatch<SetStateAction<{ projectId: string; documentId?: string }>>;
}

export const useSmartSummaryButton = ({ drawRequestId, projectId }): ControllerInterface => {
  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
    documentId?: string;
  }>({
    projectId,
  });

  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId }],
    getDrawRequestDocumentsList.bind(this, projectId, drawRequestId),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const filteredDrawRequestDocuments = useMemo(
    () => drawRequestDocumentsQuery.data?.results?.filter((doc) => !doc.deleted) || [],
    [drawRequestDocumentsQuery.data?.results],
  );

  const { handleRightDrawerOpenerClick: handleSmartSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  return {
    drawRequestDocuments: filteredDrawRequestDocuments,
    docSummaryMenu,
    docSummaryParams,
    handleSmartSummaryOpenerClick,
    setDocSummaryParams,
  };
};
