import React, { FC, useContext } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { currencyFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { ColumnV2Width, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  const { source } = useContext(TableContext);
  if (isBlocked) return <Skeleton width={'100%'} />;

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__inspector_allowance__index_${row?.index}`}
    >
      {currencyFormatter(row.inspector_allowance, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__inspector_allowance__value`}>
      {currencyFormatter(row.inspector_allowance, '-')}
    </Typography>
  );
};

const inspectorAllowance: MilestoneListColumnType = {
  name: 'inspector_allowance',
  columnText: 'Inspection allowance ($)',
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Implied cumulative amount eligible to be paid per the inspector across all line items">
      <ColumnLabel>Inspection </ColumnLabel>
      <ColumnLabel> allowance ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default inspectorAllowance;
