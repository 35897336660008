import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { Box, Typography, Divider, Stack, Skeleton } from '@mui/material';

import { FullScreenDialog, DisbursementStatement, StyledLink } from '@components';
import { colors } from '@theme';
import { SuccessIcon } from '@svgAsComponents';
import * as Controller from './controller';
import { HookState } from '@interfaces';

const Card = styled(Box)({
  display: 'flex',
  flexFlow: 'column nowrap',
  allignItems: 'center',
  width: '820px',
  backgroundColor: colors.background.white,
  padding: '16px',
});

const Icon = () => (
  <Box
    sx={{
      alignSelf: 'center',
      marginBottom: '6px',
    }}
  >
    <SuccessIcon />
  </Box>
);

const ApprovalSuccessfulFinal: FC<{
  open: boolean;
  requestId: string;
  onSubmit: (navigateLink?: string) => void;
  type?: string;
  projectId: string;
}> = ({ open, requestId, onSubmit, type, projectId }) => {
  const { titleTexts, state } = Controller.useApprovalSuccessfulFinal(requestId, type);

  return (
    <FullScreenDialog
      open={open}
      content={() => (
        <Card>
          <Icon />

          <Stack alignItems="center">
            {state === HookState.FETCHING && (
              <Stack spacing={2}>
                <Skeleton height={72} />
                <Skeleton height={72} />
              </Stack>
            )}
            {state === HookState.SUCCESS && (
              <>
                <Typography
                  variant="h4"
                  sx={{ color: colors.status.success.medium, textAlign: 'center', mb: 1 }}
                >
                  {titleTexts.title}
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre', textAlign: 'center' }}>
                  {titleTexts.text}
                  <StyledLink
                    onClick={() => onSubmit()}
                    to={`/projects/${projectId}/payments/draw-requests/${requestId}`}
                  >
                    payment details
                  </StyledLink>
                </Typography>
              </>
            )}
          </Stack>

          <Divider sx={{ marginTop: '24px' }} />
          <DisbursementStatement drawRequestId={requestId} />
        </Card>
      )}
      actionButtons={() => (
        <>
          <Stack direction="row" sx={{ marginX: 'auto' }}>
            <Button variant="new" color="secondary" onClick={() => onSubmit()}>
              Back to project
            </Button>
            <Button
              variant="new"
              color="secondary"
              onClick={() => onSubmit('/requests/draws-and-changes')}
              sx={{ ml: '8px' }}
            >
              Back to requests
            </Button>
          </Stack>
          <Button onClick={window.print} variant="new" color="secondary">
            Print
          </Button>
        </>
      )}
      onClose={onSubmit}
    />
  );
};

export default ApprovalSuccessfulFinal;
