import React, { FC, Fragment } from 'react';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import { HookState } from '@interfaces';
import { colors } from '@theme';
import * as Controller from './controller';
import {
  ButtonWithTooltipOnClick,
  CommentHeader,
  Loader,
  SearchBox,
  ServiceMessage,
  Tags,
  WysiwygEditor,
} from '@components';
import { PinIcon } from '@svgAsComponents';
import FilterMultiSelect from './FilterMultiSelect';

const MessagePanel: FC<{
  projectId?: string;
  requestId?: string;
  milestoneId?: string;
  inspectionId?: string;
  documentId?: string;
  disableInput?: boolean;
  source: string;
  tab?: string;
}> = ({
  projectId,
  requestId,
  milestoneId,
  inspectionId,
  documentId,
  disableInput,
  source,
  tab,
}) => {
  const {
    state,
    postMessage,
    commentField,
    comments,
    handleSearchSubmit,
    clearSearch,
    search,
    commentsAreLoading,
    isPostingComment,
    handleFilters,
    filters,
    tags,
    reportTag,
    setReportTag,
    updateComment,
    showReportTag,
    showSendToInspectorCheckbox,
    isSendToInspectorChecked,
    setSendToInspectorChecked,
    pinComment,
  } = Controller.useProjectComments({
    projectId,
    requestId,
    milestoneId,
    inspectionId,
    documentId,
    isProjectComments: !requestId && tab === 'comments',
  });

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <Skeleton variant="rectangular" width="100%" height={24} sx={{ mt: '2rem' }} />
          <Skeleton variant="rectangular" width="100%" height={100} sx={{ mt: '1.5rem' }} />
        </>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Message panel" />;
    }
  }

  return (
    <>
      {!disableInput && (
        <>
          <Stack direction="row" sx={{ width: '100%', justifyContent: 'flex-end' }}>
            <Tags
              items={tags}
              reportTag={reportTag}
              showReportTag={showReportTag && tags.some((tag) => tag.includes('Draw'))}
              setReportTag={setReportTag}
              source={`${source}__header_tags`}
            />
          </Stack>
          <Stack sx={{ width: '100%', mt: 0.5 }}>
            <WysiwygEditor editField={commentField} source={source} />
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              sx={{ mb: 3 }}
            >
              {showSendToInspectorCheckbox && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendToInspectorChecked}
                      onChange={(_e, value) => setSendToInspectorChecked(value)}
                      data-cy={`${source}__send_to_inspector__checkbox`}
                      data-tour="send_to_inspector__checkbox"
                    />
                  }
                  label={<Typography variant="body3">Send to inspection agency</Typography>}
                />
              )}

              <ButtonWithTooltipOnClick
                sx={{ width: '100px', height: '40px', alignSelf: 'flex-end', mt: 1 }}
                onClick={() => {
                  if (commentField.validate()) postMessage(commentField.value);
                }}
                conditionallyDisabled={!commentField.value}
                disabled={isPostingComment}
                tooltipText="Comment is empty"
                dataTestName={`${source}__send__button`}
              >
                {isPostingComment ? 'Commenting' : 'Comment'}
              </ButtonWithTooltipOnClick>
            </Stack>
          </Stack>
        </>
      )}

      {/* don't show search and filter for only milestone or for only document comments */}
      {!milestoneId && !documentId && (
        <Grid container spacing={1} sx={{ mb: '24px' }} alignItems="center">
          <Grid item xs={6}>
            <SearchBox
              search={search}
              onSubmit={handleSearchSubmit}
              clearSearch={clearSearch}
              dataTestName={`${source}__search__input`}
              height="32px"
            />
          </Grid>
          <Grid item xs={6}>
            <FilterMultiSelect
              onChange={handleFilters}
              dataTestName={`${source}__filters__button`}
            />
          </Grid>
        </Grid>
      )}
      {(Boolean(search) || Boolean(filters?.length)) &&
        !commentsAreLoading &&
        !comments?.length && (
          <Typography variant="body3">No results for selected params</Typography>
        )}

      {commentsAreLoading && (
        <Grid container justifyContent="center">
          <Loader />
        </Grid>
      )}

      {comments?.map((comment, index) => {
        const tags = comment.tags.filter((tag) => tag !== 'Report');
        const reportTag = comment.tags.includes('Report');
        const showTagForReport = showReportTag && comment.tags.some((tag) => tag.includes('Draw'));

        if (!comment.message) return null;
        return (
          <Fragment key={comment.id}>
            <CommentHeader comment={comment} source={source} index={index} />
            <Typography
              dangerouslySetInnerHTML={{ __html: comment.message }}
              variant="body2"
              sx={{ pt: 1, pb: 2, wordWrap: 'break-word' }}
              data-cy={`${source}__comment__message__index_${index}`}
            />
            <Stack direction="row" alignItems="flex-end">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 24,
                  height: 24,
                  backgroundColor: comment.pinned_at
                    ? colors.status.information.light
                    : colors.neutral.lightest,
                  mr: 1,
                  cursor: 'pointer',
                }}
                onClick={pinComment(comment)}
                data-cy={`${source}__pin_comment__icon__index_${index}`}
              >
                <PinIcon
                  size={16}
                  color={comment.pinned_at ? colors.status.information.medium : colors.icons.gray}
                />
              </Stack>
              <Tags
                items={tags}
                reportTag={reportTag}
                showReportTag={showTagForReport}
                setReportTag={updateComment(comment)}
                source={`${source}__comment__index_${index}`}
              />
            </Stack>

            <Divider sx={{ mt: 2, mb: 3, color: colors.neutral.lighter }} />
          </Fragment>
        );
      })}
    </>
  );
};

export default MessagePanel;
