import React, { FC, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';

import { colors } from '@theme';
import { dateFormatter, getTruncateText, WithPermission } from '@utils';
import { NavigateIcon, PeopleIcon } from '@svgAsComponents';
import { HookState, PermissionNamesEnums } from '@interfaces';
import {
  LabelAndValue,
  LoadingSkeleton,
  ServiceMessage,
  StatusChip,
  WidgetPaperCard,
  ProjectImage,
} from '@components';
import NodeCard from '../NodeCard';
import ProjectProperty from '../ProjectProperty';
import ScopeOfWorkLongText from '../ScopeOfWorkLongText';
import * as Controller from './controller';
import { SettingsContext } from '@context';

const ProjectDetails: FC<{ onlyInfo?: boolean }> = ({ onlyInfo }) => {
  const { projectId } = useParams();
  const controller = Controller.useProject(projectId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <NodeCard title="Project details">
          <LoadingSkeleton type="overviewBlock" />
        </NodeCard>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="project details" />;
    }

    case HookState.SUCCESS: {
      return <ProjectDetailsBody onlyInfo={onlyInfo} controller={controller} />;
    }

    default:
      return null;
  }
};

export default ProjectDetails;

const ProjectDetailsBody: FC<{
  controller: Controller.ControllerInterface;
  onlyInfo?: boolean;
}> = ({ controller, onlyInfo }) => {
  const {
    project,
    projectProperty,
    projectType,
    exitStrategy,
    isLongDescription,
    completion,
    duration,
    contractual,
    setAnchorEl,
    anchorEl,
    statusValue,
    modelsCount,
    unitsCount,
    navigateToPHBBudget,
  } = controller;
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);

  return (
    <WidgetPaperCard
      style={{ minHeight: '100%', position: 'relative' }}
      title="Project information"
      rightIcons={
        onlyInfo
          ? []
          : [
              {
                Component: (
                  <WithPermission permissionKey={PermissionNamesEnums.PROJECT__PEOPLE}>
                    <IconButton
                      sx={{ mr: '4px', height: '24px' }}
                      onClick={() => navigate(`/projects/${projectId}/people`)}
                      data-cy="project__overview__people__icon"
                    >
                      <PeopleIcon />
                    </IconButton>
                  </WithPermission>
                ),
              },
            ]
      }
    >
      <Stack spacing={onlyInfo ? 0.5 : 1} width="100%">
        {!onlyInfo && (
          <Stack direction="row" alignItems="flex-start" sx={{ mb: '8px', mt: '16px' }}>
            <Typography data-cy="project_adress" variant="body3SemiBold">
              {project.address?.full_address}
            </Typography>
            <IconButton
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/search/?api=1&query=${project.address?.full_address}`,
                )
              }
              sx={{ pt: '2px' }}
              data-cy="project__overview__navigate_to_address__icon"
            >
              <NavigateIcon />
            </IconButton>
          </Stack>
        )}
        {!onlyInfo && (
          <>
            <ProjectImage
              project={project}
              source="project__overview__project_image"
              disabled
              sx={{
                width: 'unset',
                minWidth: '100%',
                opacity: 1,
              }}
            />
            <Box sx={{ pb: 1 }} />
          </>
        )}

        <Stack>
          <LabelAndValue label="Project status">
            <StatusChip
              color={statusValue.color}
              backgroundColor={statusValue.backgroundColor}
              label={statusValue.text}
              dataTestName="project__overview__status__value"
            />
          </LabelAndValue>
          {project.status_change_reason && (
            <>
              <Typography variant="label">
                Reason:{' '}
                <Typography
                  dangerouslySetInnerHTML={{ __html: project.status_change_reason }}
                  variant="labelSemiBold"
                  data-cy="project__overview__status__change_reason__value"
                />
              </Typography>
            </>
          )}
        </Stack>
        {contractual && (
          <LabelAndValue
            label="Original project completion date"
            text={dateFormatter({ date: contractual })}
            textDataTestName="project__overview__original_completion_date__value"
          />
        )}
        <Stack>
          <LabelAndValue
            label={completion.title}
            text={dateFormatter({ date: completion.value })}
            textDataTestName="project__overview__estimated_completion_date__value"
          />
          {project.estimated_completion_date_change_reason && (
            <>
              <Typography variant="label">
                Reason:{' '}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: project.estimated_completion_date_change_reason,
                  }}
                  variant="labelSemiBold"
                  data-cy="project__overview__estimated_completion_date__change_reason__value"
                />
              </Typography>
            </>
          )}
        </Stack>
        <LabelAndValue
          label={duration.title}
          text={
            duration.value === 1
              ? `${duration.value} month`
              : duration.value
                ? `${duration.value} months`
                : ''
          }
          textDataTestName="project__overview__duration__value"
        />

        {Boolean(project?.property_existing_type) && (
          <>
            <Box sx={{ py: onlyInfo ? 0.5 : 1.5 }}>
              <Divider />
            </Box>
            <LabelAndValue
              label="Project type"
              text={projectType}
              textDataTestName="project__overview__type__value"
            />
            {exitStrategy && (
              <LabelAndValue
                label="Exit strategy"
                text={exitStrategy}
                textDataTestName="project__overview__exit_strategy__value"
              />
            )}
            <LabelAndValue
              label="Project property (existing)"
              text={project.property_existing_type}
              textDataTestName="project__overview__property__value"
            />
            {project.property_proposed_type && (
              <LabelAndValue
                label="Project property (proposed)"
                text={project.property_proposed_type}
                textDataTestName="project__overview__property_proposed__value"
              />
            )}
          </>
        )}

        {isPHBProject && (
          <>
            {Boolean(modelsCount) && (
              <LabelAndValue label="Models">
                <Typography
                  variant="body3SemiBold"
                  color={colors.text.link}
                  sx={{ cursor: 'pointer' }}
                  onClick={navigateToPHBBudget}
                  data-cy="project__overview__models_count__value"
                >
                  {modelsCount}
                </Typography>
              </LabelAndValue>
            )}
            {Boolean(unitsCount) && (
              <LabelAndValue label="Units">
                <Typography
                  variant="body3SemiBold"
                  color={colors.text.link}
                  sx={{ cursor: 'pointer' }}
                  onClick={navigateToPHBBudget}
                  data-cy="project__overview__units_count__value"
                >
                  {unitsCount}
                </Typography>
              </LabelAndValue>
            )}
          </>
        )}

        {Boolean(projectProperty?.length) && !isPHBProject && (
          <>
            <Box sx={{ py: onlyInfo ? 0.5 : 1.5 }}>
              <Divider />
            </Box>
            <ProjectProperty projectProperty={projectProperty} />
          </>
        )}

        {Boolean(project.scope_of_work) && !onlyInfo && (
          <>
            <Box sx={{ py: 1.5 }}>
              <Divider />
            </Box>
            <Typography variant="body3" sx={{ color: colors.text.medium, mb: 0.5 }}>
              Scope of work
            </Typography>
            <Typography
              variant="body3"
              component="span"
              data-cy="project__overview__scope_of_work__value"
            >
              {isLongDescription
                ? getTruncateText(project?.scope_of_work, 120)
                : project?.scope_of_work}

              {isLongDescription && (
                <ScopeOfWorkLongText
                  scopeOfWork={project?.scope_of_work}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              )}
            </Typography>
          </>
        )}
      </Stack>
    </WidgetPaperCard>
  );
};
