import { useQueryClient } from 'react-query';
import { DocumentTypeEnum, EventType, QueryNamesEnums } from '@interfaces';
import { onlyProgressPhotos } from '@constants';

export const useUpdateProjectStatusInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string }) => {
    const { projectId } = params;
    if (!projectId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_INSPECTIONS_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PORTFOLIO_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_STATS);
    queryClient.invalidateQueries({
      predicate: (query) =>
        (query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
          (query.queryKey[1] as { projectId?: string })?.projectId) == projectId,
    });
  };
};

export const useReviewRequestInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; drawRequestId: string; event_type?: string }) => {
    const { projectId, drawRequestId, event_type } = params;

    if (!projectId || !drawRequestId) return;

    if (event_type !== EventType.DRAW_REQUEST_DELETED) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_REVIEWS,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_AUDIT_LOG,
        { drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
        { projectId, drawRequestId },
      ]);
    } else {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
          (query.queryKey[1] as { projectId?: string })?.projectId == projectId &&
          (query.queryKey[1] as { drawRequestId?: string })?.drawRequestId != drawRequestId,
      });
    }

    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PORTFOLIO_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMMENTS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_AUDIT_LOG, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
  };
};

export const useAssignCoordinatorInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; drawRequestId: string }) => {
    const { projectId, drawRequestId } = params;
    if (!projectId || !drawRequestId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
  };
};

export const useSwitchReviewerInvalidation = () => {
  const queryClient = useQueryClient();

  return (params: { projectId: string; drawRequestId: string }) => {
    const { projectId, drawRequestId } = params;
    if (!projectId || !drawRequestId) return;

    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }]);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
      { projectId, drawRequestId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
  };
};

const DOCUMENT_ADDED_TIMEOUT_MS = 5000;
let documentAddedCount = 0;
let documentAddedTimeout = null;
let isInvalidated = false;

export const useDocumentUploadedInvalidation = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = (params: {
    projectId: string;
    document_type: DocumentTypeEnum;
    drawRequestId?: string;
    milestoneId?: string;
    inspectionId?: string;
  }) => {
    const { projectId, document_type, drawRequestId, milestoneId, inspectionId } = params;

    const queriesToInvalidate = [];

    const addQuery = (queryKey: string, options: Record<string, unknown>) => {
      queriesToInvalidate.push([queryKey, options]);
    };

    switch (document_type) {
      case DocumentTypeEnum.PROJECT_IMPORT_TEMPLATE:
        addQuery(QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId });
        break;
      case DocumentTypeEnum.THUMB:
        addQuery(QueryNamesEnums.GET_PROJECT, { projectId });
        break;
      case DocumentTypeEnum.PROGRESS_PHOTO:
        //TODO remove GET_PROJECT_MILESTONE, GET_DRAW_REQUEST_MILESTONE, GET_INSPECTION_MILESTONES queries after removing ENG_8203_migrate_proofpoints_to_documents
        addQuery(QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId });
        if (drawRequestId) {
          addQuery(QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS, { projectId, drawRequestId });
          addQuery(QueryNamesEnums.GET_DRAW_REQUEST, { drawRequestId, projectId });
        }
        if (milestoneId) {
          addQuery(QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE, {
            drawRequestId,
            projectId,
            milestoneId,
          });
          addQuery(QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS, {
            drawRequestId,
            projectId,
            milestoneId,
            query: onlyProgressPhotos,
          });
          addQuery(QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId, milestoneId });
          addQuery(QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS, {
            projectId,
            milestoneId,
            query: onlyProgressPhotos,
          });
        }
        if (inspectionId) {
          addQuery(QueryNamesEnums.GET_INSPECTION_DOCUMENTS, {
            inspectionId,
            projectId,
            query: onlyProgressPhotos,
          });
          addQuery(QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId });
          addQuery(QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId });
        }
        break;
      default:
        break;
    }

    queriesToInvalidate.forEach(([queryKey, options]) => {
      queryClient.invalidateQueries(queryKey, {}, { ...options, cancelRefetch: true });
    });
  };

  return async (params: {
    projectId: string;
    document_type: DocumentTypeEnum;
    drawRequestId?: string;
    milestoneId?: string;
    inspectionId?: string;
  }) => {
    documentAddedCount += 1;
    if (documentAddedCount === 1 && !isInvalidated) {
      isInvalidated = true;
      invalidateQueries(params);
    } else if (documentAddedCount > 0) {
      if (documentAddedTimeout) {
        clearTimeout(documentAddedTimeout);
      }
      documentAddedTimeout = setTimeout(() => {
        invalidateQueries(params);
        documentAddedCount = 0;
        documentAddedTimeout = null;
        isInvalidated = false;
      }, DOCUMENT_ADDED_TIMEOUT_MS);
    }
  };
};
