import React, { useMemo } from 'react';
import { Box, Divider, IconButton, Popover, Stack } from '@mui/material';
import { ColumnChooserIcon } from '@svgAsComponents';
import { ComponentProps } from './interface';
import _ from 'lodash';
import ColumnCheck from './ColumnCheck';
import { columnInitScheme, getColumnByName } from '@utils';

const ColumnsFilter = ({
  columns,
  changeFieldVisibility,
  hiddenColumns,
  isUpdating,
  source,
  milestoneColumns,
}: ComponentProps) => {
  //here we handle what columns should be shown via columns filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const groupedColumns = useMemo(
    () =>
      columns
        .reduce((result: typeof columnInitScheme, column) => {
          const columnIconType = getColumnByName(milestoneColumns || [], column.name)?.icon_type;
          const columnWithError = { ...column, hasError: columnIconType === 'ERROR' };

          const existIndex = [
            ...result[1].initColumns,
            ...result[2].initColumns,
            ...result[3].initColumns,
          ].indexOf(column.name);
          switch (true) {
            case existIndex === -1:
              result[0].columns.push(columnWithError);
              break;
            case existIndex < result[1].initColumns.length:
              result[1].columns.push(columnWithError);
              break;
            case existIndex < result[1].initColumns.length + result[2].initColumns.length:
              result[2].columns.push(columnWithError);
              break;
            case existIndex <
              result[1].initColumns.length +
                result[2].initColumns.length +
                result[3].initColumns.length:
              result[3].columns.push(columnWithError);
              break;
          }
          return result;
        }, _.cloneDeep(columnInitScheme))
        .filter((itm) => !!itm.columns.length),
    [columns, milestoneColumns],
  );

  return (
    <>
      <IconButton
        aria-label="filter"
        aria-controls="fields-list"
        aria-haspopup="true"
        onClick={handleClick}
        size="large"
        data-cy={`${source}__column_filter__icon`}
      >
        <ColumnChooserIcon />
      </IconButton>
      <Popover
        id="fields-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
      >
        {groupedColumns.map((item, upperIndex) => {
          const isGroupChecked = item.columns.every((item) => !hiddenColumns.includes(item.name));
          const groupChange = () =>
            item.columns.forEach((column) => {
              if (isGroupChecked) {
                // don't hide columns with ERROR
                if (!column.hasError || hiddenColumns?.includes(column.name))
                  changeFieldVisibility(column.name);
                return;
              }
              if (hiddenColumns?.includes(column.name)) changeFieldVisibility(column.name);
            });
          return (
            <Stack key={upperIndex} sx={{ minWidth: 320 }}>
              <Stack direction="row" alignItems="stretch" spacing={2} pb={2} pt={2}>
                <Box borderRadius="0 4px 4px 0" bgcolor={item.color} width={4} />
                <Box>
                  {item.name && (
                    <ColumnCheck
                      description={item.description}
                      key={upperIndex.toString()}
                      label={item.name}
                      checked={isGroupChecked}
                      onClick={groupChange}
                      index={0}
                      disabled={isUpdating}
                      id={'_'}
                      source={'group'}
                    />
                  )}
                  {item.columns.map((column: any, index) =>
                    (typeof column.Header === 'string' && Boolean(column.Header)) ||
                    column.columnText ? (
                      <ColumnCheck
                        key={upperIndex + index.toString()}
                        id={column.id || column.name}
                        label={column.columnText || column.Header}
                        checked={!hiddenColumns?.includes(column.name || column.accessor)}
                        onClick={() => {
                          // don't hide columns with ERROR
                          if (
                            column.hasError &&
                            !hiddenColumns?.includes(column.name || column.accessor)
                          )
                            return;
                          changeFieldVisibility(column.id || column.name || column.accessor);
                        }}
                        tooltipText={
                          column.hasError ? 'Column with pending actions cannot be hidden' : null
                        }
                        disabled={isUpdating || column.name === 'name'}
                        index={index}
                        source={`${source}__column_filter__${column.name || column.accessor}`}
                      />
                    ) : null,
                  )}
                </Box>
              </Stack>

              <Divider />
            </Stack>
          );
        })}
      </Popover>
    </>
  );
};

export default ColumnsFilter;
