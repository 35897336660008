import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';

import {
  convertReactTableColumnsToMuiDataGridColumns,
  getHookState,
  getLink,
  getSortQueryString,
  getTeamRole,
  isCreatedInspection,
  isRestricted,
  tableHeaders as headers,
} from '@utils';
import {
  ColumnWidth,
  HookState,
  IInspection,
  IRightDrawerParams,
  IRightMenu,
  ITablePagination,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';
import { getInspectionsListNew } from '@globalService';
import {
  useColumnFilterV2,
  useCommentsPreview,
  useRightMenu,
  useSorting,
  useTablePagination,
} from '@hooks';

export interface ControllerInterface {
  state: HookState;
  rows: Array<Partial<IInspection>> | IInspection[];
  columns: GridColDef<Partial<IInspection>>[];
  handleSortClick: () => void;
  sortValue: string[];
  isFetching: boolean;
  isLoading: boolean;
  hiddenColumns: string[];
  isColumnFilterUpdating: boolean;
  handleRowClick: (row) => void;
  setFilterStringQuery: Dispatch<SetStateAction<string>>;
  setColumnVisibilityModel: (newModel: GridColumnVisibilityModel) => void;
  paginationProps: ITablePagination;
  rightDrawerParams: IRightDrawerParams;
  rightMenu: IRightMenu;
}

export const useServicesQueue = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const navigate = useNavigate();
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination();
  const { handleSortClick, sortValue } = useSorting();

  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const flags = useLaunchDarklyFlags();

  const { hiddenColumns, setColumnVisibilityModel, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.INSPECTION_LIST,
  );
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId: rightDrawerParams.projectId,
    drawRequestId: rightDrawerParams.requestId,
  });

  const onRightDrawerClose = useCallback(() => {
    updateCommentsPreviewInfo();
    setRightDrawerParams((old) => ({ ...old, activeTab: '' }));
  }, [updateCommentsPreviewInfo]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: onRightDrawerClose,
  });
  const updateRightDrawer = ({
    title,
    projectId,
    inspectionId,
    activeTab,
  }: {
    title: string;
    projectId: string;
    inspectionId: string;
    activeTab: string;
  }) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, inspectionId, activeTab });
  };

  const sortingString = getSortQueryString({ sortValue });
  const params = {
    pagination: 'true',
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage.toString(),
    sorting: sortingString,
  };

  const queryParams = new URLSearchParams(params);
  const queryURL = queryParams.toString() + `${filterStringQuery ? `&${filterStringQuery}` : ''}`;

  const inspectionsQuery = useQuery<{ results: IInspection[]; count: number }, Error>(
    [QueryNamesEnums.GET_INSPECTIONS_LIST, { queryURL }],
    getInspectionsListNew.bind(this, { queryURL }),
    { keepPreviousData: true },
  );
  const { data, isLoading, isFetching } = useMemo(() => inspectionsQuery, [inspectionsQuery]);

  const rows = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 10 }, (_, index) => ({ id: index.toString() }))
        : (data?.results?.filter((o) => !isCreatedInspection(o.status)) as IInspection[]),
    [isLoading, data],
  );

  const columns = useMemo(
    () =>
      convertReactTableColumnsToMuiDataGridColumns([
        headers.serviceName({
          isLoading,
        }),
        headers.serviceType({
          isLoading,
        }),
        headers.headerTemplate({
          headerName: 'Loan #',
          accessor: 'project.loan.external_id',
          isLoading,
          disableSortBy: true,
        }),
        headers.headerTemplate({
          isLoading,
          headerName: 'Project',
          accessor: 'project.name',
          disableSortBy: true,
          minWidth: ColumnWidth.WIDE_TEXT,
        }),
        headers.serviceStatus({
          isLoading,
          disableSortBy: false,
        }),
        headers.inspectionAgencyStatus({
          isLoading,
        }),
        headers.date({
          accessor: 'ordered_at',
          header: 'Date ordered',
          isLoading,
        }),
        headers.date({
          accessor: 'gc_requested_at',
          header: 'Date requested',
          isLoading,
        }),
        headers.scheduledInspectionDate({
          accessor: 'scheduled_at',
          header: 'Date scheduled',
          isLoading,
          disableSortBy: true,
        }),
        headers.date({
          accessor: 'completed_at',
          header: 'Date completed',
          isLoading,
        }),
        headers.inspectionServiceColumn({
          header: 'Agency',
          isLoading,
          teamRole,
        }),
        headers.headerTemplate({
          headerName: 'External Id',
          accessor: 'provider_order_id',
          isLoading,
          disableSortBy: true,
        }),
        headers.inspectorAllowanceColumn({
          isLoading,
        }),
        headers.headerTemplate({
          headerName: 'Address',
          accessor: 'project.address.address_1',
          isLoading,
          disableSortBy: true,
          minWidth: ColumnWidth.WIDE_TEXT,
        }),
        headers.headerTemplate({
          headerName: 'City',
          accessor: 'project.address.city',
          isLoading,
          disableSortBy: true,
        }),
        headers.state({ isLoading, accessor: 'project.address.state' }),
        headers.headerTemplate({
          headerName: 'Project type',
          accessor: 'project.type',
          isLoading,
          disableSortBy: true,
        }),
        headers.drNumber({
          isLoading,
        }),
        headers.propertyType({
          isLoading,
          disableSortBy: true,
          accessor: 'project.property_existing_type',
          header: 'Existing property type',
          minWidth: ColumnWidth.WIDE_TEXT,
        }),
        headers.propertyType({
          isLoading,
          disableSortBy: true,
          accessor: 'project.property_proposed_type',
          header: 'Proposed property type',
          minWidth: ColumnWidth.WIDE_TEXT,
        }),
        headers.projectStatus({
          isLoading,
          accessor: 'project.status',
          disableSortBy: true,
        }),
        ...(flags?.['ENG_7663_customer_in_inspections_list'] &&
        !isRestricted(PermissionNamesEnums.REQUEST_QUEUE__CUSTOMER__VIEW, permissions)
          ? [
              headers.headerTemplate({
                headerName: 'Customer',
                accessor: 'customer.name',
                isLoading,
                disableSortBy: true,
              }),
            ]
          : []),
        headers.headerTemplate({
          headerName: 'Borrower',
          accessor: 'project.borrower_name',
          isLoading,
          disableSortBy: true,
        }),
        headers.amount({
          header: 'Loan commitment',
          accessor: 'project.loan.loc_commitment',
          isLoading,
          disableSortBy: true,
        }),
        headers.amount({
          header: 'Original scheduled value ($)',
          accessor: 'project.original_estimate',
          isLoading,
          disableSortBy: true,
        }),
        headers.amount({
          header: 'Revised scheduled value ($)',
          accessor: 'project.revised_estimate',
          isLoading,
          minWidth: ColumnWidth.WIDE_TEXT,
          disableSortBy: true,
        }),
        ...(!isRestricted(PermissionNamesEnums.REQUEST_QUEUE__SCORES__VIEW, permissions)
          ? [
              headers.projectScore({
                headerName: 'Project health',
                accessor: 'project.overall_score',
                isLoading,
                disableSortBy: true,
              }),
            ]
          : []),
        headers.inspectionActions({
          isLoading,
          source: 'services_queue__table__body__actions',
        }),
        headers.inspectionDocumentsAndComments({
          isLoading,
          updateRightDrawer,
          source: 'services_queue__table__body',
        }),
      ]),
    [isLoading, hiddenColumns, permissions, teamRole, flags],
  );

  const handleRowClick = (row) => {
    const link = getLink({ row, tableKey: TableKeyEnum.INSPECTION_LIST });
    link && navigate(link);
  };

  const itemsCount = useMemo(() => data?.count, [data]);

  return {
    state: getHookState(inspectionsQuery),
    rows,
    columns,
    handleSortClick,
    sortValue,
    isFetching,
    isLoading,
    hiddenColumns,
    isColumnFilterUpdating,
    handleRowClick,
    setFilterStringQuery,
    setColumnVisibilityModel,
    paginationProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onPageChange,
      onRowsPerPageChange,
      itemsCount,
    },
    rightDrawerParams,
    rightMenu,
  };
};
