import { useGetData } from '@context';
import { QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router';

export const useInfoCredit = ({ requestId, milestoneId }) => {
  const { projectId } = useParams();

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: ['requested_credit_amount', 'approved_credit_amount', 'credit_reason'],
    args: { projectId, drawRequestId: requestId, milestoneId },
  });

  return {
    name: project.data.name,
    milestone,
  };
};
