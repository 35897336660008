import React, { useContext } from 'react';
import { Typography, Stack } from '@mui/material';

import {
  currencyFormatter,
  getTextOrDash,
  getValueColor,
  getStatusChip,
  isRequestDraft,
} from '@utils';
import { IDrawRequest, ValueTypeEnum } from '@interfaces';
import { LabelAndValueWithPreload, ProgressWithArrow, LabelAndValue } from '@components';
import * as Controller from '../controller';
import { WarningIcon } from '@svgAsComponents';
import { AuthContext } from '@context';
import { typography } from '@theme';

export const RemainingReallocationBalanceWithProgress = ({
  controller,
  valueErrorText,
}: {
  controller: Controller.ControllerInterface;
  valueErrorText: string;
}) => {
  const { requested_budget_change, approved_budget_change } =
    controller.drawRequest?.totals?.all || {};
  const { isApproveReallocateComplete, isRequestReallocateComplete } = controller;

  return (
    <>
      {controller.localIsUserCreator && (
        <LabelAndValueWithPreload
          color={getValueColor({ isError: !isRequestReallocateComplete })}
          valueIcon={!isRequestReallocateComplete ? <WarningIcon size={14} /> : null}
          valueIconTooltip={valueErrorText}
          isLoading={controller.isUpdating}
          label="Borrower reallocation balance"
          text={currencyFormatter(requested_budget_change, '-')}
          textDataTestName="requests_tab__summary__borrower_reallocation__value"
        />
      )}
      <LabelAndValueWithPreload
        color={getValueColor({ isError: !isApproveReallocateComplete })}
        valueIcon={!isApproveReallocateComplete ? <WarningIcon size={14} /> : null}
        valueIconTooltip={valueErrorText}
        isLoading={controller.isUpdating}
        label={
          controller.localIsUserCreator ? 'Lender reallocation balance' : 'Reallocation balance'
        }
        text={currencyFormatter(approved_budget_change, '-')}
        textDataTestName="requests_tab__summary__lender_reallocation__value"
      />
    </>
  );
};

export const RetainageBalanceToDate = ({ isUpdating, totals, retainageRate }) => {
  return (
    <LabelAndValueWithPreload
      isLoading={isUpdating}
      label={`Retainage balance to date ${retainageRate ? `(${retainageRate}%)` : ''}`}
    >
      <ProgressWithArrow
        showProgress
        prevValue={getTextOrDash(totals?.previous_retainage_balance_to_date)}
        nextValue={getTextOrDash(totals?.retainage_balance_to_date)}
        nextValueType={
          totals?.retainage_balance_to_date < 0
            ? { [ValueTypeEnum.IS_ERROR]: true }
            : { [ValueTypeEnum.IS_IN_REVIEW]: true }
        }
        prevValueDataTestName="requests_tab__summary__retainage_balance__value"
        nextValueDataTestName="requests_tab__summary__retainage_balance__nextValue"
      />
    </LabelAndValueWithPreload>
  );
};

export const ApprovedToDate = ({ isUpdating, totals, isInReview, outOfBudgetError }) => (
  <LabelAndValueWithPreload
    isLoading={isUpdating}
    label="Approved to date"
    valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
  >
    <ProgressWithArrow
      showProgress
      prevValue={getTextOrDash(
        totals?.previous_approved_amount_cumulative,
        totals?.previous_lender_allowance_rate,
      )}
      nextValue={getTextOrDash(
        totals?.approved_amount_cumulative - totals?.approved_budget_change,
        totals?.lender_allowance_rate - totals?.approved_adjustments_rate,
      )}
      nextValueType={
        isInReview
          ? totals?.requested_budget_change || outOfBudgetError
            ? { [ValueTypeEnum.IS_ERROR]: true }
            : { [ValueTypeEnum.IS_IN_REVIEW]: true }
          : null
      }
      prevValueDataTestName="requests_tab__summary__approved_to_date__value"
      nextValueDataTestName="requests_tab__summary__approved_to_date__nextValue"
    />
  </LabelAndValueWithPreload>
);

export const BalanceToFinish = ({ isUpdating, totals, isInReview, outOfBudgetError }) => (
  <LabelAndValueWithPreload
    color={getValueColor({ isInReview })}
    isLoading={isUpdating}
    label="Balance to finish"
    valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
  >
    <ProgressWithArrow
      showProgress
      prevValue={getTextOrDash(
        totals?.balance_to_finish + (totals?.approved_amount - totals?.approved_budget_change),
        totals?.balance_to_finish_rate +
          (totals?.approved_amount_relative - totals?.approved_adjustments_rate),
      )}
      nextValue={getTextOrDash(totals?.balance_to_finish, totals?.balance_to_finish_rate)}
      nextValueType={
        isInReview
          ? totals?.requested_budget_change || outOfBudgetError
            ? { [ValueTypeEnum.IS_ERROR]: true }
            : { [ValueTypeEnum.IS_IN_REVIEW]: true }
          : null
      }
      prevValueDataTestName="requests_tab__summary__balance_to_finish__value"
      nextValueDataTestName="requests_tab__summary__balance_to_finish__nextValue"
    />
  </LabelAndValueWithPreload>
);

export const CreatedBy = ({ team, submitted_by, isUpdating }) => {
  const { user } = useContext(AuthContext);
  const companyOrTeam =
    user?.active_team?.company?.id === submitted_by?.company?.id
      ? team?.name || submitted_by?.company?.name
      : submitted_by?.company?.name;
  return (
    <LabelAndValueWithPreload
      isLoading={isUpdating}
      label="Created by"
      text={`${submitted_by?.full_name} / ${companyOrTeam}`}
    ></LabelAndValueWithPreload>
  );
};

export const RequestStatusWithReason = ({
  drawRequest,
  source,
}: {
  drawRequest: IDrawRequest;
  source?: string;
}) => {
  const reasonAndNotes = drawRequest?.is_resubmit_change_reason?.split('<br/>Notes:');
  return (
    <Stack spacing={0.1}>
      <LabelAndValue label="Draw status">{getStatusChip(drawRequest)}</LabelAndValue>
      {drawRequest?.is_resubmit_change_reason && isRequestDraft(drawRequest?.status) && (
        <>
          <LabelAndValue
            label="Reason: "
            text={reasonAndNotes[0]}
            justifyContent="flex-start"
            marginRightAfterLabel={false}
            labelStyle={{ ...typography.label }}
            textStyle={{ ...typography.labelSemiBold }}
            textDataTestName={`${source}__reason__value`}
          />
          {reasonAndNotes[1] && (
            <Typography variant="label">
              Notes:{' '}
              <Typography
                dangerouslySetInnerHTML={{ __html: reasonAndNotes[1] }}
                variant="labelSemiBold"
                data-cy={`${source}__reason__notes`}
              />
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
};
