import { IUISettings } from '@interfaces';
import { uiSettings } from '../paths';
import { dataProvider } from '@api';

export const getUISettings = async (): Promise<IUISettings> =>
  dataProvider.get(uiSettings()).json();

export const updateUiSettings = async (settings: Partial<IUISettings>): Promise<IUISettings> =>
  dataProvider
    .patch(uiSettings(), {
      json: settings,
    })
    .json();
