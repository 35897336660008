import { SyntheticEvent, useMemo, useState, useContext, useEffect } from 'react';
import { useParams, useNavigate, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
  IProject,
  QueryNamesEnums,
  IDrawRequest,
  IInspection,
  PermissionNamesEnums,
} from '@interfaces';
import {
  isRestricted,
  getActiveDrawRequestFromList,
  getCurrentInspection,
  isCancelRequestedInspection,
} from '@utils';
import { PermissionsContext, useGetData } from '@context';
import { getProjectDrawRequestsList, getProjectInspectionsList } from '@globalService';
import { TOOLTIP_TEXTS } from '@constants';

import inspectionIcon from 'encirclelabs-assets/services/inspection.svg';
import titleIcon from 'encirclelabs-assets/services/title.svg';
import appraisalIcon from 'encirclelabs-assets/services/appraisal.svg';
import feasibilityIcon from 'encirclelabs-assets/services/feasibility.svg';

export const TABS = {
  INSPECTION: { label: 'Inspection', value: 'inspection', icon: inspectionIcon },
  TITLE: { label: 'Title', value: 'title', icon: titleIcon },
  APPRAISAL: { label: 'Appraisal', value: 'appraisal', icon: appraisalIcon },
  FEASIBILITY: { label: 'Feasibility', value: 'feasibility', icon: feasibilityIcon },
};

interface ControllerInterface {
  handleTabChange: (event: SyntheticEvent<Element, Event>, value: string) => void;
  activeTab: string;
  SwitcherTabs: {
    label: string;
    value: string;
    isActive: boolean;
    icon: string;
    disabled?: boolean;
    disabledTooltipText?: string;
  }[];
  TABS: typeof TABS;
  goBack: () => void;
  project: Partial<IProject>;
  drawRequestInProgress: IDrawRequest;
}

export const useOrderService = (): ControllerInterface => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const match = matchPath('/projects/:projectId/order-service/:tab', pathname);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: !isRestricted(PermissionNamesEnums.PROJECT__INSPECTION, permissions),
    },
  );

  const drawRequestInProgress = useMemo(
    () => getActiveDrawRequestFromList(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const inProgressInspection = useMemo(
    () => getCurrentInspection(projectInspectionsQuery.data?.results),
    [projectInspectionsQuery.data?.results],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedInspection(inProgressInspection?.status),
    [inProgressInspection],
  );
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'status_change_reason'],
    args: { projectId },
  });

  const handleTabChange = (_event: SyntheticEvent<Element, Event>, value: string | number) => {
    if (value) setActiveTab(value.toString());
  };
  const [activeTab, setActiveTab] = useState<string>(null);

  useEffect(() => {
    setActiveTab(match?.params?.tab);
  }, [match]);

  const SwitcherTabs = useMemo(
    () => [
      ...(!isRestricted(PermissionNamesEnums.PROJECT__INSPECTION__CREATE, permissions)
        ? [
            {
              label: `${TABS.INSPECTION.label}`,
              value: TABS.INSPECTION.value,
              isActive: activeTab === TABS.INSPECTION.value,
              icon: TABS.INSPECTION.icon,
              disabled: Boolean(inProgressInspection),
              disabledTooltipText: isInspectionCancelRequested
                ? TOOLTIP_TEXTS.inspectionCancelRequested
                : TOOLTIP_TEXTS.inspectionOrdered,
            },
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.PROJECT__INSPECTION__CREATE, permissions) // TODO for future it'll be service permission
        ? [
            {
              label: `${TABS.TITLE.label}`,
              value: TABS.TITLE.value,
              isActive: activeTab === TABS.TITLE.value,
              icon: TABS.TITLE.icon,
            },
            {
              label: `${TABS.APPRAISAL.label}`,
              value: TABS.APPRAISAL.value,
              isActive: activeTab === TABS.APPRAISAL.value,
              icon: TABS.APPRAISAL.icon,
            },
            {
              label: `${TABS.FEASIBILITY.label}`,
              value: TABS.FEASIBILITY.value,
              isActive: activeTab === TABS.FEASIBILITY.value,
              icon: TABS.FEASIBILITY.icon,
            },
          ]
        : []),
    ],
    [activeTab, permissions, inProgressInspection, isInspectionCancelRequested],
  );

  return {
    handleTabChange,
    activeTab,
    SwitcherTabs,
    TABS,
    goBack: () => navigate(`/projects/${projectId}/overview/`), // TODO check if go -1 exists go to it, if no to overview
    project: project.data,
    drawRequestInProgress,
  };
};
