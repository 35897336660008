import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { colors } from '@theme';
import {
  CSVUploader,
  ProjectCardsList,
  ProjectsV2,
  SearchBox,
  StyledBox,
  TablePaginationNew,
  TabSwitcher,
} from '@components';
import { Link } from 'react-router-dom';
import { useProjects } from './controller';
import { CSVBoxSheetsEnum, PermissionNamesEnums } from '@interfaces';
import { WithPermission } from '@utils';
import { useLaunchDarklyFlags } from '@context';

const Projects = () => {
  const {
    handleTabChange,
    tab,
    SwitcherTabs,
    search,
    handleSearchSubmit,
    clearSearch,
    activeTabProjectsCount,
    tablePagination,
    url,
    noProjectsText,
  } = useProjects();
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    tablePagination;
  const flags = useLaunchDarklyFlags();
  if (flags?.['ENG_7990_new_projects_page']) return <ProjectsV2 />;

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flexGrow: 1,
          p: { md: 3, xs: 2 },
        }}
      >
        <StyledBox
          sx={{
            flexGrow: 1,
            p: { sm: 2, xs: 0 },
            backgroundColor: { xs: colors.background.gray, sm: colors.background.white },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item sx={{ mb: 2 }}>
              <Stack direction="row" spacing={1}>
                <SearchBox
                  search={search}
                  onSubmit={handleSearchSubmit}
                  clearSearch={clearSearch}
                  height="32px"
                />
                <TabSwitcher
                  tabs={SwitcherTabs}
                  onTabChange={handleTabChange}
                  activeTab={tab}
                  tabStyle={{ padding: 0, marginTop: 0 }}
                  isSmall
                  source="projects__status_switcher"
                />
              </Stack>
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              sx={{
                pt: 0.5,
                '& a': { textDecoration: 'none' },
              }}
            >
              <TablePaginationNew
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={activeTabProjectsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
              <WithPermission permissionKey={PermissionNamesEnums.PROJECT__CREATE__BUTTON}>
                <Stack direction="row" spacing={2} ml={5}>
                  <CSVUploader
                    buttonTitle="Bulk budget upload"
                    sheetKey={CSVBoxSheetsEnum.IMPORT_BUDGET_BULK}
                  />
                  <CSVUploader
                    buttonTitle="Upload project"
                    sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT}
                  />
                  <Link to="/projects/add-new">
                    <Button data-cy="add_new_project_button" size="small">
                      Add project
                    </Button>
                  </Link>
                </Stack>
              </WithPermission>
            </Stack>
          </Grid>
          <ProjectCardsList url={url} noProjectsText={noProjectsText} />
        </StyledBox>
      </Stack>
    </Stack>
  );
};

export default Projects;
