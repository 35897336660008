import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import {
  currencyFormatter,
  getSortedByIndexMulestones,
  percentFormatter,
  tableHeaders as headers,
  getGroupFromMilestones,
} from '@utils';
import { ReactTable } from '@components';
import { ControllerInterface } from '../controller';
import { useLaunchDarklyFlags } from '@context';

export const LineItems: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const flags = useLaunchDarklyFlags();
  const { drawRequestData, milestones } = controller;
  const sortedMilestones = getSortedByIndexMulestones(milestones);
  const groups = getGroupFromMilestones(milestones);

  const columns = useMemo(
    () => [
      headers.simpleText({
        header: 'Line items',
        secondLine: 'external_id',
        accessor: 'name',
        FooterStatic: () => <></>,
      }),
      headers.amount({
        header: 'Revised scheduled value',
        accessor: 'revised_estimate',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
        CustomFooter: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.revised_estimate, '-')}
            </Typography>
          </Stack>
        ),
      }),
      headers.amountAndPrecents({
        header: 'Requested draw',
        accessor: 'requested_amount',
        precentAccessor: 'requested_amount_relative',
        FooterStatic: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.requested_amount, '-')}
            </Typography>
            <Typography variant="printBody1SemiBold">
              {percentFormatter({
                value: drawRequestData.totals.all.requested_amount_relative,
                roundTo: 0,
              })}
            </Typography>
          </Stack>
        ),
      }),
      headers.amountAndPrecents({
        header: 'Inspection recommen...',
        accessor: 'inspector_allowance_incremental',
        precentAccessor: 'inspector_allowance_rate_incremental',
        FooterStatic: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.inspector_allowance_incremental, '-')}
            </Typography>
            <Typography variant="printBody1SemiBold">
              {percentFormatter({
                value: drawRequestData.totals.all.inspector_allowance_rate_incremental,
                roundTo: 0,
              })}
            </Typography>
          </Stack>
        ),
      }),
      headers.amountAndPrecents({
        header: 'Inspection allowance',
        accessor: 'inspector_allowance',
        precentAccessor: 'inspector_allowance_rate',
        FooterStatic: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.inspector_allowance, '-')}
            </Typography>
            <Typography variant="printBody1SemiBold">
              {percentFormatter({
                value: drawRequestData.totals.all.inspector_allowance_rate,
                roundTo: 0,
              })}
            </Typography>
          </Stack>
        ),
      }),
      headers.amountAndPrecents({
        header: 'Lender allowance',
        accessor: 'approved_amount_cumulative',
        precentAccessor: 'lender_allowance_rate',
        FooterStatic: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.approved_amount_cumulative, '-')}
            </Typography>
            <Typography variant="printBody1SemiBold">
              {percentFormatter({
                value: drawRequestData.totals.all.lender_allowance_rate,
                roundTo: 0,
              })}
            </Typography>
          </Stack>
        ),
      }),
      headers.amountAndPrecents({
        header: 'Approved for this draw',
        accessor: 'approved_amount',
        precentAccessor: 'approved_amount_relative',
        FooterStatic: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.approved_amount, '-')}
            </Typography>
            <Typography variant="printBody1SemiBold">
              {percentFormatter({
                value: drawRequestData.totals.all.approved_amount_relative,
                roundTo: 0,
              })}
            </Typography>
          </Stack>
        ),
      }),
      headers.amountAndPrecents({
        header: 'Variance to lender allowance',
        accessor: 'variance_to_lender_allowance',
        precentAccessor: 'variance_to_lender_allowance_rate',
        FooterStatic: () => (
          <Stack alignItems="flex-end">
            <Typography variant="printBody1SemiBold">
              {currencyFormatter(drawRequestData.totals.all.variance_to_lender_allowance, '-')}
            </Typography>
            <Typography variant="printBody1SemiBold">
              {percentFormatter({
                value: drawRequestData.totals.all.variance_to_lender_allowance_rate,
                roundTo: 0,
              })}
            </Typography>
          </Stack>
        ),
      }),
    ],
    [],
  );
  if (!sortedMilestones.length) return null;
  return sortedMilestones.length ? (
    <Box sx={{ breakInside: 'avoid' }}>
      <Typography variant="printH2" pr="15px" pl="15px" data-cy="report_line_items_section_title">
        Line items
      </Typography>
      <ReactTable data={sortedMilestones} columns={columns} footer={true} source="report" />
      {flags?.['ENG_7916_models_in_report'] && groups.length ? (
        <>
          <Typography
            variant="printH2"
            pr="15px"
            pl="15px"
            data-cy="report_line_items_section_title"
          >
            Models / units
          </Typography>
          <ReactTable data={groups} columns={columns} footer={true} source="report" />
        </>
      ) : null}
    </Box>
  ) : null;
};
