import { useQueries } from 'react-query';
import { useContext, useMemo } from 'react';
import {
  ICompany,
  IState,
  PermissionNamesEnums,
  QueryNamesEnums,
  ScoreColorEnum,
} from '@interfaces';
import {
  getCompanies,
  getCompaniesListByRole,
  getProjectPropertyTypes,
  getProjectTypes,
  getStates,
} from '@globalService';
import { TEAM_ROLES, ProjectScoresDisplayValues } from '@constants';
import { isRestricted } from '@utils';
import { PermissionsContext } from '@context';

export interface ControllerInterface {
  borrowerCompanies: ICompany[];
  customerCompanies: ICompany[];
  investorCompanies: ICompany[];
  lenderCompanies: ICompany[];
  projectPropertyTypes: { type: string; name: string }[];
  projectTypes: { type: string; name: string }[];
  states: IState[];
  scoresValues: { value: ScoreColorEnum; name: string }[];
}

export const useProjectFilters = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_COMPANIES_BY_ROLE, { role: TEAM_ROLES.Owner }],
      queryFn: getCompaniesListByRole.bind(this, TEAM_ROLES.Owner),
    },
    {
      queryKey: [QueryNamesEnums.GET_COMPANIES_BY_ROLE, { role: TEAM_ROLES.Lender }],
      queryFn: getCompaniesListByRole.bind(this, TEAM_ROLES.Lender),
    },
    {
      queryKey: [QueryNamesEnums.GET_COMPANIES_BY_ROLE, { role: TEAM_ROLES.Investor }],
      queryFn: getCompaniesListByRole.bind(this, TEAM_ROLES.Investor),
    },
    {
      queryKey: [QueryNamesEnums.GET_COMPANIES, { query: '?is_customer=true&sorting=name' }],
      queryFn: getCompanies.bind(this, '?is_customer=true&sorting=name'),
      enabled: !isRestricted(PermissionNamesEnums.COMPANIES__LIST, permissions),
    },
    { queryKey: [QueryNamesEnums.GET_STATES], queryFn: getStates.bind(this) },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TYPES, { query: '&has_current_customer=true' }],
      queryFn: getProjectTypes.bind(this, '&has_current_customer=true'),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES,
        { query: '&has_current_customer=true' },
      ],
      queryFn: getProjectPropertyTypes.bind(this, '&has_current_customer=true'),
    },
  ]);

  const borrowerCompaniesQuery = requestedDataQueries[0].data;
  const lenderCompaniesQuery = requestedDataQueries[1].data;
  const investorCompaniesQuery = requestedDataQueries[2].data;
  const customerCompaniesQuery = requestedDataQueries[3].data;
  const statesQuery = requestedDataQueries[4].data;
  const projectTypesQuery = requestedDataQueries[5].data;
  const projectPropertyTypesQuery = requestedDataQueries[6].data;

  const projectTypes = useMemo(() => {
    if (projectTypesQuery?.results?.length)
      return projectTypesQuery.results.map((item) => ({
        type: item.name,
        name: item.name_display,
      }));
    return [];
  }, [projectTypesQuery]);

  const projectPropertyTypes = useMemo(() => {
    if (projectPropertyTypesQuery?.results?.length)
      return projectPropertyTypesQuery.results.map((item) => ({
        type: item.name,
        name: item.name_display,
      }));
    return [];
  }, [projectPropertyTypesQuery]);

  return {
    borrowerCompanies: borrowerCompaniesQuery?.results || [],
    customerCompanies: customerCompaniesQuery?.results || [],
    investorCompanies: investorCompaniesQuery?.results || [],
    lenderCompanies: lenderCompaniesQuery?.results || [],
    projectPropertyTypes,
    projectTypes,
    states: statesQuery || [],
    scoresValues: ProjectScoresDisplayValues,
  };
};
