import { useQuery } from 'react-query';
import {
  HookState,
  IDrawRequest,
  IPieChartData,
  IProjectProgress,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequest, getProjectProgress } from '@globalService';
import { useParams } from 'react-router-dom';
import { calculateFraction, getHookState, percentFormatter } from '@utils';
import { useCallback, useMemo } from 'react';
import { ProgressParams } from './ProjectGraphsController';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';

export interface ControllerInterface {
  state: HookState;
  getRequestProgressItems: () => ProgressParams;
  getRequestOriginalValuePieChartData: () => IPieChartData[];
}

const defaultProgressItems = {
  currentScheduledValue: { after_value: 0, before_value: 0, total: 0 },
  originalScheduledValue: { after_value: 0, before_value: 0, total: 0 },
  constructionHoldback: { after_value: 0, before_value: 0, total: 0 },
  originalBorrowerEquity: { after_value: 0, before_value: 0, total: 0 },
  additionalBorrowerEquity: { after_value: 0, before_value: 0, total: 0 },
};

export const useRequestPaymentGraph = (): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();
  const flags = useLaunchDarklyFlags();

  const progressQuery = useQuery<IProjectProgress, Error>(
    [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
    getProjectProgress.bind(this, projectId),
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );
  const drawRequest = useMemo(() => drawRequestQuery.data, [drawRequestQuery.data]);
  const projectProgress = useMemo(() => progressQuery.data, [progressQuery.data]);

  const createProgressItem = (after_value: number, before_value: number, total: number) => ({
    after_value,
    before_value,
    total,
    after: calculateFraction(after_value, total),
    before: calculateFraction(before_value, total),
  });

  const getRequestProgressItems = useCallback(() => {
    if (!drawRequest) return defaultProgressItems;

    const {
      current_construction_holdback_cumulative,
      current_original_borrower_equity_cumulative,
      current_additional_borrower_equity_used_cumulative,
      previous_construction_holdback_cumulative,
      previous_original_borrower_equity_cumulative,
      previous_additional_borrower_equity_used_cumulative,
    } = drawRequest;

    return {
      currentScheduledValue: createProgressItem(
        current_construction_holdback_cumulative +
          current_original_borrower_equity_cumulative +
          current_additional_borrower_equity_used_cumulative,
        previous_construction_holdback_cumulative +
          previous_original_borrower_equity_cumulative +
          previous_additional_borrower_equity_used_cumulative,
        projectProgress?.total_construction_budget?.total,
      ),
      originalScheduledValue: createProgressItem(
        current_construction_holdback_cumulative + current_original_borrower_equity_cumulative,
        previous_construction_holdback_cumulative + previous_original_borrower_equity_cumulative,
        projectProgress?.total_original_construction_budget?.total,
      ),
      constructionHoldback: createProgressItem(
        current_construction_holdback_cumulative,
        previous_construction_holdback_cumulative,
        projectProgress?.construction_holdback?.total,
      ),
      originalBorrowerEquity: createProgressItem(
        current_original_borrower_equity_cumulative,
        previous_original_borrower_equity_cumulative,
        projectProgress?.original_borrower_equity?.total,
      ),
      additionalBorrowerEquity: createProgressItem(
        current_additional_borrower_equity_used_cumulative,
        previous_additional_borrower_equity_used_cumulative,
        projectProgress?.additional_borrower_equity_used?.total,
      ),
    };
  }, [drawRequest, projectProgress]);

  const revisedValueTotal = useMemo(
    () => drawRequest?.construction_holdback + drawRequest?.borrower_equity || 0,
    [drawRequest?.construction_holdback, drawRequest?.borrower_equity],
  );

  const getRequestOriginalValuePieChartData = useCallback(
    () => [
      {
        label: `Construction holdback (${percentFormatter({
          value: calculateFraction(drawRequest?.construction_holdback, revisedValueTotal),
        })})`,
        color: colors.status.information.medium,
        value: drawRequest?.construction_holdback,
      },
      {
        label: `Original borrower equity (${percentFormatter({
          value: calculateFraction(
            drawRequest?.borrower_equity - +drawRequest?.additional_borrower_equity_used,
            revisedValueTotal,
          ),
        })})`,
        color: colors.status.orange.medium,
        value: drawRequest?.borrower_equity - +drawRequest?.additional_borrower_equity_used,
      },
      ...(flags?.['ENG_7417_show_additional_equity_in_new_payment_tab']
        ? [
            {
              label: `Additional borrower equity (${percentFormatter({
                value: calculateFraction(
                  drawRequest?.additional_borrower_equity_used,
                  revisedValueTotal,
                ),
              })})`,
              color: colors.status.violet.medium,
              value: drawRequest?.additional_borrower_equity_used,
            },
          ]
        : []),
    ],
    [
      revisedValueTotal,
      drawRequest?.construction_holdback,
      drawRequest?.borrower_equity,
      drawRequest?.additional_borrower_equity_used,
      flags,
    ],
  );

  return {
    state: getHookState([progressQuery, drawRequestQuery]),
    getRequestProgressItems,
    getRequestOriginalValuePieChartData,
  };
};
