import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  emailAndPasswordValidationSchema,
  getErrorText,
  getProtectedPassword,
  initialValues,
} from '@utils';
import { ThirdPartyLoginButtons, ForgotPasswordLink, MFAModal } from '../components';
import { LoginWireframe, InputField } from '@components';
import * as Controller from './controller';
import { ErrorDual } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';
interface CustomizedState {
  fromResetPassword: boolean;
  from: boolean;
}

const SimpleLogin = () => {
  const { handleLogin, requestMfa, isRequestingMfa } = Controller.useSimpleLogin();
  const flags = useLaunchDarklyFlags();

  const location = useLocation();
  const state = location?.state as CustomizedState;

  // fromResetPassword flag is used to show different titles for login page
  // if user was redirected here from reset password screen
  const [fromResetPassword, setFromResetPassword] = useState(state?.fromResetPassword);

  const [openMfaModal, setOpenMfaModal] = useState(false);
  const [isSendingMfaCode, setIsSendingMfaCode] = useState(false);

  const logout = () => {
    setOpenMfaModal(false);
    formik.setFieldValue('password', '');
    formik.setFieldValue('email', '');
    formik.setFieldError('email', '');
  };

  const handleResendMfaRequest = async () => {
    if (!isSendingMfaCode) {
      await requestMfa({
        email: formik.values.email.trim(),
        password: formik.values.password.trim(),
      });
      setIsSendingMfaCode(true);
      setTimeout(() => {
        setIsSendingMfaCode(false);
      }, 60000);
    }
  };

  const sendMfaCode = async (code: string, rememberMe: boolean) => {
    try {
      await handleLogin({
        email: formik.values.email.trim(),
        password: formik.values.password.trim(),
        mfa_code: code,
        remember_this_device_for_mfa: rememberMe,
      });
    } catch (err) {
      formik.setFieldValue('password', '');
      setOpenMfaModal(false);
      setIsSendingMfaCode(false);
    }
  };

  const isMFAEnabled = async (values: { email: string; password: string }) => {
    try {
      const response = await requestMfa({
        email: values.email.trim(),
        password: values.password.trim(),
      });
      return response;
    } catch (err) {
      setOpenMfaModal(false);
      setIsSendingMfaCode(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: emailAndPasswordValidationSchema,
    onSubmit: async (values) => {
      try {
        if (!flags?.['ENG_7918_ask_mfa_on_login']) {
          await handleLogin({ email: values.email.trim(), password: values.password.trim() });
          return;
        }
        setIsSendingMfaCode(true);
        setTimeout(() => {
          setIsSendingMfaCode(false);
        }, 60000);
        const response = await isMFAEnabled(values);
        if (!response) return;

        if (!response.is_mfa_enabled) {
          await handleLogin({ email: values.email.trim(), password: values.password.trim() });
        } else {
          setOpenMfaModal(true);
        }
      } catch (err) {
        // switch to simple login screen if fromResetPassword and error
        if (fromResetPassword) setFromResetPassword(false);
        await formik.setFieldValue('password', '');
        await formik.setFieldError('password', getErrorText(err as ErrorDual));
      }
    },
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      document.getElementById('root').scrollIntoView(true);
    }
  }, [formik]);

  const getTitle = useMemo(() => {
    if (fromResetPassword)
      return (
        <>
          <Typography variant="h1">Success!</Typography>
          <Typography variant="body2" sx={{ mt: '16px' }}>
            Your password has been changed.
          </Typography>
          <Typography variant="body2">Login with your email to access your account.</Typography>
        </>
      );
    if (!state?.from) return <Typography variant="h1">Hi, welcome back!</Typography>;
    return null;
  }, [fromResetPassword]);

  const getFormContent = () => (
    <>
      <InputField
        data-cy="email"
        name="email"
        label="Email"
        type="email"
        variant="outlined"
        size="small"
        disabled={formik.isSubmitting}
      />
      <InputField
        data-cy="password"
        type="password"
        name="password"
        label="Password"
        variant="outlined"
        mt="24px"
        size="small"
        disabled={formik.isSubmitting}
        {...getProtectedPassword()}
      />
      <ForgotPasswordLink />
    </>
  );

  const getFormButtons = useMemo(() => {
    return (
      <>
        <LoadingButton
          data-cy="submit"
          fullWidth
          type="submit"
          loading={formik.isSubmitting || isRequestingMfa}
        >
          Log in
        </LoadingButton>

        <ThirdPartyLoginButtons />
      </>
    );
  }, [formik.isSubmitting]);

  return (
    <>
      <LoginWireframe
        formik={formik}
        getTitle={getTitle}
        getFormContent={getFormContent()}
        getFormButtons={getFormButtons}
      />
      <MFAModal
        open={openMfaModal}
        logout={logout}
        handleResendMfaRequest={handleResendMfaRequest}
        sendMfaCode={sendMfaCode}
        isSendingMfaCode={isSendingMfaCode}
      />
    </>
  );
};

export default React.memo(SimpleLogin);
