// TODO remove after ENG_8420_simplify_filters_remove_awaiting

import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import { getDrawRequestListStats } from '@globalService';
import { IDrawRequestsStats, QueryNamesEnums, DrawRequestStatus } from '@interfaces';

export interface ControllerInterface {
  stats: IDrawRequestsStats;
}

const keysToRemove = [DrawRequestStatus.IN_REVIEW];
const filterStatuses = (
  statuses: IDrawRequestsStats['statuses'],
  keysToRemove: string[],
): IDrawRequestsStats['statuses'] => {
  if (isEmpty(statuses)) return {};
  return Object.keys(statuses)
    .filter((key) => !keysToRemove.includes(key))
    .reduce(
      (obj, key) => {
        obj[key] = statuses[key];
        return obj;
      },
      {} as IDrawRequestsStats['statuses'],
    );
};

export const useRequestsSummaryBlock = (): ControllerInterface => {
  const stats = useQuery<IDrawRequestsStats, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST_STATS],
    getDrawRequestListStats.bind(this, ''),
  );

  return {
    stats: { statuses: filterStatuses(stats.data?.statuses, keysToRemove) },
  };
};
