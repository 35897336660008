import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnType, CommonRowType } from './common';
import { TableContext } from '../controller';
import { LineItemEditIconWithIndicator } from '@svgAsComponents';
import { ColumnV2Width, RequestTableTabTypesEnum, RequestTableTabsEnum } from '@interfaces';
import { checkLineItemHasCreditOrABE, creditAvalibleForMilestone } from '@utils';

const LineItemDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source, updateRightDrawer } = useContext(TableContext);
  const creditAvalible = creditAvalibleForMilestone(row);
  const lineItemHasCreditOrABE = Boolean(checkLineItemHasCreditOrABE(row));

  return (
    <IconButton
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.BREAKDOWN,
              type: RequestTableTabTypesEnum.SUBMIT,
              creditAvalible,
            })
          : row?.updateRightDrawer()
      }
      data-cy={`${source}__body__line_item_details__button__icon__index_${row.project_milestone?.index}`}
    >
      <LineItemEditIconWithIndicator hasValues={lineItemHasCreditOrABE} />
    </IconButton>
  );
};

const Memoized = memo(LineItemDetails);

const submitLineItemDetails: MilestoneListColumnType = {
  name: 'additional_borrower_equity',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default submitLineItemDetails;
