import { useCallback, useContext, useMemo, useState } from 'react';
import JSZip from 'jszip';
import { matchPath, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  checkIsInvestor,
  checkIsLender,
  getHookState,
  getTeamRole,
  tableHeaders as headers,
} from '@utils';
import {
  excludeCommentsQueryFields,
  excludeCommentsWithTotalsAllQueryFields,
  LineItemFilterValues,
} from '@constants';
import {
  ChangeDrawRequestDocumentParam,
  ChangeProjectDocumentParam,
  DeleteInspectionDocument,
  DocumentActionTypeEnum,
  DocumentTabEnums,
  IDocument,
  IDocumentType,
  IDrawRequest,
  IMilestone,
  IProject,
  IProjectDocument,
  IProjectMilestone,
  PopupTypeEnum,
  QueryNamesEnums,
} from '@interfaces';
import { AuthContext, SettingsContext } from '@context';
import {
  useCommentsPreview,
  useConfirmationModal,
  useImagePicker,
  useRightMenu,
  useSafeSnackbar,
} from '@hooks';
import {
  deleteDrawRequestDocument,
  deleteInspectionDocument,
  deleteProjectDocument,
  getDrawRequest,
  getDrawRequestDocumentsList,
  getDrawRequestDocumentsTypeList,
  getDrawRequestMilestones,
  getInspectionDocuments,
  getMilestoneDocuments,
  getProject,
  getProjectDocuments,
  getProjectDocumentTypes,
  getProjectMilestoneDocuments,
  getProjectMilestoneGroupDocuments,
  getProjectMilestonesList,
  restoreDrawRequestDocument,
  restoreProjectDocument,
} from '@globalService';
import { ControllerInterface, ControllerProps } from './interface';
import { useDocumentTableColumns } from './useDocumentTableColumns';

export const useDocuments = ({
  projectId,
  drawRequestId,
  requestDocuments,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  source,
}: ControllerProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = match?.params['*']?.split('/')[0];

  const { user } = useContext(AuthContext);
  const { isPHBProject } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const query = excludeCommentsQueryFields;

  const { isCurrentProjectArchived } = useContext(SettingsContext);

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: !!projectId },
  );

  // get documents types
  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );
  const drawRequestDocumentsTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS_TYPES],
    getDrawRequestDocumentsTypeList.bind(this),
  );

  // get documents
  const projectDocsQuery =
    tabPathname === DocumentTabEnums.PROJECT ? 'include_draw_request_documents=false' : '';
  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId, query: projectDocsQuery }],
    getProjectDocuments.bind(this, { projectId, query: projectDocsQuery }),
    { enabled: Boolean(projectId && !drawRequestId) },
  );
  const drawRequestDocumentsQuery = useQuery<{ results: IDocument[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId }],
    getDrawRequestDocumentsList.bind(this, projectId, drawRequestId),
    { enabled: Boolean(projectId && drawRequestId && !milestoneId) },
  );
  const requestMilestoneDocumentsQuery = useQuery<{ results: IDocument[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS, { projectId, milestoneId, drawRequestId }],
    getMilestoneDocuments.bind(this, { projectId, drawRequestId, milestoneId }),
    { enabled: Boolean(milestoneId && drawRequestId && !isPHBProject) },
  );
  const projectMilestoneDocumentsQuery = useQuery<{ results: IDocument[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS, { projectId, milestoneId }],
    getProjectMilestoneDocuments.bind(this, { projectId, milestoneId }),
    { enabled: Boolean(milestoneId && !drawRequestId && !isPHBProject) },
  );
  const projectMilestoneGroupDocumentsQuery = useQuery<{ results: IDocument[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS, { projectId, milestoneId }],
    getProjectMilestoneGroupDocuments.bind(this, { projectId, milestoneId }),
    { enabled: Boolean(milestoneId && isPHBProject) },
  );
  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query }],
    getProjectMilestonesList.bind(this, { projectId, query }),
    { enabled: !drawRequestId && !isPHBProject },
  );
  const inspectionDocumentsQuery = useQuery<{ results: IProjectDocument[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId, inspectionId }],
    getInspectionDocuments.bind(this, { projectId, inspectionId }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequestId) },
  );
  const drawRequestMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        filterKey: LineItemFilterValues.ALL.filterKey,
      },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      filterKey: LineItemFilterValues.ALL.filterKey,
    }),
    { enabled: Boolean(projectId && drawRequestId && !isPHBProject) },
  );

  const isMilestoneDocs = useMemo(
    () => Boolean(milestoneId) || Boolean(milestoneSubmitId),
    [milestoneId, milestoneSubmitId],
  );

  const isInspectionDocs = useMemo(() => Boolean(inspectionId), [inspectionId]);

  const isRightDrawerDocs = useMemo(
    () => isMilestoneDocs || requestDocuments || isInspectionDocs,
    [isMilestoneDocs, requestDocuments, isInspectionDocs],
  );

  const { isConfirmModalOpened, askConfirm, closeConfirmModal, confirmCallback } =
    useConfirmationModal();

  const { pdf, gallery, open, close } = useImagePicker();

  const [actionType, setActionType] = useState('');
  const [modalInfo, setModalInfo] = useState({
    title: '',
    text: '',
    confirmButtonLabel: '',
    type: PopupTypeEnum.CONFIRMATION,
  });

  const [rightDrawerParams, setRightDrawerParams] = useState<{
    projectId: string;
    documentId: string;
  }>({
    documentId: '',
    projectId,
  });

  const [docSummaryParams, setDocSummaryParams] = useState<{
    projectId: string;
    documentId?: string;
  }>({
    projectId,
  });

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId,
    ...(drawRequestId ? { drawRequestId } : {}),
    ...(inspectionId ? { inspectionId } : {}),
    documentId: rightDrawerParams.documentId,
  });

  const updateRightDrawer = (documentId) => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
    setRightDrawerParams({ projectId, documentId });
  };

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const { handleRightDrawerOpenerClick: handleDocSummaryOpenerClick, ...docSummaryMenu } =
    useRightMenu({});

  const deleteProjectDocumentation = useMutation<Response, Error, ChangeProjectDocumentParam>(
    deleteProjectDocument,
    {
      onSuccess: () => {
        // Query Invalidations to refetch project documentation list
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
        if (milestoneId) {
          if (isPHBProject) {
            queryClient.invalidateQueries([
              QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS,
              { projectId, milestoneId },
            ]);
          } else {
            queryClient.invalidateQueries([
              QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
              { projectId, milestoneId },
            ]);
          }
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const restoreProjectDocumentation = useMutation<Response, Error, ChangeProjectDocumentParam>(
    restoreProjectDocument,
    {
      onSuccess: () => {
        // Query Invalidations to refetch project documentation list
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const deleteDrawDocument = useMutation<Response, Error, ChangeDrawRequestDocumentParam>(
    deleteDrawRequestDocument,
    {
      onSuccess: () => {
        invalidateDrawQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const restoreDrawDocument = useMutation<Response, Error, ChangeDrawRequestDocumentParam>(
    restoreDrawRequestDocument,
    {
      onSuccess: () => {
        invalidateDrawQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const deleteInspectionDocumentMutation = useMutation<Response, Error, DeleteInspectionDocument>(
    deleteInspectionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
          { projectId, inspectionId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const invalidateDrawQueries = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
    if (milestoneId) {
      if (isPHBProject) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONE_GROUP_DOCS,
          { projectId, milestoneId },
        ]);
      } else {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
          { projectId, milestoneId },
        ]);
      }
    }
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
  };

  const getConfirmCallback = async ({ documentId }) => {
    if (actionType === DocumentActionTypeEnum.RESTORE_PROJECT_DOCUMENT) {
      await restoreProjectDocumentation.mutateAsync({
        projectId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.DELETE_PROJECT_DOCUMENT) {
      await deleteProjectDocumentation.mutateAsync({
        projectId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.RESTORE_DR_DOCUMENT) {
      await restoreDrawDocument.mutateAsync({
        projectId,
        drawRequestId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.DELETE_DR_DOCUMENT) {
      await deleteDrawDocument.mutateAsync({
        projectId,
        drawRequestId,
        documentId,
      });
    }

    if (actionType === DocumentActionTypeEnum.DELETE_INSPECTION_DOCUMENT) {
      await deleteInspectionDocumentMutation.mutateAsync({
        projectId,
        inspectionId,
        documentId,
      });
    }
    closeConfirmModal();
    setActionType('');
  };

  // data for table
  const data = useMemo(() => {
    if (inspectionId) return inspectionDocumentsQuery.data?.results;
    if (isPHBProject && milestoneId) return projectMilestoneGroupDocumentsQuery.data?.results;
    if (milestoneId && drawRequestId) return requestMilestoneDocumentsQuery.data?.results;
    if (milestoneId && !drawRequestId) return projectMilestoneDocumentsQuery.data?.results;
    if (drawRequestId) return drawRequestDocumentsQuery.data?.results;
    return projectDocumentsQuery.data?.results;
  }, [
    drawRequestId,
    milestoneId,
    projectDocumentsQuery,
    drawRequestDocumentsQuery,
    projectMilestoneDocumentsQuery,
    isPHBProject,
    inspectionId,
  ]);

  const isLoading = useMemo(
    () => projectDocumentsQuery.isLoading || drawRequestDocumentsQuery.isLoading,
    [projectDocumentsQuery, drawRequestDocumentsQuery],
  );

  const rows = useMemo(
    () =>
      isLoading
        ? Array(3).fill({})
        : data
            ?.map((o) => ({
              ...o,
              rowStyle: o?.deleted ? { textDecoration: 'line-through', opacity: 0.5 } : {},
            }))
            ?.filter((a) => a?.file_representations),
    [data, isLoading],
  );

  const openComments = useCallback((row) => {
    updateRightDrawer(row?.original?.id);
  }, []);

  const deleteDocument = (documentId: string, name: string) => {
    setModalInfo({
      title: 'Delete file',
      text: `You are about to delete the file "${name}".`,
      type: PopupTypeEnum.ERROR,
      confirmButtonLabel: 'Delete',
    });
    setActionType(
      inspectionId
        ? DocumentActionTypeEnum.DELETE_INSPECTION_DOCUMENT
        : drawRequestId
          ? DocumentActionTypeEnum.DELETE_DR_DOCUMENT
          : DocumentActionTypeEnum.DELETE_PROJECT_DOCUMENT,
    );
    askConfirm({ documentId });
  };

  const restoreDocument = (documentId: string, name: string) => {
    setActionType(
      drawRequestId
        ? DocumentActionTypeEnum.RESTORE_DR_DOCUMENT
        : DocumentActionTypeEnum.RESTORE_PROJECT_DOCUMENT,
    );
    setModalInfo({
      title: 'Restore file',
      text: `You are about to restore the file "${name}".`,
      type: PopupTypeEnum.CONFIRMATION,
      confirmButtonLabel: 'Restore',
    });
    askConfirm({ documentId });
  };

  const {
    name,
    lineItem,
    companyPrivateSwitcher,
    processedStatus,
    uploadedBy,
    icons,
    openAllDocsSummary,
  } = useDocumentTableColumns({
    projectId,
    drawRequestId,
    isLoading,
    source,
    open,
    setDocSummaryParams,
    handleDocSummaryOpenerClick,
    isMilestoneDocs,
    openComments,
    drawRequestData: drawRequestQuery.data,
    restoreDocument,
    deleteDocument,
  });

  const columns = useMemo(
    () => [
      name,
      ...(isRightDrawerDocs
        ? []
        : [
            headers.documentType({
              isLoading,
              dataTestName: `${source}__document_type__value`,
            }),
            lineItem,
            ...(checkIsLender(teamRole) || checkIsInvestor(teamRole)
              ? [companyPrivateSwitcher]
              : []),
            headers.date({
              accessor: 'created_at',
              header: 'Date uploaded',
              isLoading,
              dataTestName: `${source}__date_uploaded__value`,
            }),
            uploadedBy,
            processedStatus,
          ]),
      icons(),
    ],
    [data, isLoading, teamRole, isRightDrawerDocs, drawRequestQuery.data?.status],
  );

  const downloadDocs = () => {
    const zip = new JSZip();
    const promises = [];

    data.forEach((file) => {
      promises.push(
        fetch(file.link)
          .then((response) => response.blob())
          .then((blob) => zip.file(file.name, blob)),
      );
    });

    Promise.all(promises)
      .then(() => {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const url = URL.createObjectURL(content);
          const a = document.createElement('a');
          a.href = url;
          a.download = drawRequestId ? 'request_files.zip' : 'project_files.zip';
          a.click();
        });
      })
      .catch(() => enqueueSnackbar('Downloading failed', { variant: 'error' }));
  };

  const refetchDocuments = useMemo(() => {
    return [
      projectDocumentsQuery.refetch,
      ...(drawRequestId && !isPHBProject ? [drawRequestDocumentsQuery.refetch] : []),
      ...(milestoneId && drawRequestId && !isPHBProject
        ? [requestMilestoneDocumentsQuery.refetch]
        : []),
      ...(milestoneId && !isPHBProject ? [projectMilestoneDocumentsQuery.refetch] : []),
      ...(milestoneId && isPHBProject ? [projectMilestoneGroupDocumentsQuery.refetch] : []),
      ...(inspectionId ? [inspectionDocumentsQuery.refetch] : []),
    ];
  }, [drawRequestId, milestoneId, projectId, isPHBProject, inspectionId]);

  const milestones = useMemo(() => {
    if (isMilestoneDocs) return [];
    else if (drawRequestId) return drawRequestMilestonesQuery.data?.results || [];
    return (projectMilestonesQuery.data?.results as IMilestone[]) || [];
  }, [
    isMilestoneDocs,
    drawRequestId,
    projectMilestonesQuery.data,
    drawRequestMilestonesQuery.data?.results,
  ]);

  const filteredDocuments = useMemo(() => rows?.filter((doc) => !doc.deleted) || [], [rows]);

  return {
    state: getHookState(drawRequestId ? drawRequestDocumentsQuery : projectDocumentsQuery),
    rows,
    columns,
    documentTypes: drawRequestId
      ? drawRequestDocumentsTypesQuery.data
      : projectDocumentTypesQuery.data,
    refetchDocuments,
    isConfirmModalOpened,
    modalInfo,
    closeConfirmModal,
    confirmCallback: () => confirmCallback({ action: getConfirmCallback }),
    pdf,
    gallery,
    close,
    isCurrentProjectArchived,
    downloadDocs,
    rightMenu,
    isRightDrawerDocs,
    milestones,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    projectName: projectQuery.data?.name,
    openPdfViewer: (file) => open([file]),
    filteredDocuments,
  };
};
