import React from 'react';
import { useLaunchDarklyFlags } from '@context';
import ProjectIndexV2 from './ProjectIndexV2';
import Project from './index';

const ProjectIndexPage = () => {
  const flags = useLaunchDarklyFlags();

  if (flags?.['ENG_8320_current_inspection_as_service']) return <ProjectIndexV2 />;
  return <Project />;
};

export default ProjectIndexPage;
