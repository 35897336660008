import React, { useContext } from 'react';
import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ProjectHeader, ProjectSettingsOptionsMenu } from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { LenderChecklist, ProjectScores, ServicesButton } from '..';
import { PermissionNamesEnums } from '@interfaces';
import { isMobileOnly } from 'react-device-detect';
import { checkIsOwner, WithPermission } from '@utils';
import { CommentIconWithIndicator } from '@svgAsComponents';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

const ProjectMainInfo = ({ handleRightDrawerOpenerClick }) => {
  const flags = useLaunchDarklyFlags();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { projectId } = useParams();
  const { showMessagePanel, teamRole, projectCommentsPreview, showScores } =
    Controller.useProjectMainInfo(projectId);
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ pt: 3, pb: 1, px: 2 }}
      spacing={2}
    >
      <Grid item sx={{ width: { md: 'auto', xs: '100%' } }}>
        <ProjectHeader />
      </Grid>

      <Grid item sx={{ width: { md: 'auto', xs: '100%' } }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={1}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent="flex-start"
        >
          {showScores && !isCurrentProjectArchived && (
            <WithPermission permissionKey={PermissionNamesEnums.PROJECT__OVERVIEW__SCORES__VIEW}>
              <ProjectScores />
            </WithPermission>
          )}
          {!isMobileOnly && (
            <>
              <LenderChecklist />

              <Stack direction="row" spacing={1} alignItems="center">
                {showMessagePanel && (
                  <Stack
                    sx={{
                      backgroundColor: colors.main.primary.main,
                      width: '40px',
                      height: '40px',
                      borderRadius: '2px',
                    }}
                  >
                    <IconButton
                      sx={{
                        '& > svg > path: hover': {
                          fill: colors.main.primary.lighter,
                        },
                      }}
                      onClick={() =>
                        handleRightDrawerOpenerClick({
                          title: 'Comments',
                        })
                      }
                      data-tour="comments_icon"
                      data-cy="project__comments__icon"
                    >
                      <CommentIconWithIndicator
                        color={colors.neutral.white}
                        hasComments={projectCommentsPreview?.has_comments}
                        hasUnreadComments={projectCommentsPreview?.has_unread_comments}
                      />
                    </IconButton>
                  </Stack>
                )}
                <ServicesButton
                  withUserFocus={flags?.['ENG_6709_focus_user'] && checkIsOwner(teamRole)}
                />
                <ProjectSettingsOptionsMenu />
              </Stack>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProjectMainInfo;
