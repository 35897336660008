import React, { ChangeEvent } from 'react';
import { FormControlLabel, Radio, RadioGroup, Stack, Typography, Tooltip } from '@mui/material';
import { colors } from '@theme';

interface CustomRadioGroupProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: { value: string; label: string }[];
  dataTestName: string;
  direction?: 'row' | 'column';
  disabled?: boolean;
  disabledTooltipText?: string;
}

const CustomRadioGroup = ({
  value,
  onChange,
  options,
  dataTestName,
  direction = 'row',
  disabled,
  disabledTooltipText,
}: CustomRadioGroupProps) => {
  return (
    <RadioGroup
      aria-label="inputType"
      name="inputType"
      value={value}
      onChange={onChange}
      data-cy={dataTestName}
    >
      <Stack direction={direction} alignItems="flex-start">
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            data-cy={`${dataTestName}_${option.value.toLowerCase()}`}
            control={<Radio disabled={disabled} />}
            label={
              <Tooltip title={disabled ? disabledTooltipText : ''}>
                <Typography variant="body2" sx={disabled ? { color: colors.neutral.dark } : {}}>
                  {option.label}
                </Typography>
              </Tooltip>
            }
            sx={{ m: 0 }}
          />
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default CustomRadioGroup;
