import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { colors } from '@theme';
import { MagicWandIcon } from '@svgAsComponents';
import { BetaChip, DocumentSummaryList, RightDrawer } from '@components';
import * as Controller from './controller';
import { IProjectDocument, MediaFile } from '@interfaces';

interface ComponentProps {
  drawRequestId: string;
  projectName: string;
  openPdfViewer?: (file: IProjectDocument | MediaFile) => void;
  source: string;
}

const SmartSummaryButton = ({
  drawRequestId,
  projectName,
  openPdfViewer,
  source,
}: ComponentProps) => {
  const { projectId } = useParams();
  const {
    drawRequestDocuments,
    docSummaryMenu,
    docSummaryParams,
    setDocSummaryParams,
    handleSmartSummaryOpenerClick,
  } = Controller.useSmartSummaryButton({ drawRequestId, projectId });

  const openAllDocsSummary = () => {
    handleSmartSummaryOpenerClick({
      title: (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
          <MagicWandIcon />
          <Typography variant="h2" sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
          <Stack ml={1}>
            <BetaChip dataTestName="right_drawer__documents_summary__beta__chip" />
          </Stack>
        </Stack>
      ),
    });
    setDocSummaryParams({ projectId });
  };

  return (
    <>
      <Button
        onClick={openAllDocsSummary}
        data-cy={`${source}__button`}
        sx={{ background: colors.button.gradient }}
        size="small"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <MagicWandIcon size={16} color={colors.white} />
          <Typography variant="labelSemiBold" color={colors.white} sx={{ whiteSpace: 'nowrap' }}>
            SmartSummary
          </Typography>
        </Stack>
      </Button>
      <RightDrawer {...docSummaryMenu} drawerWidth="720px">
        <DocumentSummaryList
          {...docSummaryParams}
          projectName={projectName}
          rows={drawRequestDocuments}
          source={`${source}__list__summary`}
          openPdfViewer={openPdfViewer}
        />
      </RightDrawer>
    </>
  );
};

export default SmartSummaryButton;
