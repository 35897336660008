import { useQuery } from 'react-query';
import { HookState, IProject, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';
import { getProjectList } from '@globalService';

export interface ControllerInterface {
  state: HookState;
  data: IProject[];
}

export const useProjects = (url: string): ControllerInterface => {
  const projectsListQuery = useQuery<{ results: IProject[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_LIST, { url }],
    getProjectList.bind(this, url),
  );

  return {
    state: getHookState(projectsListQuery),
    data: projectsListQuery?.data?.results || [],
  };
};
