import { useQuery } from 'react-query';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getDrawRequestListStats } from '@globalService';
import {
  IDrawRequestsStats,
  QueryNamesEnums,
  RequestCombinedStatus,
  DrawRequestStatus,
} from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

export interface StatusItem {
  label: string;
  value: number;
}

export interface ControllerInterface {
  stats: StatusItem[];
}

// TODO remove after ENG_8420_simplify_filters_remove_awaiting and ! https://trustpoint.atlassian.net/browse/ENG-8427
const keysToRemove = [RequestCombinedStatus.AWAITING_OTHERS, RequestCombinedStatus.AWAITING_YOU];
const filterStatuses = (
  statuses: IDrawRequestsStats['statuses'],
  keysToRemove: string[],
): IDrawRequestsStats['statuses'] => {
  if (isEmpty(statuses)) return {};
  return Object.keys(statuses)
    .filter((key) => !keysToRemove.includes(key))
    .reduce(
      (obj, key) => {
        obj[key] = statuses[key];
        return obj;
      },
      {} as IDrawRequestsStats['statuses'],
    );
};

const sortStatuses = (statuses: IDrawRequestsStats['statuses']): StatusItem[] => {
  const priorityOrder = [
    DrawRequestStatus.DRAFT,
    DrawRequestStatus.IN_REVIEW,
    RequestCombinedStatus.ON_HOLD,
    DrawRequestStatus.APPROVED,
    DrawRequestStatus.COMPLETED,
  ];

  const statusArray = Object.keys(statuses).map((key) => ({
    label: key,
    value: statuses[key],
  }));

  return statusArray.sort(
    (a, b) =>
      priorityOrder.indexOf(a.label as DrawRequestStatus) -
      priorityOrder.indexOf(b.label as DrawRequestStatus),
  );
};

export const useRequestsSummaryBlock = (): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { data: statsData } = useQuery<IDrawRequestsStats, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST_STATS],
    getDrawRequestListStats.bind(this, ''),
  );

  const processedStatuses = useMemo(() => {
    if (!statsData?.statuses) {
      return [];
    }

    const filteredStatuses = flags?.['ENG_8420_simplify_filters_remove_awaiting']
      ? filterStatuses(statsData.statuses, keysToRemove)
      : statsData.statuses;

    return sortStatuses(filteredStatuses);
  }, [flags, statsData]);

  return {
    stats: processedStatuses,
  };
};
