import React, { MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box } from '@mui/material';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';

import { scoreColorMap } from '@constants';
import { colors } from '@theme';

const ScoreWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'withTooltip' && prop !== 'isSmall',
})<{ withTooltip: boolean; isSmall: boolean }>(({ withTooltip, isSmall }) => ({
  ...(isSmall ? { height: 48 } : { minHeight: 84 }),
  borderRadius: 4,
  backgroundColor: colors.background.white,
  ...(withTooltip
    ? {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        '&:hover': {
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
          backgroundColor: colors.background.lightest,
        },
      }
    : {}),
}));

const StatusIcon = styled(Box)({
  borderRadius: 2,
  minWidth: '16px',
  minHeight: '16px',
});

const ScoreItem = ({ title, value, isSmall }) => {
  const color = scoreColorMap[value?.color || 'NOT_APPLICABLE'];

  const showScoreTooltips = !!value?.comments.length;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;

  return (
    <>
      <ScoreWrapper
        container
        wrap="nowrap"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
        withTooltip={showScoreTooltips && !isSmall}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        isSmall={isSmall}
      >
        <Typography
          variant={isSmall ? 'body3SemiBold' : 'body2SemiBold'}
          sx={{
            color,
            cursor: 'pointer',
            mr: '8px',
          }}
        >
          {title}
        </Typography>
        <StatusIcon sx={{ backgroundColor: color }} />
      </ScoreWrapper>
      {showScoreTooltips && (
        <Popover
          id="score-popover"
          sx={{
            top: '8px',
            pointerEvents: 'none',
            '.MuiPopover-paper': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '16px',
              width: '336px',
              background: colors.background.white,
              boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
              borderRadius: '4px',
            },
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography variant="labelSemiBold" sx={{ mb: '8px' }}>
            Comments
          </Typography>
          <Typography variant="label">{`Key factors adversely affecting the ${title.toLowerCase()}`}</Typography>
          <Divider sx={{ margin: '8px 0 8px 0' }} flexItem />
          {value?.comments.map(({ text }) => (
            <Typography sx={{ whiteSpace: 'pre-wrap' }} key={text} variant="label">
              {text}
            </Typography>
          ))}
        </Popover>
      )}
    </>
  );
};

export default ScoreItem;
