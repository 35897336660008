import React, { FC, useState, useCallback } from 'react';
import { Stack } from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridSlots,
} from '@mui/x-data-grid';

import { HookState, ITablePagination, TableKeyEnum } from '@interfaces';
import {
  ServiceMessage,
  StyledBox,
  FiltersV2,
  TablePagination,
  RightDrawer,
  TableItemRightDrawer,
} from '@components';
import * as Controller from './controller';
import { colors } from '@theme';

const ServicesQueue = () => {
  const controller = Controller.useServicesQueue();

  switch (controller.state) {
    case HookState.FETCHING: {
      return <Table controller={controller} />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="services" />;
    }

    case HookState.SUCCESS: {
      return <Table controller={controller} />;
    }

    default:
      return null;
  }
};

export default ServicesQueue;

const Table: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    hiddenColumns,
    setColumnVisibilityModel,
    setFilterStringQuery,
    isFetching,
    paginationProps,
    isColumnFilterUpdating,
    rightDrawerParams,
    rightMenu,
  } = controller;

  const [columnButtonEl, setColumnButtonEl] = useState<HTMLDivElement | null>(null);

  const handleColumnButtonClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setColumnButtonEl(event.currentTarget);
  }, []);

  // TODO:
  // 1. add context menu
  // 2. fix jumping column picker button
  return (
    <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 2 }}>
      <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          columnVisibilityModel={hiddenColumns.reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {})}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          slots={{
            toolbar: CustomToolbar as GridSlots['toolbar'],
          }}
          slotProps={{
            panel: {
              anchorEl: columnButtonEl,
            },
            toolbar: {
              setColumnButtonEl: handleColumnButtonClick,
              setFilterStringQuery,
              paginationProps,
            },
          }}
          columnBufferPx={200}
          sx={{
            flexGrow: 1,
            '& .MuiDataGrid-virtualScroller': {
              overflow: 'auto',
            },
          }}
          hideFooter={true}
          disableColumnSelector={isColumnFilterUpdating}
        />
      </StyledBox>
      <RightDrawer {...rightMenu}>
        <TableItemRightDrawer
          projectId={rightDrawerParams.projectId}
          requestId={rightDrawerParams.requestId}
          activeTab={rightDrawerParams.activeTab}
        />
      </RightDrawer>
    </Stack>
  );
};

const CustomToolbar = ({
  setFilterStringQuery,
  setColumnButtonEl,
  paginationProps,
}: {
  setFilterStringQuery: any;
  setColumnButtonEl: (event: React.MouseEvent<HTMLDivElement>) => void;
  paginationProps: ITablePagination;
}) => {
  const { page, rowsPerPage, onPageChange, onRowsPerPageChange, rowsPerPageOptions, itemsCount } =
    paginationProps;

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" p={2}>
      <FiltersV2
        source="inspections"
        setFilterStringQuery={setFilterStringQuery}
        filters={['inspectionsListStatusFilter', 'customerFilter', 'inspectionsListAgencyFilter']}
        padding={0}
        width="unset"
        tableKey={TableKeyEnum.INSPECTION_LIST}
        onPageChange={onPageChange}
      />

      <Stack direction="row" spacing={1} alignItems="center">
        <TablePagination
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          itemsCount={itemsCount}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />

        <div onClick={setColumnButtonEl}>
          <GridToolbarColumnsButton />
        </div>

        <GridToolbarDensitySelector />
      </Stack>
    </Stack>
  );
};
