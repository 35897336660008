import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const customerFilter: FilterObject = {
  title: 'Customer',
  filterKey: 'customer',
  defaultFilters: [],
  permissionKey: PermissionNamesEnums.REQUEST_QUEUE__CUSTOMER__VIEW,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParams: {
    type: QueryNamesEnums.GET_COMPANIES,
    keys: ['id', 'name'],
    args: {
      query: '?is_customer=true&sorting=name',
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
    },
  },
  cypressSelector: 'filters__customer',
};
