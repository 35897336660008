import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { getUserFullName } from '@utils';

export const coordinatorFilter: FilterObject = {
  title: 'Coordinator',
  filterKey: 'coordinator',
  permissionKey: PermissionNamesEnums.REQUESTS__COORDINATOR,
  defaultFilters: [],
  getDataParams: {
    type: QueryNamesEnums.GET_COORDINATORS,
    keys: ['id', 'first_name', 'last_name'],
    args: {},
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: getUserFullName(item),
        })),
    },
  },
  cypressSelector: 'filters__coordinator',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
};
