import { GridEventListener } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';

const triggerHeight = 64;

const useScrollCheck = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  const mainElement = useRef(document.querySelector('main'));
  const [inTablePinned, setInTablePinned] = useState(false);
  const tabelRef = useRef<HTMLElement>();
  const position = useRef<number>(0);
  const scrollPosition = useRef<number>(0);

  const checkPosition = useCallback(() => {
    if (tabelRef.current) {
      const rect = tabelRef.current.getBoundingClientRect();
      console.log(rect.top);
      const isPinned = rect.top <= triggerHeight;
      setInTablePinned(isPinned);
      if (isPinned) scrollPosition.current = mainElement.current.scrollTop;
    }
  }, [triggerHeight]);

  useEffect(() => {
    const handleScroll = () => {
      checkPosition();
    };

    mainElement.current.addEventListener('scroll', handleScroll);
    checkPosition();
    return () => {
      mainElement.current.removeEventListener('scroll', handleScroll);
    };
  }, [checkPosition]);

  const handleEvent: GridEventListener<'scrollPositionChange'> = (params) => {
    if (params.top === 0 && params.top < position.current) {
      setInTablePinned(false);
      mainElement.current.scrollTo(0, scrollPosition.current);
    }
    position.current = params.top;
  };

  React.useEffect(() => {
    apiRef.current.subscribeEvent('scrollPositionChange', handleEvent);
  }, []);

  return {
    inTablePinned,
    tabelRef,
    setInTablePinned,
  };
};

export default useScrollCheck;
