import React, { FC, memo, useContext, useMemo } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnType, CommonRowType } from './common';
import { TableContext } from '../controller';
import { FundingIconWithIndicator, InfoIcon } from '@svgAsComponents';
import { ColumnV2Width, RequestTableTabsEnum } from '@interfaces';
import { creditAvalibleForMilestone, wasCreditItteraction } from '@utils';

const ApproveCredit: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(TableContext);
  const showCreditInfo = wasCreditItteraction(row);
  const creditAvalible = creditAvalibleForMilestone(row);
  const hasCredit = useMemo(() => row.requested_amount < 0, [row]);

  return row.activeToEdit && creditAvalible ? (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.CREDIT_APPROVE,
      })}
      data-cy={`${source}__body__credit__icon__index_${row.project_milestone?.index}`}
    >
      <FundingIconWithIndicator hasCredit={hasCredit} />
    </IconButton>
  ) : showCreditInfo ? (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.CREDIT_INFO,
      })}
      data-cy={`${source}__body__credit_info__icon__index_${row.project_milestone?.index}`}
    >
      <InfoIcon />
    </IconButton>
  ) : null;
};

const Memoized = memo(ApproveCredit);

const approveCredit: MilestoneListColumnType = {
  name: 'credit_reason',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default approveCredit;
