import React, { Dispatch, SetStateAction } from 'react';
import {
  Stack,
  Box,
  TableCell,
  TableRow,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { DotIcon } from '@svgAsComponents';
import { ITeam } from '@interfaces';
import { useDragAndDrop } from '@hooks';
import { PermissionIconWithLink } from '@components';
import { useLaunchDarklyFlags } from '@context';

const DND_ITEM_TYPE = 'team';

interface ComponentProps {
  team: ITeam;
  index: number;
  setItems: Dispatch<SetStateAction<ITeam[]>>;
  handleBulkUpdateTeam: () => void;
  hasCompanyTeamsEditPermission: boolean;
  handleTeamApprovalFlow: (value: boolean, index: number) => void;
  excludedFromApproval?: boolean;
  listLength?: number;
  isApprovalFlowMutating: boolean;
}

const TeamRow = ({
  team,
  index,
  setItems,
  handleBulkUpdateTeam,
  hasCompanyTeamsEditPermission,
  handleTeamApprovalFlow,
  excludedFromApproval,
  listLength,
  isApprovalFlowMutating,
}: ComponentProps) => {
  const flags = useLaunchDarklyFlags();
  const { opacity, boxShadow, getDragItemIcon, dropRef } =
    hasCompanyTeamsEditPermission && listLength > 1
      ? useDragAndDrop({
          dndItemType: DND_ITEM_TYPE,
          setItems,
          index,
          onEnd: handleBulkUpdateTeam,
        })
      : { opacity: 1, boxShadow: 'none', getDragItemIcon: () => null, dropRef: null };

  const { name, members } = team || {};

  if (flags?.['ENG_8045_exclude_team_from_approval_flow'])
    return (
      <TableRow
        ref={dropRef}
        sx={{
          opacity,
          boxShadow,
          '& td': { borderBottom: 'none' },
        }}
      >
        <TableCell>{!excludedFromApproval ? `${index + 1}. ` : ''}</TableCell>
        {hasCompanyTeamsEditPermission && (
          <TableCell sx={{ p: 0, mx: 5, alignItems: 'flex-end' }}>
            {!excludedFromApproval ? getDragItemIcon() : null}
          </TableCell>
        )}
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1SemiBold">{name}</Typography>
            <Box sx={{ mr: 2, ml: 2 }}>
              <DotIcon />
            </Box>
            <Stack direction="row" alignItems="center">
              <Typography variant="body1" mr={1}>
                Members:
              </Typography>
              <Typography variant="body1SemiBold">{members?.length}</Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Switch
                checked={!excludedFromApproval}
                onChange={(_, value) => handleTeamApprovalFlow(value, index)}
                data-cy="company_teams__workflow__include_in_approval_flow__switch"
                disabled={isApprovalFlowMutating}
              />
            }
            componentsProps={{ typography: { variant: 'body3' } }}
            name="includeInApprovalFlow"
            label="Include in approval flow"
          />
        </TableCell>
        <TableCell>
          <PermissionIconWithLink
            teamId={team.id}
            dataTestName="company_teams__team_permission_from_workflow_icon"
          />
        </TableCell>
      </TableRow>
    );

  return (
    <Stack
      ref={dropRef}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: '100%', opacity, boxShadow, minHeight: '65px' }}
    >
      <Typography variant="body1" sx={{ width: '20px' }}>{`${index + 1}. `}</Typography>
      {hasCompanyTeamsEditPermission && (
        <Stack sx={{ p: 0, mx: 5, alignItems: 'flex-end' }}>{getDragItemIcon()}</Stack>
      )}
      <Stack direction="row" sx={{ width: '50%', maxWidth: '50%', minWidth: '50%' }}>
        <Typography variant="body1SemiBold">{name}</Typography>
        <Box sx={{ mr: 2, ml: 2 }}>
          <DotIcon />
        </Box>
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" mr={1}>
            Members:
          </Typography>
          <Typography variant="body1SemiBold">{members?.length}</Typography>
        </Stack>
      </Stack>
      <PermissionIconWithLink
        teamId={team.id}
        dataTestName="company_teams__team_permission_from_workflow_icon"
      />
    </Stack>
  );
};

export default TeamRow;
