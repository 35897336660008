import { IMilestone, IPHBTableItem } from '@interfaces';
import { Dispatch, SetStateAction } from 'react';

interface IExpandCollapseTableProps {
  setListItems: Dispatch<SetStateAction<IPHBTableItem[]>>;
  createTableObject: ({
    item,
    isExpanded,
    isNested,
    index,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
  }) => IPHBTableItem;
}

interface IExpandCollapseTableReturn {
  onExpandClick: (id: string, isExpanded: boolean) => void;
  updateListItemsWithParentGroup: (updatedMilestone: IMilestone) => void;
  updateListItemsWithMsList: (updatedMilestones: IMilestone[]) => void;
}
const useExpandCollapseTable = ({
  setListItems,
  createTableObject,
}: IExpandCollapseTableProps): IExpandCollapseTableReturn => {
  const modifyList = (
    modifyFn: (list: IPHBTableItem[], item: IPHBTableItem, index: number) => IPHBTableItem[],
    id: string,
  ) => {
    setListItems((prevListItems) => {
      const itemIndex = prevListItems.findIndex((item) => item.id === id);
      return modifyFn(prevListItems, prevListItems[itemIndex], itemIndex);
    });
  };

  const insertMilestoneGroups = (list: IPHBTableItem[], item: IPHBTableItem, index: number) => {
    const expandedList = [...list];
    expandedList[index] = createTableObject({ item, isExpanded: true, index });

    if (item?.milestone_groups?.length > 0) {
      const milestoneTableObjects = item.milestone_groups.map((msGroup, msGroupIndex) => {
        const formattedIndex = String(msGroupIndex).padStart(2, '0');
        return createTableObject({
          item: msGroup,
          isNested: true,
          index: `${index}_${formattedIndex}`,
        });
      });
      expandedList.splice(index + 1, 0, ...milestoneTableObjects);
    }

    return expandedList;
  };

  const removeMilestoneGroups = (list: IPHBTableItem[], item: IPHBTableItem, index: number) => {
    const updatedList = list.filter((listItem) => listItem.tags[0]?.id !== item.tags[0]?.id);
    updatedList.splice(index, 0, createTableObject({ item, index }));
    return updatedList;
  };

  const onExpandClick = (id: string, isExpanded: boolean) => {
    const action = isExpanded ? insertMilestoneGroups : removeMilestoneGroups;
    modifyList(action, id);
  };

  const updateListItemsWithParentGroup = (updatedMilestone: IMilestone) => {
    setListItems((currentObjects) => {
      const updatedMilestoneId = updatedMilestone?.tags?.[0]?.id;
      const updatedMilestoneIndex = currentObjects.findIndex(
        (obj) => obj?.tags?.[0]?.id === updatedMilestoneId,
      );

      if (updatedMilestoneIndex === -1) return currentObjects; // No matching object found

      // Merge the updated milestone into the found object
      const parentObj = { ...currentObjects[updatedMilestoneIndex], ...updatedMilestone };

      // Update the objects by merging it with 'updatedMilestone'
      let newObjects = [
        ...currentObjects.slice(0, updatedMilestoneIndex),
        parentObj,
        ...currentObjects.slice(updatedMilestoneIndex + 1),
      ];

      if (parentObj?.isExpanded && updatedMilestone.milestone_groups?.length) {
        // Update next 'updatedMilestone.milestone_groups' items
        const endReplaceIndex =
          updatedMilestoneIndex + 1 + updatedMilestone.milestone_groups.length;
        newObjects = [
          ...newObjects.slice(0, updatedMilestoneIndex + 1),
          ...updatedMilestone.milestone_groups.map((group, msGroupIndex) => ({
            ...newObjects[updatedMilestoneIndex + msGroupIndex + 1],
            ...group,
          })),
          ...newObjects.slice(endReplaceIndex),
        ];
      }
      return newObjects;
    });
  };

  const updateListItemsWithMsList = (updatedMilestones: IMilestone[]) => {
    setListItems((currentObjects) => {
      let newObjects = [...(currentObjects || [])];

      updatedMilestones.forEach((updatedMilestone) => {
        const updatedMilestoneId = updatedMilestone?.tags?.[0]?.id;
        const index = newObjects.findIndex((obj) => obj?.tags?.[0]?.id === updatedMilestoneId);

        if (index !== -1) {
          const parentObj = { ...newObjects[index], ...updatedMilestone };
          newObjects[index] = parentObj;

          if (parentObj.isExpanded && updatedMilestone.milestone_groups?.length) {
            // Remove old existing milestone_groups, if any
            newObjects = removeMilestoneGroups(newObjects, updatedMilestone, index);
            // Insert new milestone_groups
            newObjects = insertMilestoneGroups(newObjects, updatedMilestone, index);
          }
        }
      });

      return newObjects;
    });
  };

  return { onExpandClick, updateListItemsWithParentGroup, updateListItemsWithMsList };
};

export default useExpandCollapseTable;
