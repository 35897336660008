import React, { useMemo, FC, useContext, useCallback } from 'react';
import { MilestoneListColumnType, CommonRowType } from './common';
import { TableContext } from '../controller';
import { PermissionsContext } from '@context';
import { isRestricted, useBlockerCell } from '@utils';
import { DeleteIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ColumnV2Width, PermissionNamesEnums } from '@interfaces';
import { IconButton } from '@mui/material';

const DeleteIconRow: FC<{ row: CommonRowType }> = ({ row }) => {
  const isDeleting = useBlockerCell({ milestoneId: row.id });
  const { permissions } = useContext(PermissionsContext);
  const { deleteMilestone } = useContext(TableContext);

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      row.localNew &&
      !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__NEWLINE_ADD, permissions),
    [permissions, row],
  );
  const deleteCallback = useCallback(() => deleteMilestone(row.id), [row.id]);

  return (
    <IconButton disabled={isDeleting || !isEditable} onClick={deleteCallback}>
      <DeleteIcon size={24} color={colors.icons.gray} />
    </IconButton>
  );
};

const deleteItem: MilestoneListColumnType = {
  name: 'id',
  columnText: '',
  Header: null,
  noMargin: true,
  noBorder: true,
  renderCell: ({ row }) => <DeleteIconRow row={row} />,
  minWidth: () => ColumnV2Width.WIDE_ICON,
  maxWidth: () => ColumnV2Width.WIDE_ICON,
};

export default deleteItem;
