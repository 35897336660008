import React from 'react';
import { Box } from '@mui/material';

import { useLaunchDarklyFlags } from '@context';

const PageLayoutContentWrapper = ({ children, withWarning }) => {
  const flags = useLaunchDarklyFlags();

  let shift = 64; // for AppBar
  if (!flags?.['ENG_7509_switch_footer_visibility']) {
    shift += 84; // for Footer
  }
  if (withWarning) {
    shift += 56; // for ProjectWarning
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        '& > div': {
          // Make sure first component in Outlet at lease cover visible part of screen
          minHeight: `calc(100vh - ${shift}px)`,
        },
      }}
    >
      {children}
    </Box>
  );
};

export default PageLayoutContentWrapper;
