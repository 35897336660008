import { useQuery } from 'react-query';

import { IProject, QueryNamesEnums } from '@interfaces';
import { getProject } from '@globalService';

interface ControllerInterface {
  project: IProject;
}

export const useRequestQueueRightModal = ({ projectId }): ControllerInterface => {
  // TODO check it for asking only name after ENG-6187: Data Provider for project query
  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  return {
    project: projectQuery?.data,
  };
};
