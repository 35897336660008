import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import {
  ColumnWidth,
  CSVBoxSheetsEnum,
  IMenuItem,
  IProjectsPortfolioItem,
  IRightDrawerParams,
  IRightMenu,
  ITablePagination,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { getPortfolioList, getProjectPropertyTypes } from '@globalService';
import {
  useColumnFilterV2,
  useRightMenu,
  useSorting,
  useTablePagination,
  useUpdateUiSettings,
} from '@hooks';
import { useNavigate } from 'react-router-dom';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  checkIsExternalUser,
  checkIsInvestor,
  checkIsLender,
  getSortQueryString,
  getTeamRole,
  isRestricted,
  tableHeaders,
} from '@utils';
import { CSVUploader } from '@components';
import { Typography } from '@mui/material';

export interface ControllerInterface {
  projectsCount: number;
  tablePagination: ITablePagination;
  isListView: boolean;
  updateListView: ({ listView }: { listView: boolean }) => void;
  addProjectMenuItems: IMenuItem[];
  setFilterStringQuery: Dispatch<SetStateAction<string>>;
  columns: any[];
  hiddenColumns: string[];
  changeFieldVisibility: (id: string) => void;
  isColumnFilterUpdating: boolean;
  rightMenu: IRightMenu;
  rightDrawerParams: IRightDrawerParams;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  projects: IProjectsPortfolioItem[];
  filteredProjectsCount: number;
  handleSortClick: (column: string) => void;
  sortValue: { [key: string]: number }[];
}

enum ViewTypes {
  LIST = 'list',
  CARD = 'card',
}

export const useProjects = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const { handleSortClick, sortValue } = useSorting();
  const navigate = useNavigate();

  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isInvestor = useMemo(() => checkIsInvestor(teamRole), [teamRole]);
  const isInternalUser = useMemo(() => !checkIsExternalUser(teamRole), [teamRole]);

  const tablePagination = useTablePagination();

  const [isListView, setListView] = useState(true);
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});
  const [transformedData, setTransformedData] = useState([]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: () => setRightDrawerParams((old) => ({ ...old, activeTab: '' })),
  });

  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.RISK_RADAR,
  );

  const updateRightDrawer = ({
    title,
    projectId,
    activeTab,
  }: {
    title: string;
    projectId: string;
    activeTab: string;
  }) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, activeTab });
  };

  const filterStringQueryWithPagination = useMemo(() => {
    const sortString = getSortQueryString({ sortValue, statusFieldSortName: 'project_status' });
    const sortUrl = sortString ? `&sorting=${sortString}` : '';
    const paginationUrl = `pagination=true&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}`;
    return `${filterStringQuery}&${paginationUrl}&${sortUrl}`;
  }, [filterStringQuery, tablePagination, sortValue]);

  const requestedDataQueries = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES,
        { query: '&has_current_customer=true' },
      ],
      queryFn: getProjectPropertyTypes.bind(this, '&has_current_customer=true'),
    },
  ]);

  const {
    data: { results, count } = {},
    isLoading,
    isFetching,
    isError,
  } = useQuery<{ results: IProjectsPortfolioItem[]; count: number }, Error>(
    [QueryNamesEnums.GET_PORTFOLIO_LIST, { filterStringQuery: filterStringQueryWithPagination }],
    getPortfolioList.bind(this, {
      filterStringQuery: filterStringQueryWithPagination,
      useV2: true,
    }),
    {
      keepPreviousData: true,
    },
  );

  const emptyQuery = 'query={}';
  const { data: { count: projectsCount } = {} } = useQuery<
    { results: IProjectsPortfolioItem[]; count: number },
    Error
  >(
    [QueryNamesEnums.GET_PORTFOLIO_LIST, { filterStringQuery: emptyQuery }],
    getPortfolioList.bind(this, {
      filterStringQuery: emptyQuery,
      useV2: true,
    }),
  );

  const projectPropertyTypesQuery = requestedDataQueries[0].data;

  const projectPropertyTypes = useMemo(() => {
    if (projectPropertyTypesQuery?.results?.length)
      return projectPropertyTypesQuery.results.map((item) => ({
        type: item.name,
        name: item.name_display,
      }));
    return [];
  }, [projectPropertyTypesQuery]);

  function transformScoresToKeys(dataArray) {
    return dataArray.map((data) => {
      if (data?.scores && Array.isArray(data.scores?.scores)) {
        const updatedScores = {};

        data.scores.scores.forEach((score) => {
          if (score?.agent_id) {
            updatedScores[score.agent_id] = score;
          }
        });
        updatedScores[data.scores?.project_score?.agent_id] = data.scores?.project_score;

        data.scores = updatedScores;
      }

      return data;
    });
  }

  useEffect(() => {
    if (results?.length) {
      setTransformedData(transformScoresToKeys(results));
    } else {
      setTransformedData([]);
    }
  }, [results]);

  useEffect(() => {
    tablePagination.setPage(0);
  }, []);

  useEffect(() => {
    const viewType = settings?.personal_setting?.tables?.[TableKeyEnum.RISK_RADAR]?.view_type;
    if (viewType) {
      setListView(viewType === ViewTypes.LIST);
    }
  }, [settings?.personal_setting?.tables?.[TableKeyEnum.RISK_RADAR]?.view_type]);

  const addProjectMenuItems = useMemo(
    () => [
      {
        text: 'Manually',
        action: () => navigate(`add-new`),
        dataTestName: 'projects__add__manually__menu_item',
      },
      {
        text: (
          <CSVUploader
            sheetKey={CSVBoxSheetsEnum.IMPORT_BUDGET_BULK}
            renderItem={(launch) => (
              <Typography variant="body3SemiBold" onClick={launch}>
                Bulk budget upload
              </Typography>
            )}
          />
        ),
        dataTestName: 'projects__add__upload_bulk__menu_item',
      },
      {
        text: (
          <CSVUploader
            sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT}
            renderItem={(launch) => (
              <Typography variant="body3SemiBold" onClick={launch}>
                Upload project
              </Typography>
            )}
          />
        ),
        dataTestName: 'projects__add__upload__menu_item',
      },
    ],
    [],
  );

  const columns = React.useMemo(
    () => [
      tableHeaders.headerTemplate({
        headerName: 'Loan #',
        accessor: 'loan.external_id',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.headerTemplate({
        headerName: 'Project name',
        accessor: 'name',
        isLoading,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.headerTemplate({
        headerName: 'Address',
        accessor: 'address.address_1',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.headerTemplate({
        headerName: 'City',
        accessor: 'address.city',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.state({ isLoading, accessor: 'address.state' }),
      tableHeaders.headerTemplate({
        headerName: 'Project type',
        accessor: 'project_type',
        isLoading,
      }),
      tableHeaders.propertyType({
        header: 'Existing property type',
        isLoading,
        valuesList: projectPropertyTypes,
        accessor: 'property_existing_type',
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.propertyType({
        header: 'Proposed property type',
        isLoading,
        valuesList: projectPropertyTypes,
        accessor: 'property_proposed_type',
      }),
      tableHeaders.projectStatus({
        isLoading,
        accessor: 'status',
      }),
      tableHeaders.requestName({ isLoading }),
      tableHeaders.statusWithRole({
        header: 'Request status',
        accessor: 'draw_request.status',
        isLoading,
        riskRadar: true,
        disableSortBy: true,
      }),
      ...(!isRestricted(PermissionNamesEnums.RISK_RADAR__CUSTOMER__VIEW, permissions)
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Customer',
              accessor: 'customer.name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isLender || isInvestor || isInternalUser
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Borrower',
              accessor: 'borrower_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(isInvestor || isInternalUser
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Lender',
              accessor: 'lender_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.RISK_RADAR__INVESTOR__VIEW, permissions)
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Investor',
              accessor: 'investor_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      ...(!isRestricted(PermissionNamesEnums.REQUESTS__COORDINATOR, permissions)
        ? [
            tableHeaders.headerTemplate({
              headerName: 'Coordinator',
              accessor: 'coordinator.full_name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      tableHeaders.amount({
        header: 'Loan commitment',
        accessor: 'loan.loc_commitment',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.amount({
        header: 'Original scheduled value ($)',
        accessor: 'project_funds.original_estimate',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.amount({
        header: 'Revised scheduled value ($)',
        accessor: 'project_funds.revised_estimate',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.amount({
        header: 'Total construction holdback ($)',
        accessor: 'construction_holdback_total',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.amount({
        header: 'Disbursed construction holdback ($)',
        accessor: 'construction_holdback_current',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.amount({
        header: 'Remaining construction holdback ($)',
        accessor: 'construction_holdback_available',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.date({
        header: 'Funding date',
        accessor: 'loan.close_date',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.date({
        header: 'Original maturity date',
        accessor: 'loan.maturity_date',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.date({
        header: 'Extended maturity date ',
        accessor: 'loan.extended_maturity_date',
        isLoading,
        disableSortBy: true,
      }),
      tableHeaders.headerTemplate({
        isLoading,
        headerName: 'Length of project (month)',
        accessor: 'duration',
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      tableHeaders.percentage({
        accessor: 'inspector_allowance_rate',
        header: 'Inspector allowance (%)',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.TEXT,
      }),
      tableHeaders.percentage({
        accessor: 'lender_allowance_rate',
        header: 'Lender allowance (%)',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.TEXT,
      }),
      tableHeaders.percentage({
        accessor: 'gap',
        header: 'Variance to lender allowance (%)',
        isLoading,
        disableSortBy: true,
        minWidth: ColumnWidth.WIDE_TEXT,
      }),
      ...(!isRestricted(PermissionNamesEnums.RISK_RADAR__SCORES__VIEW, permissions)
        ? [
            tableHeaders.projectScore({
              headerName: 'Project health',
              accessor: 'scores.overall_score',
              isLoading,
            }),
            tableHeaders.projectScore({
              headerName: 'Budget health',
              accessor: 'scores.budget_score',
              isLoading,
              size: 'small',
            }),
            tableHeaders.projectScore({
              headerName: 'Policy risk',
              accessor: 'scores.policy_score',
              isLoading,
              size: 'small',
            }),
            tableHeaders.projectScore({
              headerName: 'Schedule health',
              accessor: 'scores.schedule_score',
              isLoading,
              size: 'small',
            }),
            tableHeaders.projectScore({
              headerName: 'Borrower risk',
              accessor: 'scores.borrower_score',
              isLoading,
              size: 'small',
            }),
          ]
        : []),
      tableHeaders.projectActions({
        isLoading,
        updateRightDrawer,
        source: 'projects__table__body__actions',
      }),
    ],
    [open, isLoading, permissions, hiddenColumns],
  );

  const updateListView = ({ listView }: { listView: boolean }) => {
    if (isListView !== listView) {
      setListView(listView);
      updateSettings({
        personal_setting: {
          tables: {
            ...settings.personal_setting?.tables,
            [TableKeyEnum.RISK_RADAR]: {
              view_type: listView ? ViewTypes.LIST : ViewTypes.CARD,
              hidden_columns: hiddenColumns,
            },
          },
        },
      });
    }
  };

  return {
    projectsCount,
    tablePagination,
    isListView,
    updateListView,
    addProjectMenuItems,
    setFilterStringQuery,
    columns,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    rightMenu,
    rightDrawerParams,
    isLoading,
    isFetching,
    isError,
    projects: transformedData,
    filteredProjectsCount: count,
    handleSortClick,
    sortValue,
  };
};
