import React from 'react';
import { ComponentProps } from './interface';

const Icon = ({ size = 17 }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 14L4.66667 14C3.5621 14 2.66667 13.1046 2.66667 12V4C2.66667 2.89543 3.5621 2 4.66667 2H11.3333C12.4379 2 13.3333 2.89543 13.3333 4L13.3333 12C13.3333 13.1046 12.4379 14 11.3333 14ZM11.3333 12.6667C11.7015 12.6667 12 12.3682 12 12L12 4C12 3.63181 11.7015 3.33333 11.3333 3.33333L4.66667 3.33333C4.29848 3.33333 4 3.63181 4 4L4 12C4 12.3682 4.29848 12.6667 4.66667 12.6667L11.3333 12.6667ZM4.66667 8C4.66667 7.63181 4.96514 7.33333 5.33333 7.33333H9.33333C9.70152 7.33333 10 7.63181 10 8C10 8.36819 9.70152 8.66667 9.33333 8.66667H5.33333C4.96514 8.66667 4.66667 8.36819 4.66667 8ZM5.33333 10C4.96514 10 4.66667 10.2985 4.66667 10.6667C4.66667 11.0349 4.96514 11.3333 5.33333 11.3333H8C8.36819 11.3333 8.66667 11.0349 8.66667 10.6667C8.66667 10.2985 8.36819 10 8 10H5.33333Z"
      fill="#1F4739"
    />
  </svg>
);

export default Icon;
