import { LoanStatusesList } from '@constants';
import find from 'lodash/find';

import { useDropdownFieldModel, useStringFieldModel, usePhoneFieldModel } from '@models';
import { regexValidation, validationShareRule } from '@utils';
import { useProjectAndLoanDates } from '@hooks';

export const useProjectFields = ({ isNewBorrower }) => {
  const {
    projectDates: {
      funding_date,
      estimated_start_date,
      original_completion_date,
      estimated_completion_date,
      maturity_date,
      extended_maturity_date,
    },
  } = useProjectAndLoanDates({});

  const state = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.name),
  });

  const address_1 = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const city = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const zip_code = useStringFieldModel({
    initValue: '',
    validationRule: (value) => regexValidation('zip_code', value) && Boolean(value),
    validateOnChange: true,
  });

  const exit_strategy = useDropdownFieldModel({
    initValue: null,
  });

  const external_id = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const loc_commitment = useStringFieldModel({
    initValue: null,
    validateOnChange: true,
  });

  const prefunding_cost = useStringFieldModel({
    initValue: null,
    validateOnChange: true,
  });

  const construction_holdback = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
  });

  const post_funding_construction_budget = useStringFieldModel({
    initValue: '0',
    validationRule: (value) => Boolean(+value),
    validateOnChange: true,
  });

  const retainage_rate = useStringFieldModel({
    initValue: null,
    validateOnChange: true,
  });

  const project_type = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });

  const property_existing_type = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
  });

  const property_proposed_type = useDropdownFieldModel({
    initValue: null,
  });

  const scope_of_work = useStringFieldModel({
    initValue: '',
    validateOnChange: true,
  });

  const borrower_company_name = useStringFieldModel({
    initValue: '',
    validationRule: (value) => (isNewBorrower ? Boolean(value?.trim()) : true),
    validateOnChange: true,
  });

  const borrower_email = useStringFieldModel({
    initValue: '',
    validationRule: (value) => regexValidation('email', value),
    validateOnChange: true,
  });

  const borrower_company_state = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => (isNewBorrower ? Boolean(value?.name) : true),
  });

  const borrower_company_address_1 = useStringFieldModel({
    initValue: '',
    validationRule: (value) => (isNewBorrower ? Boolean(value?.trim()) : true),
    validateOnChange: true,
  });

  const borrower_company_city = useStringFieldModel({
    initValue: '',
    validationRule: (value) => (isNewBorrower ? Boolean(value?.trim()) : true),
    validateOnChange: true,
  });

  const borrower_company_zip_code = useStringFieldModel({
    initValue: '',
    validationRule: (value) =>
      isNewBorrower ? regexValidation('zip_code', value) && Boolean(value) : true,
    validateOnChange: true,
  });

  const contribution_fraction = useStringFieldModel({
    initValue: '100',
    validationRule: validationShareRule,
    validateOnChange: true,
  });

  const borrowerEquityLocal = useStringFieldModel({
    initValue: '0',
    validationRule: validationShareRule,
    validateOnChange: true,
  });

  const borrower_equity = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
  });

  const property_after_repair_value = useStringFieldModel({
    initValue: null,
    validateOnChange: true,
  });

  const borrower_company = useDropdownFieldModel({
    initValue: null,
  });

  const legal_entity = useStringFieldModel({
    initValue: '',
  });

  const first_name = useStringFieldModel({
    initValue: '',
    validationRule: (value) => (isNewBorrower ? Boolean(value?.trim()) : true),
    validateOnChange: true,
  });

  const last_name = useStringFieldModel({
    initValue: '',
    validationRule: (value) => (isNewBorrower ? Boolean(value?.trim()) : true),
    validateOnChange: true,
  });

  const phone = usePhoneFieldModel({
    initValue: '',
    isRequired: isNewBorrower,
  });

  const payment_configuration_comment = useStringFieldModel({
    initValue: null,
  });

  const loan_status = useDropdownFieldModel({
    initValue: find(LoanStatusesList, { name: 'ACTIVE' }),
    validateOnChange: true,
  });

  const sqft_existing_value = useStringFieldModel({
    initValue: '',
  });
  const sqft_proposed_value = useStringFieldModel({
    initValue: '',
  });

  const CREATE_PROJECT_STEPS = [
    {
      title: 'Property details',
      fields: {
        state,
        city,
        address_1,
        zip_code,
        project_type,
        property_existing_type,
        property_proposed_type,
        scope_of_work,
        exit_strategy,
        sqft_existing_value,
        sqft_proposed_value,
      },
    },
    {
      title: 'Loan details',
      fields: {
        external_id,
        funding_date,
        loc_commitment,
        prefunding_cost,
        construction_holdback,
        property_after_repair_value,
        contribution_fraction,
        maturity_date,
        extended_maturity_date,
        retainage_rate,
        borrower_equity,
        post_funding_construction_budget,
        estimated_start_date,
        original_completion_date,
        estimated_completion_date,
        payment_configuration_comment,
        loan_status,
        borrowerEquityLocal,
      },
    },
    {
      title: 'Borrower details (Optional)',
      fields: {
        borrower_email,
        borrower_company_name,
        borrower_company_state,
        borrower_company_address_1,
        borrower_company_city,
        borrower_company_zip_code,
        borrower_company,
        legal_entity,
        first_name,
        last_name,
        phone,
      },
    },
  ];

  return {
    CREATE_PROJECT_STEPS,
  };
};
