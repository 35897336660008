import React, { useCallback } from 'react';

import { ITablePagination } from '@interfaces';

export const useTablePagination = (): ITablePagination => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  return {
    onPageChange: useCallback((event, newPage) => {
      setPage(newPage);
    }, []),
    onRowsPerPageChange: useCallback((event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }, []),
    page,
    rowsPerPage,
    rowsPerPageOptions: [50, 75, 100],
    setPage,
  };
};
