import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { currencyFormatter, isDrawRequest } from '@utils';
import { colors } from '@theme';
import { LabelAndValueWithPreload, ProgressWithArrow } from '@components';
import { ValueTypeEnum } from '@interfaces';
import { useRequestApproveSummary } from './controller';
import { useLaunchDarklyFlags } from '@context';

interface ComponentProps {
  showShortVersion?: boolean;
  projectId: string;
  drawRequestId: string;
  source?: string;
}

const RequestApproveSummaryBlock: FC<ComponentProps> = ({
  projectId,
  drawRequestId,
  showShortVersion = false,
  source = 'project__checklist__request_summary',
}) => {
  const controller = useRequestApproveSummary({ projectId, drawRequestId });
  const flags = useLaunchDarklyFlags();
  const { drawRequest, isInReview, dataIsLoading, retainageRate, isReallocationAllowed } =
    controller;

  const {
    approved_budget_change,
    requested_budget_change,
    requested_amount,
    approved_amount,
    revised_estimate,
    requested_revised_estimate,
    retainage_release_requested,
    retainage_release_approved,
    requested_additional_borrower_equity,
    additional_borrower_equity,
    requested_credit_amount,
    approved_credit_amount,
  } = drawRequest?.totals?.all || {};

  const { approved_reallocation, requested_reallocation } = drawRequest || {};

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
        <Typography variant="labelSemiBold" sx={{ color: colors.text.medium }}>
          Requested ➝ Approved
        </Typography>
      </Stack>

      {isDrawRequest(drawRequest) && (
        <LabelAndValueWithPreload isLoading={dataIsLoading} label="Draw amount">
          <ProgressWithArrow
            prevValue={currencyFormatter(requested_amount || 0)}
            nextValue={currencyFormatter(approved_amount || 0)}
            getTextStyleForActive={isInReview}
            prevValueDataTestName={`${source}__draw_amount__value`}
            nextValueDataTestName={`${source}__draw_amount__next_value`}
            nextValueType={approved_amount < 0 ? { [ValueTypeEnum.IS_ERROR]: true } : null}
          />
        </LabelAndValueWithPreload>
      )}

      {isReallocationAllowed && (
        <LabelAndValueWithPreload isLoading={dataIsLoading} label="Reallocations">
          <ProgressWithArrow
            prevValue={currencyFormatter(requested_reallocation || 0)}
            nextValue={currencyFormatter(approved_reallocation || 0)}
            getTextStyleForActive={isInReview}
            prevValueDataTestName={`${source}__reallocation__value`}
            nextValueDataTestName={`${source}__reallocation__next_value`}
          />
        </LabelAndValueWithPreload>
      )}
      {Boolean(retainageRate) && (
        <LabelAndValueWithPreload
          isLoading={dataIsLoading}
          label={`Retainage released for this draw`}
        >
          <ProgressWithArrow
            prevValue={currencyFormatter(retainage_release_requested || 0)}
            nextValue={currencyFormatter(retainage_release_approved || 0)}
            getTextStyleForActive={isInReview}
            prevValueDataTestName="project__request_approve_summary__retainage_release_requested"
            nextValueDataTestName="project__request_approve_summary__retainage_release_approved"
          />
        </LabelAndValueWithPreload>
      )}
      {flags?.['ENG_8141_credit_metric_to_checklist'] && (
        <LabelAndValueWithPreload isLoading={dataIsLoading} label="Credit">
          <ProgressWithArrow
            prevValueDataTestName="project__checklist__request_summary__credit__value"
            nextValueDataTestName="project__checklist__request_summary__credit__next_value"
            prevValue={currencyFormatter(requested_credit_amount || 0)}
            nextValue={currencyFormatter(approved_credit_amount || 0)}
            getTextStyleForActive={isInReview}
          />
        </LabelAndValueWithPreload>
      )}
      {flags?.['ENG_5682_borrower_equity_change_in_checklist'] && (
        <LabelAndValueWithPreload isLoading={dataIsLoading} label="Additional borrower equity">
          <ProgressWithArrow
            prevValue={currencyFormatter(requested_additional_borrower_equity || 0)}
            nextValue={currencyFormatter(additional_borrower_equity || 0)}
            getTextStyleForActive={isInReview}
          />
        </LabelAndValueWithPreload>
      )}

      {!showShortVersion && (
        <LabelAndValueWithPreload isLoading={dataIsLoading} label="Revised scheduled values">
          <ProgressWithArrow
            prevValue={currencyFormatter(requested_revised_estimate, '-')}
            nextValue={currencyFormatter(revised_estimate, '-')}
            showProgress
            prevValueType={requested_budget_change ? { [ValueTypeEnum.IS_ERROR]: true } : null}
            nextValueType={approved_budget_change ? { [ValueTypeEnum.IS_ERROR]: true } : null}
          />
        </LabelAndValueWithPreload>
      )}
    </Stack>
  );
};

export default RequestApproveSummaryBlock;
