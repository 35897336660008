import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { ICompanyFull } from '@interfaces';
import CompanyInfoField from '../CompanyInfoField';

const CompanyInfoDetails: FC<{ company: ICompanyFull; isEditAllowed: boolean }> = ({
  company,
  isEditAllowed,
}) => {
  return (
    <>
      <Stack direction="row" spacing={2.5}>
        <CompanyInfoField
          label="URL"
          value={company.url || ''}
          item="url"
          isEditAllowed={isEditAllowed}
          helperText="Invalid url"
        />
        <CompanyInfoField
          label="Employer identification number"
          value={company.ein || ''}
          item="ein"
          isEditAllowed={isEditAllowed}
          helperText="Employer identification number format should be XX-XXXXXX"
        />
        <CompanyInfoField
          label="Dun & Bradstreet D-U-N-S number"
          value={company.duns || ''}
          item="duns"
          isEditAllowed={isEditAllowed}
          helperText="Dun & Bradstreet D-U-N-S number format should be 9 digit number"
        />
      </Stack>
    </>
  );
};

export default CompanyInfoDetails;
