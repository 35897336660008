import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.gray }: ComponentProps) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4863 4.81063C19.3955 5.35539 18.8802 5.72336 18.3354 5.6325C17.7907 5.54165 17.4227 5.02638 17.5136 4.48162L17.628 3.7955L2.13487 14.3864C1.67893 14.698 1.05666 14.5811 0.744989 14.1252C0.433317 13.6692 0.550268 13.0469 1.00621 12.7353L16.5147 2.13392L15.7895 1.97781C15.2496 1.86158 14.9062 1.32966 15.0224 0.789747C15.1386 0.249831 15.6705 -0.0936294 16.2105 0.0226059L19.2107 0.668515C19.7329 0.780922 20.0745 1.2838 19.9867 1.81062L19.4863 4.81063ZM15 11.0002C15 10.4479 15.4477 10.0002 16 10.0002H19C19.5523 10.0002 20 10.4479 20 11.0002C20 11.5525 19.5523 12.0002 19 12.0002H16C15.4477 12.0002 15 11.5525 15 11.0002ZM8.5 14.0002C7.94772 14.0002 7.5 14.4479 7.5 15.0002C7.5 15.5525 7.94772 16.0002 8.5 16.0002H11.5C12.0523 16.0002 12.5 15.5525 12.5 15.0002C12.5 14.4479 12.0523 14.0002 11.5 14.0002H8.5ZM0 19.0002C0 18.4479 0.447715 18.0002 1 18.0002H4C4.55228 18.0002 5 18.4479 5 19.0002C5 19.5525 4.55228 20.0002 4 20.0002H1C0.447715 20.0002 0 19.5525 0 19.0002ZM7.5 19.0002C7.5 18.4479 7.94772 18.0002 8.5 18.0002H11.5C12.0523 18.0002 12.5 18.4479 12.5 19.0002C12.5 19.5525 12.0523 20.0002 11.5 20.0002H8.5C7.94772 20.0002 7.5 19.5525 7.5 19.0002ZM15 19.0002C15 18.4479 15.4477 18.0002 16 18.0002H19C19.5523 18.0002 20 18.4479 20 19.0002C20 19.5525 19.5523 20.0002 19 20.0002H16C15.4477 20.0002 15 19.5525 15 19.0002ZM16 14.0002C15.4477 14.0002 15 14.4479 15 15.0002C15 15.5525 15.4477 16.0002 16 16.0002H19C19.5523 16.0002 20 15.5525 20 15.0002C20 14.4479 19.5523 14.0002 19 14.0002H16Z"
      fill={color}
    />
  </svg>
);

export default Icon;
