import { FilterObject } from '../interface';

export const isOnHoldFilter: FilterObject = {
  title: 'On hold',
  filterKey: 'is_on_hold',
  defaultFilters: ['false'],
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getStaticValues: () => [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ],
  cypressSelector: 'filters__is_on_hold',
};
