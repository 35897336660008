import React from 'react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import truncate from 'lodash/truncate';
import { MAX_TEXT_LENGTH, fileTypesMap } from '@constants';
import { NoTypeIcon } from '@svgAsComponents';
import { colors, typography } from '@theme';
import { documentCanBeProcessed } from '@utils';

const nameColumn = ({ isLoading, source, drawRequestId, open }) => ({
  Header: 'Name',
  accessor: drawRequestId ? 'original_filename' : 'name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value }, row }) => {
    if (isLoading) return <Skeleton />;
    const Icon =
      fileTypesMap[row?.original?.mime_type] ||
      fileTypesMap[row?.original?.file_representations?.original?.mime_type] ||
      NoTypeIcon;

    const canBeOpen = documentCanBeProcessed(row?.original);
    return (
      <Tooltip title={value?.length > MAX_TEXT_LENGTH ? value : ''}>
        <Typography
          variant="body3"
          style={{
            display: 'flex',
            alignItems: 'center',
            ...(canBeOpen && {
              ...typography.body3SemiBold,
              color: colors.text.link,
              cursor: 'pointer',
            }),
            ...(!row?.original?.file && typography.body3SemiBold),
          }}
          onClick={() => (canBeOpen ? open([row?.original]) : {})}
          data-cy={`${source}__name__value__index_${row.index}`}
        >
          <Icon />
          <Box sx={{ pr: 1 }} />
          {truncate(value || row?.original?.document_type, { length: MAX_TEXT_LENGTH })}
        </Typography>
      </Tooltip>
    );
  },
});

export default nameColumn;
