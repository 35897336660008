import React, { useState, useEffect } from 'react';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { Typography, Stack } from '@mui/material';

import { AnimatedRedirect } from '../components';

const CustomLoginCallback = ({ errorComponent }) => {
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      // Once the authentication is no longer pending, hide the loader
      setLoading(false);
    }
  }, [authState]);

  return (
    <>
      {loading && (
        <Stack alignItems="center" sx={{ minHeight: '100vh' }}>
          <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} spacing={6}>
            <AnimatedRedirect />

            <Typography variant="h2">Redirecting...</Typography>
          </Stack>
        </Stack>
      )}
      <LoginCallback errorComponent={errorComponent} />
    </>
  );
};

export default CustomLoginCallback;
