import React, { FC, memo, useCallback, useContext } from 'react';
import { CommonRowType, MilestoneImages, MilestoneListColumnType } from './common';
import { FilesUploader } from '@components';
import { TableContext } from '../controller';
import { useFilesUploader } from '@hooks';
import { useParams } from 'react-router-dom';
import { ColumnV2Width, TransloaditTemplateEnum } from '@interfaces';
import { getFilteredByRepresentationPhotos } from '@utils';

const InspectionPhotos: FC<{ row: CommonRowType }> = ({ row }) => {
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();
  const { inspectionId, projectId } = useParams();
  // if row has an id -> it is milestone id
  const isMilestone = Boolean(row.id);
  const { source } = useContext(TableContext);

  const images = getFilteredByRepresentationPhotos(row.proofpoints);
  const uploadClick = useCallback(
    () =>
      uploadMedia({
        fields: {
          inspection_id: inspectionId,
          project_id: projectId,
        },
        templateType: TransloaditTemplateEnum.PROOFPOINTS,
      }),
    [],
  );

  const uploader = () => (
    <FilesUploader
      open={isFilesUploaderOpened}
      onClose={filesUploaderClose}
      transloaditSignature={transloaditSignature}
      fields={['document_type', ...(isMilestone ? ['milestone_id'] : [])]}
      metaDataDefault={{ ...(isMilestone && { milestone_id: row.id }) }}
      restrictions={restrictions}
      source={`${source}__body__inspection_photos__index_${row?.project_milestone?.index}`}
    />
  );

  return (
    <MilestoneImages
      images={images}
      canAddPhotos={row.canAddPhotos}
      disabled={row.disabled}
      uploadClick={uploadClick}
      isFilesUploaderOpened={isFilesUploaderOpened}
      transloaditSignature={transloaditSignature}
      uploader={uploader}
      generalRowDataSource={row.generalRowDataSource}
    />
  );
};

const Memoized = memo(InspectionPhotos);

const inspectionPhotos: MilestoneListColumnType = {
  name: 'proofpoints',
  columnText: 'Inspection photos',
  noMargin: true,
  noBorder: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.WIDE_ICON,
  maxWidth: () => ColumnV2Width.WIDE_ICON,
};

export default inspectionPhotos;
