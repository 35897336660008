export const integrationData = (domainName = 'domain_name', accessType = 'access_type') =>
  `companies/auth/connectors/?domain=${domainName}&access_type=${accessType}`;
export const companyUsers = (companyId: string, querySearch?: string | null, sorting?: string) =>
  `companies/${companyId}/users/?limit=999${querySearch ? `&${querySearch}` : ''}${sorting || ''}`;
export const companyUser = (companyId: string, userId: string) =>
  `companies/${companyId}/users/${userId}`;
export const companyProjects = (companyId: string) => `companies/${companyId}/projects/?limit=20`;
export const myCompany = () => 'companies/my/';
export const company = (companyId = 'company_pk') => `companies/${companyId}/`;
// TODO check why query not work on BE query
export const companies = (query: string, restQlParams?: string) =>
  // eslint-disable-next-line no-constant-condition
  query
    ? `companies/${query}${restQlParams && false ? `&query=${restQlParams}` : ''}`
    : 'companies/';
export const supersetDashboards = (companyId = 'company_pk') =>
  `companies/${companyId}/dashboards/`;
export const companyPoliciesTemplates = (companyId = 'company_pk') =>
  `checklists/companies/${companyId}/templates/`;
export const companyPoliciesTemplate = (
  companyId = 'company_pk',
  templateId = 'checklist_template_pk',
) => `checklists/companies/${companyId}/templates/${templateId}/`;
export const companyPoliciesProjectsList = (
  companyId = 'company_pk',
  templateId = 'checklist_template_pk',
) => `checklists/companies/${companyId}/templates/${templateId}/projects/?query={id,name}`;
export const companySettings = (companyId = 'company_pk') => `companies/${companyId}/settings/`;
