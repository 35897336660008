// TODO should be deleted after releasing ENG_7877_order_service

import React, { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ConfirmationModal, Popup, SuccessModal } from '@components';
import { IInspectionClosePopupEnum, PopupTypeEnum } from '@interfaces';
import { InspectionDialog } from '../index';
import * as Controller from './controller';
import { InspectionIcon } from '@svgAsComponents';

const OrderInspection = ({ drawRequestId, onClose }) => {
  const controller = Controller.useOrderInspection({ drawRequestId, onClose });
  const {
    confirmationModal,
    handleOrderInspection,
    showInspectionDialog,
    showOrderInspectionModal,
    popup,
    setPopup,
    closeModal,
    closeOrderInspectionModal,
  } = controller;

  const selectedPopup = useMemo(() => {
    switch (popup) {
      case IInspectionClosePopupEnum.SUCCESS:
        return <SuccessModal text="Inspection has been ordered" open onClose={closeModal} />;
      case IInspectionClosePopupEnum.MISSING_BORROWER_PHONE:
        return (
          <Popup
            open
            type={PopupTypeEnum.ERROR}
            title="Inspection representative phone number required"
          >
            <Stack alignItems="center" sx={{ width: '100%' }}>
              <Stack alignItems="center" mb={7.5} sx={{ whiteSpace: 'pre-line' }}>
                <Typography textAlign="center" variant="body2">
                  Before scheduling an inspection, we need the phone number of the inspection
                  coordinator, but it’s not set for your Borrower. Please contact
                  support@trustpoint.ai for assistance.
                </Typography>
              </Stack>
              <Button variant="new" color="secondary" onClick={closeModal}>
                Got it
              </Button>
            </Stack>
          </Popup>
        );
      default:
        return null;
    }
  }, [popup, drawRequestId, onClose]);

  return (
    <>
      {showInspectionDialog && (
        <InspectionDialog
          onClose={(popupType) => (popupType ? setPopup(popupType) : onClose())}
          drawRequestId={drawRequestId}
        />
      )}
      {selectedPopup}
      <ConfirmationModal
        open={showOrderInspectionModal}
        onClose={closeOrderInspectionModal}
        title={confirmationModal.title}
        text={confirmationModal.text}
        confirmCallback={handleOrderInspection}
        confirmButtonLabel="Order"
        type={PopupTypeEnum.GENERAL}
        icon={InspectionIcon}
      />
    </>
  );
};

export default OrderInspection;
