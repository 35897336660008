import React, { FC, useContext } from 'react';
import { Grid, Typography } from '@mui/material';

import { dateFormatter, isCustomer } from '@utils';
import { AuthContext } from '@context';
import { IProjectComment } from '@interfaces';
import { colors } from '@theme';
import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')(() => ({
  color: colors.text.medium,
}));

export const CommentHeader: FC<{ comment: IProjectComment; source: string; index: number }> = ({
  comment,
  source,
  index,
}) => {
  const { user } = useContext(AuthContext);
  const headerText = () => {
    if (comment?.is_external_comment && comment?.commenter) {
      return (
        <>
          <b>{comment.commenter.split(' / ')?.[0]}</b>
          <StyledSpan> {' / ' + comment.commenter.split(' / ')?.[1]}</StyledSpan>
        </>
      );
    }
    if (!comment.created_by) return '';
    return isCustomer(user) ? (
      <>
        <b>{comment.created_by?.full_name}</b>
        <StyledSpan> {comment.team?.name ? ' / ' + comment.team?.name : ''}</StyledSpan>
      </>
    ) : (
      <>
        <b>{comment.created_by?.full_name}</b>
        <StyledSpan>
          {comment.team?.company?.name ? ' / ' + comment.team?.company?.name : ''}
        </StyledSpan>
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography variant="label" data-cy={`${source}__comment__index_${index}__author`}>
          {headerText()}
        </Typography>
        <Typography
          variant="label"
          color={colors.text.medium}
          data-cy={`${source}__comment__index_${index}__date`}
        >
          {dateFormatter({ date: comment.created_at, withTime: true })}
        </Typography>
      </Grid>
    </>
  );
};

export default CommentHeader;
