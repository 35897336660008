import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { ComponentProps } from './interface';
import {
  ConfirmationModal,
  CustomTextField,
  EditPolicyItem,
  InfoIconWithTooltip,
  Popup,
} from '@components';
import { AuthContext, PermissionsContext } from '@context';
import { useStringFieldModel } from '@models';
import { PermissionNamesEnums, PoliciesItemLocal, PopupTypeEnum } from '@interfaces';
import { isPolicyItemDefault, isRestricted } from '@utils';
import PoliciesItemRow from './PoliciesItemRow';
import PoliciesTemplateSelect from './PoliciesTemplateSelect';
import { colors } from '@theme';

const PoliciesListEditMode = ({
  isCompanyPolicyTemplate,
  policiesList,
  setEditMode,
  handleSave,
  handleDelete,
  handleClose,
  templatesList,
  handleTemplateSelected,
  source,
  isPlatformPolicy,
}: ComponentProps) => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);

  const emptyItem = { label: '', created_by_team: user?.active_team?.id, is_custom: true };
  const [items, setItems] = useState<PoliciesItemLocal[]>([...(policiesList?.items || [])]);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  useEffect(() => setItems([...(policiesList?.items || [])]), [policiesList]);

  const [checked, setChecked] = useState<boolean>(!!policiesList?.is_default);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const name = useStringFieldModel({
    initValue: policiesList?.name,
    validationRule: (value) => Boolean(value?.trim()),
  });

  const saveList = useCallback(() => {
    const newItems = items.filter((item) => Boolean(item.label?.trim()));
    if (!isCompanyPolicyTemplate) {
      handleSave({ items: newItems });
      setEditMode(null);
      return;
    }

    if (name.value) {
      handleSave({ items: newItems, name: name.value, is_default: checked });
      setEditMode(null);
    }
  }, [items, name.value, checked]);

  const closeEditMode = useCallback(() => {
    handleClose && handleClose();
    setEditMode(null);
  }, [setEditMode, handleClose]);

  const confirmDelete = useCallback(() => {
    handleDelete();
    setEditMode(null);
  }, [setEditMode, handleDelete]);

  const openConfirmModal = useCallback(() => {
    name.validate();
    if (name.value) setConfirmationModalType('save');
  }, [name.value]);

  const [confirmationModalType, setConfirmationModalType] = useState<string>('');
  const closeConfimModal = () => setConfirmationModalType('');
  const modalText =
    'Please note that changing policy will affect future draw requests where this policy is used. Past and current requests will not be affected.';

  const isEditable = useCallback(
    (item: PoliciesItemLocal) => {
      if (!item.is_custom) return false;
      if (isCompanyPolicyTemplate) return true;
      if (isCompleted(item)) return false;
      return (
        item.created_by_team === user?.active_team?.id &&
        !isRestricted(PermissionNamesEnums.PROJECT__POLICIES__ADDITIONAL_ITEMS__EDIT, permissions)
      );
    },
    [permissions],
  );

  const isCompleted = (item) => item.checked || Boolean(item.exception_reasons?.length);

  const onChangeTemplate = (event: SelectChangeEvent) => {
    handleTemplateSelected(event.target.value);
    setSelectedTemplate(event.target.value);
  };

  const [openEditPolicyItem, setOpenEditPolicyItem] = useState<PoliciesItemLocal>();

  const confirmationModals = {
    default: (
      <Popup open type={PopupTypeEnum.ERROR} title="Warning">
        <Stack alignItems="center" sx={{ width: '100%' }}>
          <Stack alignItems="center" mb={7.5} sx={{ whiteSpace: 'pre-line' }}>
            <Typography textAlign="center" variant="body2">
              This policy template serves as the default for new projects and therefore cannot be
              deleted
            </Typography>
          </Stack>
          <Button variant="new" color="secondary" onClick={closeConfimModal}>
            Got it
          </Button>
        </Stack>
      </Popup>
    ),
    delete: (
      <ConfirmationModal
        open
        title="Are you sure?"
        text={`${name.value} will be deleted`}
        onClose={closeConfimModal}
        confirmCallback={confirmDelete}
        type={PopupTypeEnum.CONFIRMATION}
        source="policy__items__delete"
      />
    ),
    save: (
      <ConfirmationModal
        open
        title="Confirm changes"
        text={modalText}
        onClose={closeConfimModal}
        confirmCallback={saveList}
        type={PopupTypeEnum.CONFIRMATION}
        source="policy__items__update"
      />
    ),
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {isCompanyPolicyTemplate && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <CustomTextField
              field={name}
              label="Policy name"
              sx={{ flex: 1 }}
              inputProps={{
                'data-cy': `${source}_policy__items__policy_name__input`,
              }}
            />
            {!isPlatformPolicy && (
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    sx={{ width: 54 }}
                    data-cy={`${source}_policy__items__make_default__switch`}
                  />
                }
                componentsProps={{ typography: { variant: 'body2' } }}
                label="Make default"
              />
            )}
          </Stack>
          {!policiesList?.id && (
            <PoliciesTemplateSelect
              selectedTemplate={selectedTemplate}
              templatesList={templatesList}
              onChangeTemplate={onChangeTemplate}
            />
          )}
          <Stack pt={3} pb={0.5}>
            <Divider />
          </Stack>
        </>
      )}
      <Box sx={{ display: 'flex', flexFlow: 'column nowrap' }}>
        {items.map((item, index) => {
          if (isEditable(item))
            return (
              <Stack key={item.id || index}>
                <PoliciesItemRow
                  item={item}
                  index={index}
                  setItems={setItems}
                  limitedEditing={!isCompanyPolicyTemplate}
                  setOpenEditPolicyItem={() => setOpenEditPolicyItem(item)}
                  source={source}
                />
                <Divider />
              </Stack>
            );
          return (
            <Stack key={item.id || item.label}>
              <Stack
                direction="row"
                sx={{ mr: 1, mb: 1, ml: 1.5, mt: 1.5 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  {item.label}{' '}
                  {isPolicyItemDefault(item.type) &&
                    !isRestricted(
                      PermissionNamesEnums.PROJECT__DRAW_REQUEST__POLICIES__VIEW,
                      permissions,
                    ) && <Typography variant="label">(Default)</Typography>}
                </Typography>
                {!isPlatformPolicy && (
                  <InfoIconWithTooltip
                    data-cy={`${source}_policy__items__list__info_tooltip__icon__index_${index}`}
                    color={
                      item.description ? colors.status.information.medium : colors.neutral.medium
                    }
                    tooltipText={
                      item.description && (
                        <>
                          <Typography variant="labelSemiBold">Description:</Typography>
                          <Typography
                            variant="label"
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          />
                        </>
                      )
                    }
                  />
                )}
              </Stack>
              <Divider sx={{ mt: 0.5 }} />
            </Stack>
          );
        })}
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        sx={{ mt: 2 }}
      >
        <Button
          variant="text"
          onClick={closeEditMode}
          data-cy={`${source}_policy__items__edit__cancel__button`}
        >
          Cancel
        </Button>
        {isCompanyPolicyTemplate && policiesList?.id && handleDelete && (
          <Button
            variant="new"
            color="error"
            onClick={() =>
              setConfirmationModalType(policiesList?.is_default ? 'default' : 'delete')
            }
            data-cy={`${source}_policy__items__edit__delete__button`}
          >
            Delete
          </Button>
        )}
        <Button
          variant="new"
          color="secondary"
          onClick={() => setOpenEditPolicyItem(emptyItem)}
          data-cy={`${source}_policy__items__edit__add_policy__button`}
        >
          Add requirement
        </Button>
        <Button
          onClick={isCompanyPolicyTemplate && !isPlatformPolicy ? openConfirmModal : saveList}
          data-cy={`${source}_policy__items__edit__save__button`}
        >
          Save
        </Button>
      </Stack>

      <EditPolicyItem
        source={source}
        policyItem={openEditPolicyItem}
        saveItem={(editedItem: PoliciesItemLocal) =>
          setItems((old: PoliciesItemLocal[]) => {
            if (!openEditPolicyItem.label)
              return [...old, { ...openEditPolicyItem, ...editedItem }];
            return old.map((item: PoliciesItemLocal) => {
              if (item.id === openEditPolicyItem.id && item.label === openEditPolicyItem.label)
                return { ...item, ...editedItem };
              return item;
            });
          })
        }
        onClose={() => setOpenEditPolicyItem(null)}
        items={items}
      />
      {isCompanyPolicyTemplate && confirmationModals[confirmationModalType]}
    </DndProvider>
  );
};

export default PoliciesListEditMode;
