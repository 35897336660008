import { useSafeSnackbar } from '@hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorDual, MilestoneTag, MilestoneTagsTypesEnums, QueryNamesEnums } from '@interfaces';
import {
  getProjectMilestoneTags,
  patchProjectMilestoneTag,
  postProjectMilestoneTag,
} from '@globalService';
import { DropdownFieldModel, useDropdownFieldModel } from '@models';

export type ControllerInterface = {
  onNextClick: () => Promise<void>;
  mainLevelOption: DropdownFieldModel;
  nestedLevelOption: DropdownFieldModel;
  isLoading: boolean;
};

export const useChooseNamingModal = (): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectId } = useParams();

  const projectMilestoneTagsQuery = useQuery<{ results: MilestoneTag[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
    getProjectMilestoneTags.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const mainLevelOption = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.name_display?.trim()),
    validateOnChange: true,
  });

  const nestedLevelOption = useDropdownFieldModel({
    initValue: null,
  });

  const patchMsTag = useMutation<
    Response,
    ErrorDual,
    { projectId: string; milestoneTagId: string; json: Partial<MilestoneTag> }
  >(patchProjectMilestoneTag, {
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: 'error' });
    },
  });

  const postMsTag = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<MilestoneTag> }
  >(postProjectMilestoneTag, {
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: 'error' });
    },
  });

  const getTagId = (type: MilestoneTagsTypesEnums) =>
    projectMilestoneTagsQuery.data?.results.find((tag) => tag.type === type)?.id;

  const updateMsTag = async ({ type, name, projectId, tagId }) => {
    const tagData = {
      name,
      type,
    };

    if (tagId) {
      await patchMsTag.mutateAsync({
        projectId,
        milestoneTagId: tagId,
        json: tagData,
      });
    } else {
      await postMsTag.mutateAsync({
        projectId,
        json: tagData,
      });
    }
  };

  const onNextClick = async () => {
    const modelTagId = getTagId(MilestoneTagsTypesEnums.MODEL);
    if (mainLevelOption.value && nestedLevelOption.value) {
      await updateMsTag({
        type: MilestoneTagsTypesEnums.MODEL,
        name: mainLevelOption.value.name_display,
        projectId,
        tagId: modelTagId,
      });
    }

    const unitTagId = getTagId(MilestoneTagsTypesEnums.UNIT);
    await updateMsTag({
      type: MilestoneTagsTypesEnums.UNIT,
      name: nestedLevelOption.value?.name_display || mainLevelOption.value.name_display,
      projectId,
      tagId: unitTagId,
    });

    await updateMsTag({
      type: MilestoneTagsTypesEnums.LINE_ITEM,
      name: 'Line item',
      projectId,
      tagId: getTagId(MilestoneTagsTypesEnums.LINE_ITEM),
    });

    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS);

    nestedLevelOption.value ? navigate(`edit-models`) : navigate(`edit-units`);
  };
  return {
    onNextClick,
    mainLevelOption,
    nestedLevelOption,
    isLoading: projectMilestoneTagsQuery.isLoading || patchMsTag.isLoading || postMsTag.isLoading,
  };
};
