import React, { useContext, useMemo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';

import { LabelAndValue, StatusChip } from '@components';
import { IProject } from '@interfaces';
import { SettingsContext } from '@context';
import { statusMap } from '@constants';

interface ComponentProps {
  project: Partial<IProject>;
  showStatusChip?: boolean;
}
const ProjectNameLabel = ({ project, showStatusChip = false }: ComponentProps) => {
  const {
    settings: { display: staticStatuses },
    isCurrentProjectActive,
  } = useContext(SettingsContext);
  const statusValue = useMemo(
    () => (staticStatuses ? statusMap(project?.status, staticStatuses, 'project') : {}),
    [project?.status, staticStatuses],
  );

  return (
    <Stack direction="row" spacing={1}>
      <LabelAndValue
        label="Project:"
        text={project?.name}
        size="body2"
        marginRightAfterLabel={false}
      />
      {showStatusChip && statusValue && (
        <Tooltip
          title={
            isCurrentProjectActive ? (
              ''
            ) : (
              <>
                Reason:{' '}
                <Typography
                  variant="body3"
                  dangerouslySetInnerHTML={{ __html: project?.status_change_reason }}
                />
              </>
            )
          }
        >
          <span>
            <StatusChip
              color={statusValue.color}
              backgroundColor={statusValue.backgroundColor}
              label={statusValue.text}
              size="medium"
            />
          </span>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ProjectNameLabel;
