import { QueryNamesEnums } from '@interfaces';
import {
  getDrawRequestMilestones,
  getProjectWithQuery,
  getDrawRequestMilestone,
  getCompaniesListByRoleWithQuery,
  getProjectsTeamsWithQuery,
  getCoordinatorsWithQuery,
  getCompaniesWithQuery,
  getInspectionAgenciesWithQuery,
  getProjectPropertWithQuery,
  getProjectTypesWithQuery,
  getStatesWithQuery,
  getProjectMilestone,
} from '@globalService';

export const dataProviders = {
  [QueryNamesEnums.GET_PROJECT]: getProjectWithQuery,
  [QueryNamesEnums.GET_PROJECT_MILESTONE]: getProjectMilestone,
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE]: getDrawRequestMilestone,
} as const;

export const dataProvidersLists = {
  [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: getDrawRequestMilestones,
  [QueryNamesEnums.GET_COMPANIES_BY_ROLE]: getCompaniesListByRoleWithQuery,
  [QueryNamesEnums.GET_PROJECTS_TEAMS]: getProjectsTeamsWithQuery,
  [QueryNamesEnums.GET_COORDINATORS]: getCoordinatorsWithQuery,
  [QueryNamesEnums.GET_COMPANIES]: getCompaniesWithQuery,
  [QueryNamesEnums.GET_INSPECTION_AGENCIES]: getInspectionAgenciesWithQuery,
  [QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES]: getProjectPropertWithQuery,
  [QueryNamesEnums.GET_PROJECT_TYPES]: getProjectTypesWithQuery,
  [QueryNamesEnums.GET_STATES]: getStatesWithQuery,
} as const;
