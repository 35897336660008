import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  getHookState,
  getLink,
  getSortQueryString,
  getTeamRole,
  isCreatedInspection,
  isRestricted,
  tableHeaders as headers,
} from '@utils';
import {
  ColumnWidth,
  HookState,
  IInspection,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';
import { getInspectionsList, getInspectionsListNew } from '@globalService';
import { useColumnFilterV2, useMultiFiltering, useSorting, useTablePagination } from '@hooks';

export interface ControllerInterface {
  state: HookState;
  rows: IInspection[];
  columns: object[];
  handleFiltersChange: any;
  resetFiltersToDefault: () => void;
  filters: any;
  page: number;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  onPageChange: (event, newPage) => void;
  onRowsPerPageChange: (event) => void;
  itemsCount: number;
  handleSortClick: () => void;
  sortValue: string[];
  isFetching: boolean;
  isLoading: boolean;
  hiddenColumns: string[];
  changeFieldVisibility: (id: string) => void;
  isColumnFilterUpdating: boolean;
  handleRowClick: (row) => void;
  setFilterStringQuery: Dispatch<SetStateAction<string>>;
}

export const useInspectionsList = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const navigate = useNavigate();
  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination();
  const { handleSortClick, sortValue } = useSorting();
  const { handleFiltersChange, resetFiltersToDefault, filters } = useMultiFiltering(
    TableKeyEnum.INSPECTION_LIST,
  );
  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const flags = useLaunchDarklyFlags();

  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.INSPECTION_LIST,
  );

  const sortingString = getSortQueryString({ sortValue });
  const params = {
    pagination: 'true',
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage.toString(),
    sorting: sortingString,
  };

  const queryParams = new URLSearchParams(params);
  const queryURL = queryParams.toString() + `${filterStringQuery ? `&${filterStringQuery}` : ''}`;

  const inspectionsQueryNew = useQuery<{ results: IInspection[]; count: number }, Error>(
    [QueryNamesEnums.GET_INSPECTIONS_LIST, { queryURL }],
    getInspectionsListNew.bind(this, { queryURL }),
    { keepPreviousData: true },
  );

  const inspectionsQuery = useQuery<{ results: IInspection[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_INSPECTIONS_LIST,
      {
        params: {
          pagination: true,
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          sorting: getSortQueryString({ sortValue }),
          filtering: filters,
        },
      },
    ],
    getInspectionsList,
    {
      keepPreviousData: true,
    },
  );
  const { data, isLoading, isFetching } = useMemo(
    () =>
      flags?.['ENG_7663_customer_in_inspections_list'] ? inspectionsQueryNew : inspectionsQuery,
    [flags, inspectionsQuery, inspectionsQueryNew],
  );

  const itemsCount = useMemo(() => data?.count, [data]);

  const rows = useMemo(
    () =>
      isLoading ? Array(10).fill({}) : data?.results?.filter((o) => !isCreatedInspection(o.status)),
    [isLoading, data],
  );

  const columns = useMemo(
    () => [
      headers.inspectionName({
        isLoading,
      }),
      headers.headerTemplate({
        headerName: 'Loan #',
        accessor: 'project.loan.external_id',
        isLoading,
        disableSortBy: true,
      }),
      headers.headerTemplate({
        isLoading,
        headerName: 'Project',
        accessor: 'project.name',
        disableSortBy: true,
      }),
      headers.serviceStatus({
        isLoading,
        disableSortBy: false,
      }),
      headers.inspectionAgencyStatus({
        isLoading,
      }),
      headers.date({
        accessor: 'ordered_at',
        header: 'Date ordered',
        isLoading,
      }),
      headers.date({
        accessor: 'gc_requested_at',
        header: 'Date requested',
        isLoading,
      }),
      headers.scheduledInspectionDate({
        accessor: 'scheduled_at',
        header: 'Date scheduled',
        isLoading,
        disableSortBy: true,
      }),
      headers.date({
        accessor: 'completed_at',
        header: 'Date completed',
        isLoading,
      }),
      headers.inspectionServiceColumn({
        header: 'Agency',
        isLoading,
        teamRole,
      }),
      headers.headerTemplate({
        headerName: 'External Id',
        accessor: 'provider_order_id',
        isLoading,
        disableSortBy: true,
      }),
      headers.inspectorAllowanceColumn({
        isLoading,
      }),
      headers.headerTemplate({
        headerName: 'Address',
        accessor: 'project.address.address_1',
        isLoading,
        disableSortBy: true,
      }),
      headers.headerTemplate({
        headerName: 'City',
        accessor: 'project.address.city',
        isLoading,
        disableSortBy: true,
      }),
      headers.state({ isLoading, accessor: 'project.address.state' }),
      headers.headerTemplate({
        headerName: 'Project type',
        accessor: 'project.type',
        isLoading,
        disableSortBy: true,
      }),
      headers.drNumber({
        isLoading,
      }),
      headers.propertyType({
        isLoading,
        disableSortBy: true,
        accessor: 'project.property_existing_type',
        header: 'Existing property type',
      }),
      headers.propertyType({
        isLoading,
        disableSortBy: true,
        accessor: 'project.property_proposed_type',
        header: 'Proposed property type',
      }),
      headers.projectStatus({
        isLoading,
        accessor: 'project.status',
        disableSortBy: true,
      }),
      ...(flags?.['ENG_7663_customer_in_inspections_list'] &&
      !isRestricted(PermissionNamesEnums.REQUEST_QUEUE__CUSTOMER__VIEW, permissions)
        ? [
            headers.headerTemplate({
              headerName: 'Customer',
              accessor: 'customer.name',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
      headers.headerTemplate({
        headerName: 'Borrower',
        accessor: 'project.borrower_name',
        isLoading,
        disableSortBy: true,
      }),
      headers.amount({
        header: 'Loan commitment',
        accessor: 'project.loan.loc_commitment',
        isLoading,
        disableSortBy: true,
      }),
      headers.amount({
        header: 'Original scheduled value ($)',
        accessor: 'project.original_estimate',
        isLoading,
        disableSortBy: true,
      }),
      headers.amount({
        header: 'Revised scheduled value ($)',
        accessor: 'project.revised_estimate',
        isLoading,
        minWidth: ColumnWidth.WIDE_TEXT,
        disableSortBy: true,
      }),
      ...(!isRestricted(PermissionNamesEnums.REQUEST_QUEUE__SCORES__VIEW, permissions)
        ? [
            headers.projectScore({
              headerName: 'Project health',
              accessor: 'project.overall_score',
              isLoading,
              disableSortBy: true,
            }),
          ]
        : []),
    ],
    [isLoading, hiddenColumns, permissions, teamRole, flags],
  );

  const handleRowClick = (row) => {
    const link = getLink({ row, tableKey: TableKeyEnum.INSPECTION_LIST });
    link && navigate(link);
  };

  return {
    state: getHookState(inspectionsQuery),
    rows,
    columns,
    handleFiltersChange,
    resetFiltersToDefault,
    filters,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange,
    itemsCount,
    handleSortClick,
    sortValue,
    isFetching,
    isLoading,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    handleRowClick,
    setFilterStringQuery,
  };
};
