import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { checkIsGeneralContractor, checkIsPaymentManager, getTeamRole } from '@utils';
import { AuthContext, SettingsContext } from '@context';
import { IProject, QueryNamesEnums, ICommentsPreview, TTeamRole } from '@interfaces';
import { getProject } from '@globalService';

const requiredKeys = ['status', 'name', 'comments_preview'] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;

export interface ControllerInterface {
  showMessagePanel: boolean;
  projectName: string;
  projectCommentsPreview: ICommentsPreview;
  showScores: boolean;
  teamRole: TTeamRole;
}

export const useProjectMainInfo = (projectId: string): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);

  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const showMessagePanel = useMemo(
    () => !checkIsGeneralContractor(teamRole) && !checkIsPaymentManager(teamRole),
    [teamRole],
  );

  return {
    showMessagePanel,
    teamRole,
    projectName: projectQuery.data?.name,
    projectCommentsPreview: projectQuery.data?.comments_preview,
    showScores: !isCurrentProjectArchived,
  };
};
