import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const inspectionAgencyFilter: FilterObject = {
  title: 'Agency',
  filterKey: 'inspection_company',
  permissionKey: PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParams: {
    type: QueryNamesEnums.GET_INSPECTION_AGENCIES,
    keys: ['id', 'display_name'],
    args: {},
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.id,
          label: item.display_name,
        })),
    },
  },
  cypressSelector: 'filters__inspection_agency',
};
