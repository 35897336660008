import {
  DeleteAllListItemsParam,
  DeleteBuildingParam,
  DeleteListItemParam,
  DeleteModelParam,
  EnumTypeForList,
  ICompany,
  IDocument,
  IDocumentType,
  IMilestone,
  IMilestoneColumn,
  IMilestonePhotos,
  IMilestoneTotal,
  IPermission,
  IProject,
  IProjectCoordinator,
  IProjectCreatePayload,
  IProjectFunds,
  IProjectMilestone,
  IProjectProgress,
  IProjectProperty,
  IProjectScoresNew,
  IProjectSettings,
  IProjectsPortfolioStats,
  IProjectStats,
  IProjectUserData,
  IPropertyDetail,
  IState,
  ITeam,
  IUser,
  IWorkflowReason,
  MilestoneTag,
  PatchBuildingParam,
  PatchListItemParam,
  PatchModelParam,
  PatchProjectProdBuildGroupParam,
  UpdateProjectPhotoPayload,
} from '@interfaces';

import {
  companiesByRole,
  inviteProjectBorrowersPath,
  milestoneById,
  project,
  projectBuildingById,
  projectBuildingModelById,
  projectBuildingModels,
  projectBuildingModelsDelete,
  projectBuildings,
  projectCoordinators,
  projectCoordinatorsWithQuery,
  projectDocumentTypeByName,
  projectDocumentTypes,
  projectExitStrategies,
  projectFunds,
  projectGroupStats,
  projectMilestoneColumns,
  projectMilestoneDocuments,
  projectMilestoneGroupDocuments,
  projectMilestones,
  projectMilestonesBulk,
  projectMilestonesDelete,
  projectMilestonesList,
  projectMilestoneTagById,
  projectMilestoneTags,
  projectPermissions,
  projectPhotos,
  projectProgress,
  projectPropertyDetailsNames,
  projectPropertyTypes,
  projectPropertyTypesWithQuery,
  projects,
  projectsCompanies,
  projectScoresNew,
  projectSettings,
  projectsPathList,
  projectsStates,
  projectsStatesWithQuery,
  projectsStats,
  projectsTeams,
  projectsTeamsWithQuery,
  projectsUsers,
  projectTeams,
  projectTeamsWithQuery,
  projectThumb,
  projectTypes,
  projectTypesWithQuery,
  projectUserData,
  workflowReasons,
} from '../paths';

import { dataProvider, dataProviderV2 } from '@api';
import { pickBy } from 'lodash';
import { InterfaceGetProjectWithQuery } from '../args/index';

export const getProjectList = async (url): Promise<{ results: IProject[]; count: number }> =>
  dataProvider.get(projectsPathList(url)).json();

export const getProjectWithQuery = async ({
  projectId,
  restQlParams,
}: InterfaceGetProjectWithQuery): Promise<Partial<IProject>> => {
  return dataProvider.get(project(projectId, restQlParams)).json();
};

export const getProject = async (id: string): Promise<IProject> =>
  dataProvider.get(project(id)).json();

export const updateProjectFields = async ({
  projectId,
  json,
}: {
  projectId: string;
  json: Partial<IProject>;
}): Promise<Response> =>
  dataProvider.patch(project(projectId), {
    json,
  });

export const getProjectFunds = async (id: string): Promise<IProjectFunds> =>
  dataProvider.get(projectFunds(id)).json();

export const getProjectMilestoneListColumns = async (projectId): Promise<IMilestoneColumn[]> =>
  await dataProvider.get(projectMilestoneColumns(projectId)).json();

export const getProjectProgress = async (projectId: string): Promise<IProjectProgress> =>
  dataProvider.get(projectProgress(projectId)).json();

export const getProjectBuildings = async (
  projectId: string,
): Promise<{ results: IProjectProperty[]; count: number }> =>
  dataProvider.get(projectBuildings(projectId)).json();

export const createProjectBuilding = async ({
  projectId,
  details,
}: {
  projectId: string;
  details?: IPropertyDetail[];
}): Promise<Response> =>
  dataProvider.post(projectBuildings(projectId), {
    json: { project: projectId, ...(details && { details }) },
  });

export const getProjectScoresNew = async (id: string): Promise<IProjectScoresNew> =>
  dataProvider.get(projectScoresNew(id)).json();

export const getProjectSettings = async (projectId: string): Promise<IProjectSettings> =>
  dataProvider.get(projectSettings(projectId)).json();

export const getProjectDocumentTypes = async (): Promise<IDocumentType[]> =>
  dataProvider.get(projectDocumentTypes()).json();

export const getProjectDocumentTypeByName = async (documentType: string): Promise<IDocumentType> =>
  dataProvider.get(projectDocumentTypeByName(documentType)).json();

export const getProjectMilestonesList = async ({
  projectId,
  query,
  groupBy: group_by,
  limit = '999',
  filterKey,
}: {
  projectId: string;
  query?: string;
  groupBy?: string;
  limit?: string;
  filterKey?: string;
}): Promise<{ results: IProjectMilestone[]; totals: IMilestoneTotal; count: number }> => {
  const notEmptyParams = pickBy({ limit, query, group_by }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParams = filterKey ? `${filterKey}=true&` : '';
  return dataProvider
    .get(projectMilestonesList({ projectId, queryFiltersParams, filterParams }))
    .json();
};

export const getProjectUserData = async (id: string): Promise<IProjectUserData> =>
  dataProvider.get(projectUserData(id)).json();

export const getProjectTeams = async (
  projectId: string,
  companyId?: string,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectTeams(projectId, companyId)).json();

export const getProjectTeamsWithQuery = async ({
  projectId,
  query,
}: {
  projectId: string;
  query?: string;
}): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectTeamsWithQuery(projectId, query)).json();

export const getProjectTypesWithQuery = async ({
  restQlParams,
  query,
}: {
  query?: string;
  restQlParams?: string;
}): Promise<{ results: EnumTypeForList[] }> =>
  dataProvider.get(projectTypesWithQuery({ restQlParams, query })).json();

export const getProjectTypes = async (query?: string): Promise<{ results: EnumTypeForList[] }> =>
  dataProvider.get(projectTypes(query)).json();

export const getProjectPropertyTypes = async (
  query?: string,
): Promise<{ results: EnumTypeForList[] }> => dataProvider.get(projectPropertyTypes(query)).json();

export const getProjectPropertWithQuery = async ({
  restQlParams,
  query,
}: {
  query?: string;
  restQlParams?: string;
}): Promise<{ results: EnumTypeForList[] }> =>
  dataProvider.get(projectPropertyTypesWithQuery({ restQlParams, query })).json();

export const getProjectPropertyDetailsNames = async (): Promise<{
  results: EnumTypeForList[];
}> => dataProvider.get(projectPropertyDetailsNames()).json();

export const getProjectExitStrategiesList = async (): Promise<{
  results: EnumTypeForList[];
}> => dataProvider.get(projectExitStrategies()).json();

export const pathMilestoneItem = ({
  project,
  milestone,
  json,
}: PatchListItemParam): Promise<Response> =>
  dataProvider
    .patch(milestoneById(project, milestone), {
      json,
    })
    .json();

export const getProjectMilestone = async ({
  projectId,
  milestoneId,
  query = '',
}): Promise<IMilestone> => dataProvider.get(milestoneById(projectId, milestoneId, query)).json();

export const deleteMilestoneItem = ({
  project,
  milestone,
}: DeleteListItemParam): Promise<Response> =>
  dataProvider.delete(milestoneById(project, milestone));

export const deleteProjectMilestones = async ({
  projectId,
  isHorizontal: is_horizontal,
  isVertical: is_vertical,
}: DeleteAllListItemsParam): Promise<Response> => {
  const notEmptyParams = pickBy({ is_horizontal, is_vertical }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  return dataProvider.post(projectMilestonesDelete(projectId, queryFiltersParams)).json();
};

export const getCompaniesListByRoleWithQuery = async ({
  restQlParams,
  role,
}: {
  role?: string;
  restQlParams?: string;
}): Promise<{ results: ICompany[] }> =>
  dataProvider.get(projectsCompanies({ restQlParams, role })).json();

export const getCompaniesListByRole = async (role): Promise<{ results: ICompany[] }> =>
  dataProvider.get(companiesByRole({ role })).json();

export const getProjectPermissions = async (projectId: string): Promise<IPermission[]> =>
  dataProvider.get(projectPermissions(projectId)).json();

export const getProjectPhotos = async ({
  projectId,
  query,
}: {
  projectId: string;
  query?: string;
}): Promise<IMilestonePhotos[]> => dataProvider.get(projectPhotos(projectId, query)).json();

export const getStates = async (): Promise<IState[]> => dataProvider.get(projectsStates()).json();

export const getStatesWithQuery = async ({
  restQlParams,
}: {
  restQlParams: string;
}): Promise<{ results: IState[] }> =>
  dataProviderV2.get(projectsStatesWithQuery({ restQlParams })).json();

export const getProjectsTeamsWithQuery = async ({
  restQlParams,
}: {
  restQlParams: string;
}): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectsTeamsWithQuery({ restQlParams })).json();

export const getProjectsTeams = async (): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectsTeams()).json();

export const getCoordinatorsWithQuery = async ({
  restQlParams,
}: {
  restQlParams: string;
}): Promise<{ results: IUser[] }> =>
  dataProvider.get(projectCoordinatorsWithQuery({ restQlParams })).json();

export const getCoordinators = async (): Promise<{ results: IUser[] }> =>
  dataProvider.get(projectCoordinators()).json();

export const getProjectsUsers = async (stringQueryParams: string): Promise<{ results: IUser[] }> =>
  dataProvider.get(projectsUsers(stringQueryParams)).json();

export const getProjectsStats = async (
  stringQueryParams: string,
): Promise<IProjectsPortfolioStats> => dataProvider.get(projectsStats(stringQueryParams)).json();

export const postProject = async (projectData: IProjectCreatePayload): Promise<IProject> =>
  dataProvider
    .post(projects(), {
      json: projectData,
    })
    .json();

export const postMilestoneToProject = ({ project }): Promise<Response> =>
  dataProvider.post(projectMilestones(project));

export const postBuildingModelToProject = ({ project }): Promise<Response> =>
  dataProvider.post(projectBuildingModels(project), {
    json: { project },
  });

export const deleteProjectBuildingModels = ({ project }): Promise<Response> =>
  dataProvider.post(projectBuildingModelsDelete(project));

export const deleteProjectBuildingModel = ({
  project,
  modelId,
}: DeleteModelParam): Promise<Response> =>
  dataProvider.delete(projectBuildingModelById(project, modelId));

export const deleteProjectBuilding = ({
  project,
  buildingId,
}: DeleteBuildingParam): Promise<Response> =>
  dataProvider.delete(projectBuildingById(project, buildingId));

export const getProjectModels = (
  projectId: string,
): Promise<{
  results: IProjectProperty[];
  count: number;
}> => dataProvider.get(projectBuildingModels(projectId)).json();

export const getProjectMilestoneTags = (
  projectId: string,
): Promise<{
  results: MilestoneTag[];
}> => dataProvider.get(projectMilestoneTags(projectId)).json();

export const postProjectMilestoneTag = ({
  projectId,
  json,
}: {
  projectId: string;
  json: Partial<MilestoneTag>;
}): Promise<Response> =>
  dataProvider
    .post(projectMilestoneTags(projectId), {
      json,
    })
    .json();

export const patchProjectMilestoneTag = ({
  projectId,
  milestoneTagId,
  json,
}: {
  projectId: string;
  milestoneTagId: string;
  json: Partial<MilestoneTag>;
}): Promise<Response> =>
  dataProvider
    .patch(projectMilestoneTagById(projectId, milestoneTagId), {
      json,
    })
    .json();

export const patchProjectModel = ({ project, model, json }: PatchModelParam): Promise<Response> =>
  dataProvider
    .patch(projectBuildingModelById(project, model), {
      json,
    })
    .json();

export const patchProjectBuilding = ({
  project,
  building,
  json,
}: PatchBuildingParam): Promise<Response> =>
  dataProvider
    .patch(projectBuildingById(project, building), {
      json,
    })
    .json();

export const getProjectGroupsStats = async (stringQueryParams: string): Promise<IProjectStats> =>
  dataProvider.get(projectGroupStats(stringQueryParams)).json();

export const getWorkflowReasons = async (): Promise<IWorkflowReason[]> =>
  dataProvider.get(workflowReasons()).json();

export const postThumbToProject = ({
  projectId,
  file_representations,
}: UpdateProjectPhotoPayload): Promise<Response> =>
  dataProvider
    .post(projectThumb(projectId), {
      json: { file_representations },
    })
    .json();

export const getProjectMilestoneDocuments = async ({
  projectId,
  milestoneId,
  query = '',
}: {
  projectId: string;
  milestoneId: string;
  query?: string;
}): Promise<{ results: IDocument[] }> =>
  dataProvider.get(projectMilestoneDocuments(projectId, milestoneId, query)).json();

export const getProjectMilestoneGroupDocuments = async ({
  projectId,
  milestoneId,
}: {
  projectId: string;
  milestoneId: string;
}): Promise<{ results: IDocument[] }> =>
  dataProvider.get(projectMilestoneGroupDocuments(projectId, milestoneId)).json();

export const inviteProjectBorrowers = async ({ projectId }): Promise<Response> =>
  dataProvider.post(inviteProjectBorrowersPath(projectId));

export const updateProjectCoordinator = async ({
  projectId,
  coordinatorId,
}: IProjectCoordinator): Promise<Response> =>
  dataProvider.patch(project(projectId), { json: { coordinator: coordinatorId } });

export const patchProjectProdBuildGroup = ({
  project,
  group_by,
  tags,
  json,
  filterKey,
}: PatchProjectProdBuildGroupParam): Promise<IMilestone> => {
  const filterParams = filterKey ? `${filterKey}=true&` : '';

  return dataProvider
    .patch(projectMilestonesBulk({ project, group_by, tags, filterParams }), {
      json,
    })
    .json();
};
