import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from '../common';
import { PermissionsContext } from '@context';
import { isRestricted } from '@utils';
import { useDebounceInput, useStringFieldModel } from '@models';
import {
  ColumnAlign,
  ColumnV2Width,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
} from '@interfaces';
import { TextInputWithTooltip } from '@components';
import { TableContext } from '../../controller';

const Square: FC<{ row: ProductionBuildCommonRowType }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const { apiUpdate, resetMutation, source } = useContext(TableContext);

  const square = useStringFieldModel({
    initValue: (row['sqft'] || '').toString(),
    withProgressCheck: !row.isModelBuilding,
    blockNavigationKey: 'sqft',
  });
  const isEditable = useMemo(
    () =>
      !row.isModelBuilding &&
      !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__NEWLINE_ADD, permissions),
    [permissions, row],
  );

  const effect = useCallback(() => {
    if (row.sqft !== square.value) {
      apiUpdate({
        milestoneId: row.id,
        name: 'sqft',
        value: square.value,
        isNonNumber: true,
      });
    }
  }, [row, square]);

  const { onBlur } = useDebounceInput({
    effect,
    handleChange: square.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { sqft: '' } }),
    afterEffect: () => square.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <Stack flexDirection="row" maxWidth={220} justifyContent="space-between">
      <TextInputWithTooltip
        type="string"
        value={square.value}
        onChange={square.handleChange}
        onBlur={onBlur}
        dataTestName={`${source}__body__sqft__value__index_${row.index}`}
      />
    </Stack>
  ) : (
    <Box textAlign={ColumnAlign.TEXT}>
      <Typography variant="body3">{row.sqft}</Typography>
    </Box>
  );
};

const square: MilestoneListColumnType = {
  name: 'sqft',
  columnText: 'Square',
  noBorder: true,
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__NEWLINE_ADD, permissions),
  renderCell: ({ row }) => <Square row={row} />,
  justifyContent: 'flex-start',
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText alignItems="flex-start" tooltipText="Square of the item">
      <ColumnLabel>Square</ColumnLabel>
    </HeaderText>
  ),
};

export default square;
