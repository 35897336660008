import React, { FC } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLineItemBreakdownPanel } from './controller';
import {
  BreakdownGraphs,
  CustomCurrencyTextField,
  CustomRadioGroup,
  CustomTextField,
} from '@components';
import { RequestTableTabTypesEnum } from '@interfaces';

const LineItemBreakdownPanel: FC<{
  milestoneId?: string;
  requestId?: string;
  setOpen?: CallableFunction;
  type?: RequestTableTabTypesEnum;
  source?: string;
}> = ({ milestoneId, requestId, setOpen, type, source }) => {
  const {
    itemName,
    revisedScheduledValue,
    additionalBorrowerEquity,
    requestedAdditionalBorrowerEquity,
    costType,
    handleCostTypeChange,
    costTypeOptions,
    submit,
    isSubmiting,
    deleteLineItem,
    isEditable,
    isApproveBreakdown,
  } = useLineItemBreakdownPanel({
    requestId,
    milestoneId,
    setOpen,
    type,
  });

  return (
    <Stack flex={1}>
      <Stack mt={1} flex={1} spacing={4}>
        <Stack>
          <BreakdownGraphs requestId={requestId} milestoneId={milestoneId} />
        </Stack>
        <Divider />
        <CustomTextField
          field={itemName}
          label="Line item name"
          required
          disabled={!isEditable}
          inputProps={{ 'data-cy': `${source}__line_item_modal__name_input` }}
        />
        {isApproveBreakdown ? (
          <Stack spacing={4}>
            <CustomCurrencyTextField
              field={revisedScheduledValue}
              label="Revised scheduled value ($)"
              disabled={!isEditable}
              required
              inputProps={{ 'data-cy': `${source}__line_item_modal__revised_estimate__input` }}
            />
            <Stack direction="row" spacing={2}>
              <CustomCurrencyTextField
                field={requestedAdditionalBorrowerEquity}
                label="Requested additional borrower equity ($)"
                inputProps={{
                  'data-cy': `${source}__line_item_modal__requested_additional_borrower_equity__input`,
                }}
                disabled
              />
              <CustomCurrencyTextField
                field={additionalBorrowerEquity}
                label="Approved additional borrower equity ($)"
                inputProps={{
                  'data-cy': `${source}__line_item_modal__additional_borrower_equity__input`,
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2}>
            <CustomCurrencyTextField
              field={revisedScheduledValue}
              label="Revised scheduled value ($)"
              disabled={!isEditable}
              required
              inputProps={{ 'data-cy': `${source}__line_item_modal__revised_estimate__input` }}
            />
            <CustomCurrencyTextField
              field={requestedAdditionalBorrowerEquity}
              label="Additional borrower equity ($)"
              inputProps={{
                'data-cy': `${source}__line_item_modal__additional_borrower_equity__input`,
              }}
            />
          </Stack>
        )}
        <CustomRadioGroup
          disabled={!isEditable}
          dataTestName={`${source}__line_item_modal__cost_type__radio_group`}
          value={costType}
          onChange={handleCostTypeChange}
          options={costTypeOptions}
        />
      </Stack>
      <Stack spacing={2} justifyContent="flex-end" direction="row">
        {isEditable && (
          <Button
            onClick={deleteLineItem}
            color="error"
            data-cy={`${source}__line_item_modal__delete__button`}
          >
            Delete
          </Button>
        )}
        <LoadingButton
          loading={!!isSubmiting}
          onClick={submit}
          data-cy={`${source}__line_item_modal__submit__button`}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default LineItemBreakdownPanel;
