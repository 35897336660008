import { useCallback, useContext, useEffect, useState } from 'react';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';

import { DrawRequestTypeEnum, TableKeyEnum } from '@interfaces';
import { useUpdateUiSettings } from '@hooks';
import { SettingsContext } from '@context';
import { useDebouncedEffect } from '@models';
import { transformObjectToFalseKeysArray } from '@utils';

export const useColumnFilterV2 = (tableKey: TableKeyEnum | DrawRequestTypeEnum) => {
  const { updateSettings, isColumnFilterUpdating } = useUpdateUiSettings();
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    const savedColumns = settings.personal_setting?.tables?.[tableKey]?.hidden_columns;
    if (savedColumns) setHiddenColumns(savedColumns);
  }, [tableKey, settings]);

  const changeFieldVisibility = useCallback(
    (id: string) => {
      setHiddenColumns((oldsList) =>
        oldsList.includes(id) ? oldsList.filter((item) => item !== id) : [...oldsList, id],
      );
    },
    [tableKey],
  );

  const setColumnVisibilityModel = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      setHiddenColumns(transformObjectToFalseKeysArray(newModel));
    },
    [tableKey],
  );

  useDebouncedEffect(
    () => {
      if (!settings.personal_setting?.tables?.[tableKey]) return;
      if (settings.personal_setting?.tables?.[tableKey]?.hidden_columns !== hiddenColumns) {
        updateSettings({
          personal_setting: {
            tables: {
              ...settings.personal_setting?.tables,
              [tableKey]: {
                ...settings.personal_setting?.tables?.[tableKey],
                hidden_columns: hiddenColumns,
              },
            },
          },
        });
      }
    },
    [hiddenColumns],
    1000,
  );

  const updateUserColumns = useCallback(
    (columns) => {
      setHiddenColumns(columns);
      updateSettings({
        personal_setting: {
          tables: {
            ...settings.personal_setting?.tables,
            [tableKey]: {
              ...settings.personal_setting?.tables?.[tableKey],
              hidden_columns: columns,
            },
          },
        },
      });
    },
    [updateSettings, hiddenColumns, settings],
  );

  return {
    updateUserColumns,
    setHiddenColumns,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    setColumnVisibilityModel,
  };
};
