import { useQuery } from 'react-query';
import { useContext } from 'react';

import { getTooltipText, isActionEnabled, isRequestApproved, isRequestHistorical } from '@utils';
import { IDrawRequest, QueryNamesEnums } from '@interfaces';
import { getDrawRequest } from '@globalService';
import { excludeCommentsWithTotalsAllQueryFields } from '@constants';
import { SettingsContext } from '@context';

export interface ControllerInterface {
  isRequestOnHold: boolean;
  isHistoricalRequest: boolean;
  isActionButtonDisabled: boolean;
  disableActionButtonTooltip: string;
  isRequestApproved: boolean;
}

export const useRequestInfo = ({
  drawRequest,
  projectId,
}: {
  drawRequest: IDrawRequest;
  projectId: string;
}): ControllerInterface => {
  const { isCurrentProjectActive } = useContext(SettingsContext);
  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId: drawRequest?.id, query: excludeCommentsWithTotalsAllQueryFields },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: drawRequest?.id,
      query: excludeCommentsWithTotalsAllQueryFields,
    }),
    { enabled: Boolean(drawRequest?.id) },
  );

  return {
    isRequestOnHold: drawRequest?.is_on_hold,
    isHistoricalRequest: isRequestHistorical(drawRequest?.source),
    disableActionButtonTooltip: getTooltipText({
      nonActiveProject: !isCurrentProjectActive,
    }),
    isActionButtonDisabled: !isActionEnabled(!isCurrentProjectActive, drawRequestQuery.isLoading),
    isRequestApproved: isRequestApproved(drawRequest?.status),
  };
};
