import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';

import {
  ICompany,
  IProjectChecklist,
  PermissionNamesEnums,
  PoliciesTypeEnum,
  PostTemplateParams,
  QueryNamesEnums,
  TemplateChecklistParam,
} from '@interfaces';
import {
  createProjectPoliciesTemplate,
  createRequestPoliciesTemplate,
  getCompanyPoliciesTemplates,
  getMyCompany,
  updateChecklistTemplate,
  updateProjectPoliciesTemplate,
} from '@globalService';
import { PoliciesTypeMap } from '@constants';
import { ApplyTemplateFnParams, ControllerInterface } from './interface';
import { isRestricted } from '@utils';
import { PermissionsContext } from '@context';

export const usePoliciesTemplateSelect = (assigneeRole: string): ControllerInterface => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = match?.params['*']?.split('/')[0];
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const companyPoliciesTemplateQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_COMPANY_POLICIES_TEMPLATES, { companyId: companyQuery.data?.id }],
    getCompanyPoliciesTemplates.bind(this, companyQuery.data?.id),
    {
      enabled:
        Boolean(companyQuery.data?.id) &&
        !isRestricted(PermissionNamesEnums.PROJECT__POLICIES__ADDITIONAL_ITEMS__EDIT, permissions),
    },
  );

  const companyPolicies = useMemo(
    () =>
      companyPoliciesTemplateQuery.data?.filter((o) => o.type === PoliciesTypeMap[tabPathname]) ||
      [],
    [companyPoliciesTemplateQuery.data, tabPathname],
  );

  const updatePoliciesItems = useMutation<Response, Error, TemplateChecklistParam>(
    updateChecklistTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_CHECKLIST_TEMPLATES,
          { projectId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const createProjectPolicies = useMutation<Response, Error, PostTemplateParams>(
    createProjectPoliciesTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const createRequestPolicies = useMutation<Response, Error, PostTemplateParams>(
    createRequestPoliciesTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_CHECKLIST_TEMPLATES,
          { projectId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateProjectPoliciesItems = useMutation<Response, Error, TemplateChecklistParam>(
    updateProjectPoliciesTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const applyTemplate = useCallback(
    ({ templateId, policiesId }: ApplyTemplateFnParams) => {
      const policiesTemplate = companyPolicies.find((o) => o.id === templateId);
      if (!policiesTemplate) return;

      const itemsList = policiesTemplate.items.map((o, index) => ({
        name: { name: o.name },
        index,
        ...(o.type && { type: o.type }),
        description: o.description,
        is_custom: o.is_custom,
      }));
      const policiesParams = {
        items: itemsList,
        template: policiesTemplate.id,
        assignee_role: policiesTemplate.assignee_role,
        type: policiesTemplate.type,
      };

      if (tabPathname === PoliciesTypeEnum.PROJECT) {
        if (!policiesId) {
          policiesParams['project'] = projectId;
          createProjectPolicies.mutateAsync({ projectId, policiesParams });
          return;
        }

        updateProjectPoliciesItems.mutateAsync({
          projectId,
          checklistId: policiesId,
          policiesParams,
        });
        return;
      }

      if (!policiesId) {
        policiesParams['project'] = projectId;
        createRequestPolicies.mutateAsync({ projectId, policiesParams });
        return;
      }
      updatePoliciesItems.mutateAsync({ projectId, checklistId: policiesId, policiesParams });
    },
    [companyPolicies, tabPathname],
  );

  const modalText =
    'Please note that changing policy will affect future draw requests where this policy is used. Past and current requests will not be affected.';

  return {
    policiesList: companyPolicies.filter((o) => o.assignee_role === assigneeRole),
    applyTemplate,
    modalText,
  };
};
