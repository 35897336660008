import React from 'react';
import { Typography } from '@mui/material';
import { CenteredStyledBox } from '@components';

const ServiceComponent = () => {
  return (
    <CenteredStyledBox>
      <Typography variant="h3">Service company</Typography>
    </CenteredStyledBox>
  );
};

export default ServiceComponent;
