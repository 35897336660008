import find from 'lodash/find';

import {
  DrawRequestSourceEnum,
  DrawRequestStatus,
  DrawRequestTypeEnum,
  IDrawRequest,
  IInspection,
  IInspectionAgency,
  IMilestone,
  InspectionServiceEnum,
  InspectionStatusEnum,
  IProject,
  ItemStatusesEnum,
  LineItemHighlightVariantEnum,
  PaymentConfiguration,
  PolicyHistoryActionEnum,
  PolicyItemTypesEnum,
  PolicyListSourceEnums,
  ProjectStatusEnum,
} from '@interfaces';
import { CommonRowType } from '../components/MilestoneList/columns';
import { checkIsExternalUser } from './byRoles';
import { dateFormatter } from './formators';
import { colors } from '@theme';
import { LoanStatusesList } from '@constants';
import { useLaunchDarklyFlags } from '@context';

export const isInspectionFailed = (status: string) => status === InspectionStatusEnum.ERROR;

export const isInspectionCanceled = (status: string) => status === InspectionStatusEnum.CANCELLED;

export const isInspectionServiceManual = (inspectionService: string) =>
  inspectionService === InspectionServiceEnum.MANUAL ||
  inspectionService === InspectionServiceEnum.NVMS;

export const isAutomatedInspection = (inspectionService: string) =>
  inspectionService === InspectionServiceEnum.TRINITY ||
  inspectionService === InspectionServiceEnum.PROXYPICS;

export const isPseudoAutomatedInspection = (inspectionService: string) =>
  inspectionService === InspectionServiceEnum.NVMS;

export const getActiveDrawRequestFromList = (data: any) => {
  const list = data?.results || data || [];
  return list?.find(
    ({ status }) => status !== DrawRequestStatus.DRAFT && status !== DrawRequestStatus.COMPLETED,
  );
};

export const getActiveDrawRequestFromListForInspection = (data: any) => {
  const list = data?.results || data || [];
  return list?.find(({ status }) => isRequestInReview(status));
};

export const getDrawRequestForApproval = (drawRequests?: IDrawRequest[]) =>
  drawRequests
    ? drawRequests.find(
        ({ waits_current_user_approval, status }) =>
          waits_current_user_approval && !isRequestDraft(status),
      )
    : null;

export const isChangeRequest = (drawRequest?: Partial<IDrawRequest>) =>
  drawRequest?.type === DrawRequestTypeEnum.CHANGE_REQUEST;

export const isDrawRequest = (drawRequest?: Partial<IDrawRequest>) =>
  drawRequest?.type === DrawRequestTypeEnum.DRAW_REQUEST;

export const isCreditInRequest = (ml: Array<Partial<IMilestone>>) =>
  ml.some((item) => item.approved_credit_amount || item.requested_credit_amount);

export const isCreatedProject = (status: ProjectStatusEnum): boolean =>
  status === ProjectStatusEnum.CREATED;
export const isProjectPaused = (project: IProject) => project?.status === ProjectStatusEnum.PAUSED;
export const isRequestDraft = (status: string) => status === DrawRequestStatus.DRAFT;
export const isRequestInReview = (status: string) => status === DrawRequestStatus.IN_REVIEW;
export const isRequestCompleted = (status: string) => status === DrawRequestStatus.COMPLETED;
export const isRequestApproved = (status: string) => status === DrawRequestStatus.APPROVED;
export const isRequestActive = (status: string) =>
  status !== DrawRequestStatus.DRAFT && status !== DrawRequestStatus.COMPLETED;

export const isRequestDraftOrInReview = (status: string) =>
  status === DrawRequestStatus.DRAFT || status === DrawRequestStatus.IN_REVIEW;

export const isRequestHistorical = (source: string) => source === DrawRequestSourceEnum.MANUAL;

export const isInactiveProject = (status: ProjectStatusEnum): boolean =>
  [
    ProjectStatusEnum.INACTIVE_COMPLETE,
    ProjectStatusEnum.INACTIVE_INCOMPLETE,
    ProjectStatusEnum.PAUSED,
  ].includes(status);

export const isActiveProject = (status: ProjectStatusEnum): boolean =>
  status === ProjectStatusEnum.ACTIVE;

export const isCompletedProject = (status: ProjectStatusEnum): boolean =>
  status === ProjectStatusEnum.INACTIVE_COMPLETE;

export const getItemLocalHighlight = (item) => {
  if (item?.milestone_is_in_current_draw) {
    return LineItemHighlightVariantEnum.CURRENT;
  }

  if (item?.milestone_is_active || item?.draw_requests?.length || item?.change_requests?.length) {
    return LineItemHighlightVariantEnum.ACTIVE;
  }

  return undefined;
};

export const isCompletedInspection = (status: InspectionStatusEnum) =>
  status === InspectionStatusEnum.COMPLETED;

export const isOrderedInspection = (status: InspectionStatusEnum) =>
  status === InspectionStatusEnum.ORDERED;

export const isPendingInspection = (status: InspectionStatusEnum) =>
  status === InspectionStatusEnum.PENDING;

export const isActiveInspection = (status: InspectionStatusEnum) =>
  isOrderedInspection(status) || isCreatedInspection(status);

export const isCancelRequestedInspection = (status: InspectionStatusEnum) =>
  status === InspectionStatusEnum.CANCEL_REQUESTED;

export const getInspectionName = (inspection: IInspection) => {
  switch (inspection?.status) {
    case InspectionStatusEnum.PENDING:
      return 'Inspection (pending)';
    case InspectionStatusEnum.COMPLETED:
      return `Inspection ${dateFormatter({ date: inspection?.completed_at })}`;
    case InspectionStatusEnum.ERROR:
      return 'Inspection (failed)';
    case InspectionStatusEnum.CANCELLED:
    case InspectionStatusEnum.CANCEL_REQUESTED:
      return 'Inspection (canceled)';
    default:
      return 'Inspection in progress';
  }
};

export const isCreatedInspection = (status: string) => status === InspectionStatusEnum.CREATED;

export const isMilestoneIncomplete = (row: CommonRowType, adjustments_field: string) =>
  +row.previous_approved_amount_cumulative <
    row.revised_estimate + (+row.additional_borrower_equity || 0) ||
  row[adjustments_field] > 0 ||
  row.approved_adjustments > 0;

export const isRequestHasChangedValues = (drawRequest: IDrawRequest) => {
  const hasRequestedChanges = drawRequest.requested_reallocation > 0;

  const hasRequestedAmounts = drawRequest.totals?.all?.requested_amount > 0;

  const hasRequestedRetainage = drawRequest.totals?.all?.retainage_release_requested > 0;

  return isDrawRequest(drawRequest)
    ? hasRequestedChanges || hasRequestedAmounts || hasRequestedRetainage
    : hasRequestedChanges;
};

export const getInspectionNameByRole = ({
  inspectionAgency,
  teamRole,
}: {
  inspectionAgency: IInspectionAgency;
  teamRole: string;
}) => {
  const isExternalUser = checkIsExternalUser(teamRole);
  const { display_name, service } = inspectionAgency || {};
  return isInspectionServiceManual(service)
    ? isExternalUser
      ? display_name
      : `${service} + ${display_name} `
    : display_name;
};

export const getColorCodingScheme = ({ request }: { request: IDrawRequest }) => {
  const flags = useLaunchDarklyFlags();

  const { status, project, is_on_hold, is_resubmit, waits_current_user_approval } = request;
  if (is_on_hold || (is_resubmit && isRequestDraft(status)) || isProjectPaused(project)) {
    return {
      backgroundColor: colors.status.error.lighter,
      borderColor: colors.status.error.medium,
    };
  }

  if (!flags?.['ENG_8420_simplify_filters_remove_awaiting'] && waits_current_user_approval) {
    return {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    };
  }

  switch (status) {
    case DrawRequestStatus.DRAFT:
      return { backgroundColor: colors.neutral.lightest, borderColor: colors.neutral.dark };
    case DrawRequestStatus.IN_REVIEW:
      return {
        backgroundColor: colors.status.information.lighter,
        borderColor: colors.status.information.medium,
      };
    case DrawRequestStatus.APPROVED:
      return {
        backgroundColor: colors.status.success.lighter,
        borderColor: colors.status.success.medium,
      };
    case DrawRequestStatus.COMPLETED:
      return flags?.['ENG_8420_simplify_filters_remove_awaiting']
        ? {
            backgroundColor: colors.status.violet.lighter,
            borderColor: colors.status.violet.medium,
          }
        : { backgroundColor: colors.neutral.lighter, borderColor: colors.neutral.darker };
    default:
      return { backgroundColor: 'transparent', borderColor: 'transparent' };
  }
};

export const isSequentialPaymentConfiguration = (configuration_type: string) =>
  configuration_type === PaymentConfiguration.SEQUENTIAL;

export const isReallocationEnabledByProject = (project?: IProject) =>
  project?.is_reallocation_enabled;

export const isReallocationEnabled = (drawRequest: IDrawRequest, project: IProject) =>
  isChangeRequest(drawRequest) || project?.is_reallocation_enabled;

export const getCurrentInspection = (inspectionsList: IInspection[]) =>
  inspectionsList?.find(
    (item) =>
      isOrderedInspection(item.status) ||
      isPendingInspection(item.status) ||
      isCancelRequestedInspection(item.status),
  );

export const isTextPolicyItem = (type: string) => type === PolicyItemTypesEnum.COMMENT;
export const isAttestationPolicyItem = (type: string) => type === PolicyItemTypesEnum.ATTESTATION;
export const isCompletionDatePolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL;
export const isInspectionPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.INSPECTION_ORDERING;
export const isRequiredDocumentPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD;
export const isOptionalDocumentPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.OPTIONAL_DOCUMENT_UPLOAD;

export const isDocumentPolicyItem = (type: PolicyItemTypesEnum) =>
  [
    PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD,
    PolicyItemTypesEnum.OPTIONAL_DOCUMENT_UPLOAD,
  ].includes(type);

export const isSubmissionPolicySource = (source: string) =>
  source === PolicyListSourceEnums.SUBMISSION;

export const isHistoryActionCreate = (action: PolicyHistoryActionEnum) =>
  action === PolicyHistoryActionEnum.CREATE;

export const isHistoryActionUpdate = (action: PolicyHistoryActionEnum) =>
  action === PolicyHistoryActionEnum.UPDATE;

export const isPolicyItemCompleted = (status: ItemStatusesEnum) =>
  status === ItemStatusesEnum.COMPLETED;

export const isPolicyItemSkipped = (status: ItemStatusesEnum) =>
  status === ItemStatusesEnum.SKIPPED;

export const getLoanStatusByValue = (servicing_status: string) =>
  find(LoanStatusesList, { name: servicing_status });

// Should always (ENG-7891):
// first pick Ordered Inspection over other statuses
// if no ordered should always pick Completed inspection over Canceled
// From the inspections of the same status should pick the latest one
export const getLastRelevantInspection = (inspections: IInspection[]) => {
  if (!inspections?.length) return null;
  // Filter out inspections with the status of "Created"
  const filteredInspections = inspections.filter(
    (inspection) => !isCreatedInspection(inspection.status),
  );

  // Sort inspections by status priority (Ordered > Completed > Others) and then by date
  const sortedInspections = filteredInspections.sort((a, b) => {
    const statusPriority = (status) => {
      if (isOrderedInspection(status)) return 3;
      if (isCompletedInspection(status)) return 2;
      return 1;
    };

    // Sort by status priority first
    const priorityComparison = statusPriority(b.status) - statusPriority(a.status);
    if (priorityComparison !== 0) return priorityComparison;

    // If the same status, sort by the latest date
    return new Date(b.ordered_at).getTime() - new Date(a.ordered_at).getTime();
  });

  // Find the first inspection that matches the highest priority criteria
  return sortedInspections.find(
    (inspection) =>
      isOrderedInspection(inspection.status) || isCompletedInspection(inspection.status),
  );
};

export const isPolicyItemDefault = (type: PolicyItemTypesEnum) =>
  [
    PolicyItemTypesEnum.DRAW_REQUEST_FORM,
    PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
    PolicyItemTypesEnum.INSPECTION_ORDERING,
  ].includes(type);
