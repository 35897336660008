import React from 'react';
import { Stack } from '@mui/material';

import { HookState, PermissionNamesEnums } from '@interfaces';
import { CompanyInfoBlock, LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import { CompanyInfoDetails, CompanyInfoEditPopup, CustomNavigationLinksBlock } from './components';
import { WithPermission } from '@utils';

const CompanyInfo = () => {
  const controller = Controller.useCompanyInfo();
  const {
    state,
    data,
    isOpenCompanyInfoPopup,
    setOpenCompanyInfoPopup,
    isEditAllowed,
    showLicenses,
  } = controller;

  const company = data;
  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Company Users" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack spacing={2} sx={{ flex: 1 }}>
          <StyledBox>
            <CompanyInfoBlock
              company={company}
              isEditAllowed={isEditAllowed}
              setOpenCompanyInfoPopup={setOpenCompanyInfoPopup}
              source="my_company"
            />
          </StyledBox>
          {showLicenses && (
            <StyledBox sx={{ pb: 3 }}>
              <CompanyInfoDetails company={company} isEditAllowed={isEditAllowed} />
            </StyledBox>
          )}
          <WithPermission permissionKey={PermissionNamesEnums.COMPANY__EDIT}>
            <CustomNavigationLinksBlock links={company?.custom_navigation_links || []} />
          </WithPermission>

          {isOpenCompanyInfoPopup && (
            <CompanyInfoEditPopup
              company={company}
              open={isOpenCompanyInfoPopup}
              onClose={() => setOpenCompanyInfoPopup(false)}
              source="my_company"
            />
          )}
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default CompanyInfo;
