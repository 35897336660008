import React from 'react';
import { Box } from '@mui/material';
import { matchPath, Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import {
  Footer,
  LoadingSkeleton,
  NewAppVersionMessage,
  NoTeamEnrollmentPage,
  PageLayoutContentWrapper,
  PermissionDenied,
  ScheduledMaintenanceBanner,
  StyledBox,
  TermsOfServiceModal,
} from '@components';
import { getTeamRole } from '@utils';
import { HookState } from '@interfaces';
import { LaunchdarklyProvider } from '@context';
import { useMobileWarning } from '@hooks';
import { ComponentProps } from './interface';
import { ContentBody } from './Content';
import * as Controller from './controller';
import AppToolbar from './AppToolbar';
import ProjectWarning from './ProjectWarning';
import VerifyBorrowerModal from '../../components/VerifyBorrowerModal';

function PageLayout({ screensACL, updateAvailable }: ComponentProps) {
  const controller = Controller.usePageLayout();
  const { permissionDenied, state, user, projectActivationWarning } = controller;

  const { MobileWarningComponent } = useMobileWarning();
  const { pathname } = useLocation();
  const useEncodedSearchParams = (params) => {
    const [searchParams] = useSearchParams();
    return params.reduce((acc, param) => {
      const value = searchParams.get(param);
      if (value) acc[param] = encodeURIComponent(value);
      return acc;
    }, {});
  };
  const { sso_domain_name, sso_access_token } = useEncodedSearchParams([
    'sso_domain_name',
    'sso_access_token',
  ]);

  const match = matchPath({ path: '/:tab', end: false }, pathname);

  const teamRole = getTeamRole(user);

  if (MobileWarningComponent) return <MobileWarningComponent />;

  if (!user?.isAllowedToLogin && sso_domain_name && sso_access_token)
    return (
      <Navigate
        to={`/login-with-sso?sso_access_token=${sso_access_token}&sso_domain_name=${sso_domain_name}`}
        state={{ from: pathname }}
      />
    );

  if (!user?.isAllowedToLogin) return <Navigate to="/login" state={{ from: pathname }} />;

  if (!teamRole) return <NoTeamEnrollmentPage />;

  if (!screensACL[teamRole]) return <Navigate to="/no-content" />;

  if (!screensACL[teamRole]?.available?.includes(match?.pathname))
    return <Navigate to={screensACL[teamRole]?.default} />;

  return (
    <LaunchdarklyProvider>
      <AppToolbar screensACL={screensACL} controller={controller} />
      <ContentBody>
        <Box sx={{ minHeight: 64 }} />
        <ScheduledMaintenanceBanner />
        {updateAvailable && <NewAppVersionMessage />}
        {projectActivationWarning && (
          <ProjectWarning innerElement={projectActivationWarning?.warningWithLinks} />
        )}
        <PageLayoutContentWrapper withWarning={projectActivationWarning}>
          {state === HookState.FETCHING && (
            <StyledBox>
              <LoadingSkeleton type="overviewBlock" />
            </StyledBox>
          )}
          {user?.agreed_to_terms && !permissionDenied && state !== HookState.FETCHING && <Outlet />}
          {permissionDenied && <PermissionDenied />}
        </PageLayoutContentWrapper>
        <Footer />
      </ContentBody>
      <TermsOfServiceModal />
      <VerifyBorrowerModal />
    </LaunchdarklyProvider>
  );
}

export default PageLayout;
