import React, {
  Dispatch,
  ReactElement,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import {
  EditPaymentConfigurationTypeInterface,
  ErrorDual,
  IDataFieldModel,
  IProject,
  IProjectCreatePayload,
  IUser,
  QueryNamesEnums,
} from '@interfaces';
import { inviteProjectBorrowers, postProject } from '@globalService';
import { StringFieldModel } from '@models';
import { TEAM_ROLES } from '@constants';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useEditPaymentConfigurationType,
  useLeavePageBlocker,
  useProjectFields,
  useSafeSnackbar,
} from '@hooks';
import { calculateFraction, checkIsFieldsValid, getErrorText, roundToTwoDigits } from '@utils';
import { useLaunchDarklyFlags } from '@context';

export interface ControllerInterface {
  activeStep: number;
  steps: Array<string>;
  nextStep: () => void;
  prevStep: () => void;
  goBack: () => void;
  nextButtonTooltip: string;
  isSubmitting: boolean;
  mainRef: Ref<HTMLDivElement>;
  propertyDetailsFields: IDataFieldModel;
  loanDetailsFields: IDataFieldModel;
  borrowerDetailsFields: IDataFieldModel;
  getLeavePageConfirmModal: () => ReactElement<string, string>;
  error: string;
  setError: Dispatch<React.SetStateAction<string>>;
  borrowerEquityLocal: StringFieldModel;
  isNewBorrower: boolean;
  setIsNewBorrower: (value: boolean) => void;
  isProductionBuildProject: boolean;
  setIsProductionBuildProject: Dispatch<React.SetStateAction<boolean>>;
  borrowerUser: IUser;
  setBorrowerUser: Dispatch<React.SetStateAction<IUser>>;
  createProjectWithoutBorrowerModal: ConfirmModalHookInterface;
  createProjectSubmit: () => Promise<void>;
  editPaymentConfigurationData: EditPaymentConfigurationTypeInterface;
  inviteBorrowers: boolean;
  setInviteBorrowers: Dispatch<SetStateAction<boolean>>;
}

export const useCreateProject = (): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { getLeavePageConfirmModal, setTriggerExit } = useLeavePageBlocker({
    currentPagePathname: '/projects/add-new',
    confirmTitle: 'Warning',
    isUpdated: true,
    confirmText:
      'Are you sure you want to exit project creation? In this case the data you entered will be lost.',
  });
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isNewBorrower, setIsNewBorrower] = useState(false);
  const [borrowerUser, setBorrowerUser] = useState(null);
  const [isProductionBuildProject, setIsProductionBuildProject] = useState(false);
  const editPaymentConfigurationData = useEditPaymentConfigurationType();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [inviteBorrowers, setInviteBorrowers] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const { CREATE_PROJECT_STEPS } = useProjectFields({ isNewBorrower });

  const {
    state,
    city,
    address_1,
    zip_code,
    project_type,
    property_existing_type,
    property_proposed_type,
    scope_of_work,
    exit_strategy,
    sqft_existing_value,
    sqft_proposed_value,
  } = CREATE_PROJECT_STEPS[0].fields;
  const {
    external_id,
    funding_date,
    loc_commitment,
    prefunding_cost,
    construction_holdback,
    property_after_repair_value,
    maturity_date,
    extended_maturity_date,
    retainage_rate,
    borrower_equity,
    post_funding_construction_budget,
    estimated_start_date,
    original_completion_date,
    estimated_completion_date,
    payment_configuration_comment,
    loan_status,
    borrowerEquityLocal,
  } = CREATE_PROJECT_STEPS[1].fields;
  const {
    borrower_email,
    borrower_company_name,
    borrower_company_state,
    borrower_company_address_1,
    borrower_company_city,
    borrower_company_zip_code,
    legal_entity,
    first_name,
    last_name,
    phone,
  } = CREATE_PROJECT_STEPS[2].fields;

  const mainRef = useRef(null);

  useEffect(() => {
    mainRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [activeStep]);

  const [error, setError] = useState<string>('');

  const sendInviteMutation = useMutation<Response, Error, { projectId: string }>(
    inviteProjectBorrowers,
    {
      onSuccess: (_, vars) => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
        setTriggerExit(() => ({
          path: `/projects/${vars.projectId}`,
          isNavigationConfirmed: true,
        }));
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const createProjectMutation = useMutation<IProject, ErrorDual, IProjectCreatePayload>(
    postProject,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_COMPANY_TEAMS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_STATS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
        if (inviteBorrowers) {
          sendInviteMutation.mutateAsync({ projectId: data?.id });
        } else {
          setTriggerExit(() => ({
            path: `/projects/${data.id}`,
            isNavigationConfirmed: true,
          }));
        }
      },
      onError: (error: ErrorDual) => {
        createProjectMutation.reset();
        const errorText = getErrorText(error as ErrorDual);
        setError(errorText);
      },
    },
  );

  const createProjectSubmit = async () => {
    const projectData = {
      address: {
        address_1: address_1.value,
        city: city.value,
        state: state.value?.name,
        zip_code: zip_code.value,
      },
      companies:
        !borrowerUser?.id && !isNewBorrower
          ? []
          : [
              {
                contribution_fraction: calculateFraction(
                  roundToTwoDigits(
                    post_funding_construction_budget.floatValue - construction_holdback.floatValue,
                  ),
                  post_funding_construction_budget.floatValue,
                ),
                role: TEAM_ROLES.Owner,
                ...(borrowerUser?.id
                  ? {
                      ...borrowerUser?.company,
                      members: [{ id: borrowerUser.id, email: borrowerUser.email }],
                    }
                  : {
                      name: borrower_company_name.value,
                      address: {
                        address_1: borrower_company_address_1.value,
                        city: borrower_company_city.value,
                        state: borrower_company_state.value?.name,
                        zip_code: borrower_company_zip_code.value,
                      },
                      members: [
                        {
                          email: borrower_email.value,
                          first_name: first_name.value,
                          last_name: last_name.value,
                          phone: phone.valueToSave,
                        },
                      ],
                    }),
              },
            ],
      ...(estimated_start_date.value
        ? { estimated_start_date: format(estimated_start_date.value, 'yyyy-MM-dd') }
        : {}),
      ...(original_completion_date.value
        ? { original_completion_date: format(original_completion_date.value, 'yyyy-MM-dd') }
        : {}),
      ...(estimated_completion_date.value
        ? { estimated_completion_date: format(estimated_completion_date.value, 'yyyy-MM-dd') }
        : {}),
      exit_strategy: exit_strategy.value?.name,
      loan: {
        external_id: external_id.value,
        ...(loan_status.value ? { servicing_status: loan_status.value?.name } : {}),
        ...(funding_date.value ? { funding_date: format(funding_date.value, 'yyyy-MM-dd') } : {}),
        ...(maturity_date.value
          ? { maturity_date: format(maturity_date.value, 'yyyy-MM-dd') }
          : {}),
        ...(extended_maturity_date.value
          ? { extended_maturity_date: format(extended_maturity_date.value, 'yyyy-MM-dd') }
          : {}),
        loc_commitment: +loc_commitment.value,
        construction_holdback: +construction_holdback.value,
        borrower_equity: +borrower_equity.value,
        prefunding_cost: +prefunding_cost.value,
      },
      name: address_1.value + ' ' + city.value,
      project_type: project_type.value?.name,
      property_existing_type: property_existing_type.value?.name,
      property_proposed_type: property_proposed_type.value?.name,
      scope_of_work: scope_of_work.value,
      property_after_repair_value: +property_after_repair_value.value,
      ...(retainage_rate.value ? { retainage_rate: +retainage_rate.value } : {}),
      is_advanced_budget_tracking_enabled: isProductionBuildProject,
      legal_entity: legal_entity.value,
      payment_configuration_type: editPaymentConfigurationData.configurationType,
      payment_configuration_comment: payment_configuration_comment.value,
      ...(flags?.['ENG_8013_add_sqft_to_project_intake'] &&
      !isProductionBuildProject &&
      (sqft_existing_value.value || sqft_proposed_value.value)
        ? {
            property_details: [
              {
                name: 'SQFT',
                existing_value: sqft_existing_value.value,
                proposed_value: sqft_proposed_value.value,
              },
            ],
          }
        : {}),
    };
    await createProjectMutation.mutateAsync({ ...projectData });
  };

  const createProjectWithoutBorrowerModal = useConfirmationModal();

  const handleNextClick = useCallback(() => {
    if (checkIsFieldsValid(CREATE_PROJECT_STEPS[activeStep].fields)) {
      if (activeStep < 2) {
        setActiveStep((prev) => prev + 1);
      } else createProjectSubmit();
    }
  }, [CREATE_PROJECT_STEPS, activeStep]);

  const goBack = () => {
    navigate(-1);
  };

  return {
    activeStep,
    steps: CREATE_PROJECT_STEPS.map((o) => o.title),
    nextStep: handleNextClick,
    prevStep: () => {
      setActiveStep((step) => step - 1);
    },
    goBack,
    nextButtonTooltip: '',
    isSubmitting: createProjectMutation.isLoading || sendInviteMutation.isLoading,
    mainRef,
    propertyDetailsFields: CREATE_PROJECT_STEPS[0].fields,
    loanDetailsFields: CREATE_PROJECT_STEPS[1].fields,
    editPaymentConfigurationData,
    borrowerDetailsFields: CREATE_PROJECT_STEPS[2].fields,
    getLeavePageConfirmModal,
    error,
    setError,
    borrowerEquityLocal,
    isNewBorrower,
    setIsNewBorrower,
    isProductionBuildProject,
    setIsProductionBuildProject,
    borrowerUser,
    setBorrowerUser,
    createProjectWithoutBorrowerModal,
    createProjectSubmit,
    inviteBorrowers,
    setInviteBorrowers,
  };
};
