import { dataProvider } from '@api';
import { IProjectComment, ICommentType } from '@interfaces';
import { commentTypes } from '@globalService';

const trimUrl = (url: string) => url?.replace('/api/v1/', '');

const extraParams = 'include_children=true&limit=9999';

export const getComments = async (url: string): Promise<{ results: IProjectComment[] }> =>
  url
    ? dataProvider
        .get(`${trimUrl(url)}${url.includes('?') ? `&${extraParams}` : `?${extraParams}`}`)
        .json()
    : null;

export const postComment = async ({
  url,
  value,
}: {
  url: string;
  value: { message: string; tags?: string[] };
}): Promise<Response> => dataProvider.post(trimUrl(url), { json: value }).json();

export const patchComment = async ({
  url,
  value,
}: {
  url: string;
  value: { tags?: string[]; pinned_at?: Date | null };
}): Promise<Response> => dataProvider.patch(trimUrl(url), { json: value }).json();

export const getCommentTypes = async (): Promise<ICommentType> =>
  dataProvider.get(commentTypes()).json();
