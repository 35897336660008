import React, { useMemo } from 'react';
import { Box, FormControl, MenuItem, Stack, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IFilterOption } from '@interfaces';

interface ComponentProps {
  onChangeCallback: (value: string) => void;
  filterLabel?: string;
  filterValue: string;
  options: string[] | IFilterOption[];
  disabled?: boolean;
  source?: string;
}

const Filter = ({
  onChangeCallback,
  filterLabel = '',
  options = [],
  filterValue,
  disabled = false,
  source = '',
}: ComponentProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChangeCallback(event.target.value);
  };

  const menuOptions = useMemo(
    () =>
      options.map((option) => {
        if (typeof option === 'string') {
          return { filterValue: option, disabled: false };
        }
        return option;
      }),
    [options],
  );

  return (
    <Box sx={{ minWidth: 260 }}>
      <FormControl fullWidth size="small">
        <Select
          value={filterValue}
          onChange={handleChange}
          displayEmpty
          renderValue={(value: string) => (
            <Stack direction="row">
              {filterLabel && (
                <Typography variant="body3" sx={{ mr: 0.5 }}>
                  {`${filterLabel}: `}
                </Typography>
              )}
              <Typography variant="body3SemiBold">{value}</Typography>
            </Stack>
          )}
          disabled={disabled}
          sx={{
            '&.MuiInputBase-root > .Mui-disabled ': {
              textFillColor: 'rgba(0, 0, 0, 1)',
            },
          }}
          data-cy={`${source}__select`}
        >
          {menuOptions.map(({ filterValue, disabled }, index) => (
            <MenuItem
              key={index}
              value={filterValue}
              divider
              disabled={disabled}
              data-cy={`${source}__select_item__index_${index}`}
            >
              {filterValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(Filter);
