import { useContext, useMemo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import isEqual from 'lodash/isEqual';

import {
  getCompanies,
  getCoordinators,
  getInspectionAgencies,
  getCompaniesListByRole,
  getProjectsTeams,
} from '@globalService';
import {
  ICompany,
  IInspectionAgency,
  ITeam,
  IUser,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestCombinedStatus,
  TableKeyEnum,
  FiltersSwitcherEnum,
} from '@interfaces';
import {
  checkIsLender,
  checkIsOwner,
  getArrayFromObject,
  getTeamRole,
  getUserFullName,
  isCustomer,
  isRestricted,
  checkIsPaymentManager,
} from '@utils';
import { PermissionsContext, SettingsContext, AuthContext } from '@context';
import { DrawRequestListStatusMap, ProjectStatusMap, TEAM_ROLES } from '@constants';

const VIEWS_TYPES = {
  AWAITING_YOU: { label: 'Awaiting you', value: FiltersSwitcherEnum.AWAITING_YOU },
  ALL: { label: 'All', value: FiltersSwitcherEnum.ALL },
};

const isOnHoldValues = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const useRequestsListFilters = (
  clearFilters,
  resetFiltersToDefault,
  filters,
  defaultFilters,
) => {
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const borrowerCompaniesQuery = useQuery<{ results: ICompany[] }, Error>(
    [QueryNamesEnums.GET_COMPANIES_BY_ROLE, { role: TEAM_ROLES.Owner }],
    getCompaniesListByRole.bind(this, TEAM_ROLES.Owner),
    { enabled: !isOwner },
  );

  const lenderCompaniesQuery = useQuery<{ results: ICompany[] }, Error>(
    [QueryNamesEnums.GET_COMPANIES_BY_ROLE, { role: TEAM_ROLES.Lender }],
    getCompaniesListByRole.bind(this, TEAM_ROLES.Lender),
    { enabled: !isLender },
  );

  const investorCompaniesQuery = useQuery<{ results: ICompany[] }, Error>(
    [QueryNamesEnums.GET_COMPANIES_BY_ROLE, { role: TEAM_ROLES.Investor }],
    getCompaniesListByRole.bind(this, TEAM_ROLES.Investor),
    { enabled: !isRestricted(PermissionNamesEnums.REQUEST_QUEUE__INVESTOR__VIEW, permissions) },
  );

  const customerCompaniesQuery = useQuery<{ results: ICompany[] }, Error>(
    [QueryNamesEnums.GET_COMPANIES, { query: '?is_customer=true&sorting=name' }],
    getCompanies.bind(this, '?is_customer=true&sorting=name'),
    { enabled: !isRestricted(PermissionNamesEnums.COMPANIES__LIST, permissions) },
  );

  const coordinatorsQuery = useQuery<{ results: IUser[] }, Error>(
    [QueryNamesEnums.GET_COORDINATORS],
    getCoordinators.bind(this),
    { enabled: !isRestricted(PermissionNamesEnums.REQUESTS__COORDINATOR, permissions) },
  );

  const coordinators = useMemo(() => {
    if (!coordinatorsQuery.data?.results) return [];

    return coordinatorsQuery.data.results.map((user) => ({
      ...user,
      full_name: getUserFullName(user),
    }));
  }, [coordinatorsQuery.data?.results]);

  const inspectionAgenciesQuery = useQuery<{ results: IInspectionAgency[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_AGENCIES],
    getInspectionAgencies.bind(this),
    { enabled: !isRestricted(PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO, permissions) },
  );

  const teamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [QueryNamesEnums.GET_PROJECTS_TEAMS],
    getProjectsTeams.bind(this),
    { enabled: Boolean(isCustomer(user)) },
  );

  const teams = useMemo(
    () => teamsQuery.data?.results?.filter((team) => team?.company?.is_customer) || [],
    [teamsQuery.data?.results],
  );

  const { settings } = useContext(SettingsContext);
  const projectStatuses = getArrayFromObject(ProjectStatusMap, 'status', 'name');
  // we show for Borrower "Awaiting for additional requirements" label instead of "Awaiting you"
  const requestStatuses = getArrayFromObject(DrawRequestListStatusMap, 'status', 'name')
    .map((obj) => {
      if (checkIsPaymentManager(teamRole) && obj.status === RequestCombinedStatus.AWAITING_YOU)
        return null;
      if (isOwner && obj.status === RequestCombinedStatus.AWAITING_YOU) {
        return {
          ...obj,
          name: 'Awaiting for additional requirements',
        };
      } else {
        return obj;
      }
    })
    .filter((o) => o);

  const [viewType, setViewType] = useState<string>(FiltersSwitcherEnum.AWAITING_YOU);

  const fns = {
    [FiltersSwitcherEnum.ALL]: clearFilters,
    [FiltersSwitcherEnum.AWAITING_YOU]: resetFiltersToDefault,
  };

  const handleViewType = (_, type: string) => {
    if (!type) return;
    fns[type]?.();
    setViewType(type);
  };

  useEffect(() => {
    const savedViewType = settings.personal_setting?.tables?.[TableKeyEnum.REQUESTS]?.view_type;
    if (savedViewType && savedViewType !== viewType) setViewType(savedViewType);
  }, [settings.personal_setting?.tables?.[TableKeyEnum.REQUESTS]?.view_type]);

  useEffect(() => {
    if (isEqual(filters, defaultFilters[TableKeyEnum.REQUESTS]))
      setViewType(FiltersSwitcherEnum.AWAITING_YOU);
    if (Object.keys(filters).every((key) => !filters[key]?.length))
      setViewType(FiltersSwitcherEnum.ALL);
    if (
      !isEqual(filters, defaultFilters[TableKeyEnum.REQUESTS]) &&
      Object.keys(filters).some((key) => filters[key]?.length)
    )
      setViewType('');
  }, [filters, teamRole]);

  return {
    borrowerCompanies: borrowerCompaniesQuery.data?.results || [],
    coordinators,
    lenderCompanies: lenderCompaniesQuery.data?.results || [],
    investorCompanies: investorCompaniesQuery.data?.results || [],
    customerCompanies: customerCompaniesQuery.data?.results || [],
    projectStatuses,
    requestStatuses,
    inspectionAgencies: inspectionAgenciesQuery.data || [],
    teams,
    viewType,
    VIEWS_TYPES,
    isOnHoldValues,
    handleViewType,
  };
};
