import React, { FC } from 'react';
import { PermissionNamesEnums } from '@interfaces';
import { Box, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import {
  currencyFormatter,
  dateFormatter,
  getStatusChip,
  getTextOrDash,
  isChangeRequest,
  isRequestInReview,
  percentFormatter,
  WithPermission,
} from '@utils';
import { InspectionLabelAndValue, LabelAndValue, ProgressWithArrow, StyledBox } from '@components';
import { ControllerInterface } from '../controller';
import ProjectDetails from '../../ProjectDetails';
import LoanDetails from '../../LoanDetails';
import Sources from '../../Sources';
import Uses from '../../Uses';

export const ProjectAndDrawInfo: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { drawRequestData, lastInspection } = controller;
  const isInReview = isRequestInReview(drawRequestData?.status);
  return (
    <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
      <Stack alignItems="center" direction="column">
        <Stack direction="column" spacing="5px" width="100%" marginTop="5px">
          <Stack direction="row" spacing="5px" width="100%">
            <StyledBox sx={{ flex: 1, border: `1px solid ${colors.background.gray}`, padding: 0 }}>
              <ProjectDetails onlyInfo />
            </StyledBox>
            <StyledBox sx={{ flex: 1, border: `1px solid ${colors.background.gray}`, padding: 0 }}>
              <LoanDetails smallSize />
            </StyledBox>
          </Stack>
          <Stack direction="row" spacing="5px" width="100%">
            <StyledBox
              sx={{
                maxWidth: '50%',
                flex: 1,
                border: `1px solid ${colors.background.gray}`,
                padding: 0,
              }}
            >
              <Sources onLoad={() => controller.setOtherModuleLoaded((data) => data + 1)} />
            </StyledBox>
            <StyledBox
              sx={{
                maxWidth: '50%',
                flex: 1,
                border: `1px solid ${colors.background.gray}`,
                padding: 0,
              }}
            >
              <Uses onLoad={() => controller.setOtherModuleLoaded((data) => data + 1)} />
            </StyledBox>
          </Stack>
        </Stack>
        <Stack direction="row" width="100%">
          <Typography variant="printH2" data-cy="report_request_section_title">{`${
            isChangeRequest(drawRequestData) ? 'Change' : 'Draw'
          } # ${drawRequestData.number}`}</Typography>
        </Stack>
        <Stack spacing="5px" width="100%">
          <Stack direction="row" spacing="5px" width="100%">
            <StyledBox
              sx={{
                flex: 1,
                border: `1px solid ${colors.background.gray}`,
                paddingX: '10px',
                paddingY: '4px',
              }}
            >
              <Stack flex={1}>
                <LabelAndValue
                  label="Submitted date"
                  text={dateFormatter({ date: drawRequestData.submitted_at }) || '-'}
                />
                <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION}>
                  <InspectionLabelAndValue inspection={lastInspection} />
                </WithPermission>
                <LabelAndValue
                  label="Approved date"
                  text={dateFormatter({ date: drawRequestData.approved_at }) || '-'}
                />
              </Stack>
            </StyledBox>
            <StyledBox
              sx={{
                flex: 1,
                border: `1px solid ${colors.background.gray}`,
                paddingX: '10px',
                paddingY: '4px',
              }}
            >
              <Stack flex={1} spacing="5px">
                <LabelAndValue
                  label="Original scheduled value"
                  text={currencyFormatter(drawRequestData.totals.all.original_estimate)}
                  noWrap
                />
                <LabelAndValue
                  label="Revised scheduled value"
                  text={currencyFormatter(drawRequestData.totals.all.revised_estimate)}
                  noWrap
                />
                <LabelAndValue label="Status">{getStatusChip(drawRequestData)}</LabelAndValue>
              </Stack>
            </StyledBox>
          </Stack>
          <Stack direction="row" spacing="5px" width="100%">
            <StyledBox
              sx={{
                flex: 1,
                border: `1px solid ${colors.background.gray}`,
                paddingX: '10px',
                paddingY: '4px',
              }}
            >
              <Stack flex={1}>
                <LabelAndValue
                  label="Borrower requested amount"
                  text={getTextOrDash(
                    drawRequestData.totals.all.requested_amount,
                    drawRequestData.totals.all.requested_amount_relative,
                  )}
                  noWrap
                />
                <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION}>
                  <LabelAndValue
                    label="Inspection recommendation"
                    text={getTextOrDash(
                      drawRequestData.totals.all.inspector_allowance_incremental,
                      drawRequestData.totals.all.inspector_allowance_rate_incremental,
                    )}
                    noWrap
                  />
                </WithPermission>
                <LabelAndValue
                  label="Lender allowance"
                  text={getTextOrDash(
                    drawRequestData.totals.all.approved_amount_cumulative,
                    drawRequestData.totals.all.lender_allowance_rate,
                  )}
                  noWrap
                />
                <LabelAndValue
                  label={
                    isInReview ? 'Amount for approval for this draw' : 'Approved for this draw'
                  }
                  text={getTextOrDash(
                    drawRequestData.totals.all.approved_amount,
                    drawRequestData.lender_allowance_rate_incremental,
                  )}
                  color={isInReview ? colors.status.information.medium : colors.text.dark}
                  noWrap
                />
              </Stack>
            </StyledBox>
            <StyledBox
              sx={{
                flex: 1,
                border: `1px solid ${colors.background.gray}`,
                paddingX: '10px',
                paddingY: '4px',
              }}
            >
              <Stack flex={1} sx={{ '&:empty': { flex: 0, margin: 0 } }}>
                <WithPermission permissionKey={PermissionNamesEnums.PROJECT__INSPECTION}>
                  <LabelAndValue label="" text="Pre ➝ Post draw" color={colors.text.medium} />
                  <LabelAndValue label="Lender allowance">
                    <ProgressWithArrow
                      prevValue={percentFormatter({
                        value: isInReview
                          ? drawRequestData.totals?.all?.previous_lender_allowance_rate
                          : drawRequestData.totals?.all?.lender_allowance_rate,
                        roundTo: 0,
                      })}
                      nextValue={percentFormatter({
                        value: drawRequestData.totals?.all?.lender_allowance_rate,
                        roundTo: 0,
                      })}
                      showProgress={isInReview}
                      getTextStyleForActive={isInReview}
                    />
                  </LabelAndValue>
                  <LabelAndValue label="Inspection allowance">
                    <ProgressWithArrow
                      prevValue={percentFormatter({
                        value: drawRequestData.inspector_allowance_rate_before,
                        roundTo: 0,
                      })}
                      nextValue={percentFormatter({
                        value: drawRequestData.totals?.all?.inspector_allowance_rate,
                        roundTo: 0,
                      })}
                    />
                  </LabelAndValue>
                  <LabelAndValue label="Variance to lender allowance" noWrap>
                    <ProgressWithArrow
                      prevValue={percentFormatter({
                        value: drawRequestData.gap_before,
                        roundTo: 0,
                      })}
                      nextValue={percentFormatter({
                        value: drawRequestData.totals?.all?.variance_to_lender_allowance_rate,
                        roundTo: 0,
                      })}
                      getTextStyleForActive={isInReview}
                    />
                  </LabelAndValue>
                </WithPermission>
              </Stack>
            </StyledBox>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
