import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Grid, Stack, Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { isMobileOnly } from 'react-device-detect';

import { teamRolesMap } from '@constants';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';
import { CompanyLink, ServiceMessage, StatusChip } from '@components';
import { HookState } from '@interfaces';
import * as Controller from './controller';

const Bullet = styled('span')({
  margin: '8px',
  fontSize: '1.25rem',
  color: colors.text.link,
});

const ProjectHeader = () => {
  const {
    state,
    projectName,
    statusValue,
    borrower,
    customer,
    isCurrentProjectActive,
    status_change_reason,
  } = Controller.useProjectHeader();
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <Box pt={2}>
          <Skeleton width="40%" variant="rectangular" height={40} />
          <Skeleton width="20%" />
          <Skeleton width="20%" />
        </Box>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="project" />;
    }

    case HookState.SUCCESS: {
      return (
        <Box>
          <Stack
            direction={isMobileOnly ? 'column' : 'row'}
            spacing={2}
            sx={{ ...(isMobileOnly && { mb: 1 }) }}
          >
            <Typography
              variant="h1"
              style={{ color: colors.text.heading }}
              data-cy="project__overview__name__value"
            >
              {projectName}
            </Typography>

            {!isCurrentProjectActive && statusValue && (
              <Tooltip
                title={
                  <>
                    Reason:{' '}
                    <Typography
                      variant="body3"
                      dangerouslySetInnerHTML={{ __html: status_change_reason }}
                    />
                  </>
                }
              >
                <span>
                  <StatusChip
                    color={statusValue.color}
                    backgroundColor={statusValue.backgroundColor}
                    label={statusValue.label}
                    size="medium"
                  />
                </span>
              </Tooltip>
            )}
          </Stack>

          <Grid container alignItems="baseline">
            {borrower?.company?.name && (
              <>
                {!flags?.['ENG_8276_remove_roles_from_overview'] && (
                  <Typography variant="body1" sx={{ color: colors.text.medium, mr: 0.5 }}>
                    Borrower:
                  </Typography>
                )}

                <CompanyLink
                  name={borrower?.company?.name}
                  companyId={borrower?.company?.id}
                  source="project__overview__borrower"
                />

                <Bullet>&#8226;</Bullet>
              </>
            )}
            {!flags?.['ENG_8276_remove_roles_from_overview'] && (
              <Typography variant="body1" sx={{ color: colors.text.medium, mr: 0.5 }}>
                {`${teamRolesMap[customer?.role]}:`}
              </Typography>
            )}

            <CompanyLink
              name={customer?.company?.name}
              companyId={customer?.company.id}
              source="project__overview__investor"
            />
          </Grid>
        </Box>
      );
    }

    default:
      return null;
  }
};

export default ProjectHeader;
