import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Button, IconButton, Stack } from '@mui/material';

import { ComponentProps } from './interface';
import { FilesUploader } from '@components';
import { useFilesUploader } from '@hooks';
import { DocumentContentTypeEnum, TransloaditTemplateEnum } from '@interfaces';
import { getLinkedObjects } from '@utils';
import { useLaunchDarklyFlags } from '@context';

function AddPhotoBox({
  projectId,
  requestId,
  milestoneId,
  dataTestName,
  icon,
  iconBackgroundColor,
}: ComponentProps) {
  const flags = useLaunchDarklyFlags();
  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
  } = useFilesUploader();

  const getContentType = useCallback(() => {
    if (milestoneId) return DocumentContentTypeEnum.MILESTONE;
    if (requestId) return DocumentContentTypeEnum.DRAW_REQUEST;
    return DocumentContentTypeEnum.PROJECT;
  }, [requestId, milestoneId]);

  const uploadClick = useCallback(() => {
    uploadMedia({
      fields: {
        ...(requestId && { draw_request_id: requestId }),
        project_id: projectId,
        ...(milestoneId && { milestone_id: milestoneId }),
        ...(flags?.['ENG_8008_project_tabs_in_docs_and_photos'] && {
          object_id: milestoneId || requestId || projectId,
          content_type: getContentType(),
        }),
      },
      templateType: TransloaditTemplateEnum.PROOFPOINTS,
    });
  }, [projectId, requestId, milestoneId, flags]);

  const uploader = () => {
    if (flags?.['ENG_8008_project_tabs_in_docs_and_photos'])
      return (
        <FilesUploader
          open={isFilesUploaderOpened}
          onClose={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          source={dataTestName}
          metaDataDefault={{
            linked_objects: getLinkedObjects({ projectId, requestId, milestoneId }),
            content_type: getContentType(),
          }}
          fields={['content_type', 'linked_objects']}
        />
      );

    return (
      <FilesUploader
        open={isFilesUploaderOpened}
        onClose={filesUploaderClose}
        transloaditSignature={transloaditSignature}
        restrictions={restrictions}
        source={dataTestName}
      />
    );
  };

  return (
    <>
      {Boolean(icon) ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            ...(iconBackgroundColor && { backgroundColor: iconBackgroundColor }),
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            borderRadius: 1,
          }}
          onClick={() => uploadClick()}
        >
          <IconButton data-cy={dataTestName}>{icon}</IconButton>
        </Stack>
      ) : (
        <Button onClick={() => uploadClick()} sx={{ mt: 4 }}>
          Add photo
        </Button>
      )}
      {isFilesUploaderOpened &&
        transloaditSignature &&
        ReactDOM.createPortal(uploader(), document.body)}
    </>
  );
}

export default AddPhotoBox;
