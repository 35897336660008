import React from 'react';
import { Grid, Skeleton } from '@mui/material';

import { ServiceMessage } from '@components';
import { currencyFormatter, WithPermission } from '@utils';
import { HealthScore, StatWidget } from './components';
import * as Controller from './controller';
import { HookState, PermissionNamesEnums } from '@interfaces';

const ProjectsPortfolioStats = ({ filtering }) => {
  const { state, statsData } = Controller.useProjectPortfolioStats(filtering);

  switch (state) {
    case HookState.FETCHING: {
      return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            <Skeleton variant="rectangular" width={150} height={94} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={200} height={94} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={200} height={94} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" width={200} height={94} />
          </Grid>
        </Grid>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="portfolio stats" />;
    }

    case HookState.SUCCESS: {
      return (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid container item wrap="nowrap" sx={{ flex: 1 }} spacing={2}>
            <Grid item sx={{ flex: 1 }}>
              <StatWidget
                label="Total loan commitment"
                mainText={currencyFormatter(statsData.commitments?.segment)}
                secondaryText={` / ${currencyFormatter(statsData.commitments?.total)} `}
              />
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <StatWidget
                label="Unfunded holdback commitment"
                mainText={currencyFormatter(statsData.holdback_available_to_draw?.segment)}
                secondaryText={` / ${currencyFormatter(
                  statsData.holdback_available_to_draw?.total,
                )} `}
              />
            </Grid>
          </Grid>

          <WithPermission permissionKey={PermissionNamesEnums.RISK_RADAR__SCORES__VIEW}>
            <Grid container item wrap="nowrap" sx={{ flex: 1 }} spacing={2}>
              <Grid item sx={{ flex: 1 }}>
                <StatWidget
                  label="Borrower(s)"
                  mainText={statsData.borrowers?.segment}
                  secondaryText={` / ${statsData.borrowers?.total}`}
                />
              </Grid>
              <Grid item sx={{ flex: 1 }}>
                <StatWidget
                  label="Loans"
                  mainText={statsData.loans?.segment}
                  secondaryText={` / ${statsData.loans?.total}`}
                />
              </Grid>
              <Grid item sx={{ flex: 2 }}>
                <HealthScore data={statsData.scores?.segment} title="Health scores (filtered)" />
              </Grid>
            </Grid>
          </WithPermission>
        </Grid>
      );
    }

    default:
      return null;
  }
};

export default ProjectsPortfolioStats;
