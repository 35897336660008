import React, { PropsWithChildren, useMemo } from 'react';
import sum from 'lodash/sum';
import includes from 'lodash/includes';
import { styled } from '@mui/system';
import { Box, Tooltip } from '@mui/material';
import chroma from 'chroma-js';
import { SizeMe } from 'react-sizeme';

import { LabelAndValue } from '@components';
import { ChartProps, LegendPosition, LegendProps, Orientation } from './interface';

const SeriesArea = styled('div', {
  shouldForwardProp: (prop) =>
    !includes(['stacked', 'orientation', 'barWidth', 'borderRadius'], prop),
})<{
  stacked?: boolean;
  orientation: Orientation;
  barWidth: number | string;
  borderRadius: string;
}>(({ stacked, orientation, barWidth, borderRadius }) => ({
  width: stacked ? (orientation === 'vertical' ? barWidth : '100%') : '100%',
  height: stacked ? (orientation === 'vertical' ? '100%' : barWidth) : '100%',
  display: 'flex',
  flexDirection:
    orientation === 'vertical' ? (stacked ? 'column-reverse' : 'row') : stacked ? 'row' : 'column',
  flexWrap: 'nowrap',
  alignItems: orientation === 'vertical' ? 'flex-end' : 'flex-start',
  overflow: 'hidden',
  ...(stacked &&
    borderRadius && {
      borderRadius,
    }),
}));

const Layout = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'orientation' && prop !== 'stacked' && prop !== 'legendPosition',
})<PropsWithChildren<{ legendPosition: LegendPosition }>>(({ legendPosition }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection:
    legendPosition === 'right'
      ? 'row'
      : legendPosition === 'left'
        ? 'row-reverse'
        : legendPosition === 'top'
          ? 'column-reverse'
          : 'column',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
}));

function Chart({
  keys = [],
  values,
  total,
  format,
  barWidth,
  paletteSrc,
  colors,
  orientation,
  stacked = false,
  legendPosition,
  legendStyle,
  borderRadius,
  CustomLegend,
  tooltipTitle,
  source = '',
}: ChartProps) {
  if (!values.length) console.warn('BarChart: Pass `values` prop to render BarChart.');

  if ((legendPosition && !keys.length) || (keys.length && !legendPosition)) {
    console.warn('BarChart: Pass `keys` and `legendPosition` props to use legend.');
  }

  const formatValue = format || String;

  const valuesSum = useMemo(() => sum(values), [values]);
  const totalDelta = useMemo(
    () => (total ? +(total - valuesSum).toFixed(2) + 0 : null),
    [total, valuesSum],
  );
  const showTotalDelta = typeof totalDelta === 'number' && totalDelta !== 0;
  const valuesAdjusted = showTotalDelta ? [...values, Math.abs(totalDelta)] : values;

  const percentages = calcShares(valuesAdjusted);
  const palette = colors || calcPalette(paletteSrc, valuesAdjusted.length);

  const legendItems = keys.length ? values.map((v, i) => [keys[i], formatValue(v)]) : [];

  return (
    <Layout legendPosition={legendPosition}>
      <SizeMe monitorHeight>
        {({ size }) => {
          return (
            <Tooltip title={tooltipTitle} placement="bottom-start">
              <SeriesArea
                orientation={orientation}
                stacked={stacked}
                barWidth={barWidth}
                borderRadius={borderRadius}
              >
                {percentages.map((percent, index) => (
                  <Box
                    key={index}
                    sx={{
                      height: calculateDimension({
                        dimensionType: 'height',
                        orientation,
                        barWidth,
                        size,
                        percent,
                      }),
                      width: calculateDimension({
                        dimensionType: 'width',
                        orientation,
                        barWidth,
                        size,
                        percent,
                      }),
                      background: palette[index],
                    }}
                    data-cy={`${source}__bar__index_${index}__percentage__${percent}`}
                  />
                ))}
              </SeriesArea>
            </Tooltip>
          );
        }}
      </SizeMe>
      {CustomLegend ? CustomLegend : null}
      {legendPosition && !CustomLegend && (
        <Legend style={legendStyle} items={legendItems} legendColors={palette} />
      )}
    </Layout>
  );
}

function Legend({ items, legendColors, style }: LegendProps) {
  return (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', ml: '10px', pb: '1rem' }} style={style}>
      {items.map((item, i) => (
        <Box
          key={`${item[0]}-${item[1]}`}
          sx={{ display: 'flex', alignItems: 'center', pt: '1rem' }}
        >
          <Box
            sx={{
              minWidth: '16px',
              minHeight: '16px',
              background: legendColors[i],
              borderRadius: '2px',
              mr: '8px',
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            <LabelAndValue label={item[0]} text={item[1]} />
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function calcShares(values) {
  const total = sum(values);
  return values.map((v) => v / total);
}

function calcPalette(paletteSrc, count) {
  return chroma.scale(paletteSrc).mode('lch').colors(count);
}

const MIN_SIZE = 2;
function calculateDimension({ dimensionType, orientation, barWidth, size, percent }) {
  let dimensionValue;

  if (dimensionType === 'width') {
    dimensionValue = orientation === 'vertical' ? barWidth : size.width * percent;
  } else {
    dimensionValue = orientation === 'vertical' ? size.height * percent : barWidth;
  }

  // we want to show graph's part if it is more than 0 but too small to be shown
  if (dimensionValue > 0 && dimensionValue < MIN_SIZE) {
    dimensionValue = MIN_SIZE;
  }

  return dimensionValue;
}
export default Chart;
