import React, { useCallback } from 'react';
import { Button, Grid } from '@mui/material';

import { inspectionStatusMap } from '@constants';
import { FilterButton } from '@components';
import { getArrayFromObject, isCreatedInspection } from '@utils';
import { ComponentProps } from './interface';
import * as Controller from './controller';

const InspectionFilters = ({
  handleFiltersChange,
  resetFiltersToDefault,
  filters,
}: ComponentProps) => {
  const { inspectionAgenciesList } = Controller.useInspectionListFilters();

  const statusEqualityFunction = useCallback(
    (option, value) => option.status === value?.status,
    [],
  );
  const agencyEqualityFunction = useCallback((option, value) => option.id === value?.id, []);

  return (
    <Grid container justifyContent="space-between" wrap="nowrap" p={2}>
      <Grid item sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid container wrap="wrap" spacing={1}>
          <Grid item>
            <FilterButton
              labels={getArrayFromObject(inspectionStatusMap, 'status', 'name').filter(
                (o) => !isCreatedInspection(o.status),
              )}
              initialValue={filters.status}
              title="Inspection status"
              valueName="status"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={statusEqualityFunction}
              dataTestName="inspections_queue__filters__inspection_status__button"
            />
          </Grid>

          <Grid item>
            <FilterButton
              labels={inspectionAgenciesList}
              label="display_name"
              initialValue={filters.inspection_agency}
              title="Agency"
              valueName="inspection_agency"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={agencyEqualityFunction}
              dataTestName="inspections_queue__filters__inspection_agency__button"
            />
          </Grid>

          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={resetFiltersToDefault}
              data-cy="inspections_queue__reset_filters__button"
            >
              Reset filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InspectionFilters;
