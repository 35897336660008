import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Button, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import {
  approximationEqual,
  calculateRequestedAdjustment,
  canBeEdited,
  checkIsAdmin,
  currencyFormatter,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  isRestricted,
  notAllowNegative,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { TextInputWithTooltip } from '@components';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { ColumnV2Width, IMilestoneTotal, PermissionNamesEnums } from '@interfaces';
import { WarningIcon } from '@svgAsComponents';
import { colors } from '@theme';

const Cell: FC<{ row: CommonRowType; index: number }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const flags = useLaunchDarklyFlags();
  const {
    apiUpdate,
    contingencyMode,
    getLineItemError,
    highLightCellByData,
    resetMutation,
    source,
    updateRequestedChanges,
  } = useContext(TableContext);
  const error = getLineItemError(row.id, 'requested_amount');
  const { isPHBProject } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);

  const requested_amount = useStringFieldModel({
    initError: error,
    initValue: (row['requested_amount'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'requested_amount',
  });

  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'requested_amount',
    tags,
  });

  const isEditable = useMemo(
    () =>
      canBeEdited({ row, contingencyMode, adjustments_field: 'requested_adjustments', error }) &&
      row.localIsUserCreator &&
      (!isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__CREATE, permissions) ||
        !isRestricted(PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE, permissions) ||
        checkIsAdmin(teamRole)),
    [permissions, row, contingencyMode],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: requested_amount.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(row.requested_amount, requested_amount.floatValue),
        name: 'requested_amount',
        ...(row.tags && { milestoneTags: tags }),
        ...props,
      }),
    [requested_amount.floatValue, row.requested_amount, tags],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: requested_amount.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { requested_amount: 0 } }),
    afterEffect: () => requested_amount.changeNavigationBlockContext(false),
    delay: isPHBProject ? 1500 : 500,
  });

  const getTooltipActionButtons = () => (
    <Stack direction="row" justifyContent="flex-end" mt={2} sx={{ width: '100%' }} spacing={2}>
      <Button
        variant="text"
        size="small"
        onClick={() => {
          requested_amount.setValue('');
          effect({ value: '0', isEqualInit: false });
        }}
      >
        Clear input
      </Button>

      <Button
        variant="new"
        size="small"
        color="secondary"
        onClick={() => {
          const requestedAdjustmentValue = calculateRequestedAdjustment(
            row,
            +requested_amount.floatValue,
          );
          effect({
            value: requestedAdjustmentValue,
            name: 'requested_adjustments',
            isEqualInit: false,
          });
          if (flags?.['ENG_7209_reallocation_ux_improvements'] && updateRequestedChanges) {
            updateRequestedChanges({
              value: requestedAdjustmentValue,
              requestedAmount: requested_amount.floatValue,
              milestoneId: row.id,
              key: 'requested_adjustments',
            });
          }
          highLightCellByData(row.id, 'requested_adjustments');
          highLightCellByData(row.id, 'requested_revised_estimate');
        }}
      >
        Increase change
      </Button>

      {flags?.['ENG_7209_reallocation_ux_improvements'] && (
        <Button
          variant="new"
          size="small"
          color="secondary"
          onClick={() => {
            requested_amount.setValue(row.balance_to_finish.toString());
            effect({ value: +row.balance_to_finish, isEqualInit: false });
            highLightCellByData(row.id, 'requested_amount');
          }}
        >
          Adjust draw
        </Button>
      )}
    </Stack>
  );

  if (isBlocked) return <Skeleton width={'100%'} />;
  return isEditable && !(row['requested_amount'] < 0) ? (
    <TextInputWithTooltip
      onBlur={onBlur}
      value={requested_amount.value}
      onChange={onChange}
      error={Boolean(error)}
      type="currency"
      tooltipText={row.disabled?.reason}
      disabled={row.disabled?.value}
      errorTip={error}
      tooltipActionButtons={getTooltipActionButtons()}
      tooltipMinWidth="400px"
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      dataTestName={`milestone_row_requested_for_this_draw_index_${row?.project_milestone?.index}`}
      iconDataTestName={`${source}__body__requested_amount__error_icon__index_${row?.project_milestone?.index}`}
    />
  ) : (
    <>
      {error && (
        <Tooltip
          placement="top-end"
          title={
            <Typography
              variant="label"
              dangerouslySetInnerHTML={{ __html: error }}
              sx={{ whiteSpace: 'pre-line' }}
            ></Typography>
          }
          sx={{ mr: 'auto' }}
        >
          <Box>
            <WarningIcon color={colors.status.error.medium} size={16} />
          </Box>
        </Tooltip>
      )}
      <Typography variant="body3">{currencyFormatter(row.requested_amount, '-')}</Typography>
    </>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy="milestone_row_requested_amount_total">
      {currencyFormatter(row.requested_amount)}
    </Typography>
  );
};

const requestedAmount: MilestoneListColumnType = {
  name: 'requested_amount',
  columnText: 'Requested draw ($)',
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__CREATE, permissions) ||
    !isRestricted(PermissionNamesEnums.PROJECT__HISTORICAL_DRAW_REQUEST__CREATE, permissions),
  renderCell: ({ row, indx }) => <Cell row={row} index={indx} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Amount requested by borrower for this draw">
      <ColumnLabel>Requested</ColumnLabel>
      <ColumnLabel>draw ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default requestedAmount;
