/* eslint-disable no-unused-vars */
import { debug, transformFiltersToQueryString } from '@utils';
import { IProjectsPortfolioItem, TableKeyEnum } from '@interfaces';
import { dataProvider, dataProviderV2 } from '@api';
import { portfolio } from '@globalService';

export const getPortfolioList = async ({
  params = { filtering: '', sorting: '', search: '' },
  filterStringQuery = '',
  useV2 = false,
}): Promise<{ results: IProjectsPortfolioItem[]; count: number }> => {
  try {
    const { filtering, sorting, search } = params;
    const queryParams = new URLSearchParams(params);
    // delete filtering key because we send structure key=id1&key=id2
    queryParams.delete('filtering');
    queryParams.delete('search');
    // delete empty sorting key
    if (!sorting) {
      queryParams.delete('sorting');
    }
    let stringQueryParams = queryParams.toString();

    stringQueryParams += `&${new URLSearchParams({ q: search })}`;
    stringQueryParams += transformFiltersToQueryString({
      filtering,
      tableKey: TableKeyEnum.RISK_RADAR,
    });

    const provider = useV2 ? dataProviderV2 : dataProvider;

    return await provider.get(portfolio(filterStringQuery || stringQueryParams)).json();
  } catch (error) {
    await debug(error, 'Something went wrong while getting portfolio');
  }
};
