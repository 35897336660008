import React, { FC } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CustomCurrencyTextField,
  CustomRadioGroup,
  CustomTextField,
  Popup,
  WysiwygEditor,
} from '@components';
import { EditIcon, PlusIcon } from '@svgAsComponents';
import { LineItemEditProps } from './interface';
import { useLineItemEdit } from './controller';
import { LineItemModalTypeEnums } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

const LineItemEdit: FC<LineItemEditProps> = ({
  request,
  open,
  type,
  lineItem,
  setLineItemModal,
}) => {
  const {
    itemName,
    comment,
    requestedScheduledValue,
    additionalBorrowerEquity,
    costType,
    costTypeOptions,
    onClose,
    handleCostTypeChange,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting,
    isDeleting,
    isDrawRequestDraft,
  } = useLineItemEdit({ requestId: request.id, lineItem, setLineItemModal });

  const flags = useLaunchDarklyFlags();
  return (
    <Popup
      open={open}
      title={`${type === LineItemModalTypeEnums.ADD ? 'Add' : 'Edit'} line item`}
      icon={type === LineItemModalTypeEnums.ADD ? PlusIcon : EditIcon}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ mt: 4 }} spacing={2}>
          <CustomTextField
            field={itemName}
            label="Line item name"
            required
            inputProps={{
              'data-cy': 'edit_line_item_modal__name_input',
            }}
          />
          {flags?.['ENG_7938_breakdown_of_line_item'] ? (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <CustomCurrencyTextField
                field={requestedScheduledValue}
                label={
                  isDrawRequestDraft
                    ? 'Requested scheduled value ($)'
                    : 'Revised scheduled value ($)'
                }
                required
                disabled={type === LineItemModalTypeEnums.EDIT}
                inputProps={{
                  'data-cy': 'edit_line_item_modal__estimate_input',
                }}
              />
              <CustomCurrencyTextField
                field={additionalBorrowerEquity}
                label={'Additional borrower equity ($)'}
                disabled={type === LineItemModalTypeEnums.EDIT}
                inputProps={{
                  'data-cy': 'edit_line_item_modal__additional_borrower_equity_input',
                }}
              />
            </Stack>
          ) : (
            <CustomCurrencyTextField
              field={requestedScheduledValue}
              label={
                isDrawRequestDraft ? 'Requested scheduled value ($)' : 'Revised scheduled value ($)'
              }
              required
              disabled={type === LineItemModalTypeEnums.EDIT}
              inputProps={{
                'data-cy': 'edit_line_item_modal__estimate_input',
              }}
            />
          )}

          <CustomRadioGroup
            dataTestName="edit_line_item_modal__cost_type__radio_group"
            value={costType}
            onChange={handleCostTypeChange}
            options={costTypeOptions}
          />
          <Divider />
          {type === LineItemModalTypeEnums.ADD && (
            <WysiwygEditor editField={comment} source="edit_line_item_modal__comment" />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 7.5 }}
          spacing={1}
        >
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          {type === LineItemModalTypeEnums.EDIT && (
            <LoadingButton
              loading={isDeleting}
              variant="new"
              color="error"
              onClick={handleDeleteClick}
              data-cy="delete_line_item_modal_button"
            >
              Delete
            </LoadingButton>
          )}
          <LoadingButton
            onClick={handleSubmitClick}
            loading={isSubmitting}
            data-cy={
              type === LineItemModalTypeEnums.ADD
                ? 'add_line_item_modal_button'
                : 'edit_line_item_modal_button'
            }
          >
            {type === LineItemModalTypeEnums.ADD ? 'Add' : 'Save'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default LineItemEdit;
