import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const stateFilter: FilterObject = {
  title: 'State',
  filterKey: 'state',
  needToUpdateUrl: true,
  getDataParams: {
    type: QueryNamesEnums.GET_STATES,
    keys: ['state', 'state_display'],
    args: {},
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.state,
          label: item.state_display,
        })),
    },
  },
  cypressSelector: 'filters__state',
};
