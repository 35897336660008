import React, { FC, useCallback, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import { DocumentButton, LoadingSkeleton, StatusChip, WysiwygEditor } from '@components';
import { HookState } from '@interfaces';
import { SectionWrapper } from '../index';
import { ChecklistItemProps } from '../../main/interfaces';
import { useStringFieldModel } from '@models';
import { colors } from '@theme';
import { debounceFunction, emptyHTML, getChecklistEditedByCompany, isTextPolicyItem } from '@utils';
import snakeCase from 'lodash/snakeCase';

const ChecklistItem: FC<ChecklistItemProps> = ({
  checklistController,
  checklistItem,
  validationController,
  askConfirm,
  index,
  innerRef,
}) => {
  const { state, startUploading, isLoading, handleSkip, handleDone } = checklistController;
  const note = useStringFieldModel({
    initValue: checklistItem.note || '',
  });
  const sectionKey = snakeCase(checklistItem.label);
  const isSectionDone = validationController.validatedChecklist?.[sectionKey]?.isDone;
  const isSectionSkipped = validationController.validatedChecklist?.[sectionKey]?.isSkipped;
  const isAdditional = Boolean(checklistItem.created_by_team);
  const isEditable = !validationController.isAdditionalRequirementResubmit || isAdditional;

  const handleInputChange = useCallback(
    debounceFunction(async (value) => {
      handleDone({
        checklistItemId: checklistItem.id,
        comment: emptyHTML(value) ? '' : value,
        shouldComplete: isTextPolicyItem(checklistItem.type)
          ? !(emptyHTML(value) && value)
          : isSectionDone,
      });
    }, 500),
    [checklistItem.type, checklistItem.note, isSectionDone],
  );

  // automatically save the note if it's changed
  // and mark as done if it's a text policy item
  useEffect(() => {
    if (note.value && note.value !== checklistItem.note) {
      handleInputChange(note.value);
      if (
        isTextPolicyItem(checklistItem.type) &&
        !isSectionSkipped &&
        isSectionDone === emptyHTML(note.value)
      ) {
        validationController.onSectionButtonsClick({
          sectionKey,
          key: 'isDone',
          isChecklist: true,
          item: { ...checklistItem, note: note.value },
        });
      }
    }
  }, [
    checklistItem.note,
    checklistItem.type,
    note.value,
    isSectionDone,
    isSectionSkipped,
    sectionKey,
  ]);

  return (
    <>
      <SectionWrapper
        title={
          <Stack direction="row" alignItems="center">
            {checklistItem.label}
            <Box component="span" sx={{ ml: 1 }} />
            {isAdditional && validationController.isAdditionalRequirementResubmit && (
              <StatusChip
                color={colors.status.orange.medium}
                backgroundColor={colors.status.orange.lighter}
                label="Additional requirement"
              />
            )}
          </Stack>
        }
        sectionKey={sectionKey}
        controller={validationController}
        handleSkip={() => {
          if (isSectionSkipped) {
            validationController.onSectionButtonsClick({
              sectionKey,
              key: 'isSkipped',
              isChecklist: true,
            });
          }
          handleSkip(checklistItem.id, checklistItem.label, !isSectionSkipped);
        }}
        hideSkipButton={
          isTextPolicyItem(checklistItem.type)
            ? Boolean(checklistItem.note)
            : Boolean(checklistItem.documents?.length)
        }
        handleDone={() => {
          handleDone({
            checklistItemId: checklistItem.id,
            comment: note.value,
            shouldComplete: !isSectionDone,
          });
        }}
        isChecklist
        collapsedSectionText={
          isTextPolicyItem(checklistItem.type)
            ? null
            : {
                label: 'Uploaded:',
                value: checklistItem.documents?.length?.toString(),
              }
        }
        isEditable={isEditable}
        tooltipText={isAdditional ? getChecklistEditedByCompany(checklistItem.historyEvents) : ''}
        innerRef={innerRef}
        itemType={checklistItem.type}
      >
        {checklistItem.description && (
          <Stack sx={{ ml: 8, mt: 1, mr: 8 }}>
            <Typography
              data-cy={`request__submission__policy_items__description__index_${index}`}
              variant="body1"
              dangerouslySetInnerHTML={{ __html: checklistItem.description }}
            />
          </Stack>
        )}
        <Box mx={8} mt={4}>
          {!isTextPolicyItem(checklistItem.type) && (
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              {state === HookState.FETCHING && <LoadingSkeleton />}
              {state === HookState.SUCCESS && Boolean(checklistItem.documents?.length) && (
                <Stack
                  direction="row"
                  sx={{ flexWrap: 'wrap' }}
                  onClick={(e) => (isLoading ? e.stopPropagation() : null)}
                >
                  {checklistItem.documents?.map((document, documentIndex) => (
                    <Box mt={1} mr={1} key={document.id}>
                      <DocumentButton
                        size="medium"
                        document={document}
                        handleDelete={isEditable ? askConfirm : undefined}
                        handleDeleteProps={{
                          checklistItemId: checklistItem.id,
                          fileName: document.name,
                        }}
                        dataTestName={`request__submission__policy_items__docs_button__button__index_${index}__doc_index_${documentIndex}`}
                      />
                    </Box>
                  ))}
                </Stack>
              )}
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="new"
                  onClick={() => startUploading(checklistItem.id)}
                  disabled={!isEditable}
                  data-cy={`request__submission__policy_items__documents__upload__button__index_${index}`}
                >
                  Upload document(s)
                </Button>
              </Box>
            </Stack>
          )}
          <Stack
            sx={{
              width: {
                xs: '100%',
                md: '50%',
              },
              mt: 5,
            }}
          >
            <WysiwygEditor
              editField={note}
              placeholder={`Write a comment ${
                isTextPolicyItem(checklistItem.type) ? '*' : '(optional)'
              }`}
              source={`request__submission__policy_items__comments__index_${index}`}
            />
          </Stack>
        </Box>
      </SectionWrapper>
      <Box sx={{ mb: 2 }} />
    </>
  );
};

export default ChecklistItem;
