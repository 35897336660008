/* eslint-disable no-console */
/* eslint-disable dot-notation */
import React, { useContext, useMemo } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

import {
  ErrorBoundary,
  ThemeConfig,
  AuthProvider,
  TransloaditProvider,
  OktaContext,
  hooks,
  isLocalhost,
  packageJsonVersion,
  NotistackProvider,
} from 'encirclelabs-shared';

import Router from './navigation/routes';

function App() {
  if (!isLocalhost) hooks.useCacheBoost({ version: packageJsonVersion });

  const navigate = useNavigate();
  const { oktaData } = useContext(OktaContext);

  const router = useMemo(() => {
    const restoreOriginalUri = async () => {
      navigate('/login-with-sso', { state: { fromRedirect: true } });
    };

    return oktaData?.issuer ? (
      <Security oktaAuth={new OktaAuth(oktaData)} restoreOriginalUri={restoreOriginalUri}>
        <Router />
      </Security>
    ) : (
      <Router />
    );
  }, [oktaData, navigate]);

  return (
    <ThemeConfig>
      <ErrorBoundary>
        <AuthProvider>
          <TransloaditProvider>
            <NotistackProvider>{router}</NotistackProvider>
          </TransloaditProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeConfig>
  );
}

export default App;
