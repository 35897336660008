import React, { FC, useContext } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { currencyFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { ColumnV2Width, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton width={'100%'} />;

  const constructionHoldbackAmount = +row.funders.LENDER * (+row.revised_estimate / 100);
  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__construction_holdback__value__index_${row?.project_milestone?.index}`}
    >
      {currencyFormatter(constructionHoldbackAmount, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { borrowerEquityRate, source } = useContext(TableContext);
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  const constructionHoldbackAmount = (100 - borrowerEquityRate) * (+row.revised_estimate / 100);
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__construction_holdback__value`}>
      {currencyFormatter(constructionHoldbackAmount, '-')}
    </Typography>
  );
};

const constructionHoldback: MilestoneListColumnType = {
  name: 'construction_holdback',
  columnText: 'Construction holdback ($)',
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Amount heldback from the loan to be disbursed during the draw process.">
      <ColumnLabel>Construction </ColumnLabel>
      <ColumnLabel> holdback ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default constructionHoldback;
