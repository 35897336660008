import React, { ReactNode } from 'react';
import { IUsersListRow, ITeam, IUser } from '@interfaces';
import { Typography } from '@mui/material';
import { colors } from '@theme';

export type CellProps = {
  row: IUsersListRow;
  isActivationAllowed?: boolean;
  team?: ITeam;
  coordinator?: IUser;
  showCoordinator?: boolean;
};

export type UserListItem = {
  icon: string;
  name: string;
  phone: string;
  email: string;
  last_online: string;
  status: string;
  teams: string;
  optionsMenu: string;
  coordinator: IUser;
};

export type UserListColumnType = {
  name: keyof UserListItem;
  columnText: string;
  renderCell?: (props: CellProps) => ReactNode;
  width?: string;
  minWidth?: string;
};

export const ColumnLabel = ({ children, ...props }) => (
  <Typography component="div" variant="labelSemiBold" sx={{ color: colors.text.medium }} {...props}>
    {children}
  </Typography>
);
