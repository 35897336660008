export const drawRequestFields = [
  'id',
  'approved_amount',
  'coordinator{id,first_name,last_name}',
  'current_reviewer_team{*,-members,-company}',
  'customer',
  'days_since_submitted',
  'disbursed_at',
  'variance_to_lender_allowance_rate',
  'inspection',
  'inspector_allowance_rate',
  'lender_allowance_rate',
  'number',
  'project{id,status,status_change_reason,name,address,type,overall_score,borrower_name,lender_name,investor_name,loan{external_id}}',
  'requested_amount',
  'revised_estimate',
  'status',
  'submitted_at',
  'type',
  'next_review_role',
  'waits_current_user_approval',
  'is_resubmit',
  'is_resubmit_change_reason',
  'is_on_hold',
  'is_on_hold_change_reason',
];

export const drawRequestListFields = [
  'id',
  'counter_per_request_type',
  'number',
  'type',
  'status',
  'waits_current_user_approval',
  'team',
  'approved_amount',
  'approved_amount_rate',
  'lender_allowance_rate',
  'approved_amount_cumulative',
  'submitted_at',
  'disbursed_amount',
  'disbursed_at',
  'borrower_equity',
  'construction_holdback',
  'is_resubmit',
  'is_resubmit_change_reason',
  'is_on_hold',
  'is_on_hold_change_reason',
  'approved_reallocation',
  'approved_budget_change',
  'revised_estimate',
  'estimated_disbursement_date',
  'source',
  'requested_amount',
  'retainage_approved_amount_holdback',
  'retainage_release_approved',
  'borrower_equity_fees',
  'borrower_equity_with_fees',
  'construction_holdback_with_fees',
  'fees_amount',
];

export const commentsPreviewQueryFields = '{id,comments_preview}';

export const drawRequestReportQuery = `{
approved_at,
current_reviewer_team,
gap_before,
inspector_allowance_rate_before,
lender_allowance_rate_incremental,
next_review_role,
number,
status,
submitted_at,
totals{all},
type,
is_resubmit,
is_resubmit_change_reason,
is_on_hold,
is_on_hold_change_reason,
}`;

export const projectListFields =
  '{thumb_representations,status,id,name,address,last_draw_request{number,status,next_review_role,waits_current_user_approval,type,source,is_resubmit,is_on_hold},archived_at}';

export const excludeCommentsQueryFields = '{-comments_preview}';
export const excludeCommentsWithTotalsAllQueryFields =
  '{-comments_preview,totals{all,current{approved_amount,retainage_release_approved,requested_amount}}}';
export const drawRequestQueryFields =
  'requested_reallocation,requested_reallocation_rate,approved_reallocation,approved_reallocation_rate,lender_allowance_rate_incremental,providers,errors,retainage_approved_amount_holdback,retainage_requested_amount_holdback';

export const proofpointsQueryFields = '{proofpoints}';
export const inspectionListQueryFields =
  '{-milestones,-comments_preview,-photos,-proofpoints,-report,project{id},-totals,-draw_request_totals}';

export const onlyProgressPhotos = '?only_progress_photos=true';
