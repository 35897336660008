import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { useSafeSnackbar } from '@hooks';
import { IDrawRequest, IInspection, QueryNamesEnums, UpdateInspectionPayload } from '@interfaces';
import { getProjectDrawRequestsList, patchInspectionToProject } from '@globalService';
import { isDrawRequest } from '@utils';

interface ControllerInterface {
  drawRequestId: string;
  drawRequests: IDrawRequest[];
  saveInspectionRequest: () => void;
  unlinkInspectionRequest: () => void;
  handleChange: (event: SelectChangeEvent) => void;
  renderValueFn: (value: string) => string;
  open: boolean;
  setOpen: (open: boolean) => void;
  labelText: string;
  isMutating: boolean;
}

export const useInspectionRequestPicker = (inspection: IInspection): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [drawRequestId, setDrawRequestId] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const { projectId } = useParams();

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: !!projectId },
  );

  const handleChange = (event: SelectChangeEvent) => {
    setDrawRequestId(event.target.value);
  };

  const drawRequests = useMemo(() => {
    return drawRequestsQuery.data?.results?.filter((x) => isDrawRequest(x)) || [];
  }, [drawRequestsQuery.data]);

  useEffect(() => {
    const linkedDrawRequest = drawRequests.find((x) => x.id === inspection.draw_request?.id);
    if (linkedDrawRequest) setDrawRequestId(linkedDrawRequest.id);
  }, [drawRequests]);

  const updateInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: inspection.draw_request?.id },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
          { projectId, inspectionId: inspection.id },
        ]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_INSPECTIONS);
        enqueueSnackbar('Inspection linked', { variant: 'success' });
        setOpen(false);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
      },
    },
  );

  const saveInspectionRequest = useCallback(async () => {
    await updateInspection.mutateAsync({
      projectId,
      inspectionData: { inspectionId: inspection.id, draw_request: drawRequestId },
    });
  }, [drawRequestId]);

  const unlinkInspectionRequest = () => {
    updateInspection.mutateAsync({
      projectId,
      inspectionData: { inspectionId: inspection.id, draw_request: null },
    });
  };

  const renderValueFn = useCallback(
    (value) => {
      const drawRequest = drawRequests.find((x) => x.id === value);
      return drawRequest ? `Draw ${drawRequest?.counter_per_request_type}` : 'Not linked';
    },
    [drawRequests],
  );

  const labelText = useMemo(
    () => (drawRequestsQuery.isFetching ? '' : renderValueFn(inspection.draw_request?.id)),
    [drawRequestsQuery],
  );

  return {
    drawRequests,
    saveInspectionRequest,
    unlinkInspectionRequest,
    handleChange,
    drawRequestId,
    renderValueFn,
    open,
    setOpen,
    labelText,
    isMutating: updateInspection.isLoading,
  };
};
