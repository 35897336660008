import React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChecklistApproveModal, ModalWithRadioChoice, Popup } from '@components';

import * as Controller from './controller';
import ProjectDeactivationReasonsModal from '../ProjectDeactivationReasonsModal';
import { PolicyListReasonsTypeEnum, PopupTypeEnum, ProjectStatusEnum } from '@interfaces';

import { UpdateIcon } from '@svgAsComponents';

export default function UpdateProjectStatus({ open, handleOpen, valuesBasedOnPermissions }) {
  const {
    changeStatusModal,
    confirmModal,
    newStatus,
    inviteBorrowers,
    setInviteBorrowers,
    checklistModalShow,
    setChecklistModalShow,
    projectId,
    policyItems,
    checklistId,
    errors,
    setErrors,
  } = Controller.useProjectOptions(open, handleOpen);
  const navigate = useNavigate();

  return (
    <>
      <ModalWithRadioChoice
        open={changeStatusModal.open}
        title={valuesBasedOnPermissions.labelText}
        options={valuesBasedOnPermissions.statusesList}
        initialValue={changeStatusModal.initialValue as ProjectStatusEnum}
        confirmCallback={changeStatusModal.changeStatus}
        closeCallback={() => changeStatusModal.handleOpen(false)}
        inviteBorrowers={inviteBorrowers}
        setInviteBorrowers={setInviteBorrowers}
        icon={UpdateIcon}
        source="project__options_menu__status_update_modal"
        confirmButtonLabel="Update"
      />

      <ProjectDeactivationReasonsModal
        open={confirmModal.isConfirmModalOpened}
        onClose={confirmModal.closeConfirmModal}
        confirmText={confirmModal.confirmText}
        onApprove={({ value, changeReasons }) =>
          confirmModal.confirmCallback({
            action: () => confirmModal.confirmChangeStatus({ value, changeReasons }),
          })
        }
        newStatus={newStatus}
      />

      {checklistModalShow && (
        <ChecklistApproveModal
          open={checklistModalShow}
          onClose={() => setChecklistModalShow(false)}
          onApproveClose={() => {
            setChecklistModalShow(false);
            confirmModal.askConfirm({ value: ProjectStatusEnum.ACTIVE });
          }}
          checklistItems={policyItems}
          checklistId={checklistId}
          projectId={projectId}
          isRequiredReasons
          source="project__options_menu__status__exception_reasons_modal"
          checklistType={PolicyListReasonsTypeEnum.PROJECT}
        />
      )}

      {Boolean(errors?.length) && (
        <Popup open type={PopupTypeEnum.ERROR} title="Project activation error">
          <Stack sx={{ width: '100%' }}>
            <Stack spacing={2}>
              {errors.map((error, index) => (
                <Typography key={index} variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {error.message}
                  {Boolean(error.loan && error.budget) && (
                    <>
                      <br />
                      <b>Loan Details:</b> {error.loan}
                      <br />
                      <b>Budget:</b> {error.budget}
                    </>
                  )}
                </Typography>
              ))}
            </Stack>
            <Stack sx={{ mt: 1 }}>
              <Divider sx={{ my: '16px' }} />
              <Typography variant="body2">
                Please, make changes in budget or loan, so all the needed values match.
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 7.5 }}>
              <Button
                variant="text"
                onClick={() => setErrors(null)}
                data-cy="project_activation_error__confirmation_dialog__cancel__button"
              >
                Cancel
              </Button>
              <Button
                variant="new"
                color="secondary"
                onClick={() => navigate(`/projects/${projectId}/settings/loan`)}
                data-cy="project_activation_error__confirmation_dialog__edit_loan__button"
              >
                Edit loan
              </Button>
              <Button
                onClick={() => navigate(`/projects/${projectId}/draws/all`)}
                data-cy="project_activation_error__confirmation_dialog__edit_budget__button"
              >
                Edit budget
              </Button>
            </Stack>
          </Stack>
        </Popup>
      )}
    </>
  );
}
