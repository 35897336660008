import React, { FC } from 'react';
import { Stack } from '@mui/material';

import { ChecklistItemLocal as IChecklistItem } from '@interfaces';
import * as Controller from './controller';
import { ApprovalButtons, ApprovalModals, ApprovalTeams, ApproveSummary } from './components';

interface ComponentProps {
  checklistItems?: IChecklistItem[];
  projectId: string;
  drawRequestId: string;
  hideActonButtons?: boolean;
}

const DrawRequestOperationButtons: FC<ComponentProps> = ({
  checklistItems,
  projectId,
  drawRequestId,
  hideActonButtons,
}) => {
  const controller = Controller.useApprovalButtonsAndModals({
    checklistItems,
    projectId,
    drawRequestId,
  });

  if (!projectId || !drawRequestId) return null;
  return (
    <Stack spacing={2}>
      <ApproveSummary projectId={projectId} drawRequestId={drawRequestId} />

      {!controller.isRequestForDisbursement && (
        <ApprovalTeams
          projectId={projectId}
          request={controller?.drawRequest}
          approveActionType={controller?.approveActionType}
        />
      )}

      {!hideActonButtons && (
        <>
          <ApprovalButtons controller={controller} projectId={projectId} />
          <ApprovalModals
            controller={controller}
            checklistItems={checklistItems}
            projectId={projectId}
            drawRequestId={drawRequestId}
          />
        </>
      )}
    </Stack>
  );
};

export default DrawRequestOperationButtons;
