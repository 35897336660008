import { useContext, useState, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useFormik } from 'formik';
import { useLocation, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { ErrorDual } from '@interfaces';
import { useLogin } from '@hooks';
import { getIntegrationData } from '@globalService';
import { domainValidationSchema, getErrorText } from '@utils';
import { OktaContext } from '@context';
export interface LoginPayload {
  sso_access_token?: string;
  auth_connector?: string;
  sso_domain_name?: string;
}
export interface ControllerInterface {
  formik: any;
  inProgress: boolean;
  sso_access_token: string;
  fromRedirect: boolean;
}
interface CustomizedState {
  fromRedirect?: boolean;
  from?: string;
}

export const useSSOIntegration = (): ControllerInterface => {
  const { postLoginData } = useLogin({
    errorCallback: (error) => {
      formik.setFieldError('domainName', getErrorText(error as ErrorDual));
    },
    onSettledCallback: () => {
      setInProgress(false);
    },
  });

  const okta = useOktaAuth();
  const { authState, oktaAuth } = okta || {};
  const { setOktaData, clearOktaData } = useContext(OktaContext);

  const [inProgress, setInProgress] = useState(false);
  const authConnector = JSON.parse(sessionStorage.getItem('oktaData'))?.state;

  // to define if it's initial sso login page or after redirect callback
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const iss = searchParams.get('iss')?.split('//').pop();
  const sso_domain_name = searchParams.get('sso_domain_name');
  const sso_access_token = searchParams.get('sso_access_token');

  const state = location?.state as CustomizedState;
  // location.state - 'fromRedirect' flag is used to identify redirect after okta sso login
  const fromRedirect = useMemo(() => {
    return state?.fromRedirect;
  }, [location]);

  const getConnectorData = (domainName) => {
    cleanupOktaData();
    getIntegrationData(domainName, 'INTERNAL')
      .then((data) => {
        if (data) {
          const oktaData = {
            issuer: `https://${data.domain}`,
            clientId: data.client_id,
            redirectUri: `${window.location.origin}/login-with-sso/callback`,
            state: data.id,
          };
          // when redirect from Okta will be done we need to initiate the app with Okta provider
          // as we don't ask for okta data on redirect we'll use them from SS
          sessionStorage.setItem('oktaData', JSON.stringify(oktaData));
          // location.state - 'from' flag is used to redirect user to original url if he was logged out due to access token expiration
          if (state?.from) sessionStorage.setItem('originalUrl', JSON.stringify(state?.from));
          setOktaData(oktaData);
        }
      })
      .catch((error) => {
        formik.setFieldError('domainName', error?.message);
        formik.setSubmitting(false);
        setInProgress(false);
      });
  };

  const formik = useFormik({
    initialValues: { domainName: '' },
    validationSchema: domainValidationSchema,
    onSubmit: (values) => {
      getConnectorData(values.domainName);
    },
  });

  useEffect(() => {
    if (!authConnector && iss) getConnectorData(iss);

    // check if it initial page and we re-init app with okta provider -> initiate okta auth
    if (authConnector && !fromRedirect) initiateOktaAuth();

    if ((authConnector && fromRedirect) || (sso_domain_name && sso_access_token)) handleLogin();
  }, [fromRedirect, authConnector, iss, sso_domain_name, sso_access_token]);

  const cleanupOktaData = () => {
    localStorage.clear();
    sessionStorage.clear();
    if (clearOktaData) clearOktaData();
  };

  const initiateOktaAuth = async () => {
    try {
      setInProgress(true);
      await oktaAuth.signInWithRedirect();
    } catch (err: any) {
      const error =
        typeof err === 'object'
          ? err?.errorSummary
          : typeof err === 'string'
            ? err
            : 'Unknown Okta login error';
      formik.setFieldError('domainName', error);
    } finally {
      setInProgress(false);
    }
  };

  const handleLogin = async () => {
    let params = {};
    if (sso_domain_name && sso_access_token)
      params = {
        sso_domain_name,
        sso_access_token,
      };

    if (authState?.accessToken?.accessToken && authConnector)
      params = {
        sso_access_token: authState.accessToken.accessToken,
        auth_connector: authConnector,
      };
    if (isEmpty(params)) return;
    setInProgress(true);
    await postLoginData.mutateAsync(params);
  };

  return {
    formik,
    inProgress,
    sso_access_token,
    fromRedirect,
  };
};
