import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { SelectChangeEvent } from '@mui/material';

import { IDrawRequest, ITeam, QueryNamesEnums, UpdateRequestPayload } from '@interfaces';
import { postComment, updateDrawRequestReviewer } from '@globalService';
import { useProjectTeams, useSafeSnackbar, useSwitchReviewerInvalidation } from '@hooks';
import { StringFieldModel, useStringFieldModel } from '@models';
import { emptyHTML, getBasicUrl } from '@utils';
import { useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  teams: ITeam[];
  onSwitchApprover: () => void;
  handleChange: (event: SelectChangeEvent) => void;
  approverId: string;
  teamsIsLoading: boolean;
  notes: StringFieldModel;
  isMutating: boolean;
}

export const useSwitchApproverPopup = (
  projectId: string,
  request: IDrawRequest,
  onClose: () => void,
): ControllerInterface => {
  const handleSwitchApproverInvalidation = useSwitchReviewerInvalidation();
  const flags = useLaunchDarklyFlags();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { companyTeamsWithoutCurrentUserTeam, nextApprovalLevelTeam, teamsIsLoading } =
    useProjectTeams({
      currentReviewerTeam: request?.current_reviewer_team,
      prId: projectId,
    });

  const [approverId, setApproverId] = useState<string>(null);

  const notes = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
    validateOnChange: true,
  });

  useEffect(() => {
    setApproverId(nextApprovalLevelTeam?.id || request?.current_reviewer_team?.id || null);
  }, [request?.current_reviewer_team, nextApprovalLevelTeam]);

  const updateRequestMutation = useMutation<Response, Error, UpdateRequestPayload>(
    updateDrawRequestReviewer,
    {
      onSuccess: () => {
        if (flags?.['ENG_7910_updates_based_on_draw_events']) {
          handleSwitchApproverInvalidation({ projectId, drawRequestId: request?.id });
        } else {
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL);
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
          queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST);
        }

        enqueueSnackbar('Reviewer team is updated', { variant: 'success' });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: request?.id },
        ]);
      },
    },
  );

  const handleChange = (event: SelectChangeEvent) => {
    setApproverId(event.target.value);
  };

  const postCommentMutation = useMutation<
    Response,
    Error,
    { url: string; value: { message: string } }
  >(postComment, {
    onSuccess: () => {
      notes.setValue('');
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const onSwitchApprover = useCallback(async () => {
    if (notes.validate()) {
      const team = companyTeamsWithoutCurrentUserTeam.find((item) => item.id === approverId);
      const message = team
        ? `Sent for approval to ${team.company?.name}/${team.name}:\n\n${notes.value}`
        : notes.value;
      const postCommentUrl = getBasicUrl({
        requestType: 'post',
        projectId,
        requestId: request?.id,
      });
      await postCommentMutation.mutateAsync({
        url: postCommentUrl,
        value: { message },
      });
    }
    await updateRequestMutation.mutateAsync({
      project: projectId,
      drawRequest: request?.id,
      current_reviewer_team: approverId,
    });
  }, [approverId, notes, companyTeamsWithoutCurrentUserTeam]);

  return {
    teams: companyTeamsWithoutCurrentUserTeam,
    onSwitchApprover,
    handleChange,
    approverId,
    teamsIsLoading,
    notes,
    isMutating: updateRequestMutation.isLoading || postCommentMutation.isLoading,
  };
};
