import React, { useContext, useMemo } from 'react';
import { Button, Grid } from '@mui/material';

import { inspectionStatusMap, ProjectScoresDisplayValues } from '@constants';
import {
  checkIsLender,
  checkIsOwner,
  getArrayFromObject,
  getTeamRole,
  isCreatedInspection,
  isCustomer,
  WithPermission,
} from '@utils';
import { AuthContext } from '@context';
import { FilterButton, SearchBox, ToggleButtonGroup } from '@components';
import { ComponentProps } from './interface';
import * as Controller from './controller';
import { PermissionNamesEnums, ScoreValuesEnum } from '@interfaces';

const DrawRequestsListFilters = ({
  handleFiltersChange,
  resetFiltersToDefault,
  clearFilters,
  filters,
  defaultFilters,
  handleSearchSubmit,
  clearSearch,
  search,
}: ComponentProps) => {
  const {
    borrowerCompanies,
    coordinators,
    lenderCompanies,
    investorCompanies,
    customerCompanies,
    projectStatuses,
    requestStatuses,
    inspectionAgencies,
    teams,
    viewType,
    VIEWS_TYPES,
    isOnHoldValues,
    handleViewType,
  } = Controller.useRequestsListFilters(
    clearFilters,
    resetFiltersToDefault,
    filters,
    defaultFilters,
  );

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const isUserCustomer = useMemo(() => isCustomer(user), [user]);

  return (
    <Grid container justifyContent="space-between" wrap="nowrap" p={2}>
      <Grid item sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid container wrap="wrap" spacing={1}>
          <Grid item>
            <SearchBox
              height="32px"
              search={search}
              onSubmit={handleSearchSubmit}
              clearSearch={clearSearch}
            />
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={viewType}
              typesList={Object.values(VIEWS_TYPES)}
              handleChange={handleViewType}
              size="small"
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={isOnHoldValues}
              title="On hold"
              initialValue={filters.is_on_hold}
              valueName="is_on_hold"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              dataTestName="requests_queue__filters__on_hold__button"
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={requestStatuses}
              title="Request status"
              initialValue={filters.status}
              valueName="status"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.status === value?.status}
              dataTestName="requests_queue__filters__request_button__button"
            />
          </Grid>
          {isUserCustomer && (
            <Grid item>
              <FilterButton
                labels={teams}
                title="Current reviewer team"
                initialValue={filters.current_reviewer_team}
                valueName="current_reviewer_team"
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                dataTestName="requests_queue__filters__current_reviewer_team__button"
              />
            </Grid>
          )}
          <Grid item>
            <FilterButton
              labels={projectStatuses}
              title="Project status"
              initialValue={filters.project_status}
              valueName="project_status"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.status === value?.status}
              dataTestName="requests_queue__filters__project_status__button"
            />
          </Grid>
          <WithPermission permissionKey={PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO}>
            <Grid item>
              <FilterButton
                labels={getArrayFromObject(inspectionStatusMap, 'status', 'name').filter(
                  (o) => !isCreatedInspection(o.status),
                )}
                initialValue={filters.inspection_status}
                title="Inspection status"
                valueName="inspection_status"
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.status === value?.status}
                dataTestName="requests_queue__filters__inspection_status__button"
              />
            </Grid>
          </WithPermission>
          {!isLender && (
            <Grid item>
              <FilterButton
                labels={lenderCompanies}
                initialValue={filters.lender}
                title="Lender"
                dataTestName="requests_queue__filters__lender__button"
                valueName="lender"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          )}
          {!isOwner && (
            <Grid item>
              <FilterButton
                labels={borrowerCompanies}
                initialValue={filters.borrower}
                title="Borrower"
                dataTestName="requests_queue__filters__borrower__button"
                valueName="borrower"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          )}
          <WithPermission permissionKey={PermissionNamesEnums.REQUESTS__COORDINATOR}>
            <Grid item>
              <FilterButton
                labels={coordinators}
                label="full_name"
                initialValue={filters.coordinator}
                title="Coordinator"
                dataTestName="requests_queue__filters__coordinator__button"
                valueName="coordinator"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          </WithPermission>
          <WithPermission permissionKey={PermissionNamesEnums.REQUEST_QUEUE__SCORES__VIEW}>
            <Grid item>
              <FilterButton
                labels={ProjectScoresDisplayValues}
                initialValue={filters[ScoreValuesEnum.PROJECT_HEALTH]}
                title="Project health"
                valueName={ScoreValuesEnum.PROJECT_HEALTH}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                dataTestName="requests_queue__filters__project_health__button"
              />
            </Grid>
          </WithPermission>
          <WithPermission permissionKey={PermissionNamesEnums.REQUEST_QUEUE__SCORES__VIEW}>
            <Grid item>
              <FilterButton
                labels={ProjectScoresDisplayValues}
                initialValue={filters[ScoreValuesEnum.BORROWER_SCORE]}
                title="Borrower risk"
                valueName={ScoreValuesEnum.BORROWER_SCORE}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                dataTestName="requests_queue__filters__project_borrower__button"
              />
            </Grid>
          </WithPermission>
          <WithPermission permissionKey={PermissionNamesEnums.REQUEST_QUEUE__CUSTOMER__VIEW}>
            <Grid item>
              <FilterButton
                labels={customerCompanies}
                initialValue={filters.customer}
                title="Customer"
                valueName="customer"
                updateFilters={handleFiltersChange}
                dataTestName="requests_queue__filters__customer__button"
              />
            </Grid>
          </WithPermission>
          <WithPermission permissionKey={PermissionNamesEnums.REQUEST_QUEUE__INVESTOR__VIEW}>
            <Grid item>
              <FilterButton
                labels={investorCompanies}
                initialValue={filters.investor}
                title="Investor"
                valueName="investor"
                updateFilters={handleFiltersChange}
                dataTestName="requests_queue__filters__investor__button"
              />
            </Grid>
          </WithPermission>
          <WithPermission permissionKey={PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO}>
            <Grid item>
              <FilterButton
                labels={inspectionAgencies}
                label="display_name"
                initialValue={filters.inspection_agency}
                title="Agency"
                dataTestName="requests_queue__filters__inspection_agency__button"
                valueName="inspection_agency"
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) =>
                  option.display_name === value?.display_name
                }
              />
            </Grid>
          </WithPermission>
          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={resetFiltersToDefault}
              data-cy="requests_queue__reset_filters__button"
            >
              Reset filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrawRequestsListFilters;
