import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import {
  ILocalFee,
  IProject,
  PaymentConfiguration,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { isCreatedProject, isRestricted } from '@utils';
import { updateProjectFields } from '@globalService';
import { StringFieldModel, useStringFieldModel } from '@models';
import { PermissionsContext } from '@context';
import { IFeesControllerInterface, useEditPaymentConfigurationType, useFees } from '@hooks';
import { useLocation } from 'react-router-dom';

interface ControllerInterface {
  configurationType: PaymentConfiguration;
  configurationTypeOptions: { value: string; label: string }[];
  setConfigurationType: Dispatch<SetStateAction<PaymentConfiguration>>;
  isEditable: boolean;
  comment: StringFieldModel;
  exitPath: string;
  isSubmitting: boolean;
  handleSubmitClick: (fees: ILocalFee[]) => void;
  feesController: IFeesControllerInterface;
  isUpdated: boolean;
  canEditFees: boolean;
}

export const usePayments = ({ project }: { project: IProject }): ControllerInterface => {
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state: locationState } = useLocation();
  const { configurationType, setConfigurationType, configurationTypeOptions } =
    useEditPaymentConfigurationType();
  const feesController = useFees();

  const comment = useStringFieldModel({
    initValue: null,
  });

  useEffect(() => {
    if (project?.payment_configuration_type) {
      setConfigurationType(project?.payment_configuration_type);
    }
    if (project?.payment_configuration_comment) {
      comment.setValue(project?.payment_configuration_comment);
    }
  }, [project]);

  const updateProjectPaymentConfig = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json: Partial<IProject>;
    }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      // set initial value if mutation failed
      setConfigurationType(project?.payment_configuration_type);
    },
  });

  const isEditable = useMemo(
    () =>
      isCreatedProject(project.status) &&
      !isRestricted(PermissionNamesEnums.PROJECT__PAYMENT__PARTIES__EDIT, permissions),
    [project.status, permissions],
  );

  const exitPath = useMemo(
    () => locationState?.['prevUrl'] || `/projects/${project.id}/overview`,
    [locationState, project.id],
  );

  const handleSubmitClick = useCallback(async () => {
    await updateProjectPaymentConfig.mutateAsync({
      projectId: project.id,
      json: {
        payment_configuration_type: configurationType,
        ...(comment.value ? { payment_configuration_comment: comment.value } : {}),
        fees: feesController.fees.filter((fee) => fee.amount && fee.name.trim()),
      },
    });
    return true;
  }, [
    updateProjectPaymentConfig,
    project.id,
    project,
    configurationType,
    comment.value,
    feesController.fees,
  ]);

  const canEditFees = useMemo(
    () => !isRestricted(PermissionNamesEnums.PROJECT__PAYMENT__FEES_EDIT, permissions),
    [permissions],
  );

  const isUpdated = useMemo(
    () =>
      (isEditable || canEditFees) &&
      (Boolean(project?.payment_configuration_type !== configurationType) ||
        feesController.isFeesUpdated ||
        comment.value !== project?.payment_configuration_comment),
    [
      project?.payment_configuration_type,
      configurationType,
      feesController.isFeesUpdated,
      comment.value,
      project?.payment_configuration_comment,
      isEditable,
      canEditFees,
    ],
  );

  return {
    configurationType,
    configurationTypeOptions,
    setConfigurationType,
    isEditable,
    comment,
    exitPath,
    isSubmitting: updateProjectPaymentConfig.isLoading,
    handleSubmitClick,
    feesController,
    isUpdated,
    canEditFees,
  };
};
