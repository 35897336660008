import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  CSVBoxSheetsEnum,
  HookState,
  PermissionNamesEnums,
  PopupTypeEnum,
  TableKeyEnum,
} from '@interfaces';
import {
  ButtonWithTooltip,
  ConfirmationModal,
  CSVUploader,
  Filter,
  IsProductionBuildCheckbox,
  LoadingSkeleton,
  MilestoneList,
  ServiceMessage,
  StyledBox,
} from '@components';
import { colors } from '@theme';
import UploadBudgetImage from 'encirclelabs-assets/upload_budget.svg';
import CreateBudgetImage from 'encirclelabs-assets/create_budget.svg';
import { getTooltipText, WithPermission } from '@utils';
import * as Controller from './controller';
import { DeleteIcon } from '@svgAsComponents';
import { useLaunchDarklyFlags } from '@context';
import { CSVSampleLinks, CSVSampleLinksNames } from '@constants';

const Budget = () => {
  const { projectId } = useParams();
  const controller = Controller.useProjectMilestones(projectId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="milestones" />;
    }

    case HookState.SUCCESS: {
      if (controller.createMode) return <NoBudgetContent controller={controller} />;
      return <Milestones controller={controller} />;
    }

    default:
      return null;
  }
};

const NoBudgetContent = ({ controller }) => {
  const { editBudget, dynamicColumns, isOwner } = controller;
  const flags = useLaunchDarklyFlags();

  return (
    <WithPermission
      permissionKey={[
        PermissionNamesEnums.PROJECT__CREATE__BUTTON,
        PermissionNamesEnums.PROJECT__BUDGET__EDIT,
      ]}
    >
      {flags?.['ENG_4271_production_build_budget'] && (
        <Stack mb={2}>
          <IsProductionBuildCheckbox isDefaultProdBuildProject={false} />
        </Stack>
      )}
      <Stack
        direction="row"
        sx={{ flexGrow: 1, backgroundColor: colors.background.white, padding: 3 }}
      >
        <Stack justifyContent="center" alignItems="center" spacing={6} sx={{ flexGrow: 1 }}>
          <img
            src={UploadBudgetImage}
            style={{ maxWidth: '100%', width: '246px' }}
            alt="budget upload"
          />
          <CSVUploader
            buttonTitle="Upload budget"
            sheetKey={CSVBoxSheetsEnum.IMPORT_PROJECT_BUDGET}
            dynamicColumns={dynamicColumns}
            templateUrl={isOwner ? CSVSampleLinks.BUDGET_UPLOAD : null}
            templateName={isOwner ? CSVSampleLinksNames.BUDGET_UPLOAD : ''}
          />
        </Stack>
        <Stack spacing={3} alignItems="center">
          <Divider orientation="vertical" sx={{ height: '45%' }} />
          <Typography variant="body1">or</Typography>
          <Divider orientation="vertical" sx={{ height: '45%' }} />
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={6} sx={{ flexGrow: 1 }}>
          <img
            src={CreateBudgetImage}
            style={{ maxWidth: '100%', width: '246px' }}
            alt="budget creation"
          />
          <Button
            color="secondary"
            variant="new"
            size="small"
            onClick={editBudget}
            data-cy="create_budget_from_scratch_button"
          >
            Create budget from scratch
          </Button>
        </Stack>
      </Stack>
    </WithPermission>
  );
};

const Milestones: FC<{ controller: Controller.ControllerInterface }> = ({ controller }) => {
  const {
    initColumns,
    milestones,
    totals,
    handleFiltersChange,
    filterOptions,
    editBudget,
    editMode,
    addLineList,
    patchMilestone,
    showEdit,
    update,
    isAllNamesNotFill,
    deleteBudgetModal,
    deleteBudget,
    deleteMilestone,
    filterValue,
    updateListData,
  } = controller;

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneList
        withoutStickLeftColumn
        updateData={updateListData}
        deleteMilestone={deleteMilestone}
        refetch={update}
        patchMilestone={patchMilestone}
        withColumnIndication
        tableKey={TableKeyEnum.LINE_ITEMS}
        initColumns={initColumns}
        milestones={milestones}
        totals={totals}
        key={`listWithEdit${editMode}`}
        exportToCSV
        headerRightPart={
          editBudget
            ? [
                {
                  Component: (
                    <WithPermission
                      permissionKey={[
                        PermissionNamesEnums.PROJECT__CREATE__BUTTON,
                        PermissionNamesEnums.PROJECT__BUDGET__EDIT,
                      ]}
                    >
                      <Stack spacing={1} direction="row">
                        {showEdit && (
                          <ButtonWithTooltip
                            dataTestName="edit-done-button"
                            color="secondary"
                            variant={editMode ? 'text' : 'new'}
                            size="small"
                            onClick={editBudget}
                            tooltipText={getTooltipText({
                              isAllNamesNotFill,
                            })}
                            disabled={isAllNamesNotFill}
                          >
                            {editMode ? 'Done' : 'Edit budget'}
                          </ButtonWithTooltip>
                        )}
                        {editMode && (
                          <LoadingButton
                            loading={false}
                            size="small"
                            onClick={addLineList}
                            data-cy="add_new_line_item_button"
                          >
                            Add line item
                          </LoadingButton>
                        )}
                      </Stack>
                    </WithPermission>
                  ),
                },
              ]
            : []
        }
        headerLeftPart={[
          {
            Component: (
              <Filter
                filterLabel="Show"
                onChangeCallback={handleFiltersChange}
                options={filterOptions}
                filterValue={filterValue}
              />
            ),
          },
        ]}
        menuItems={
          Boolean(milestones?.length) && showEdit
            ? [
                {
                  action: deleteBudgetModal.askConfirm,
                  text: 'Delete budget',
                  icon: <DeleteIcon size={15} />,
                  dataTestName: 'delete_budget__menu_item',
                },
              ]
            : []
        }
        source="project__budget__line_items_table"
      />
      <ConfirmationModal
        open={deleteBudgetModal.isConfirmModalOpened}
        title="Delete budget"
        text="Are you sure you want to delete all line items? If you delete, you will lose the data you entered."
        onClose={deleteBudgetModal.closeConfirmModal}
        confirmCallback={deleteBudget}
        type={PopupTypeEnum.ERROR}
      />
    </Stack>
  );
};

export default Budget;
