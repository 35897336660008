import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { useMemo } from 'react';
import find from 'lodash/find';

import { IProject, ISystemConfig, QueryNamesEnums, SystemConfigKeysEnum } from '@interfaces';
import { getProjectWithQuery, getSystemConfig } from '@globalService';
import { refetchPooling, replaceObjectProperties } from '@utils';

export type ControllerInterface = {
  refetch: () => Promise<UseQueryResult> | void;
  csvBoxSheetId: string;
};

export const useCSVUploader = ({ projectId, sheetKey }): ControllerInterface => {
  const queryClient = useQueryClient();

  const configQuery = useQuery<{ results: ISystemConfig[] }, Error>(
    [QueryNamesEnums.GET_SYSTEM_CONFIG],
    getSystemConfig.bind(this),
  );

  const updateProjectListQueries = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_LIST);
  };

  const query = '{original_estimate}';
  const projectQuery = useQuery<Promise<Partial<IProject>>, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId, query }],
    getProjectWithQuery.bind(this, { projectId, restQlParams: query }),
    { enabled: false },
  );

  const updateProjectQueries = () => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
    refetchPooling({
      refetch: projectQuery.refetch,
      timeout: 1000,
      refetchCallback: (query) => {
        queryClient.setQueriesData<IProject>(
          { queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }] },
          (oldPaymentConfig) =>
            replaceObjectProperties({
              data: oldPaymentConfig,
              newData: { original_estimate: query.data?.original_estimate },
            }),
        );
      },
    });
  };

  const csvBoxSheetId = useMemo(
    () => find(configQuery.data?.results, { key: SystemConfigKeysEnum.CSVBOX })?.value?.[sheetKey],
    [configQuery.data],
  );

  const refetch = useMemo(() => {
    if (projectId) return updateProjectQueries;
    return updateProjectListQueries;
  }, [projectId]);

  return {
    refetch,
    csvBoxSheetId,
  };
};
