import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { colors } from '@theme';
import {
  ButtonWithTooltipOnClick,
  CenteredStyledBox,
  ConfirmationModal,
  InspectionInputsBlock,
  LabelAndValue,
  ToolTipLine,
  WysiwygEditor,
} from '@components';
import * as Controller from './controller';
import { getOptionLabel } from '@utils';
import { IInspectionAgency, IInspectionClosePopupEnum, PopupTypeEnum } from '@interfaces';
import { InspectionIcon } from '@svgAsComponents';
import InspectionRelatedDocuments from '../../../project/components/InspectionRelatedDocuments';
import snakeCase from 'lodash/snakeCase';

const InspectionServiceOrder: FC<{
  drawRequestId?: string;
  onClose: (showPopup?: IInspectionClosePopupEnum) => void;
}> = ({ drawRequestId, onClose }) => {
  const { projectId } = useParams();
  const {
    inspectionComment,
    handleInspectionRequest,
    cancelInputsValues,
    drNumber,
    project,
    isSubmitting,
    inspectionAgencySelected,
    setInspectionAgencySelected,
    inspectionAgenciesList,
    manualInspectionAgenciesList,
    otherInspectionName,
    setOtherInspection,
    isOtherInspectionSelected,
    isAutomatedInspectionSelected,
    isSubmitDisabled,
    orderInspectionWithoutAppraisalModal,
    handleCheckAppraisalBeforeOrderingInspection,
    borrowerComment,
    inspectionFields,
    tooltipText,
    submitDisabledTooltipText,
    createdInspection,
    requestHasReallocationModal,
    reallocationWarningText,
    goToRequest,
    isPseudoAutomatedInspectionSelected,
  } = Controller.useInspectionDialog(projectId, onClose, drawRequestId);
  const filter = createFilterOptions<IInspectionAgency>();

  return (
    <>
      <Stack justifyContent="space-between" sx={{ flex: 1 }}>
        <Stack spacing={2}>
          <CenteredStyledBox>
            <Typography variant="h3">Service company</Typography>
            {inspectionAgencySelected?.display_name ? (
              <Stack
                direction="row"
                sx={{ mt: '24px' }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack sx={{ width: isOtherInspectionSelected ? '48%' : '100%' }}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    options={inspectionAgenciesList}
                    value={inspectionAgencySelected}
                    getOptionLabel={getOptionLabel((option) => `${option.display_name}`)}
                    onChange={(e, value) => {
                      setInspectionAgencySelected(value as IInspectionAgency);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Inspection company"
                        InputProps={{ ...params.InputProps }}
                        inputProps={{
                          ...params.inputProps,
                          'data-cy': 'order_inspection_dialog__inspection_agency_type__input',
                        }}
                        size="small"
                      />
                    )}
                  />
                </Stack>
                {isOtherInspectionSelected && (
                  <Stack sx={{ width: '48%' }}>
                    <Autocomplete
                      disablePortal
                      value={otherInspectionName}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setOtherInspection({ display_name: newValue });
                        } else if (newValue?.inputValue) {
                          // Create a new value from the user input
                          setOtherInspection({ display_name: newValue.inputValue });
                        } else {
                          setOtherInspection(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.display_name,
                        );
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            display_name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Agency name"
                          InputProps={{ ...params.InputProps }}
                          inputProps={{
                            ...params.inputProps,
                            'data-cy':
                              'order_inspection_dialog__manual_inspection_agency_name__input',
                          }}
                          size="small"
                        />
                      )}
                      options={manualInspectionAgenciesList}
                      freeSolo
                      renderOption={(props, option) => {
                        const optionLabel =
                          typeof option === 'string' ? option : option.display_name;
                        return (
                          <li {...props} data-cy={`dropdown_option_${snakeCase(optionLabel)}`}>
                            {optionLabel}
                          </li>
                        );
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.display_name;
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            ) : (
              <LabelAndValue label="Agency" text={inspectionAgencySelected?.display_name || '-'} />
            )}
          </CenteredStyledBox>

          <CenteredStyledBox>
            <Stack spacing={3}>
              <Typography variant="h3">Onsite contact information</Typography>
              <Stack spacing={1}>
                {drNumber && <LabelAndValue label="Draw request" text={`#${drNumber}`} />}
                <LabelAndValue label="Address" text={project?.address?.address_1 || '-'} />
              </Stack>
            </Stack>
          </CenteredStyledBox>

          <CenteredStyledBox>
            {tooltipText && <ToolTipLine typographyVariant="body2" text={tooltipText} />}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ mt: 2 }}>
                <InspectionInputsBlock
                  source="order_inspection_dialog"
                  inputWidth={6}
                  showRequestedDate
                  {...inspectionFields}
                />
              </Box>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="label">Comment from borrower (Internal)</Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: borrowerComment || '-' }}
                />
              </Stack>

              <Stack>
                <Typography variant="label">
                  Comment (Feel free to provide additional info, i.e. inspect ADU, etc.)
                </Typography>

                <WysiwygEditor
                  editField={inspectionComment}
                  source="order_inspection_dialog__comment"
                />
              </Stack>
            </LocalizationProvider>
          </CenteredStyledBox>

          {(isAutomatedInspectionSelected || isPseudoAutomatedInspectionSelected) && (
            <InspectionRelatedDocuments
              drawRequestId={drawRequestId}
              inspectionId={createdInspection?.id}
            />
          )}

          {(isAutomatedInspectionSelected || isPseudoAutomatedInspectionSelected) && (
            <CenteredStyledBox>
              <Typography variant="body1">
                <span style={{ color: colors.status.information.medium, fontWeight: 700 }}>
                  Disclaimer.{' '}
                </span>
                By clicking “Order” I understand and agree that I am ordering a product or service
                from a third-party provider, not TrustPoint.This order is subject to the provider’s,
                and not TrustPoint’s, terms and conditions. I understand that I can review the
                provider’s terms and conditions on the provider’s website, and I agree to abide by
                and be bound by the provider’s terms and conditions.
              </Typography>
            </CenteredStyledBox>
          )}
        </Stack>

        <ConfirmationModal
          open={orderInspectionWithoutAppraisalModal.isConfirmModalOpened}
          title="Warning"
          text={
            'Proceeding without attached appraisal may extend the inspection resolution time. \n\n Continue anyway?'
          }
          onClose={orderInspectionWithoutAppraisalModal.closeConfirmModal}
          confirmCallback={handleInspectionRequest}
          isLoading={isSubmitting}
          type={PopupTypeEnum.ERROR}
        />

        <ConfirmationModal
          open={requestHasReallocationModal.isConfirmModalOpened}
          onClose={goToRequest}
          title={reallocationWarningText.title}
          text={reallocationWarningText.text}
          confirmCallback={requestHasReallocationModal.closeConfirmModal}
          confirmButtonLabel="Continue ordering"
          cancelButtonLabel="Return to request tab"
          type={PopupTypeEnum.GENERAL}
          icon={InspectionIcon}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          backgroundColor: colors.background.white,
          mt: 2,
          padding: '1.5rem',
          width: '100%',
        }}
      >
        <Button
          variant="new"
          sx={{ mr: '0.5rem' }}
          onClick={() => {
            cancelInputsValues();
            onClose();
          }}
          data-cy="order_inspection_dialog__cancel__button"
        >
          Cancel
        </Button>
        <ButtonWithTooltipOnClick
          onClick={handleCheckAppraisalBeforeOrderingInspection}
          loading={isSubmitting}
          disabled={isSubmitting}
          conditionallyDisabled={isSubmitDisabled}
          tooltipText={submitDisabledTooltipText}
          dataTestName="order_inspection_dialog__submit__button"
        >
          {isSubmitting ? 'Ordering...' : 'Order'}
        </ButtonWithTooltipOnClick>
      </Stack>
    </>
  );
};

export default InspectionServiceOrder;
