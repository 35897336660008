import { dataProvider } from '@api';
import { inspectionsDocumentItem, inspectionsDocuments } from '@globalService';
import { IProjectDocument } from '@interfaces';

export const deleteInspectionDocument = async ({
  projectId,
  inspectionId,
  documentId,
}): Promise<Response> =>
  dataProvider.delete(inspectionsDocumentItem(projectId, inspectionId, documentId)).json();

export const getInspectionDocuments = async ({
  projectId,
  inspectionId,
  query = '',
}): Promise<{ results: IProjectDocument[] }> =>
  dataProvider.get(inspectionsDocuments(projectId, inspectionId, query)).json();
