import React, { FC, PropsWithChildren } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { ComponentProps, Sizes } from './interface';
import { InfoIcon, WarningIcon, Warning2Icon, ProjectListIcon } from '@svgAsComponents';
import { ToolTipLineVariantEnum } from '@interfaces';
import { colors } from '@theme';
import { backgroundColorsMap } from '@constants';

const IconMap = {
  [ToolTipLineVariantEnum.INFORMATION]: InfoIcon,
  [ToolTipLineVariantEnum.TEXT]: ProjectListIcon,
  [ToolTipLineVariantEnum.ERROR]: WarningIcon,
  [ToolTipLineVariantEnum.WARNING]: Warning2Icon,
};

const LineStyleMap = {
  [ToolTipLineVariantEnum.INFORMATION]: {
    backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.INFORMATION],
    py: 1,
    px: 2,
  },
  [ToolTipLineVariantEnum.TEXT]: {
    backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.INFORMATION],
    py: 1,
    px: 2,
  },
  [ToolTipLineVariantEnum.ERROR]: {
    backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.ERROR],
    py: 1,
    px: 2,
  },
  [ToolTipLineVariantEnum.WARNING]: {
    backgroundColor: backgroundColorsMap[ToolTipLineVariantEnum.WARNING],
    py: 1,
    px: 2,
  },
};

const ToolTipLine: FC<PropsWithChildren<ComponentProps>> = ({
  variant = ToolTipLineVariantEnum.INFORMATION,
  text,
  children,
  typographyVariant = 'body2SemiBold' as Sizes,
  withBackground = false,
  fullWidth = false,
  dataTestName,
  size,
  sx,
}) => {
  const Icon = IconMap[variant];
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        ...(withBackground && LineStyleMap[variant]),
        ...(fullWidth && { backgroundColor: colors.background.white, p: 2 }),
        maxHeight: size === 'small' ? '32px' : '40px',
        ...sx,
      }}
      spacing={1}
    >
      <Stack sx={{ flexShrink: 1 }}>
        <Icon size={size === 'small' ? 16 : 24} />
      </Stack>

      {text ? (
        <Typography
          variant={typographyVariant}
          sx={{ color: colors.status[variant]?.medium || colors.text.dark, ml: 1 }}
          data-cy={dataTestName}
        >
          {text}
        </Typography>
      ) : (
        <Box sx={{ ml: 1 }}>{children}</Box>
      )}
    </Stack>
  );
};

export default ToolTipLine;
