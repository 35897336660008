import React from 'react';
import { Box, IconButton, InputBase, Theme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { colors, typography } from '@theme';

interface Props {
  onSubmit: (value: string) => void;
  clearSearch: () => void;
  search: string;
  height?: string;
  dataTestName?: string;
}

const SearchBox = ({ onSubmit, clearSearch, search, height = '40px', dataTestName }: Props) => {
  const smMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: smMediaQuery ? height : '32px',
        backgroundColor: colors.neutral.lightest,
        borderRadius: '2px',
      }}
      data-cy={dataTestName}
    >
      <IconButton type="submit">
        <SearchIcon />
      </IconButton>
      <InputBase
        value={search}
        onChange={(e) => onSubmit(e.target.value)}
        sx={{ flex: 1, ...typography.body3, color: colors.text.medium }}
        placeholder="Search"
        endAdornment={
          <IconButton onClick={clearSearch} sx={{ mr: 1 }} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        inputProps={{ maxLength: 40 }}
      />
    </Box>
  );
};

export default SearchBox;
