import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AuthContext, PermissionsContext } from '@context';
import {
  checkIsAdmin,
  checkIsPaymentManager,
  getActiveDrawRequestFromList,
  getDrawRequestForApproval,
  getTeamRole,
  isAutomatedInspection,
  isCustomer,
  isDrawRequest,
  isOrderedInspection,
  isRequestDraft,
  isRestricted,
} from '@utils';
import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getDrawRequest, getProjectInspectionsList, getWaitingYourApproval } from '@globalService';
import memoize from 'lodash/memoize';

export const TABS = {
  OVERVIEW: { label: 'Overview', value: 'overview' },
  DRAWS: { label: 'Requests', value: 'draws' },
  DOCUMENTS: { label: 'Documents', value: 'documents' },
  INSPECTIONS: { label: 'Inspections', value: 'inspections' },
  SERVICES: { label: 'Services', value: 'services' },
  PAYMENTS: { label: 'Payments', value: 'payments' },
  PHOTOS: { label: 'Photos', value: 'photos' },
  POLICIES: { label: 'Policies', value: 'policies' },
};

export const FIRST_TAB_VALUE_MAP = {
  documents: 'All',
  payments: 'Project',
  inspections: 'All',
  services: 'All',
  photos: 'All',
  draws: 'Budget',
};

export const getFirstTabText = (tab) => FIRST_TAB_VALUE_MAP[tab];

export const getDrawRequestInProgress = memoize(getActiveDrawRequestFromList);

export const getDrawLabel = (draw, DRList) => {
  const label = isDrawRequest(draw) ? 'Draw' : 'Change';
  const prefix =
    getDrawRequestInProgress(DRList)?.id === draw.id
      ? `Active ${label}`
      : isRequestDraft(draw.status)
        ? `Draft ${label}`
        : label;
  return `${prefix} #${draw.counter_per_request_type}`;
};

export const getDataTestNameLabel = (draw) => {
  const type = isDrawRequest(draw) ? 'draw' : 'change';
  return `${type}__${draw.counter_per_request_type}`;
};

export const useGetActionTabs = () => {
  const { projectId } = useParams();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);

  const drawRequestsQuery = useQuery(
    [QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }],
    () => getWaitingYourApproval(projectId, teamRole),
  );

  const drawRequestForApproval = useMemo(
    () => getDrawRequestForApproval(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const drawRequestData = useQuery(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: drawRequestForApproval?.id }],
    () => getDrawRequest({ projectId, drawRequestId: drawRequestForApproval?.id }),
    { enabled: Boolean(drawRequestForApproval && projectId) },
  );

  const { data: { results: projectInspections } = {} } = useQuery(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    () => getProjectInspectionsList({ projectId }),
    { enabled: !isRestricted(PermissionNamesEnums.PROJECT__INSPECTION, permissions) },
  );

  const activeInspection = useMemo(
    () =>
      projectInspections?.find(
        ({ status, inspection_agency }) =>
          isOrderedInspection(status) && !isAutomatedInspection(inspection_agency?.service),
      ),
    [projectInspections],
  );

  return {
    Requests:
      drawRequestForApproval &&
      !drawRequestData.data?.totals?.all?.approved_amount &&
      isCustomer(user),
    Services: activeInspection && (checkIsAdmin(teamRole) || isCustomer(user)),
    Payments: drawRequestForApproval && checkIsPaymentManager(teamRole),
  };
};
