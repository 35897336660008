import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  IDrawRequest,
  IProject,
  QueryNamesEnums,
  SubmitDrawrequestRequestPayload,
} from '@interfaces';
import {
  getDrawRequest,
  postComment as post,
  submitDrawRequest,
  updateProjectFields,
} from '@globalService';
import { currencyFormatter, getReasonText, isChangeRequest, isDrawRequest } from '@utils';
import { useLaunchDarklyFlags } from '@context';
import { useReviewRequestInvalidation, useSafeSnackbar } from '@hooks';

import { ISubmissionController, SubmissionControllerProps } from '../interfaces';

export const useSubmission = ({
  completionDate,
  completionDateComment,
  completionDateReasons,
  comment,
  inspectionComment,
  inspectionFields,
}: SubmissionControllerProps): ISubmissionController => {
  const flags = useLaunchDarklyFlags();
  const { projectId, requestId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [showSuccessPopup, setShowSuccessPopup] = useState<string>('');
  const navigate = useNavigate();
  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
  } = inspectionFields;
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();

  const updateInfoProject = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: requestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId: requestId }),
    {
      enabled: Boolean(projectId && requestId),
    },
  );

  const postComment = useMutation<Response, Error, { url: string; value: { message: string } }>(
    post,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const submitDrawRequestMutation = useMutation<Response, Error, SubmitDrawrequestRequestPayload>(
    submitDrawRequest,
    {
      onSuccess: () => {
        showSuccessModal();
        if (flags?.['ENG_7910_updates_based_on_draw_events']) {
          handleRequestReviewInvalidation({ projectId, drawRequestId: requestId });
        } else {
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
          queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST);
          queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const showSuccessModal = useCallback(() => {
    const request = drawRequestData.data;
    if (isChangeRequest(request)) {
      const successPopupText = `Change request #${
        request.number
      } for the requested reallocations of ${currencyFormatter(
        request.requested_reallocation,
      )} has been submitted. You will be notified once it is approved.\nStay tuned!`;
      setShowSuccessPopup(successPopupText);
    }
    if (isDrawRequest(request)) {
      const successPopupText = `Draw request #${
        request.number
      } for the requested amount of ${currencyFormatter(request.totals?.all?.requested_amount)} ${
        request.requested_reallocation
          ? 'and requested reallocations of ' + currencyFormatter(request.requested_reallocation)
          : ''
      } has been submitted. You will be notified once it is approved.\nStay tuned!`;
      setShowSuccessPopup(successPopupText);
    }
  }, [drawRequestData]);

  const isProjectDataUpdated = useMemo(() => {
    return (
      (completionDate.isValid && completionDate.isChanged) || isProjectInspectionSettingsUpdated
    );
  }, [completionDate, isProjectInspectionSettingsUpdated]);

  const drawRequestSubmit = useCallback(async () => {
    if (isProjectDataUpdated) {
      await updateInfoProject.mutateAsync({
        projectId,
        json: {
          estimated_completion_date: format(completionDate.value, 'yyyy-MM-dd'),
          estimated_completion_date_change_reason: getReasonText(
            completionDateReasons,
            completionDateComment.value,
          ),
          inspection_additional_contact_name: additionalContactName.value,
          inspection_additional_contact_phone: additionalContactPhone?.valueToSave,
          inspection_entry_access_code: accessCode.value,
          inspection_primary_contact_user_id: primaryContactUserNotRequired.value?.id,
        },
      });
    }

    comment.value &&
      (await postComment.mutateAsync({
        url: `projects/${projectId}/draw_requests/${requestId}/comments/`,
        value: { message: comment.value },
      }));

    const drawRequestParams = {
      project: projectId,
      drawRequest: requestId,
    };

    await submitDrawRequestMutation.mutateAsync({
      ...drawRequestParams,
      ...(inspectionComment.value
        ? {
            gc_inspection_comment: inspectionComment.value,
          }
        : {}),
      gc_inspection_scheduled_at: format(
        inspectionRequestedAt.value || new Date(),
        'yyyy-MM-dd',
      ) as string,
    });
  }, [
    submitDrawRequestMutation,
    drawRequestData,
    flags,
    completionDate,
    completionDateComment,
    completionDateReasons,
    comment,
    inspectionComment,
    inspectionRequestedAt,
    isProjectDataUpdated,
    flags,
  ]);

  const onCloseSuccessModal = (navigateLink = `/projects/${projectId}/overview`) => {
    setShowSuccessPopup('');
    if (window?._refiner) window._refiner('showForm', '07ad54a0-46a5-11ee-a788-978ec019f75f');

    navigate(navigateLink);
  };

  return {
    drawRequestSubmit,
    showSuccessPopup,
    onCloseSuccessModal,
    isSubmitting: submitDrawRequestMutation.isLoading,
  };
};
