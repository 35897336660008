// TODO - move it to components folder

import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  ConfirmationModal,
  DocumentButton,
  DocumentUploaderWithForm,
  FilesUploader,
  LoadingSkeleton,
  CenteredStyledBox,
  ToolTipLine,
} from '@components';
import { DocumentTypeEnum, HookState, PopupTypeEnum, ToolTipLineVariantEnum } from '@interfaces';
import * as Controller from './controller';

const InspectionRelatedDocuments = ({
  drawRequestId,
  inspectionId,
  isInspectionReports = false,
  uploadRefetchCallback,
  source = 'order_inspection_dialog',
  onDocumentClick,
  isEditable = true,
  activeDocumentId = '',
}: {
  drawRequestId: string;
  inspectionId: string;
  isInspectionReports?: boolean;
  uploadRefetchCallback?: () => void;
  source?: string;
  activeDocumentId?: string;
  isEditable?: boolean;
  onDocumentClick?: (document) => void;
}) => {
  const controller = Controller.useInspectionRelatedDocuments({
    drawRequestId,
    inspectionId,
    isInspectionReports,
  });

  const {
    state,
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    refetchCallback,
    documentTypes,
    refetchDocuments,
    documents,
    isLoading,
    restrictions,
    setIsAppraisal,
    handleDelete,
    deleteModal,
    handleDeleteIconClick,
    selectedFileName,
  } = controller;

  return (
    <CenteredStyledBox sx={{ overflow: 'unset', ...(isInspectionReports && { p: 0 }) }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography variant="h3">Related documents</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            size="small"
            variant={isInspectionReports ? undefined : 'new'}
            color={isInspectionReports ? undefined : 'secondary'}
            onClick={() => {
              setIsAppraisal(false);
              handleOpenDocumentUploader({ isProjectLevel: false });
            }}
            data-cy={`${source}__add_other_documents__button`}
            disabled={!isEditable}
          >
            {isInspectionReports ? 'Add document(s)' : 'Add other doc(s)'}
          </Button>
          {!isInspectionReports && (
            <Button
              size="small"
              onClick={() => {
                setIsAppraisal(true);
                handleOpenDocumentUploader({ isProjectLevel: true });
              }}
              data-cy={`${source}__add_appraisal__button`}
              disabled={!isEditable}
            >
              Add appraisal
            </Button>
          )}
        </Stack>
      </Stack>
      {state === HookState.FETCHING && (
        <Stack spacing={2} direction="row">
          <LoadingSkeleton type="overviewBlock" />
        </Stack>
      )}
      {state === HookState.SUCCESS && (
        <>
          {!isInspectionReports && (
            <Typography variant="body1" sx={{ mb: 3 }}>
              Upload documents such as appraisals and previous inspection reports. Appraisals will
              accompany all inspection orders; other documents will only be included with the
              current one.
            </Typography>
          )}
          <Box
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            onClick={(e) => (isLoading ? e.stopPropagation() : null)}
          >
            {!documents?.length && !isInspectionReports && (
              <Box sx={{ mb: 2, mr: 2 }}>
                <ToolTipLine
                  variant={ToolTipLineVariantEnum.ERROR}
                  text="No appraisal attached"
                  withBackground
                  typographyVariant="labelSemiBold"
                  size="small"
                />
              </Box>
            )}

            {documents?.map((document, index) => (
              <Box sx={{ mb: 2, mr: 2 }} key={document.id}>
                <DocumentButton
                  document={document}
                  onButtonClick={() => onDocumentClick(document)}
                  dataTestName={`${source}__docs_button__button__index_${index}`}
                  handleDelete={
                    document.document_type === DocumentTypeEnum.APPRAISAL || !isEditable
                      ? undefined
                      : handleDeleteIconClick
                  }
                  handleDeleteProps={{
                    fileName: document.name,
                  }}
                  isActive={activeDocumentId === document.id}
                  width="175px"
                />
              </Box>
            ))}
          </Box>
        </>
      )}
      {transloaditSignature?.signature && !isInspectionReports && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={documentTypes}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          refetchCallback={refetchCallback}
          refetch={[refetchDocuments]}
          restrictions={restrictions}
          source={`${source}__documents`}
        />
      )}
      {transloaditSignature?.signature && isInspectionReports && (
        <FilesUploader
          open={isFilesUploaderOpened}
          onClose={() => {
            filesUploaderClose();
          }}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          fields={['name']}
          metaFields={[{ id: 'name', name: 'Name', placeholder: 'file name' }]}
          refetchCallback={refetchCallback}
          refetch={[uploadRefetchCallback || refetchDocuments]}
          source={source}
        />
      )}
      <ConfirmationModal
        open={deleteModal.isConfirmModalOpened}
        title="Delete file"
        text={`You are about to delete the file "${selectedFileName}".`}
        onClose={deleteModal.closeConfirmModal}
        confirmCallback={() =>
          deleteModal.confirmCallback({
            action: async (args) => {
              await handleDelete(args);
              refetchDocuments();
            },
          })
        }
        type={PopupTypeEnum.ERROR}
        source={`${source}__documents__delete`}
        confirmButtonLabel="Delete"
      />
    </CenteredStyledBox>
  );
};

export default InspectionRelatedDocuments;
