import React from 'react';
import { Stack, IconButton, Typography, Tab, Tabs, Tooltip } from '@mui/material';
import { Link, matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { ProjectNameLabel } from '@components';
import { ArrowBackIcon } from '@svgAsComponents';
import { colors } from '@theme';

import * as Controller from './controller';
import { ServiceComponent, InspectionServiceOrder } from './components';

const OrderService = () => {
  const controller = Controller.useOrderService();
  const { handleTabChange, activeTab, SwitcherTabs, TABS, goBack, project, drawRequestInProgress } =
    controller;
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/order-service/:serviceType', pathname);
  const pathBase = `/projects/${project?.id}/order-service`;
  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pr: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ px: { md: 3, xs: 2 }, height: 84 }}
        >
          <IconButton onClick={goBack} data-cy="add_borrower_page__back__icon">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h1">Order service</Typography>
        </Stack>
        <ProjectNameLabel project={project} showStatusChip />
      </Stack>
      <Stack
        sx={{
          backgroundColor: colors.background.gray,

          pt: 3,
          flex: 1,
          alignItems: 'center',
        }}
        spacing={2}
      >
        <Stack mb={2} sx={{ minWidth: '800px' }}>
          {match?.params?.serviceType ? (
            <Tabs
              value={match?.params.serviceType || activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              sx={{
                borderBottom: 'none',
              }}
            >
              {Object.values(SwitcherTabs).map((tab, index) => {
                const tabProps = getTabProps({ tab, index, activeTab, SwitcherTabs, pathBase });
                if (!tab.disabled) return <Tab {...tabProps} />;
                return (
                  <Tooltip key={tab.value} title={tab.disabledTooltipText} placement="top">
                    <span>
                      <Tab {...tabProps} />
                    </span>
                  </Tooltip>
                );
              })}
            </Tabs>
          ) : (
            <Navigate to={`${pathBase}/${TABS.INSPECTION.value}`} replace />
          )}
        </Stack>

        <Routes>
          <Route
            path={`${TABS.INSPECTION.value}`}
            element={
              <InspectionServiceOrder onClose={goBack} drawRequestId={drawRequestInProgress?.id} />
            }
          />
          <Route path={`${TABS.TITLE.value}`} element={<ServiceComponent />} />
          <Route path={`${TABS.APPRAISAL.value}`} element={<ServiceComponent />} />
          <Route path={`${TABS.FEASIBILITY.value}`} element={<ServiceComponent />} />
        </Routes>
      </Stack>
    </Stack>
  );
};

export default OrderService;

const getTabProps = ({ tab, index, activeTab, SwitcherTabs, pathBase }) => {
  return {
    key: tab.value,
    value: tab.value,
    label: tab.label,
    icon: <img src={tab.icon} alt={tab.label} style={{ width: 96, height: 96 }} />,
    sx: {
      width: '188px',
      height: '160px',
      backgroundColor: colors.background.white,
      borderRadius: '4px',
      border: activeTab === tab.value ? `1px solid ${colors.main.primary.light}` : 'none',
      boxShadow: activeTab === tab.value ? '0px 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
      '&:hover': {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
      '&.Mui-selected': {
        backgroundColor: colors.background.white,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        border: `1px solid ${colors.main.primary.light}`,
      },
      marginRight: Object.values(SwitcherTabs)?.length - 1 === index ? 0 : 2,
      textTransform: 'capitalize',
      fontWeight: 600,
    },
    to: `${pathBase}/${tab.value}`,
    component: Link,
    disabled: tab.disabled,
  };
};
