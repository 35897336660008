import React, { ChangeEvent, useContext } from 'react';
import {
  TextField,
  IconButton,
  Grid,
  Stack,
  Autocomplete,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import snakeCase from 'lodash/snakeCase';

import {
  ProjectImage,
  StyledBox,
  TabWrapperWithLeaveWarning,
  CustomTextField,
  CustomAutocomplete,
} from '@components';
import { IPropertyDetailName } from '@interfaces';
import { ArrowRightIcon, DeleteIcon } from '@svgAsComponents';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { states } from '@constants';
import * as Controller from './controller';
import { colors } from '@theme';
import { isSQFTDetail } from '@utils';

const GeneralSettings = ({
  project,
  isAllProjectDetailsDisabled,
  isLimitedProjectDetailsDisabled,
}) => {
  const controller = Controller.useGeneralSettings({ project });
  const {
    projectDetailsFields,
    handleSubmitClick,
    isSubmitting,
    isUpdated,
    exitPath,
    exitStrategiesList,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
  } = controller;
  const {
    projectName,
    scopeOfWork,
    exitStrategy,
    projectType,
    existingPropertyType,
    proposedPropertyType,
    address_1,
    city,
    state,
    zipCode,
  } = projectDetailsFields;
  const { isPHBProject } = useContext(SettingsContext);
  const flags = useLaunchDarklyFlags();

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={!isAllProjectDataValid}
      tabValue="general"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/general'}
    >
      <StyledBox>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <ProjectImage
              project={project}
              disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
              source="project__settings__general__project_image"
            />
            <Stack sx={{ flex: 1 }} spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8} sx={{ ml: -2 }}>
                  <CustomTextField
                    field={projectName}
                    label="Project name"
                    required
                    inputProps={{ 'data-cy': 'project__overview__name__input' }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <CustomAutocomplete
                    field={exitStrategy}
                    label="Exit strategy"
                    options={exitStrategiesList}
                    inputProps={{
                      'data-cy': 'project__settings__general__exit_strategy__input',
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} sx={{ ml: -2 }}>
                  <CustomAutocomplete
                    field={projectType}
                    label="Project type"
                    options={projectTypesList}
                    required
                    inputProps={{
                      'data-cy': 'project__settings__general__project_type__input',
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <CustomAutocomplete
                      field={existingPropertyType}
                      label="Property type (Existing)"
                      options={propertyTypesList}
                      required
                      inputProps={{
                        'data-cy': 'project__settings__general__property_type_exisiting__input',
                      }}
                      disabled={isAllProjectDetailsDisabled}
                    />
                    <ArrowRightIcon size={20} />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <CustomAutocomplete
                    field={proposedPropertyType}
                    label="Property type (Proposed)"
                    options={propertyTypesList}
                    inputProps={{
                      'data-cy': 'project__settings__general__property_type_proposed__input',
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={4} sx={{ ml: -2 }}>
                  <CustomTextField
                    field={address_1}
                    label="Street address"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__address_1_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <CustomTextField
                    field={city}
                    label="City"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__city_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <CustomAutocomplete
                    field={state}
                    label="State"
                    options={states}
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__state_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <CustomTextField
                    field={zipCode}
                    label="ZIP code"
                    validationText="Please enter a valid 5-digit zip code"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__zip_code_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <TextField
            data-cy="project__settings__general__scope_of_work__input"
            fullWidth
            variant="outlined"
            label="Scope of work"
            value={scopeOfWork.value || ''}
            sx={{ mb: '24px' }}
            multiline
            rows={5}
            onChange={(event: ChangeEvent<HTMLInputElement>) => scopeOfWork.handleChange(event)}
            disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
          />
        </Stack>
      </StyledBox>
      {!isPHBProject && (
        <StyledBox>
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="h3">Property details</Typography>

            <Button
              size="small"
              onClick={propertyDetails.add}
              disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
              data-cy="project__settings__general__add_property_detail__button"
            >
              Add property detail
            </Button>
          </Stack>

          {propertyDetails?.list?.map((detail, index) => (
            <Stack direction="row" spacing={2} sx={{ mb: 2 }} key={index} alignItems="center">
              <Autocomplete
                value={{ name: detail?.name, id: detail?.id } as IPropertyDetailName}
                id={detail?.id?.toString()}
                options={propertyDetails.names as IPropertyDetailName[]}
                onChange={(_: React.SyntheticEvent, newValue: IPropertyDetailName) => {
                  propertyDetails.update(index, {
                    ...detail,
                    name: newValue?.name,
                    id: newValue?.id,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Property detail name"
                    size="small"
                    sx={{ width: 260 }}
                    inputProps={{
                      ...params.inputProps,
                      'data-cy': `project__settings__general__property_detail_name__input__index_${index}`,
                    }}
                  />
                )}
                getOptionLabel={(option: IPropertyDetailName) => option?.name}
                clearIcon={null}
                isOptionEqualToValue={(option: IPropertyDetailName, value: IPropertyDetailName) =>
                  option.name === value.name
                }
                disabled={
                  (isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled) ||
                  (flags?.['ENG_8012_always_show_sqft_on_project_settings'] &&
                    isSQFTDetail(detail.name))
                }
                renderOption={renderOption}
              />

              <TextField
                fullWidth
                variant="outlined"
                label="Existing value"
                value={detail.existing_value || ' '}
                sx={{ width: 260 }}
                size="small"
                onChange={(e) =>
                  propertyDetails.update(index, { ...detail, existing_value: e.target.value })
                }
                data-cy={`project__settings__general__property_detail_existing__input__index_${index}`}
                disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
              />
              <ArrowRightIcon size={20} />
              <TextField
                fullWidth
                variant="outlined"
                label="Proposed value"
                value={detail.proposed_value || ' '}
                sx={{ width: 260 }}
                size="small"
                onChange={(e) =>
                  propertyDetails.update(index, { ...detail, proposed_value: e.target.value })
                }
                data-cy={`project__settings__general__property_detail_proposed__input__index_${index}`}
                disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
              />
              {(!flags?.['ENG_8012_always_show_sqft_on_project_settings'] ||
                !isSQFTDetail(detail.name)) && (
                <IconButton
                  onClick={() => propertyDetails.delete(index)}
                  data-cy={`project__overview__property_detail__delete__button__index_${index}`}
                  disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
                >
                  <DeleteIcon size={20} color={colors.status.error.medium} />
                </IconButton>
              )}
            </Stack>
          ))}
        </StyledBox>
      )}
    </TabWrapperWithLeaveWarning>
  );
};

export default GeneralSettings;

const renderOption = (props, option) => {
  // Check if the option is not a string and has a `disabled` property set to true
  if (option.disabled) {
    // Modify the props to prevent selection
    const newProps = {
      ...props,
      style: {
        ...props.style,
        color: 'grey', // Visual cue for disabled option.
      },
      onClick: (event) => {
        // Prevent the selection
        event.stopPropagation();
      },
    };

    return (
      <Tooltip key={option.id} title="This option is already choosen">
        <li {...newProps} data-cy={`dropdown_option_${snakeCase(option.name)}`}>
          {option.name}
        </li>
      </Tooltip>
    );
  }

  // Render normal options
  return (
    <li {...props} data-cy={`dropdown_option_${snakeCase(option.name)}`} key={option.id}>
      {option.name}
    </li>
  );
};
