import { DocumentTypeEnum, EventType, IUser, IWSMessage, IWSMessageData } from '@interfaces';

export const handleWebSocketMessage = (
  message: MessageEvent,
  user: IUser,
  dispatch: (params) => void,
  invalidateData: (params: IWSMessageData) => void,
) => {
  const data = parseWebSocketMessage(message);

  if (data && shouldInvalidateData(data, user)) {
    dispatch({ type: 'ADD_MESSAGE', payload: { text: data.data.html } });
    invalidateData(data.data);
  }

  if (shouldInvalidateDocumentAddedEvent(data)) {
    invalidateData(data.data);
  }
};

const parseWebSocketMessage = (message) => {
  try {
    return JSON.parse(message.data) as IWSMessage;
  } catch (error) {
    console.error('Failed to parse WebSocket message:', error);
    return null;
  }
};

const shouldInvalidateData = (data: IWSMessage, user: IUser) => {
  return data?.data?.user?.id !== user.id;
};

const shouldInvalidateDocumentAddedEvent = (data: IWSMessage) => {
  return (
    data?.data?.event_type === EventType.DOCUMENT_ADDED &&
    (data?.data?.document_type === DocumentTypeEnum.PROJECT_IMPORT_TEMPLATE ||
      data?.data?.document_type === DocumentTypeEnum.THUMB ||
      data?.data?.document_type === DocumentTypeEnum.PROGRESS_PHOTO)
  );
};
