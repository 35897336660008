import { IPermission, IUser, TableKeyEnum } from '@interfaces';
import * as FiltersItems from './filters';

export type FiltersTypes = keyof typeof FiltersItems;

export interface ComponentProps {
  source: string;
  padding?: number;
  filters: FiltersTypes[];
  LeftComponent?: React.FunctionComponent<any>;
  setFilterStringQuery: React.Dispatch<React.SetStateAction<string>>;
  width?: string;
  tableKey: TableKeyEnum;
  onPageChange?: (event, newPage) => void;
}

export type FilterObject = {
  noNeedToRender?: boolean;
  title: string;
  filterKey: string;
  userPermission?: (user: IUser) => boolean; // TODO: remove that when migrate to permissions
  permissionKey?: string;
  defaultFilters?: Array<string>;
  needToUpdateUrl?: boolean;
  needToUpdateSetting?: boolean;

  getDataParams?: {
    type: string;
    keys: string[];
    args: Record<string, string>;
    options: {
      strictSerialize?: (data: Array<any>) => unknown;
    };
  };
  willChangeUrl?: boolean;
  getStaticValues?: ({
    permissions,
  }: {
    permissions: IPermission[];
  }) => Array<{ label: string; value: string }>;
  cypressSelector?: string;
};

export const DEFAULT_SET_TO_URL = false;
export const DEFAULT_SET_TO_SETTINGS = false;
