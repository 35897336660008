import React, { FC } from 'react';
import { Typography, Skeleton } from '@mui/material';
import { percentFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { MilestoneListColumnType, CommonRowType, ColumnLabel, HeaderText } from './common';
import { ColumnV2Width, IMilestoneTotal } from '@interfaces';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton />;

  return (
    <Typography variant="body3">
      {percentFormatter({ value: row.inspector_allowance_rate_incremental })}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold">
      {percentFormatter({ value: row.inspector_allowance_rate_incremental })}
    </Typography>
  );
};

const inspectorAllowanceRateIncremental: MilestoneListColumnType = {
  name: 'inspector_allowance_rate_incremental',
  columnText: 'Inspection recommendation (%)',
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText>
      <ColumnLabel>Inspection</ColumnLabel>
      <ColumnLabel> recommendation (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default inspectorAllowanceRateIncremental;
