import { useQuery } from 'react-query';
import {
  HookState,
  IPieChartData,
  IProjectProgress,
  IProjectProgressItem,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectProgress } from '@globalService';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { calculateFraction, getHookState, percentFormatter } from '@utils';
import { colors } from '@theme';

export interface ControllerInterface {
  state: HookState;
  getProjectProgressItems: () => ProgressParams;
  getRevisedValuePieChartData: () => IPieChartData[];
  getOriginalValuePieChartData: () => IPieChartData[];
}

export interface ProgressParams {
  originalBorrowerEquity: Partial<IProjectProgressItem>;
  additionalBorrowerEquity: Partial<IProjectProgressItem>;
  constructionHoldback: Partial<IProjectProgressItem>;
  currentScheduledValue: Partial<IProjectProgressItem>;
  originalScheduledValue: Partial<IProjectProgressItem>;
}

export const useProjectPaymentGraph = (): ControllerInterface => {
  const { projectId } = useParams();

  const progressQuery = useQuery<IProjectProgress, Error>(
    [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
    getProjectProgress.bind(this, projectId),
  );

  const projectProgress = useMemo(() => progressQuery.data, [progressQuery.data]);

  const {
    constructionHoldback,
    originalBorrowerEquity,
    additionalBorrowerEquity,
    originalScheduledValue,
    currentScheduledValue,
  } = useMemo(
    () => ({
      constructionHoldback: projectProgress?.construction_holdback,
      originalBorrowerEquity: projectProgress?.original_borrower_equity,
      additionalBorrowerEquity: projectProgress?.additional_borrower_equity_used,
      originalScheduledValue: projectProgress?.total_original_construction_budget,
      currentScheduledValue: projectProgress?.total_construction_budget,
    }),
    [projectProgress],
  );

  const { revisedValueTotal, scheduledValueTotal } = useMemo(
    () => ({
      revisedValueTotal:
        constructionHoldback?.total +
        originalBorrowerEquity?.total +
        additionalBorrowerEquity?.total,
      scheduledValueTotal: originalBorrowerEquity?.total + constructionHoldback?.total,
    }),
    [constructionHoldback?.total, originalBorrowerEquity?.total, additionalBorrowerEquity?.total],
  );

  const getProjectProgressItems = useCallback(
    () => ({
      constructionHoldback,
      originalBorrowerEquity,
      additionalBorrowerEquity,
      originalScheduledValue,
      currentScheduledValue,
    }),
    [
      constructionHoldback,
      originalBorrowerEquity,
      additionalBorrowerEquity,
      originalScheduledValue,
      currentScheduledValue,
    ],
  );

  const getRevisedValuePieChartData = useCallback(
    () => [
      {
        label: `Construction holdback (${percentFormatter({
          value: calculateFraction(constructionHoldback?.total, revisedValueTotal),
        })})`,
        color: colors.status.information.medium,
        value: constructionHoldback?.total,
      },
      {
        label: `Original borrower equity (${percentFormatter({
          value: calculateFraction(originalBorrowerEquity?.total, revisedValueTotal),
        })})`,
        color: colors.status.orange.medium,
        value: originalBorrowerEquity?.total,
      },
      {
        label: `Additional borrower equity (${percentFormatter({
          value: calculateFraction(additionalBorrowerEquity?.total, revisedValueTotal),
        })})`,
        color: colors.status.violet.medium,
        value: additionalBorrowerEquity?.total,
      },
    ],
    [
      constructionHoldback?.total,
      revisedValueTotal,
      originalBorrowerEquity?.total,
      additionalBorrowerEquity?.total,
    ],
  );

  const getOriginalValuePieChartData = useCallback(
    () => [
      {
        label: `Construction holdback (${percentFormatter({
          value: calculateFraction(constructionHoldback?.total, scheduledValueTotal),
        })})`,
        color: colors.status.information.medium,
        value: constructionHoldback?.total,
      },
      {
        label: `Original borrower equity (${percentFormatter({
          value: calculateFraction(originalBorrowerEquity?.total, scheduledValueTotal),
        })})`,
        color: colors.status.orange.medium,
        value: originalBorrowerEquity?.total,
      },
    ],
    [constructionHoldback?.total, originalBorrowerEquity?.total, scheduledValueTotal],
  );

  return {
    state: getHookState(progressQuery),
    getProjectProgressItems,
    getRevisedValuePieChartData,
    getOriginalValuePieChartData,
  };
};
