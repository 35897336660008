enum ProjectOverviewEnum {
  PROJECT__OVERVIEW__INSPECTOR_ALLOWANCE = 'project__overview__inspector_allowance',
  PROJECT__OVERVIEW__GAP = 'project__overview__gap',
  PROJECT__OVERVIEW__PROJECT_STATUS_EDIT = 'project__overview__project_status_edit',
  PROJECT__OVERVIEW__PROJECT_COMPLETION__EDIT = 'project__overview__project_completion__edit',
  PROJECT__OVERVIEW__PROPERTY_DETAILS__EDIT = 'project__overview__property_details__edit',
  PROJECT__OVERVIEW__PROJECT_SCOPE__EDIT = 'project__overview__project_scope__edit',
  PROJECT__OVERVIEW__EXIT_STRATEGY__EDIT = 'project__overview__exit_strategy__edit',
  PROJECT__OVERVIEW__PROJECT_TYPE__EDIT = 'project__overview__project_type__edit',
  PROJECT__OVERVIEW__PROPERTY_TYPE__EDIT = 'project__overview__property_type__edit',
  PROJECT__OVERVIEW__SCORES__VIEW = 'project__overview__scores__view',
  PROJECT__OVERVIEW__NAME__EDIT = 'project__overview__name__edit',
  PROJECT__SETTINGS__LOAN_DETAILS__EDIT = 'project__settings__loan_details__edit',
}

enum ProjectDREnum {
  PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE = 'project__draw_request__inspector_allowance',
  PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_RATE_INCREMENTAL = 'project__draw_request__inspector_allowance_rate_incremental',
  PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_INCREMENTAL = 'project__draw_request__inspector_allowance_incremental',
  PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_RATE = 'project__draw_request__inspector_allowance_rate',
  PROJECT__DRAW_REQUEST__SUBMIT = 'project__draw_request__submit',
  PROJECT__DRAW_REQUEST__CREATE = 'project__draw_request__create',
  PROJECT__DRAW_REQUEST__APPROVE = 'project__draw_request__approve',
  PROJECT__DRAW_REQUEST__FUND = 'project__draw_request__fund',
  PROJECT__DRAW_REQUEST__CREDIT_REQUEST = 'project__draw_request__credit_request',
  PROJECT__DRAW_REQUEST__CREDIT_APPROVE = 'project__draw_request__credit_approve',
  PROJECT__DRAW_REQUEST__REPORT = 'project__draw_request__report',
  PROJECT__DRAW_REQUEST__COORDINATOR = 'project__draw_request__coordinator',
  PROJECT__DRAW_REQUEST__COORDINATOR__EDIT = 'project__draw_request__coordinator__edit',
  PROJECT__DRAW_REQUEST__REVIEWER_TEAM = 'project__draw_request__reviewer_team',
  PROJECT__DRAW_REQUEST__REVIEWER_TEAM__EDIT = 'project__draw_request__reviewer_team__edit',
  PROJECT__DRAW_REQUEST__POLICIES__VIEW = 'project__draw_request__policies__view',
  PROJECT__DRAW_REQUEST__POLICIES__EDIT = 'project__draw_request__policies__edit',
  PROJECT__DRAW_REQUEST__NEWLINE_ADD = 'project__draw_request__newLine__add',
  PROJECT__HISTORICAL_DRAW_REQUEST__CREATE = 'project__historical_draw_request__create',
  PROJECT__CREATE__BUTTON = 'project__create__button',
  PROJECT__DRAW_REQUEST__POLICIES__MY_TEAM_ROLE__VIEW = 'project__draw_request__policies__my_team_role__view',
  PROJECT__POLICIES__ADDITIONAL_ITEMS__EDIT = 'project__policies__additional_items__edit',
  PROJECT__DRAW_REQUEST__AUTOFILL_REQUESTED_DRAW = 'project__draw_request__autofill_inspector_allowance_requested',
  PROJECT__DRAW_REQUEST__ESTIMATED_DISBURSEMENT_DATE__EDIT = 'project__draw_request__estimated_disbursement_date__edit',
}

enum ProjectBudgetEnum {
  PROJECT__SOV__INSPECTOR_ALLOWANCE = 'project__sov__inspector_allowance',
  PROJECT__SOV__INSPECTOR_ALLOWANCE_RATE = 'project__sov__inspector_allowance_rate',
  PROJECT__SOV__GAP = 'project__sov__gap',
  PROJECT__SOV__GAP_RATE = 'project__sov__gap_rate',
  PROJECT__BUDGET__EDIT = 'project__budget__edit',
}

enum ProjectPaymentEnum {
  PROJECT__PAYMENT__MARK_AS_PAID = 'project__payment__mark_as_paid',
  PROJECT__PAYMENT__BANK__EDIT = 'project__payment__bank__edit',
  PROJECT__PAYMENT__PARTIES__EDIT = 'project__payment__parties__edit',
  PROJECT__PAYMENT__FEES_EDIT = 'project__payment__fees__edit',
  PROJECT__PAYMENT_MODEL__EDIT = 'project__payment_model__edit',
}

enum ProjectInspectionEnum {
  PROJECT__INSPECTION = 'project__inspection',
  PROJECT__INSPECTION__CREATE = 'project__inspection__order',
  PROJECT__INSPECTION__ENTER_RESULTS = 'project__inspection__enter_results',
  PROJECT__INSPECTION__DELETE = 'project__inspection__delete',
  PROJECT__INSPECTION__CARD = 'project__inspection__card',
}

enum DRInspectionEnum {
  DRAW_REQUEST_INSPECTION_INFO = 'draw_request__inspection__info',
}

enum ProjectPeopleEnum {
  PROJECT__PEOPLE = 'project__people',
  PROJECT__PEOPLE__EDIT = 'project__people__edit',
  PROJECT__PEOPLE__COORDINATOR__EDIT = 'project__people__coordinator__edit',
}

enum ProjectDocumentsEnum {
  PROJECT__DOCUMENTS__REMOVE = 'project__documents__remove',
}

enum ProjectActivityEnum {
  PROJECT__COMMENTS_ALL__VIEW = 'project__comments_all__view',
  PROJECT__AUDITLOG__VIEW = 'auditlog__view',
}

enum CompanyProfileEnum {
  COMPANY__EDIT = 'company__edit',
  COMPANY_USERS__EDIT = 'company_users__edit',
  COMPANY__POLICIES__VIEW = 'company__policies__view',
  COMPANY__POLICIES__EDIT = 'company__policies__edit',
  COMPANY__TEAMS__VIEW = 'company_teams__view',
  COMPANY__TEAMS__EDIT = 'company_teams__edit',
}

enum RequestsEnum {
  REQUESTS__COORDINATOR = 'drawrequests__coordinator',
  REQUEST_QUEUE__SCORES__VIEW = 'request_queue__scores__view',
  REQUEST_QUEUE__CUSTOMER__VIEW = 'request_queue__customer__view',
  REQUEST_QUEUE__INVESTOR__VIEW = 'request_queue__investor__view',
}

enum CompamiesEnum {
  COMPANIES__LIST = 'company__list',
}

enum RiskRadarEnum {
  RISK_RADAR__VIEW = 'risk_radar__view',
  RISK_RADAR__CUSTOMER__VIEW = 'risk_radar__customer__view',
  RISK_RADAR__INVESTOR__VIEW = 'risk_radar__investor__view',
  RISK_RADAR__SCORES__VIEW = 'risk_radar__scores__view',
}

enum AnalyticsEnum {
  ANALYTICS_DASHBOARD__VIEW = 'analytics__dashboard__view',
  ANALYTICS__EXTERNAL_LOGIN = 'analytics__external__login',
}

enum PlatformSettingsEnum {
  PLATFORM_SETTINGS__POLICY__TEMPLATE = 'platform_settings__policy__template',
}

export const PermissionNamesEnums = {
  ...CompamiesEnum,
  ...CompanyProfileEnum,
  ...DRInspectionEnum,
  ...ProjectBudgetEnum,
  ...ProjectActivityEnum,
  ...ProjectDREnum,
  ...ProjectInspectionEnum,
  ...ProjectOverviewEnum,
  ...ProjectPaymentEnum,
  ...ProjectPeopleEnum,
  ...ProjectDocumentsEnum,
  ...RequestsEnum,
  ...RiskRadarEnum,
  ...AnalyticsEnum,
  ...PlatformSettingsEnum,
};
