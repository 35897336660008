import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';
import { PermissionsContext, useGetData } from '@context';
import {
  DeleteDrawRequestListItem,
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestTableTabTypesEnum,
} from '@interfaces';
import { deleteRequestMilestoneItem, patchDrawRequestListItem } from '@globalService';
import { StringFieldModel, useStringFieldModel } from '@models';
import { costTypeMap } from '@constants';
import { isRestricted, parsePathErrorDual } from '@utils';
import { useSafeSnackbar } from '@hooks';

export interface ControllerInterface {
  revisedScheduledValue: StringFieldModel;
  additionalBorrowerEquity: StringFieldModel;
  requestedAdditionalBorrowerEquity: StringFieldModel;
  costType: string;
  costTypeOptions: { value: string; label: string }[];
  handleCostTypeChange: (event: ChangeEvent<{ value: string }>) => void;
  itemName: StringFieldModel;
  submit: () => void;
  isSubmiting: boolean;
  deleteLineItem: () => void;
  isEditable: boolean;
  isApproveBreakdown: boolean;
}

export interface BreakdownControllerProps {
  requestId: string;
  milestoneId: string;
  setOpen: CallableFunction;
  type: RequestTableTabTypesEnum;
}

export const useLineItemBreakdownPanel = ({
  requestId,
  milestoneId,
  setOpen,
  type,
}: BreakdownControllerProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);

  const costTypeOptions = [
    { value: 'HARD', label: costTypeMap.HARD },
    { value: 'SOFT', label: costTypeMap.SOFT },
  ];

  const isApproveBreakdown = type === RequestTableTabTypesEnum.APPROVE;

  const lineItem = useGetData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: [
      'id',
      'name',
      'cost_type',
      'revised_estimate',
      'requested_revised_estimate',
      'requested_additional_borrower_equity',
      'additional_borrower_equity',
      'milestone_is_new_for_current_draw',
    ],
    args: { projectId, drawRequestId: requestId, milestoneId },
  });

  const [costType, setCostType] = useState(lineItem?.data?.cost_type?.['key'] || 'HARD');

  const itemName = useStringFieldModel({
    initValue: lineItem?.data?.name || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const revisedScheduledValue = useStringFieldModel({
    initValue:
      (isApproveBreakdown
        ? lineItem?.data?.revised_estimate?.toString()
        : lineItem?.data?.requested_revised_estimate?.toString()) || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const additionalBorrowerEquity = useStringFieldModel({
    initValue: lineItem?.data?.additional_borrower_equity?.toString() || '',
  });

  const requestedAdditionalBorrowerEquity = useStringFieldModel({
    initValue: lineItem?.data?.requested_additional_borrower_equity?.toString() || '',
  });

  useEffect(() => {
    if (lineItem.data?.name) {
      setCostType(lineItem.data.cost_type?.['key'] || 'HARD');
      itemName.setValue(lineItem.data.name);
    }
    if (isApproveBreakdown) {
      if (lineItem.data?.revised_estimate) {
        revisedScheduledValue.setValue(lineItem.data.revised_estimate.toString());
        revisedScheduledValue.setValid(true);
      }
      if (lineItem.data?.requested_additional_borrower_equity) {
        requestedAdditionalBorrowerEquity.setAsFloat(
          +lineItem.data.requested_additional_borrower_equity,
        );
      }
      if (lineItem.data?.additional_borrower_equity) {
        additionalBorrowerEquity.setAsFloat(+lineItem.data.additional_borrower_equity);
      }
    } else {
      if (lineItem.data?.requested_revised_estimate) {
        revisedScheduledValue.setValue(lineItem.data.requested_revised_estimate.toString());
        revisedScheduledValue.setValid(true);
      }
      if (lineItem.data?.requested_additional_borrower_equity) {
        requestedAdditionalBorrowerEquity.setAsFloat(
          +lineItem.data.requested_additional_borrower_equity,
        );
      }
    }
  }, [lineItem.data]);

  const isEditable = useMemo(
    () =>
      lineItem.data?.milestone_is_new_for_current_draw &&
      !isRestricted(PermissionNamesEnums.PROJECT__DRAW_REQUEST__NEWLINE_ADD, permissions),
    [permissions, lineItem.data],
  );

  const handleCostTypeChange = (event) => {
    setCostType(event.target.value);
  };

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      lineItem.manualUpdate({ ...data });
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId: requestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId: requestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId },
      ]);
      setOpen(false);
    },
  });

  const submit = useCallback(() => {
    const jsonData = isApproveBreakdown
      ? {
          revised_estimate: +revisedScheduledValue.value,
          ...(additionalBorrowerEquity.value && {
            additional_borrower_equity: +additionalBorrowerEquity.value,
          }),
          name: itemName.value,
          cost_type: costType,
        }
      : {
          requested_revised_estimate: +revisedScheduledValue.value,
          ...((requestedAdditionalBorrowerEquity.value ||
            lineItem.data?.requested_additional_borrower_equity) && {
            requested_additional_borrower_equity: +requestedAdditionalBorrowerEquity.value,
          }),
          name: itemName.value,
          cost_type: costType,
        };

    patchMilestoneRequestMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      milestone: milestoneId,
      json: jsonData,
    });
  }, [
    requestId,
    projectId,
    milestoneId,
    itemName.value,
    costType,
    revisedScheduledValue.value,
    additionalBorrowerEquity.value,
    requestedAdditionalBorrowerEquity.value,
  ]);

  const deleteMilestoneMutation = useMutation<Response, ErrorDual, DeleteDrawRequestListItem>(
    deleteRequestMilestoneItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_MILESTONES);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSettled: () => {
        setOpen(false);
      },
    },
  );

  const deleteLineItem = useCallback(() => {
    deleteMilestoneMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      milestone: lineItem?.data?.id,
    });
  }, [lineItem?.data?.id, projectId, requestId]);

  const isMutating = useIsMutating();
  return {
    revisedScheduledValue,
    additionalBorrowerEquity,
    requestedAdditionalBorrowerEquity,
    costType,
    costTypeOptions,
    handleCostTypeChange,
    itemName,
    submit,
    isSubmiting: isMutating > 0,
    deleteLineItem,
    isEditable,
    isApproveBreakdown,
  };
};
