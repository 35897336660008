import { useCallback, useEffect, useState } from 'react';
import { useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';
import { ControllerInterface, PoliciesBoxProps } from './interface';
import {
  CompanyPoliciesParam,
  CompanyPoliciesTemplateParam,
  ICompany,
  MutationKeyEnum,
  PoliciesItemListLocal,
  QueryNamesEnums,
  TPolicies,
} from '@interfaces';
import {
  createCompanyPoliciesTemplate,
  deleteCompanyPoliciesTemplate,
  getMyCompany,
  updateCompanyPoliciesTemplate,
} from '@globalService';
import { useSafeSnackbar } from '@hooks';

export const usePoliciesBox = ({
  policyLists,
  templatesList,
  assigneeRole,
  type,
  source,
  setEditMode,
}: PoliciesBoxProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [openProjectsListModal, setOpenProjectsListModal] = useState<boolean>(false);
  const [activeTemplate, setActiveTemplate] = useState<TPolicies>();

  useEffect(() => {
    setEditMode(null);
    setActiveTemplate(undefined);
  }, [type]);

  useEffect(() => {
    setActiveTemplate(policyLists.find((templ) => templ.is_default) || policyLists[0]);
  }, [policyLists]);

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const handleSelectChange = (value: string) =>
    setActiveTemplate(policyLists.find((x) => x.id === value));

  const updatePoliciesItems = useMutation<Response, Error, CompanyPoliciesTemplateParam>(
    updateCompanyPoliciesTemplate,
    {
      mutationKey: MutationKeyEnum.COMPANY_POLICIES_TEMPLATE_PATCH + assigneeRole,
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_COMPANY_POLICIES_TEMPLATES,
          { companyId: companyQuery.data?.id },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_CHECKLIST_TEMPLATES]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_CHECKLIST]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const isMutating = useIsMutating({
    predicate: ({ options }) =>
      options.mutationKey === MutationKeyEnum.COMPANY_POLICIES_TEMPLATE_PATCH + assigneeRole,
  });

  const createPoliciesItems = useMutation<TPolicies, Error, CompanyPoliciesParam>(
    createCompanyPoliciesTemplate,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_COMPANY_POLICIES_TEMPLATES,
          { companyId: companyQuery.data?.id },
        ]);
        setActiveTemplate(data);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSave = useCallback(
    (templateId: string) =>
      ({ items, name, is_default }: PoliciesItemListLocal) => {
        if (templateId) {
          const itemsList = items.map((item, index) => ({
            name: { name: item.label },
            id: item.id,
            index,
            description: item.description,
            ...(item.type && { type: item.type }),
            is_custom: item.is_custom,
          }));
          updatePoliciesItems.mutateAsync({
            companyId: companyQuery.data?.id,
            templateId,
            templateData: { name, is_default, items: itemsList },
          });
          return;
        }

        const itemsList = items
          .filter((item) => item.is_custom)
          .map((item) => ({
            name: { name: item.label },
            description: item.description,
            ...(item.type && { type: item.type }),
            is_custom: item.is_custom,
          }));
        createPoliciesItems.mutateAsync({
          companyId: companyQuery.data?.id,
          templateData: { name, is_default, items: itemsList, assignee_role: assigneeRole, type },
        });
      },
    [companyQuery.data?.id, assigneeRole, type],
  );

  const deletePolicies = useMutation<Response, Error, CompanyPoliciesTemplateParam>(
    deleteCompanyPoliciesTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_COMPANY_POLICIES_TEMPLATES,
          { companyId: companyQuery.data?.id },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleDelete = useCallback(
    (templateId: string) => () =>
      deletePolicies.mutateAsync({ companyId: companyQuery.data?.id, templateId }),
    [companyQuery.data?.id],
  );

  const handleClose = useCallback(() => {
    if (!activeTemplate?.id)
      setActiveTemplate(policyLists.find((templ) => templ.is_default) || policyLists[0]);
    setEditMode(null);
  }, [activeTemplate]);

  const onCreateBtnClicked = () => {
    setActiveTemplate({ id: '', name: '', items: [] });
    setEditMode(source);
  };

  const handleTemplateSelected = (value: string) => {
    const template = templatesList.find((x) => x.id === value);
    setActiveTemplate((old) => ({
      ...old,
      items: template.items.map((item) => ({
        id: item.id,
        label: item.name,
        ...(item.type && { type: item.type }),
        checked: false,
        description: item.description,
        is_custom: item.is_custom,
      })),
    }));
  };

  return {
    activeTemplate,
    handleSelectChange,
    handleSave,
    handleDelete,
    handleClose,
    onCreateBtnClicked,
    handleTemplateSelected,
    openProjectsListModal,
    setOpenProjectsListModal,
    isMutating: Boolean(isMutating),
  };
};
