// TODO should be deleted after releasing ENG_7877_order_service

import { useQuery } from 'react-query';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IDrawRequest, IInspectionClosePopupEnum, QueryNamesEnums } from '@interfaces';
import { getDrawRequest } from '@globalService';
import { hasApprovedReallocation, hasRequestedReallocation } from '@utils';

interface ControllerInterface {
  confirmationModal: { text: string; title: string };
  handleOrderInspection: () => void;
  showInspectionDialog: boolean;
  showOrderInspectionModal: boolean;
  popup: IInspectionClosePopupEnum;
  setPopup: Dispatch<SetStateAction<IInspectionClosePopupEnum>>;
  closeModal: () => void;
  closeOrderInspectionModal: () => void;
}

interface Props {
  drawRequestId: string;
  onClose: () => void;
}

export const useOrderInspection = ({ drawRequestId, onClose }: Props): ControllerInterface => {
  const { projectId } = useParams();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(true);
  const [openInspectionDialog, setOpenInspectionDialog] = useState(false);
  const [popup, setPopup] = useState<IInspectionClosePopupEnum>(null);

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const confirmationModal = useMemo(
    () =>
      hasApprovedReallocation(drawRequestQuery.data)
        ? {
            text: 'The inspection will be ordered with the updated schedule of values based on the approved reallocation.',
            title: 'Order with approved reallocations',
          }
        : {
            text: 'The inspection will be ordered without updated budgets as no reallocation requests were approved.\n\nYou can return to the Request tab to approve the request or proceed with the inspection order.',
            title: 'Order without reallocations',
          },
    [drawRequestQuery.data],
  );

  const handleOrderInspection = () => {
    setOpenConfirmationModal(false);
    setOpenInspectionDialog(true);
  };

  const showOrderInspectionModal = useMemo(
    () => hasRequestedReallocation(drawRequestQuery.data) && openConfirmationModal,
    [openConfirmationModal, drawRequestQuery.data],
  );

  const showInspectionDialog = useMemo(
    () =>
      !hasRequestedReallocation(drawRequestQuery.data) ||
      (!openConfirmationModal && openInspectionDialog),
    [showOrderInspectionModal, openConfirmationModal, drawRequestQuery.data],
  );

  const closeModal = () => {
    setPopup(null);
    onClose();
  };

  const closeOrderInspectionModal = () => {
    setOpenConfirmationModal(false);
    onClose();
  };

  return {
    confirmationModal,
    handleOrderInspection,
    showInspectionDialog,
    showOrderInspectionModal,
    popup,
    setPopup,
    closeModal,
    closeOrderInspectionModal,
  };
};
