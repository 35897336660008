import React from 'react';
import { ComponentProps } from './interface';
import { colors } from '@theme';

const Icon = ({ size = 20, color = colors.icons.green }: ComponentProps) => (
  <svg
    width={size}
    height={(size / 21) * 18}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M2.11639 0C1.01182 0 0.116394 0.89543 0.116394 2V13C0.116394 14.1046 1.01182 15 2.11639 15V16C2.11639 17.1046 3.01182 18 4.11639 18H16.1164C17.221 18 18.1164 17.1046 18.1164 16V15C19.221 15 20.1164 14.1046 20.1164 13V10.5042V10.4951V2C20.1164 0.89543 19.221 0 18.1164 0H2.11639ZM16.1164 15V16H4.11639V15H16.1164ZM17.1164 13H18.1164V11V10.8802L15.0125 7.3883L9.75658 11.7682C9.45137 12.0226 9.02453 12.0721 8.66918 11.8944L5.2077 10.1637L2.19763 12.3137C2.17113 12.3327 2.14402 12.3501 2.11639 12.3661V13H3.11639H4.11639H16.1164H17.1164ZM18.1164 4V7.8698L15.8638 5.33564C15.5051 4.93214 14.8909 4.88616 14.4762 5.23178L8.97864 9.81309L5.56361 8.10557C5.2323 7.93992 4.83657 7.97097 4.53516 8.18627L2.11639 9.91395V4V2H4.11639H16.1164H18.1164V4ZM8.61639 4C7.78797 4 7.11639 4.67157 7.11639 5.5C7.11639 6.32843 7.78797 7 8.61639 7C9.44482 7 10.1164 6.32843 10.1164 5.5C10.1164 4.67157 9.44482 4 8.61639 4Z"
    />
  </svg>
);

export default Icon;
