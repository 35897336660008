import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueries, useQueryClient, UseQueryResult } from 'react-query';
import * as Sentry from '@sentry/react';

import { getMyCompany, getProject, getProjectSettings, getProjectUserData } from '@globalService';
import {
  ErrorDual,
  ICompanyFull,
  IProject,
  IProjectSettings,
  PermissionNamesEnums,
  QueryNamesEnums,
  ServerErrorCodesEnum,
} from '@interfaces';
import { checkIsOwner, getHookState, getTeamRole, isRestricted } from '@utils';
import { AuthContext, PermissionsContext } from '@context';
import {
  AnalyticsIcon,
  DashboardIcon,
  FileIcon,
  FolderIcon,
  NavLinkIcon1,
  NavLinkIcon2,
  PieChartIcon,
  ServicesIcon,
} from '@svgAsComponents';
import { ControllerInterface, IScreenACL, NavigationLink } from './interface';
import { useProjectActivationWarning } from '@hooks';

const isAvailable = (screensACL: IScreenACL, teamRole: string, pathname: string) =>
  screensACL[teamRole]?.available?.includes(pathname);

export const usePageLayout = (): ControllerInterface => {
  const { user, setUser } = useContext(AuthContext);
  useEffect(() => {
    const checkTokenExpiration = () => {
      const expirationTime = localStorage.getItem('tokenExpirationTime');
      const currentTime = new Date().getTime();
      const isShouldBeLoggedOut = expirationTime && currentTime > parseInt(expirationTime, 10);
      if (isShouldBeLoggedOut) {
        localStorage.clear();
        sessionStorage.clear();
        setUser(null);
        window.location.reload();
      }
    };

    window.addEventListener('focus', checkTokenExpiration);
    return () => {
      window.removeEventListener('focus', checkTokenExpiration);
    };
  }, []);

  const queryClient = useQueryClient();
  const { projectId } = useParams();

  const teamRole = getTeamRole(user);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);
  const { permissions } = useContext(PermissionsContext);

  const checkAvailability = ({ permissionName }: { permissionName: string }) =>
    !isRestricted(permissionName, permissions);

  const initialPages = useMemo(
    () => [
      {
        title: 'Risk radar',
        icon: PieChartIcon,
        path: '/risk-radar',
        value: 'risk-radar',
        dataTestName: 'menu-item-dashboard',
        isAvailable: () =>
          checkAvailability({ permissionName: PermissionNamesEnums.RISK_RADAR__VIEW }),
      },
      {
        title: 'Analytics',
        icon: AnalyticsIcon,
        path: '/analytics',
        value: 'analytics',
        dataTestName: 'menu-item-analytics',
        isAvailable: () =>
          checkAvailability({
            permissionName: PermissionNamesEnums.ANALYTICS_DASHBOARD__VIEW,
          }),
      },
      {
        title: 'Home',
        icon: DashboardIcon,
        path: '/home',
        value: 'home',
        isAvailable: () => isOwner,
      },
      {
        title: 'Requests',
        icon: FileIcon,
        path: '/requests',
        value: 'requests',
        dataTestName: 'menu-item-draw-requests',
        isAvailable,
      },
      {
        title: 'Services',
        icon: ServicesIcon,
        path: '/services',
        value: 'services',
        dataTestName: 'services_tab',
        isAvailable: () =>
          checkAvailability({
            permissionName: PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO,
          }),
      },
      {
        title: 'Projects',
        icon: FolderIcon,
        path: '/projects',
        value: 'projects',
        dataTestName: 'projects_tab',
        isAvailable,
      },
    ],
    [permissions, isOwner],
  );

  const [pages, setPages] = useState<NavigationLink[]>(initialPages);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
      enabled: Boolean(projectId && user?.isAllowedToLogin),
    },
    { queryKey: [QueryNamesEnums.GET_MY_COMPANY], queryFn: getMyCompany.bind(this) },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
      queryFn: getProjectSettings.bind(this, projectId),
      enabled: Boolean(projectId && user?.isAllowedToLogin),
    },

    {
      queryKey: [QueryNamesEnums.GET_PROJECT_USER_DATA, { projectId }],
      queryFn: getProjectUserData.bind(this, projectId),
      enabled: Boolean(projectId && user?.isAllowedToLogin),
    },
  ]);

  const projectQuery = requestedDataQueries[0] as UseQueryResult<IProject, ErrorDual>;
  const companyQuery = requestedDataQueries[1] as UseQueryResult<ICompanyFull, Error>;
  const projectSettingsQuery = requestedDataQueries[2] as UseQueryResult<IProjectSettings, Error>;

  useEffect(() => {
    if (projectQuery.data) {
      Sentry.setTag('project.name', projectQuery.data.name);
      Sentry.setTag('project.customer', projectQuery.data.customer_name);
      if (!user?.project_information_first_accessed_at && isOwner)
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER);
    }
  }, [projectQuery.data, user]);

  useEffect(() => {
    // user who can add custom link will see them on any page from my-company data,
    // other user (who enrolled to the projects, where ^user is Customer) will see these links
    // on project-related pages from project settings
    const customNavigationLinks = projectSettingsQuery.data?.custom_navigation_links?.length
      ? projectSettingsQuery.data.custom_navigation_links
      : companyQuery.data?.custom_navigation_links;
    if (customNavigationLinks) {
      const additionalNavItems = customNavigationLinks.map((item, index) => {
        return {
          title: item.label.charAt(0).toUpperCase() + item.label.slice(1),
          icon: index === 0 ? NavLinkIcon1 : NavLinkIcon2,
          path: item.url,
          isAvailable: () => true,
          isExternal: true,
        };
      });
      setPages([...initialPages, ...additionalNavItems]);
    } else {
      setPages(initialPages);
    }
  }, [projectSettingsQuery.data, companyQuery.data, initialPages]);

  const customerLogo = user?.active_team?.company?.logo || projectQuery.data?.lender_company_logo;

  const projectActivationWarning = useProjectActivationWarning({
    projectId,
  });

  return {
    customerLogo,
    state: getHookState(requestedDataQueries),
    permissionDenied: projectQuery.error?.code === ServerErrorCodesEnum.PERMISSION_DENIED,
    user,
    pages,
    projectActivationWarning,
  };
};
