import React from 'react';
import { ComponentProps, hasUnseenImagesColor } from './interface';
import { colors } from '@theme';

const PhotoGalleryIconWithIndicator = ({
  size = 20,
  color = colors.icons.gray,
  hasUnseenImages,
}: ComponentProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0.5C0.89543 0.5 0 1.39543 0 2.5V13.5C0 14.6046 0.89543 15.5 2 15.5V16.5C2 17.6046 2.89543 18.5 4 18.5H16C17.1046 18.5 18 17.6046 18 16.5V15.5C19.1046 15.5 20 14.6046 20 13.5V11.0042V10.9951V2.5C20 1.39543 19.1046 0.5 18 0.5H2ZM16 15.5V16.5H4V15.5H16ZM17 13.5H18V11.5V11.3802L14.8961 7.8883L9.64018 12.2682C9.33497 12.5226 8.90814 12.5721 8.55279 12.3944L5.0913 10.6637L2.08124 12.8137C2.05473 12.8327 2.02762 12.8501 2 12.8661V13.5H3H4H16H17ZM18 4.5V8.3698L15.7474 5.83564C15.3887 5.43214 14.7746 5.38616 14.3598 5.73178L8.86224 10.3131L5.44721 8.60557C5.11591 8.43992 4.72018 8.47097 4.41876 8.68627L2 10.414V4.5V2.5H4H16H18V4.5ZM8.5 4.5C7.67157 4.5 7 5.17157 7 6C7 6.82843 7.67157 7.5 8.5 7.5C9.32843 7.5 10 6.82843 10 6C10 5.17157 9.32843 4.5 8.5 4.5Z"
    />
    {hasUnseenImages && <circle cx="15.8835" cy="5" r="4" fill={hasUnseenImagesColor} />}
  </svg>
);

export default PhotoGalleryIconWithIndicator;
