import React, { FC } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { HookState, TableKeyEnum } from '@interfaces';
import { ReactTable, TablePagination, ServiceMessage, StyledBox, FiltersV2 } from '@components';
import { NoDataImage } from '@svgAsComponents';
import * as Controller from './controller';
import { InspectionFilters } from '../index';
import { useLaunchDarklyFlags } from '@context';

const InspectionTab = () => {
  const controller = Controller.useInspectionsList();
  const { state } = controller;

  switch (state) {
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="inspections" />
        </StyledBox>
      );
    }

    case HookState.SUCCESS:
    case HookState.FETCHING: {
      return <InspectionsTable controller={controller} />;
    }

    default:
      return null;
  }
};

export default InspectionTab;

const InspectionsTable: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    handleFiltersChange,
    resetFiltersToDefault,
    filters,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange,
    itemsCount,
    handleSortClick,
    sortValue,
    isFetching,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    handleRowClick,
    setFilterStringQuery,
  } = controller;
  const flags = useLaunchDarklyFlags();

  return (
    <StyledBox sx={{ p: 0, flexGrow: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        {flags?.['ENG_7663_customer_in_inspections_list'] ? (
          <FiltersV2
            tableKey={TableKeyEnum.INSPECTION_LIST}
            source="inspections"
            setFilterStringQuery={setFilterStringQuery}
            filters={[
              'inspectionsListStatusFilter',
              'customerFilter',
              'inspectionsListAgencyFilter',
            ]}
            onPageChange={onPageChange}
          />
        ) : (
          <InspectionFilters
            handleFiltersChange={handleFiltersChange}
            filters={filters}
            resetFiltersToDefault={resetFiltersToDefault}
          />
        )}
        {(controller.state === HookState.FETCHING ||
          (controller.state === HookState.SUCCESS && !!rows?.length)) && (
          <Stack style={{ opacity: isFetching ? 0.3 : 1 }}>
            <ReactTable
              data={rows}
              columns={columns}
              footer={false}
              initialSortBy={sortValue}
              handleSortClick={handleSortClick}
              manualSortBy
              hiddenColumns={hiddenColumns}
              isColumnFilterUpdating={isColumnFilterUpdating}
              changeFieldVisibility={changeFieldVisibility}
              onRowClick={handleRowClick}
              source="inspections__list"
              tableKey={TableKeyEnum.INSPECTION_LIST}
              showContextMenu
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 1 }}>
              <TablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={itemsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Stack>
          </Stack>
        )}
        {controller.state === HookState.SUCCESS && rows?.length === 0 && (
          <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              We know you’re curious to see how the projects are going. <br />
              You’ll see inspection information here after an inspection is ordered.
            </Typography>
          </Stack>
        )}
      </Box>
    </StyledBox>
  );
};
