import React, { FC } from 'react';
import { Stack, Typography, Box, Tab, Tabs } from '@mui/material';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import { DataGrid, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';

import { HookState, PopupTypeEnum } from '@interfaces';
import {
  ConfirmationModal,
  Gallery,
  ServiceMessage,
  StyledBox,
  PDFViewerNew,
  MessagePanel,
  RightDrawer,
  ToolTipLine,
} from '@components';
import { NoDataImage } from '@svgAsComponents';
import * as Controller from './controller';
import { Documents } from '../index';
import { convertReactTableColumnsToMuiDataGridColumns } from '@utils';

const ServicesTab = () => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const controller = Controller.useServices(projectId, match?.params?.drawRequestId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return <Table controller={controller} />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="services" />;
    }

    case HookState.SUCCESS: {
      return <Table controller={controller} />;
    }

    default:
      return null;
  }
};

export default ServicesTab;

const Table: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    isConfirmModalOpened,
    modalText,
    closeConfirmModal,
    confirmCallback,
    pdf,
    gallery,
    close,
    rightDrawerParams,
    rightMenu,
    inspectionCadence,
    TABS,
    tab,
    handleTabChange,
    hiddenColumns,
    setColumnVisibilityModel,
  } = controller;

  return (
    <>
      {Boolean(inspectionCadence) && (
        <Box mb={2}>
          <ToolTipLine
            text={`Progress inspections are scheduled every ${inspectionCadence} days for this project.`}
            typographyVariant="body1"
            fullWidth
          />
        </Box>
      )}

      <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0 }}>
        {controller.state === HookState.SUCCESS && rows?.length === 0 ? (
          <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              We know you’re curious to see how the project is going. <br />
              You’ll see inspection information here after an inspection is ordered.
            </Typography>
          </Stack>
        ) : (
          <DataGrid
            rows={rows}
            columns={convertReactTableColumnsToMuiDataGridColumns(columns)}
            loading={controller.state === HookState.FETCHING}
            columnVisibilityModel={hiddenColumns.reduce((acc, key) => {
              acc[key] = false;
              return acc;
            }, {})}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            slots={{
              toolbar: CustomToolbar,
            }}
            columnBufferPx={200}
            hideFooter={true}
            sx={{
              flexGrow: 1,
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'auto',
              },
            }}
          />
        )}
        <ConfirmationModal
          open={isConfirmModalOpened}
          title={modalText.title}
          text={modalText.text}
          onClose={closeConfirmModal}
          confirmCallback={confirmCallback}
          type={PopupTypeEnum.CONFIRMATION}
          cancelButtonLabel="Close"
        />
        {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
        {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
        <RightDrawer {...rightMenu}>
          <Tabs
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
            value={tab}
            onChange={handleTabChange}
            sx={{ mb: 4 }}
          >
            <Tab
              label={TABS.documents.label}
              value={TABS.documents.value}
              sx={{ textTransform: 'none' }}
              data-cy="inspections__list__right_drawer__tab__documents"
            />
            <Tab
              label={TABS.comments.label}
              value={TABS.comments.value}
              sx={{ textTransform: 'none' }}
              data-cy="inspections__list__right_drawer__tab__comments"
            />
          </Tabs>

          {tab === TABS.documents.value && (
            <Documents {...rightDrawerParams} source="inspections__list__right_drawer" />
          )}
          {tab === TABS.comments.value && (
            <MessagePanel {...rightDrawerParams} source="inspections__list__comments" />
          )}
        </RightDrawer>
      </StyledBox>
    </>
  );
};

const CustomToolbar = () => (
  <Stack direction="row" spacing={1} justifyContent="flex-start" pb={2} px={2}>
    <GridToolbarColumnsButton />
    <GridToolbarDensitySelector />
  </Stack>
);
