import { useQuery } from 'react-query';
import { getInspectionAgencies } from '@globalService';
import { IInspectionAgency, QueryNamesEnums } from '@interfaces';

export interface ControllerInterface {
  inspectionAgenciesList: IInspectionAgency[];
}

export const useInspectionListFilters = (): ControllerInterface => {
  const inspectionAgenciesData = useQuery<{ results: IInspectionAgency[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_AGENCIES],
    getInspectionAgencies.bind(this),
  );

  return {
    inspectionAgenciesList: inspectionAgenciesData.data?.results || [],
  };
};
