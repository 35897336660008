import React, { FC, ReactElement } from 'react';
import { ToolTipLine } from '@components';
import { ToolTipLineVariantEnum } from '@interfaces';

const ProjectWarning: FC<{
  text?: string;
  innerElement: ReactElement;
}> = ({ innerElement }) => {
  return (
    <ToolTipLine
      variant={ToolTipLineVariantEnum.INFORMATION}
      withBackground
      typographyVariant="body2"
      sx={{ minHeight: '56px' }}
    >
      {innerElement}
    </ToolTipLine>
  );
};

export default ProjectWarning;
