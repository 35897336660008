import React, { useContext } from 'react';
import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { Link, matchPath, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

import {
  Budget,
  MessagePanel,
  RightDrawer,
  StyledBox,
  TabSwitcher,
  ToolTipLine,
} from '@components';
import { colors } from '@theme';
import PaymentsTab from '../PaymentsTab';
import {
  Documents,
  DrawsTab,
  Inspections,
  OverviewTab,
  Photos,
  Policies,
  ProjectMainInfo,
  ServicesTab,
} from '../components';
import { HookState } from '@interfaces';
import { isMobileOnly } from 'react-device-detect';
import { useRightMenu } from '@hooks';
import { DotIcon } from '@svgAsComponents';
import { useProject } from './controller';
import { useGetActionTabs } from './utils';
import { ProductionBuildBudget } from '../../productionBuild';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

const Project = () => {
  const flags = useLaunchDarklyFlags();
  const { isPHBProject } = useContext(SettingsContext);
  const { projectId } = useParams();
  const {
    TABS,
    TABS_LIST,
    DRSwitcherTabs,
    activeDrawRequestId,
    activeDrTab,
    handleActiveDRTabChange,
    state,
    updateCommentsPreviewInfo,
    tabLinkPath,
    isActiveRequestHistorical,
  } = useProject(projectId);
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const pathBase = `/projects/${projectId}`;
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const tabCircle = useGetActionTabs();

  // !include here the tabs where you want to see DR switcher
  const showDRSwitcher =
    [
      TABS.DOCUMENTS.value,
      TABS.DRAWS.value,
      TABS.INSPECTIONS.value,
      TABS.PAYMENTS.value,
      TABS.PHOTOS.value,
      TABS.POLICIES.value,
      TABS.SERVICES.value,
    ].includes(match?.params?.tab) &&
    Boolean(DRSwitcherTabs.length) &&
    !isMobileOnly;

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <ProjectMainInfo handleRightDrawerOpenerClick={handleRightDrawerOpenerClick} />

      {!isMobileOnly && (
        <Box
          sx={{
            mt: '.5rem',
            position: 'relative',
            '& .MuiTabs-root': { height: '60px' },
          }}
        >
          <Tabs
            value={match?.params?.tab || 'overview'}
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
          >
            {TABS_LIST.map((tab) => (
              <Tab
                key={tab.value}
                data-cy={`project_tab_${tab.value}`}
                label={tab.label}
                value={tab.value}
                component={Link}
                to={tabLinkPath(tab.value)}
                icon={tab.hasActionIcon ? <ActionIcon showIcon={tabCircle[tab.label]} /> : null}
                iconPosition="end"
              />
            ))}
          </Tabs>
        </Box>
      )}
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 2 }}>
        {showDRSwitcher &&
          (state === HookState.SUCCESS ? (
            <>
              <StyledBox sx={{ mb: 2, p: 0 }}>
                <TabSwitcher
                  tabs={DRSwitcherTabs}
                  onTabChange={handleActiveDRTabChange}
                  activeTab={activeDrTab}
                  source="project__tab_switcher"
                />
              </StyledBox>
              {isActiveRequestHistorical && (
                <Box mb={2}>
                  <ToolTipLine
                    text="This is the historical request and may have limited data"
                    typographyVariant="body2"
                    fullWidth
                    dataTestName="draw_historical_text"
                  />
                </Box>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" width="100%" height={72} sx={{ mb: 2 }} />
          ))}
        <Routes>
          <Route path={`${TABS.OVERVIEW.value}`} element={<OverviewTab />} />
          <Route
            path={`${TABS.DRAWS.value}/all/`}
            element={
              isPHBProject && flags?.['ENG_4271_production_build_budget'] ? (
                <ProductionBuildBudget />
              ) : (
                <Budget />
              )
            }
          />
          <Route
            path={`${TABS.DRAWS.value}/*`}
            element={<DrawsTab drawRequest={activeDrawRequestId} />}
          />
          <Route
            path={`${TABS.DOCUMENTS.value}/*`}
            element={
              <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0 }}>
                <Documents source="documents_tab__documents" />
              </StyledBox>
            }
          />
          <Route path={`${TABS.INSPECTIONS.value}/*`} element={<Inspections />} />
          <Route path={`${TABS.SERVICES.value}/*`} element={<ServicesTab />} />
          <Route path={`${TABS.PAYMENTS.value}/*`} element={<PaymentsTab />} />
          <Route path={`${TABS.PHOTOS.value}/*`} element={<Photos />} />
          <Route path={`${TABS.POLICIES.value}/*`} element={<Policies />} />
        </Routes>
      </Stack>

      <RightDrawer {...rightMenu}>
        <MessagePanel projectId={projectId} source={'project__comments'} />
      </RightDrawer>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.OVERVIEW.value}`} replace />
  );
};

const ActionIcon = ({ showIcon }) => {
  return showIcon ? (
    <Stack sx={{ position: 'relative', bottom: '14px' }}>
      <DotIcon size={8} color={colors.icons.green} />
    </Stack>
  ) : null;
};
export default Project;
