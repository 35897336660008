import React, { memo, FC } from 'react';
import { Typography } from '@mui/material';
import { MilestoneListColumnType, CommonRowType, ColumnLabel, HeaderText } from './common';
import { ColumnV2Width } from '@interfaces';

const LineItemName: FC<{ row: CommonRowType }> = ({ row }) => {
  return (
    <Typography textAlign="right" variant="body3" sx={{ whiteSpace: 'pre-wrap' }}>
      {row.funders_display}
    </Typography>
  );
};

const Memoized = memo(LineItemName);

const fundsProvider: MilestoneListColumnType = {
  name: 'funders',
  columnText: 'Funds Provider',
  renderCell: ({ row }) => <Memoized row={row} />,
  Header: (
    <HeaderText tooltipText="Assigned provider(s) of funds">
      <ColumnLabel>Funds</ColumnLabel>
      <ColumnLabel> Provider</ColumnLabel>
    </HeaderText>
  ),
  minWidth: () => ColumnV2Width.SELECT_MIN,
  maxWidth: () => ColumnV2Width.TEXT_MAX,
};

export default fundsProvider;
