import { dataProvider } from '@api';
import { debug, transformFiltersToQueryString } from '@utils';
import {
  CommonInspectionPayload,
  CreateAgencyPayload,
  CreateInspectionPayload,
  IInspection,
  IInspectionAgency,
  IMilestone,
  PatchInspectionListItemParam,
  PatchInspectionProdBuildGroupParam,
  TableKeyEnum,
  UpdateInspectionPayload,
} from '@interfaces';
import {
  cancelProjectInspection,
  drawRequestInspectionsList,
  inspectionAgencies,
  inspectionAgenciesWithQuery,
  inspectionsList,
  projectInspection,
  projectInspectionMilestone,
  projectInspectionPHBMilestone,
  projectInspectionsList,
  projectInspectionsMilestones,
  scheduleProjectInspection,
  inspectionAgenciesByProject,
} from '../paths';
import { pickBy } from 'lodash';

export const getDrawRequestInspectionsList = async ({
  projectId,
  drawRequestId,
  query,
}: {
  projectId: string;
  drawRequestId: string;
  query?: string;
}): Promise<{ results: IInspection[] }> =>
  dataProvider.get(drawRequestInspectionsList({ projectId, drawRequestId, query })).json();

export const getProjectInspectionById = async ({
  projectId,
  inspectionId,
  query,
}: {
  projectId: string;
  inspectionId: string;
  query?: string;
}): Promise<IInspection> =>
  dataProvider.get(projectInspection({ projectId, inspectionId, query })).json();

export const getInspectionMilestones = async ({
  projectId,
  inspectionId,
  groupBy: group_by,
  limit = '999',
  filterKey,
}: {
  projectId: string;
  inspectionId: string;
  groupBy?: string;
  limit?: string;
  filterKey?: string;
}): Promise<{ results: IMilestone[] }> => {
  const notEmptyParams = pickBy({ group_by, limit }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParams = filterKey ? `${filterKey}=true&` : '';
  return dataProvider
    .get(projectInspectionsMilestones(projectId, inspectionId, queryFiltersParams, filterParams))
    .json();
};

export const getProjectInspectionsList = async ({
  projectId,
  query,
}: {
  projectId: string;
  query?: string;
}): Promise<{ results: IInspection[]; count: number }> =>
  dataProvider.get(projectInspectionsList(projectId, query)).json();

export const getInspectionAgencies = async (): Promise<{ results: IInspectionAgency[] }> =>
  dataProvider.get(inspectionAgencies()).json();

export const getInspectionAgenciesWithQuery = async ({
  restQlParams,
}: {
  restQlParams: string;
}): Promise<{ results: IInspectionAgency[] }> =>
  dataProvider.get(inspectionAgenciesWithQuery({ restQlParams })).json();

export const getInspectionAgenciesByProject = async (
  projectId,
): Promise<{ results: IInspectionAgency[] }> =>
  dataProvider.get(inspectionAgenciesByProject(projectId)).json();

export const getInspectionsListNew = async ({
  queryURL,
}: {
  queryURL: string;
}): Promise<{ results: IInspection[]; count: number }> => {
  const stringQueryParams =
    queryURL +
    `&query={-milestones,-comments_preview,-photos,-proofpoints,-report,-totals,-is_reallocation_enabled,-inspection_cadence,-investor_name,project{-teams},-draw_request_totals}`;
  return dataProvider.get(inspectionsList(stringQueryParams)).json();
};

export const getInspectionsList = async ({
  queryKey,
}): Promise<{ results: IInspection[]; count: number }> => {
  try {
    const [
      _key,
      {
        params,
        params: { filtering, sorting },
      },
    ] = queryKey;

    const queryParams = new URLSearchParams(params);
    // delete filtering key because we send structure key=id1&key=id2
    queryParams.delete('filtering');
    // delete empty sorting key
    if (!sorting) {
      queryParams.delete('sorting');
    }
    let stringQueryParams = queryParams.toString();

    stringQueryParams += transformFiltersToQueryString({
      filtering,
      tableKey: TableKeyEnum.INSPECTION_LIST,
    });
    stringQueryParams += `&query={-milestones,-comments_preview,-photos,-proofpoints,-report,-totals,-is_reallocation_enabled,-inspection_cadence,-investor_name,project{-teams},-draw_request_totals}`;

    return await dataProvider.get(inspectionsList(stringQueryParams)).json();
  } catch (error) {
    await debug(error, 'Something went wrong while getting portfolio');
  }
};
export const postInspectionToProject = async ({
  projectId,
  inspectionData,
}: CreateInspectionPayload): Promise<IInspection> =>
  dataProvider.post(projectInspectionsList(projectId), { json: inspectionData }).json();

export const patchInspectionToProject = async ({
  projectId,
  inspectionData,
}: UpdateInspectionPayload): Promise<IInspection> =>
  dataProvider
    .patch(projectInspection({ projectId, inspectionId: inspectionData.inspectionId }), {
      json: inspectionData,
    })
    .json();

export const patchInspectionMilestoneToProject = async ({
  projectId,
  inspectionId,
  milestoneId,
  json,
}: PatchInspectionListItemParam): Promise<Response> =>
  dataProvider
    .patch(projectInspectionMilestone(projectId, inspectionId, milestoneId), {
      json,
    })
    .json();

export const patchInspectionPHBGroup = ({
  projectId,
  inspectionId,
  group_by,
  tags,
  json,
  filterKey,
}: PatchInspectionProdBuildGroupParam): Promise<IMilestone> => {
  const filterParams = filterKey ? `${filterKey}=true&` : '';

  return dataProvider
    .patch(
      projectInspectionPHBMilestone({ projectId, inspectionId, group_by, tags, filterParams }),
      {
        json,
      },
    )
    .json();
};

export const scheduleInspectionToProject = async ({
  projectId,
  inspectionId,
}: CommonInspectionPayload): Promise<Response> =>
  dataProvider.post(scheduleProjectInspection({ projectId, inspectionId })).json();

export const cancelInspection = async ({
  projectId,
  inspectionId,
}: CommonInspectionPayload): Promise<Response> =>
  dataProvider.post(cancelProjectInspection({ projectId, inspectionId })).json();

export const deleteInspection = async ({
  projectId,
  inspectionId,
}: CommonInspectionPayload): Promise<Response> =>
  dataProvider.delete(projectInspection({ projectId, inspectionId })).json();

export const createInspectionAgency = async ({
  company,
  display_name,
  service,
}: CreateAgencyPayload): Promise<IInspectionAgency> =>
  dataProvider
    .post(inspectionAgencies(), { json: { display_name, company, ...(service && { service }) } })
    .json();
