import { getArrayFromObject } from '@utils';
import { FilterObject } from '../interface';
import { DrawRequestListStatusMapV2 } from '@constants';

export const requestStatusFilterV2: FilterObject = {
  title: 'Request status',
  filterKey: 'draw_request_status',
  needToUpdateSetting: true,
  needToUpdateUrl: true,
  defaultFilters: [],
  getStaticValues: () =>
    getArrayFromObject(DrawRequestListStatusMapV2, 'value', 'label') as unknown as Array<{
      label: string;
      value: string;
    }>,
  cypressSelector: 'filters__draw_request_status',
};
