import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const propertyProposedTypeFilter: FilterObject = {
  title: 'Proposed property type',
  filterKey: 'property_proposed_type',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParams: {
    type: QueryNamesEnums.GET_PROJECT_PROPERTY_TYPES,
    keys: ['id', 'name', 'name_display'],
    args: {
      query: 'has_current_customer=true',
    },
    options: {
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.name,
          label: item.name_display,
        })),
    },
  },
  cypressSelector: 'filters__property_proposed_type',
};
