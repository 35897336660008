import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext, SettingsContext } from '@context';
import { useUpdateUiSettings } from '@hooks';
import {
  DrawRequestStatus,
  FiltersSwitcherEnum,
  RequestCombinedStatus,
  ScoreValuesEnum,
  TableKeyEnum,
} from '@interfaces';
import { DrawRequestListStatusMap, TEAM_ROLES } from '@constants';
import { getTeamRole } from '@utils';

export const useMultiFiltering = (tableKey: string) => {
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);

  const getDefaultStatusFilterByRole = (teamRole: string) => {
    switch (teamRole) {
      case TEAM_ROLES.Owner: {
        return [
          {
            status: RequestCombinedStatus.AWAITING_YOU,
            name: 'Awaiting for additional requirements',
          },
          {
            status: DrawRequestStatus.DRAFT,
            name: DrawRequestListStatusMap[DrawRequestStatus.DRAFT],
          },
        ];
      }
      case TEAM_ROLES.PaymentManager:
      case TEAM_ROLES.CustomerSuccess: {
        return [
          {
            status: DrawRequestStatus.APPROVED,
            name: DrawRequestListStatusMap[DrawRequestStatus.APPROVED],
          },
        ];
      }
      default:
        return [
          {
            status: RequestCombinedStatus.AWAITING_YOU,
            name: DrawRequestListStatusMap[RequestCombinedStatus.AWAITING_YOU],
          },
        ];
    }
  };

  const defaultFilters = useMemo(
    () => ({
      [TableKeyEnum.INSPECTION_LIST]: {
        status: [],
        inspection_agency: [],
      },
      [TableKeyEnum.RISK_RADAR]: {
        property_existing_type: [],
        property_proposed_type: [],
        project_type: [],
        status: [{ name: 'Active', status: 'ACTIVE' }],
        state: [],
        borrower: [],
        lender: [],
        customer: [],
        investor: [],
        [ScoreValuesEnum.PROJECT_HEALTH]: [],
        [ScoreValuesEnum.BUDGET_HEALTH]: [],
        [ScoreValuesEnum.POLICY_HEALTH]: [],
        [ScoreValuesEnum.SCHEDULE_HEALTH]: [],
        [ScoreValuesEnum.BORROWER_SCORE]: [],
      },
      [TableKeyEnum.REQUESTS]: {
        project_status: [{ name: 'Active', status: 'ACTIVE' }],
        status: getDefaultStatusFilterByRole(teamRole),
        borrower: [],
        lender: [],
        coordinator: [],
        inspection_status: [],
        [ScoreValuesEnum.PROJECT_HEALTH]: [],
        investor: [],
        customer: [],
        inspection_agency: [],
        current_reviewer_team: [],
        is_on_hold: [{ name: 'No', value: false }],
      },
    }),
    [teamRole],
  );
  const [value, setValue] = useState(
    settings.personal_setting?.tables?.[tableKey]?.filters || defaultFilters[tableKey],
  );

  useEffect(() => {
    setValue((prevValue) => ({
      ...prevValue,
      ...settings.personal_setting?.tables?.[tableKey]?.filters,
    }));
  }, [settings]);

  const handleFiltersChange = useCallback(
    (newFilters) => {
      setValue((prevValue) => ({ ...prevValue, ...newFilters }));
      updateSettings({
        personal_setting: {
          tables: {
            ...settings.personal_setting?.tables,
            [tableKey]: {
              ...settings.personal_setting?.tables?.[tableKey],
              filters: { ...value, ...newFilters },
            },
          },
        },
      });
    },
    [settings, value],
  );

  const resetFiltersToDefault = useCallback(() => {
    setValue(defaultFilters[tableKey]);
    updateSettings({
      personal_setting: {
        tables: {
          ...settings.personal_setting?.tables,
          [tableKey]: {
            ...settings.personal_setting?.tables?.[tableKey],
            filters: defaultFilters[tableKey],
            ...(tableKey === TableKeyEnum.REQUESTS
              ? { view_type: FiltersSwitcherEnum.AWAITING_YOU }
              : {}),
          },
        },
      },
    });
  }, [settings]);

  const clearFilters = useCallback(() => {
    const emptyFilters = Object.keys(defaultFilters[tableKey]).reduce(
      (acc, key) => ({ ...acc, [key]: [] }),
      {},
    );
    setValue(emptyFilters);
    updateSettings({
      personal_setting: {
        tables: {
          ...settings.personal_setting?.tables,
          [tableKey]: {
            ...settings.personal_setting?.tables?.[tableKey],
            filters: emptyFilters,
            ...(tableKey === TableKeyEnum.REQUESTS ? { view_type: FiltersSwitcherEnum.ALL } : {}),
          },
        },
      },
    });
  }, [settings]);

  return {
    filters: value,
    handleFiltersChange,
    resetFiltersToDefault,
    clearFilters,
    defaultFilters,
  };
};
