import React from 'react';
import {
  LabelAndValue,
  LoadingSkeleton,
  ServiceMessage,
  StyledBox,
  UsersList,
  PermissionIconWithLink,
} from '@components';
import { Stack, Typography, Box, Divider, Button, IconButton } from '@mui/material';

import { HookState } from '@interfaces';
import { DotIcon, EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { AddTeamPopup, AddTeamMemberPopup, EditTeamPopup } from './components';
import * as Controller from './controller';
interface ListComponentsProps {
  controller: Controller.ControllerInterface;
}

const ListBlock = ({ controller }: ListComponentsProps) => {
  const {
    state,
    columns,
    teams,
    handleOpenAddTeamMemberModal,
    handleOpenEditTeamModal,
    hasCompanyTeamsEditPermission,
  } = controller;

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="company teams" />;
    }

    case HookState.SUCCESS: {
      return (
        <>
          {teams.map((team) => (
            <StyledBox key={team.id}>
              <Stack
                direction={{ xs: 'column', lg: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', lg: 'center' }}
                sx={{ width: '100%', mt: 1, mb: 3 }}
                spacing={{ xs: 2, lg: 0 }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography
                    variant="body1SemiBold"
                    sx={{ ml: 0.75, whiteSpace: { sm: 'nowrap', xs: 'normal' } }}
                  >
                    {team.name}
                  </Typography>
                  {hasCompanyTeamsEditPermission && (
                    <Box sx={{ mr: 2, ml: 2 }}>
                      <DotIcon />
                    </Box>
                  )}

                  <LabelAndValue
                    size="body1"
                    label="Role:"
                    text={team.permission_role?.display_name}
                    marginRightAfterLabel={false}
                  />
                  <DotIcon />
                  <LabelAndValue
                    size="body1"
                    label="Members:"
                    text={team.members?.length.toString() || '0'}
                    marginRightAfterLabel={false}
                  />
                  <DotIcon />
                  <LabelAndValue
                    size="body1"
                    label="Approval flow step:"
                    text={team.approval_level ? team.approval_level?.toString() : 'Not in the flow'}
                    marginRightAfterLabel={false}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2}>
                  <PermissionIconWithLink
                    teamId={team.id}
                    dataTestName="company_teams__permissions_icon"
                  />

                  {hasCompanyTeamsEditPermission && (
                    <IconButton
                      onClick={() => handleOpenEditTeamModal(team)}
                      data-cy="company_teams__edit_icon"
                    >
                      <EditIcon size={20} color={colors.icons.green} />
                    </IconButton>
                  )}
                  {hasCompanyTeamsEditPermission && (
                    <Button
                      color="secondary"
                      variant="new"
                      size="small"
                      onClick={() => handleOpenAddTeamMemberModal(team)}
                      data-cy="company_teams__add_team_member_button"
                    >
                      Add member
                    </Button>
                  )}
                </Stack>
              </Stack>

              {Boolean(team.members?.length) && (
                <>
                  <Box mb={3}>
                    <Divider />
                  </Box>
                  <UsersList columns={columns} users={team.members} team={team} fixedWidth />
                </>
              )}
            </StyledBox>
          ))}
        </>
      );
    }

    default:
      return null;
  }
};

const CompanyTeams = () => {
  const controller = Controller.useCompanyTeams();
  const {
    isAddTeamPopupOpen,
    setIsAddTeamPopupOpen,
    isAddTeamMemberPopupOpen,
    handleCloseAddTeamMemberModal,
    isEditTeamPopupOpen,
    handleCloseEditTeamModal,
    team,
    hasCompanyTeamsEditPermission,
    teams,
  } = controller;

  return (
    <Stack spacing={2}>
      <StyledBox>
        <Stack justifyContent="space-between" alignItems="center" direction="row">
          <Typography variant="h3">Teams</Typography>

          {hasCompanyTeamsEditPermission && (
            <Button
              onClick={() => setIsAddTeamPopupOpen(true)}
              data-cy="company_teams__add_team_button"
            >
              Add team
            </Button>
          )}
        </Stack>
      </StyledBox>

      <ListBlock controller={controller} />
      <AddTeamPopup
        open={isAddTeamPopupOpen}
        onClose={() => setIsAddTeamPopupOpen(false)}
        nextApprovalLevel={teams?.length + 1}
      />
      <AddTeamMemberPopup
        open={isAddTeamMemberPopupOpen}
        onClose={handleCloseAddTeamMemberModal}
        team={team}
      />
      {isEditTeamPopupOpen && (
        <EditTeamPopup
          open={isEditTeamPopupOpen}
          onClose={handleCloseEditTeamModal}
          teamId={team?.id}
          initTeamName={team?.name}
        />
      )}
    </Stack>
  );
};

export default CompanyTeams;
