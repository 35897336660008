import { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '@context';
import { checkIsOwner, getTeamRole, replaceObjectProperties } from '@utils';
import {
  StringFieldModel,
  useStringFieldModel,
  usePhoneFieldModel,
  PhoneFieldModel,
} from '@models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IProject, IUser, QueryNamesEnums, UpdateUserFields } from '@interfaces';
import { getProjectList, updateUserMe } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { useNavigate } from 'react-router-dom';

export interface ControllerInterface {
  open: boolean;
  onConfirm: () => void;
  firstNameField: StringFieldModel;
  lastNameField: StringFieldModel;
  emailField: StringFieldModel;
  phoneNumberField: PhoneFieldModel;
}

export const useVerifyBorrowerModal = (): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);

  const firstNameField = useStringFieldModel({
    initValue: user.first_name,
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const lastNameField = useStringFieldModel({
    initValue: user.last_name,
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const phoneNumberField = usePhoneFieldModel({
    initValue: user.phone,
    isRequired: true,
  });

  const emailField = useStringFieldModel({
    initValue: user.email,
  });

  const [open, setOpen] = useState(false);

  const url = 'status=ACTIVE&limit=1';
  const projectsListQuery = useQuery<{ results: IProject[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_LIST, { url }],
    getProjectList.bind(this, url),
    { enabled: isOwner },
  );

  const activeProject = useMemo(
    () => projectsListQuery.data?.count === 1 && projectsListQuery.data?.results?.[0],
    [projectsListQuery.data],
  );

  useEffect(() => {
    setOpen(
      isOwner &&
        user?.agreed_to_terms &&
        'personal_information_first_updated_at' in user &&
        !user?.personal_information_first_updated_at,
    );
  }, [user, isOwner]);

  const updateUserMutation = useMutation<IUser, Error, UpdateUserFields>(updateUserMe, {
    onSuccess: (newData) => {
      queryClient.setQueryData<IUser>([QueryNamesEnums.GET_USER], (request) =>
        replaceObjectProperties({ data: request, newData }),
      );
      if (activeProject?.id) {
        navigate(`/projects/${activeProject.id}/overview`);
      } else {
        navigate('/projects');
      }
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const onConfirm = () => {
    const isFirstNameValid = firstNameField.validate();
    const isLastNameValid = lastNameField.validate();
    const isPhoneNumberValid = phoneNumberField.validate();

    if (isFirstNameValid && isLastNameValid && isPhoneNumberValid) {
      updateUserMutation.mutateAsync({
        first_name: firstNameField.value,
        last_name: lastNameField.value,
        phone: phoneNumberField.valueToSave,
      });
    }
  };

  return {
    open,
    onConfirm,
    firstNameField,
    lastNameField,
    emailField,
    phoneNumberField,
  };
};
