import React, { FC, JSXElementConstructor, useContext, useEffect, useMemo, useState } from 'react';
import { PopperProps } from '@mui/material/Popper';
import { Box, ClickAwayListener, Grid, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete';
import { colors } from '@theme';
import { ComponentProps } from './interface';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';
import { PermissionsContext, useGetListData } from '@context';
import { Button, PopperComponent, StyledInput, StyledPopper } from './elements';

interface LabelType {
  value: string;
  label: string;
}

const FilterButton: FC<ComponentProps> = ({
  title,
  filterKey,
  getDataParams,
  handleFiltersChange,
  initValues,
  cypressSelector,
  fixedWidth,
  getStaticValues,
}) => {
  const { permissions } = useContext(PermissionsContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue] = useState<string[]>(initValues);

  useEffect(() => {
    setPendingValue(initValues);
  }, [initValues]);

  const valuesList = getDataParams
    ? useGetListData(getDataParams)
    : {
        serialized: getStaticValues({ permissions }),
        isLoading: false,
      };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    newValue: LabelType[],
    reason: AutocompleteChangeReason,
  ) {
    if (
      event.type === 'keydown' &&
      (event as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    const onlyValues = newValue.map((item) => item.value || item.toString());
    setPendingValue(onlyValues);
    handleFiltersChange(filterKey, onlyValues);
  }

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Stack flexDirection="row" alignItems="center" sx={{ minHeight: 24 }}>
        <Stack sx={{ height: 18, mr: '12px' }}>
          {selected ? <CheckboxIconChecked size={18} /> : <CheckboxIconDefault size={18} />}
        </Stack>
        <Stack>
          <Typography variant="body3" sx={{ whiteSpace: 'normal' }}>
            {option.label}
          </Typography>
        </Stack>
      </Stack>
    </li>
  );

  const renderFilterValue = () => (
    <>
      <Typography
        variant="body3SemiBold"
        color={colors.text.medium}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {pendingValue?.length ? stringToShow : 'All'}
      </Typography>
      <KeyboardArrowDownIcon />
    </>
  );

  const open = Boolean(anchorEl);
  const id = open ? `${title}-label` : undefined;

  const stringToShow = useMemo(
    () =>
      valuesList.serialized
        ?.filter((item) => pendingValue.includes(item.value))
        ?.map((item) => item.label)
        ?.join(','),
    [valuesList?.serialized, pendingValue],
  );

  return (
    <Grid container alignItems="center">
      <Box sx={{ maxWidth: fixedWidth || '320px', minWidth: fixedWidth || '120px' }}>
        <Button onClick={handleClick} data-cy={cypressSelector}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              width: '100%',
              height: '32px',
              backgroundColor: colors.neutral.lightest,
              borderRadius: '2px',
              p: 1,
              '& svg': { width: 16, height: 16 },
              color: colors.text.medium,
              ...(fixedWidth && { justifyContent: 'space-between' }),
            }}
          >
            <Typography variant="body3" color={colors.text.medium} sx={{ whiteSpace: 'nowrap' }}>
              {`${title}:`}
            </Typography>
            {(fixedWidth && (
              <Stack direction="row" sx={{ width: '108px', justifyContent: 'flex-end' }}>
                {renderFilterValue()}
              </Stack>
            )) ||
              renderFilterValue()}
          </Stack>
        </Button>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{ width: fixedWidth || '320px' }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(
                _event: React.SyntheticEvent<Element, Event>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'escape') handleClose();
              }}
              value={pendingValue}
              onChange={handleChange}
              disableCloseOnSelect
              PopperComponent={PopperComponent as JSXElementConstructor<PopperProps>}
              renderTags={() => null}
              noOptionsText="No labels"
              renderOption={renderOption}
              options={valuesList.serialized}
              isOptionEqualToValue={(option, value) => option.value === value}
              getOptionLabel={(option) => option.label}
              getOptionDisabled={() => valuesList.isLoading}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={`Find ${title.toLowerCase()}`}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Grid>
  );
};

export default FilterButton;
