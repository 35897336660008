import React from 'react';
import { Typography, Stack, styled, useMediaQuery, Theme } from '@mui/material';
import { colors } from '@theme';
import { DotIcon } from '@svgAsComponents';
import { COMPANY_DATA } from '@constants';
import { packageJsonVersion } from '@utils';
import { useLaunchDarklyFlags } from '@context';

const StyledLink = styled('a')(() => ({
  textDecoration: 'none',
  marginTop: '0.5rem',
  color: colors.main.primary.lighter,
}));

const Footer = () => {
  const middleMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const primaryTextStyle = middleMediaQuery
    ? 'body1SemiBold'
    : smallMediaQuery
      ? 'body3SemiBold'
      : 'body2SemiBold';
  const secondaryTextStyle = middleMediaQuery ? 'body2' : smallMediaQuery ? 'label' : 'body3';
  const flags = useLaunchDarklyFlags();

  if (flags?.['ENG_7509_switch_footer_visibility']) return null;
  return (
    <Stack
      id="templateFooter"
      component="footer"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        flexDirection: { xs: 'column', sm: 'row' },
        backgroundColor: colors.neutral.darkest,
        height: { xs: '112px', sm: '84px' },
        padding: '16px',
        borderTop: `1 px solid ${colors.neutral.lighter}`,
        marginTop: 'auto',
        zIndex: 1000,
      }}
    >
      <Stack sx={{ flex: '1', minWidth: { sm: '40%', xs: '100%' } }}>
        <Typography variant={primaryTextStyle} sx={{ color: colors.neutral.white }}>
          {COMPANY_DATA.FULL_NAME}
        </Typography>
        <Typography variant={secondaryTextStyle} sx={{ color: colors.neutral.white }}>
          {COMPANY_DATA.ADDRESS}
        </Typography>
      </Stack>
      <Stack
        justifyContent="center"
        sx={{
          alignItems: { sm: 'flex-end', xs: 'flex-start' },
          ml: { sm: '1.5rem', xs: 0 },
          minWidth: { sm: '40%', xs: '100%' },
          mt: { sm: 0, xs: '16px' },
        }}
      >
        <Stack direction="row" alignItems="center" textAlign="center">
          <Typography variant={primaryTextStyle} sx={{ mr: '1rem' }}>
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href={COMPANY_DATA.TERMS_OF_SERVICE_LINK}
            >
              Terms of Service
            </StyledLink>
          </Typography>
          <DotIcon />
          <Typography variant={primaryTextStyle} sx={{ ml: '1rem' }}>
            <StyledLink
              target="_blank"
              rel="noopener noreferrer"
              href={COMPANY_DATA.PRIVACY_POLICY_LINK}
            >
              Privacy Policy
            </StyledLink>
          </Typography>
        </Stack>
        <Typography
          variant={secondaryTextStyle}
          sx={{ color: colors.neutral.white }}
          textAlign="right"
        >
          {`© ${new Date().getFullYear()} ${
            COMPANY_DATA.FULL_NAME
          } All Rights Reserved, v${packageJsonVersion}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
