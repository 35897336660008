import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPremium,
  GridCellParams,
  GridToolbar,
  gridExpandedSortedRowIdsSelector,
  gridVisibleColumnDefinitionsSelector,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { GridColDefGenerator, useDemoData } from '@mui/x-data-grid-generator';
import { CommonRowType, MilestoneListColumnType } from './columns';
import useScrollCheck from './scrollHook';
import { Stack } from '@mui/material';

export default function ScrollPlayground({
  columns,
  sortedMilestones,
}: {
  columns: MilestoneListColumnType[];
  sortedMilestones: CommonRowType[];
}) {
  const apiRef = useGridApiRef();
  const { inTablePinned, tabelRef } = useScrollCheck(apiRef);
  const [coordinates, setCoordinates] = React.useState({
    rowIndex: 0,
    colIndex: 0,
  });

  const { data } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 500,
  });
  data.columns[0];
  React.useEffect(() => {
    const { rowIndex, colIndex } = coordinates;
    console.log({ rowIndex, colIndex });
    /*
    apiRef.current?.scrollToIndexes(coordinates);
    const id = gridExpandedSortedRowIdsSelector(apiRef)[rowIndex];
    const column = gridVisibleColumnDefinitionsSelector(apiRef)[colIndex];
    apiRef.current?.setCellFocus(id, column.field);*/
  }, [apiRef, coordinates]);

  const handleCellClick = (params: GridCellParams) => {
    const rowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex((id) => id === params.id);
    const colIndex = gridVisibleColumnDefinitionsSelector(apiRef).findIndex(
      (column) => column.field === params.field,
    );
    setCoordinates({ rowIndex, colIndex });
  };

  const serColumns = React.useMemo<GridColDefGenerator[]>(
    () =>
      columns.map((item) => ({
        field: item.name,
        width: +item.width,
        minWidth: +item.minWidth(false),
        headerName: item.columnText,
        renderCell: ({ row }) => (
          <Stack
            p={0}
            sx={{
              alignItems: 'center',
              overflow: 'hidden',
              flexDirection: 'row',
            }}
          >
            {item.renderCell({ row })}
          </Stack>
        ),
      })),
    [columns],
  );
  return (
    <Box
      ref={tabelRef}
      sx={{
        maxHeight: 'calc(100vh - 64px)',
        ...(inTablePinned ? { left: 16, right: 16, top: 64, zIndex: 100 } : { width: '100%' }),
        position: inTablePinned ? 'absolute' : 'relative',

        background: 'white',
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        cellSelection
        rowReordering
        autoPageSize={inTablePinned}
        autoHeight={!inTablePinned}
        apiRef={apiRef}
        disableColumnSelector
        onCellClick={handleCellClick}
        hideFooter
        {...data}
        columns={serColumns}
        rows={sortedMilestones}
        sx={{
          ['& .MuiDataGrid-cell']: {
            lineHeight: '1em',
          },
        }}
      ></DataGridPremium>
    </Box>
  );
}
