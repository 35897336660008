import React from 'react';
import { Typography, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { LoginWireframe, FormikInput } from '@components';
import * as Controller from './controller';
import { BackToLoginButton, AnimatedRedirect } from '../components';
import { colors } from '@theme';

const LoginWithSSO = () => {
  const { inProgress, formik, sso_access_token, fromRedirect } = Controller.useSSOIntegration();

  const getFormButtons = () => (
    <Stack spacing={2}>
      <LoadingButton
        fullWidth
        type="submit"
        disabled={!formik.values?.domainName}
        loading={formik.isSubmitting || inProgress}
      >
        Continue
      </LoadingButton>

      <BackToLoginButton withLogout />
    </Stack>
  );

  if (fromRedirect || sso_access_token)
    return (
      <Stack alignItems="center" sx={{ minHeight: '100vh' }}>
        <Stack sx={{ flex: 1, alignItems: 'center', justifyContent: 'center' }} spacing={6}>
          <AnimatedRedirect />

          {formik.errors?.domainName ? (
            <>
              <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                {formik.errors.domainName}
              </Typography>
              <BackToLoginButton withLogout />
            </>
          ) : (
            <Typography variant="h2">Redirecting...</Typography>
          )}
        </Stack>
      </Stack>
    );

  return (
    <LoginWireframe
      formik={formik}
      getTitle={<Typography variant="h1">Sign in with SSO</Typography>}
      getFormContent={
        <FormikInput
          name="domainName"
          label="Company domain"
          type="text"
          variant="outlined"
          size="small"
          disabled={formik.isSubmitting || inProgress}
          servererror={formik?.errors?.domainName}
        />
      }
      getFormButtons={getFormButtons()}
    />
  );
};

export default LoginWithSSO;
