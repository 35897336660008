import React, { FC } from 'react';
import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { HookState } from '@interfaces';
import {
  ButtonWithTooltip,
  ConfirmationModal,
  DocumentSummaryList,
  DocumentUploaderWithForm,
  Gallery,
  MessagePanel,
  PDFViewerNew,
  ReactTable,
  RightDrawer,
  ServiceMessage,
} from '@components';
import * as Controller from './controller';
import { MagicWandIcon, NoDataImage } from '@svgAsComponents';
import { getTooltipText } from '@utils';
import { colors } from '@theme';
import { useDocumentUploader } from './useDocumentUploader';
import { ControllerInterface, UploaderControllerInterface } from './interface';

interface IDocumentProps {
  prId?: string;
  milestoneId?: string;
  milestoneSubmitId?: string;
  requestId?: string;
  requestDocuments?: boolean;
  inspectionId?: string;
  source: string;
}

const Documents: FC<IDocumentProps> = ({
  prId,
  requestId,
  requestDocuments,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  source,
}) => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const drawRequestId = match?.params?.drawRequestId || requestId;
  const controller = Controller.useDocuments({
    projectId: projectId || prId,
    drawRequestId,
    requestDocuments,
    milestoneId,
    milestoneSubmitId,
    inspectionId,
    source,
  });
  const uploaderController = useDocumentUploader({
    projectId: projectId || prId,
    drawRequestId,
    requestDocuments,
    milestoneId,
    milestoneSubmitId,
    inspectionId,
  });

  switch (controller.state) {
    case HookState.ERROR: {
      return <ServiceMessage text="documents" />;
    }

    case HookState.FETCHING:
    case HookState.SUCCESS: {
      return (
        <DocumentsTable
          controller={{ ...controller, ...uploaderController }}
          source={source}
          drawRequestId={drawRequestId}
          milestoneId={milestoneId}
        />
      );
    }

    default:
      return null;
  }
};

export default Documents;

const DocumentsTable: FC<{
  controller: ControllerInterface & UploaderControllerInterface;
  source: string;
  drawRequestId?: string;
  milestoneId?: string;
}> = ({ controller, source, drawRequestId, milestoneId }) => {
  const {
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    refetchCallback,
    documentTypes,
    refetchDocuments,
    isConfirmModalOpened,
    modalInfo,
    closeConfirmModal,
    confirmCallback,
    pdf,
    gallery,
    close,
    rows,
    columns,
    isCurrentProjectArchived,
    downloadDocs,
    restrictions,
    rightMenu,
    isRightDrawerDocs,
    milestones,
    rightDrawerParams,
    docSummaryMenu,
    docSummaryParams,
    openAllDocsSummary,
    projectName,
    openPdfViewer,
    filteredDocuments,
  } = controller;
  const smallMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack sx={{ flex: 1, marginBottom: isRightDrawerDocs ? 9 : 0 }}>
      {!isRightDrawerDocs && (
        <Stack direction="row" justifyContent="flex-end" sx={{ pb: 2, pr: 2 }} spacing={1.5}>
          {Boolean(controller.state === HookState.SUCCESS && rows?.length) && (
            <Button
              onClick={openAllDocsSummary}
              data-cy={`${source}__documents_summary__button`}
              sx={{ background: colors.button.gradient }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <MagicWandIcon size={16} color={colors.white} />
                <Typography
                  variant="body2SemiBold"
                  color={colors.white}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  SmartSummary
                </Typography>
              </Stack>
            </Button>
          )}
          <ButtonWithTooltip
            variant="new"
            color="secondary"
            onClick={handleOpenDocumentUploader}
            disabled={isCurrentProjectArchived}
            tooltipText={getTooltipText({ isCurrentProjectArchived })}
            dataTestName={`${source}__upload__button`}
          >
            Upload doc(s)
          </ButtonWithTooltip>
          <Button
            variant="new"
            color="secondary"
            onClick={downloadDocs}
            disabled={!rows?.length}
            data-cy={`${source}__download_all__button`}
          >
            Download all doc(s)
          </Button>
        </Stack>
      )}

      {Boolean(controller.state === HookState.FETCHING || rows?.length) && (
        <ReactTable
          data={rows}
          hideHeader={isRightDrawerDocs}
          columns={columns}
          footer={false}
          source={`${source}__list`}
        />
      )}
      {Boolean(controller.state === HookState.SUCCESS && !rows?.length) && (
        <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
          <NoDataImage size={200} />
          <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
            No documents yet
          </Typography>
        </Stack>
      )}
      {transloaditSignature?.signature && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={documentTypes}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          refetchCallback={refetchCallback}
          refetch={refetchDocuments}
          restrictions={restrictions}
          milestones={milestones}
          source={source}
          drawRequestId={drawRequestId}
          milestoneId={milestoneId}
        />
      )}
      {isRightDrawerDocs && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            mt: 'auto',
            position: 'fixed',
            bottom: 0,
            backgroundColor: colors.background.white,
            py: 4.5,
            px: 2,
            width: smallMediaQuery ? '100%' : '564px',
          }}
        >
          <Button
            variant="new"
            color="secondary"
            onClick={downloadDocs}
            disabled={!rows?.length}
            data-cy={`${source}__download_all__button`}
          >
            Download all
          </Button>
          <ButtonWithTooltip
            variant="new"
            color="secondary"
            onClick={handleOpenDocumentUploader}
            disabled={isCurrentProjectArchived}
            tooltipText={getTooltipText({ isCurrentProjectArchived })}
            sx={{ ml: 1 }}
            dataTestName={`${source}__upload__button`}
          >
            Upload doc(s)
          </ButtonWithTooltip>
          {Boolean(controller.state === HookState.SUCCESS && rows?.length) && (
            <Button
              onClick={openAllDocsSummary}
              data-cy={`${source}__documents_summary__button`}
              sx={{ ml: 1, background: colors.button.gradient }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <MagicWandIcon size={16} color={colors.white} />
                <Typography
                  variant="body2SemiBold"
                  color={colors.white}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  SmartSummary
                </Typography>
              </Stack>
            </Button>
          )}
        </Stack>
      )}
      <ConfirmationModal
        open={isConfirmModalOpened}
        title={modalInfo.title}
        text={modalInfo.text}
        onClose={closeConfirmModal}
        confirmCallback={confirmCallback}
        type={modalInfo.type}
        confirmButtonLabel={modalInfo.confirmButtonLabel}
      />
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
      {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
      <RightDrawer {...rightMenu}>
        <MessagePanel {...rightDrawerParams} source={`${source}__list__comments`} />
      </RightDrawer>
      <RightDrawer
        {...docSummaryMenu}
        drawerWidth="720px"
        right={isRightDrawerDocs ? '600px' : '0'}
      >
        <DocumentSummaryList
          {...docSummaryParams}
          projectName={projectName}
          rows={filteredDocuments}
          source={`${source}__list__summary`}
          openPdfViewer={openPdfViewer}
        />
      </RightDrawer>
    </Stack>
  );
};
