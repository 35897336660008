import React, { FC, PropsWithChildren, ReactElement, useCallback, useEffect } from 'react';
import { Box, Button, Collapse, Stack, Tooltip, Typography } from '@mui/material';
import {
  CheckboxIconRoundedChecked,
  CheckboxIconRoundedDefault,
  CheckboxIconRoundedSkipped,
} from '@svgAsComponents';
import { IconButtonWithTooltip, LabelAndValue, StyledBox } from '@components';
import { useExpandedState } from '@hooks';
import { IValidationController } from '../../main/interfaces';
import { colors } from '@theme';
import { PolicyItemTypesEnum } from '@interfaces';
import { BLOCKED_POLICY_REASON, SubmissionSectionKeyMap } from '@constants';

interface ComponentProps {
  title?: string | ReactElement;
  subtitle?: string;
  hideSkipButton?: boolean;
  controller: IValidationController;
  sectionKey: string;
  isChecklist?: boolean;
  handleSkip?: () => void;
  handleDone?: () => void;
  collapsedSectionText?: {
    label?: string;
    value?: string;
  };
  isEditable?: boolean;
  tooltipText?: string;
  innerRef?: (node: HTMLDivElement) => void;
  sectionWrapperButton?: ReactElement;
  itemType: PolicyItemTypesEnum;
}

const SectionWrapper: FC<PropsWithChildren<ComponentProps>> = ({
  title,
  children,
  subtitle,
  hideSkipButton = false,
  controller,
  sectionKey,
  isChecklist = false,
  handleSkip,
  handleDone,
  collapsedSectionText,
  isEditable,
  tooltipText,
  innerRef,
  sectionWrapperButton,
  itemType,
}) => {
  const { validatedSection, onSectionButtonsClick, validatedChecklist } = controller;
  const section = isChecklist ? validatedChecklist?.[sectionKey] : validatedSection?.[sectionKey];
  const isSectionDone = section?.isDone;
  const isSectionSkipped = section?.isSkipped;
  const isEditableSection = !controller.isAdditionalRequirementResubmit || isEditable;
  const { expanded, setExpanded, getExpandButton } = useExpandedState({
    initialState: !isSectionDone && !isSectionSkipped,
  });

  useEffect(() => {
    setExpanded(!isSectionSkipped);
  }, [isSectionSkipped]);

  const disabled =
    !isSectionSkipped &&
    (isChecklist
      ? !validatedChecklist?.[sectionKey]?.isValid
      : !validatedSection?.[sectionKey]?.isValid);

  const buttonTooltipText =
    !controller.isAdditionalRequirementResubmit && disabled && !isSectionSkipped
      ? BLOCKED_POLICY_REASON[itemType] || ''
      : '';

  const checkBoxIcon = useCallback(() => {
    if (isSectionDone) {
      return (
        <CheckboxIconRoundedChecked
          size={38}
          dataTestName={`request__submission__${sectionKey}__mark_as_done__icon`}
        />
      );
    }
    if (isSectionSkipped) {
      return (
        <CheckboxIconRoundedSkipped
          size={38}
          dataTestName={`request__submission__${sectionKey}__skipped__icon`}
        />
      );
    }
    return <CheckboxIconRoundedDefault size={38} />;
  }, [isSectionDone, isSectionSkipped, sectionKey]);

  return (
    <Box ref={innerRef || null}>
      <StyledBox
        sx={[
          !isSectionDone && !isSectionSkipped && section?.highlightBorder
            ? { border: '2px solid', borderColor: colors.status.error.medium }
            : {},
          {
            px: 0,
            ...(expanded ? {} : { cursor: 'pointer' }),
            ...(sectionKey === SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM] &&
            expanded
              ? { pb: 0 }
              : {}),
          },
        ]}
        onClick={() => !expanded && setExpanded(true)}
      >
        <Stack
          direction="row"
          sx={{ alignItems: 'center', px: 2, ...(!expanded ? {} : { cursor: 'pointer' }) }}
          onClick={() => expanded && setExpanded(false)}
        >
          {isEditableSection ? (
            <IconButtonWithTooltip
              data-cy={`request__submission__${sectionKey}__checkbox__button`}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (isSectionSkipped && handleSkip) return handleSkip();
                if (handleDone) handleDone();
                onSectionButtonsClick({ sectionKey, key: 'isDone', isChecklist });
              }}
              sx={[
                {
                  p: 0,
                  '&:disabled': { opacity: 0.4 },
                },
                isSectionSkipped
                  ? {
                      '& > svg > circle: hover': { fill: colors.status.orange.dark },
                      '& > svg > path: hover': { fill: colors.white },
                    }
                  : {
                      '& > svg > path: hover': { fill: colors.status.success.dark },
                    },
              ]}
              disabled={disabled}
              tooltipText={buttonTooltipText}
            >
              {checkBoxIcon()}
            </IconButtonWithTooltip>
          ) : (
            checkBoxIcon()
          )}
          <Box flex={1} ml={1}>
            {Boolean(title) && (
              <Tooltip
                title={
                  tooltipText ? (
                    <Typography
                      variant="label"
                      data-cy={`request__submission__${sectionKey}__tooltip__value`}
                    >
                      {tooltipText}
                    </Typography>
                  ) : (
                    ''
                  )
                }
                placement="bottom-start"
                data-cy={`request__submission__${sectionKey}__tooltip`}
              >
                <Typography variant="h3">{title}</Typography>
              </Tooltip>
            )}
          </Box>
          {expanded && isEditableSection && (
            <Stack sx={{ mr: 3 }} spacing={1} direction="row">
              {sectionWrapperButton}
              {!hideSkipButton && (
                <Button
                  variant="new"
                  color="secondary"
                  disabled={isSectionDone}
                  onClick={() => {
                    if (handleSkip) return handleSkip();
                    onSectionButtonsClick({ sectionKey, key: 'isSkipped', isChecklist });
                  }}
                  data-cy={`request__submission__${sectionKey}__policy_items__skip__button`}
                >
                  {isSectionSkipped ? 'Unskip' : 'Skip'}
                </Button>
              )}
            </Stack>
          )}
          {!expanded && collapsedSectionText && (
            <LabelAndValue
              label={collapsedSectionText.label}
              text={collapsedSectionText.value}
              justifyContent="flex-start"
              size="body1"
              marginRightAfterLabel={false}
            />
          )}
          <Box>{getExpandButton()}</Box>
        </Stack>
        {expanded && subtitle && (
          <Typography variant="body2" ml={8} pb={2} pt={1}>
            {subtitle}
          </Typography>
        )}
        {children && expanded && <Collapse in={expanded}>{children}</Collapse>}
      </StyledBox>
    </Box>
  );
};

export default SectionWrapper;
