import { useContext } from 'react';
import { SettingsContext } from '@context';
import { useFilesUploader } from '@hooks';
import { DocumentContentTypeEnum, TransloaditTemplateEnum } from '@interfaces';
import { ControllerProps, UploaderControllerInterface } from './interface';

export const useDocumentUploader = ({
  projectId,
  drawRequestId,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
}: ControllerProps): UploaderControllerInterface => {
  const {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    uploadMedia,
    restrictions,
    refetchCallback,
  } = useFilesUploader();
  const { isPHBProject } = useContext(SettingsContext);

  const handleOpenDocumentUploader = () => {
    const contentType = () => {
      if (inspectionId) return DocumentContentTypeEnum.INSPECTION;
      if (milestoneId && isPHBProject) return DocumentContentTypeEnum.MILESTONE_GROUP;
      if (milestoneSubmitId) return DocumentContentTypeEnum.MILESTONE_SUBMIT;
      if (drawRequestId) return DocumentContentTypeEnum.DRAW_REQUEST;
      if (milestoneId) return DocumentContentTypeEnum.MILESTONE;
      return DocumentContentTypeEnum.PROJECT;
    };

    const objectId = () =>
      isPHBProject && milestoneId
        ? milestoneId
        : inspectionId || milestoneSubmitId || drawRequestId || milestoneId || projectId;

    const fields = {
      content_type: contentType(),
      object_id: objectId(),
    };
    uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  };

  return {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    restrictions,
    handleOpenDocumentUploader,
    refetchCallback,
  };
};
