import React, { FC, useContext } from 'react';
import { Typography, Skeleton } from '@mui/material';
import { currencyFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { MilestoneListColumnType, CommonRowType, ColumnLabel, HeaderText } from './common';
import { ColumnV2Width, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  const { source } = useContext(TableContext);
  if (isBlocked) return <Skeleton width={'100%'} />;

  const borrowerEquityAmount = +row.funders.OWNER * (+row.revised_estimate / 100);
  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__borrower_equity__value__index_${row?.project_milestone?.index}`}
    >
      {currencyFormatter(borrowerEquityAmount, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { borrowerEquityRate, source } = useContext(TableContext);
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  const borrowerEquityAmount = borrowerEquityRate * (+row.revised_estimate / 100);
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__borrower_equity__value`}>
      {currencyFormatter(borrowerEquityAmount, '-')}
    </Typography>
  );
};

const borrowerEquity: MilestoneListColumnType = {
  name: 'borrower_equity',
  columnText: "Borrower's equity ($)",
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Amount the borrower is required to pay toward the total project costs.">
      <ColumnLabel>Borrower`s </ColumnLabel>
      <ColumnLabel> equity ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default borrowerEquity;
