import React from 'react';
import { Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import orderBy from 'lodash/orderBy';

import { HookState } from '@interfaces';
import { ProjectCard, LoadingSkeleton, ServiceMessage } from '@components';
import * as Controller from './controller';
import { ComponentProps } from './interface';
import { NoProjectsImage } from '@svgAsComponents';

const ProjectCardsList = ({ url, noProjectsText }: ComponentProps) => {
  const { state, data } = Controller.useProjects(url);

  const largeMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  switch (state) {
    case HookState.FETCHING: {
      return (
        <Grid container spacing={2}>
          {[...Array(largeMediaQuery ? 3 : 2)].map((item, index) => (
            <Grid item key={index} xs={12} sm={12} md={6} lg={4}>
              <LoadingSkeleton type="overviewBlock" />
            </Grid>
          ))}
        </Grid>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="Projects" />;
    }

    case HookState.SUCCESS: {
      if (data.length)
        return (
          <Grid container spacing={2}>
            {orderBy(data, ['created_at'], ['desc']).map((item) => (
              <Grid item key={item.id} xs={12} sm={12} md={6} lg={4}>
                <ProjectCard item={item} link={'/projects'} />
              </Grid>
            ))}
          </Grid>
        );

      return (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 1, backgroundColor: 'white' }}
        >
          <NoProjectsImage />
          <Typography>{noProjectsText}</Typography>
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default ProjectCardsList;
