import React, { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { HookState, IDrawRequest, ITableHeader, QueryNamesEnums } from '@interfaces';
import { currencyFormatter, getHookState, tableHeaders } from '@utils';
import { getProject, getProjectDrawRequestsList } from '@globalService';
import { Typography } from '@mui/material';

export interface ControllerInterface {
  state: HookState;
  requests: IDrawRequest[];
  columns: ITableHeader[];
}

export const useRequestsList = (): ControllerInterface => {
  const { projectId } = useParams();

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
      enabled: !!projectId,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
      enabled: !!projectId,
    },
  ]);

  const project = requestedDataQueries[0].data;
  const drawRequestsQuery = requestedDataQueries[1];

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);
  const isLoading = useMemo(() => drawRequestsQuery.isLoading, [drawRequestsQuery.isLoading]);

  const columns = useMemo(
    () => [
      tableHeaders.requestNameLink({ isLoading, projectId }),
      tableHeaders.date({
        header: 'Submitted date',
        accessor: 'submitted_at',
        isLoading,
        typoprops: { variant: 'body2' },
      }),
      tableHeaders.date({
        header: 'Mark as disbursed date',
        accessor: 'disbursed_at',
        isLoading,
        typoprops: { variant: 'body2' },
      }),
      tableHeaders.amount({
        header: 'Total draw amount',
        accessor: 'approved_amount',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__approved_amount__value`,
      }),
      tableHeaders.amount({
        header: 'Construction holdback draw ($)',
        accessor: 'construction_holdback',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__construction_holdback__value`,
      }),
      ...(retainageRate
        ? [
            tableHeaders.amount({
              header: 'Borrower equity contribution ($)',
              accessor: 'borrower_equity',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__borrower_equity__value`,
            }),
            tableHeaders.amount({
              header: 'Retainage heldback ($)',
              accessor: 'retainage_approved_amount_holdback',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__retainage_approved_amount_holdback__value`,
            }),
            tableHeaders.amount({
              header: 'Retainage released ($)',
              accessor: 'retainage_release_approved',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__retainage_release_approved__value`,
            }),
          ]
        : []),
      tableHeaders.amount({
        header: 'Borrower portion ($)',
        accessor: 'borrower_equity_with_fees',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__borrower_equity_with_fees__value`,
      }),
      tableHeaders.amount({
        header: 'Draw fees ($)',
        accessor: 'fees_amount',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__fees_amount__value`,
      }),
      tableHeaders.amount({
        header: 'Net disbursed amount ($)',
        accessor: 'construction_holdback_with_fees',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__construction_holdback_with_fees__value`,
      }),
    ],
    [isLoading, projectId, retainageRate],
  );

  return {
    state: getHookState(requestedDataQueries),
    requests: drawRequestsQuery.data?.results,
    columns,
  };
};

const CustomFooter = ({ amount, dataTestName }: { amount: number; dataTestName?: string }) => (
  <Typography variant="body2SemiBold" data-cy={dataTestName}>
    {currencyFormatter(amount)}
  </Typography>
);
