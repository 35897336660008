import { useContext, useMemo } from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';

import { ITeam, QueryNamesEnums, ProjectStatusEnum, IStatusChip, HookState } from '@interfaces';
import { getProjectTeams, getProject } from '@globalService';
import { SettingsContext } from '@context';
import { statusMap } from '@constants';
import { getHookState, getBorrower, getCustomer } from '@utils';

interface ControllerInterface {
  state: HookState;
  projectName: string;
  statusValue: IStatusChip;
  borrower: ITeam;
  customer: ITeam;
  isCurrentProjectActive: boolean;
  status_change_reason: string;
}

export const useProjectHeader = (): ControllerInterface => {
  const { projectId } = useParams();

  const {
    isCurrentProjectActive,
    settings: { display: staticStatuses },
  } = useContext(SettingsContext);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, companyId: null }],
      queryFn: getProjectTeams.bind(this, projectId, null),
    },
  ]);

  const project = requestedDataQueries[0]?.data;
  const teams = requestedDataQueries[1]?.data?.results;

  const borrower = useMemo(() => getBorrower(teams), [teams]);

  const customer = useMemo(() => getCustomer(teams), [teams]);

  const statusValue = useMemo(
    () =>
      staticStatuses && project?.status !== ProjectStatusEnum.ACTIVE
        ? statusMap(project?.status, staticStatuses, 'project')
        : null,
    [project?.status, staticStatuses],
  );

  const projectName = useMemo(
    () => (project?.loan?.external_id ? `#${project?.loan.external_id}, ` : '') + project?.name,
    [project],
  );

  return {
    state: getHookState(requestedDataQueries),
    projectName,
    statusValue: { ...statusValue, label: statusValue?.text },
    borrower,
    customer,
    isCurrentProjectActive,
    status_change_reason: project?.status_change_reason,
  };
};
