import { useMemo, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  IProject,
  QueryNamesEnums,
  IProjectDetailsFields,
  EnumTypeForList,
  IPropertyDetail,
  ErrorDual,
  PatchBuildingParam,
} from '@interfaces';
import { updateProjectFields, patchProjectBuilding, createProjectBuilding } from '@globalService';
import { useProjectDetailsFields } from '@hooks';
import { findIdInArrByKey, parsePathErrorDual } from '@utils';
import { SettingsContext } from '@context';

interface ControllerInterface {
  handleSubmitClick: () => Promise<boolean>;
  isSubmitting: boolean;
  isUpdated: boolean;
  exitPath: string;
  projectDetailsFields: IProjectDetailsFields;
  exitStrategiesList: EnumTypeForList[];
  projectTypesList: EnumTypeForList[];
  propertyTypesList: EnumTypeForList[];
  propertyDetails: any;
  isAllProjectDataValid: boolean;
}

export const useGeneralSettings = ({ project }: { project: IProject }): ControllerInterface => {
  const { isPHBProject } = useContext(SettingsContext);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const {
    projectDetailsFields,
    exitStrategiesList,
    isProjectDetailsUpdated,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
  } = useProjectDetailsFields({
    project,
  });

  const {
    projectName,
    scopeOfWork,
    exitStrategy,
    projectType,
    existingPropertyType,
    proposedPropertyType,
    address_1,
    city,
    state,
    zipCode,
  } = projectDetailsFields;
  const { id: projectId } = project || {};

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId: project.id }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES,
        { projectId: project.id },
      ]);
    },
    onError: (error) => {
      enqueueSnackbar(
        parsePathErrorDual(error) || 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const patchBuildingMutation = useMutation<Response, ErrorDual, PatchBuildingParam>(
    patchProjectBuilding,
    {
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_BUILDING);
      },
    },
  );

  const addBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      details?: IPropertyDetail[];
    }
  >(createProjectBuilding, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const getPropertyDataForPayload = () =>
    propertyDetails?.list
      .filter((item) => item.name)
      .map(({ name_display, id, name, ...rest }) => ({ name: id, ...rest }));

  const handleSubmitClick = async () => {
    if (propertyDetails?.isEmptyDetailExist) {
      enqueueSnackbar('Property details should be filled', { variant: 'error' });
      return false;
    }

    if (!projectDetailsFields.projectName.validate()) return false;

    if (!isPHBProject && propertyDetails?.isChanged) {
      if (propertyDetails?.id) {
        await patchBuildingMutation.mutateAsync({
          project: projectId,
          building: propertyDetails.id.toString(),
          json: { details: getPropertyDataForPayload() },
        });
      } else {
        await addBuildingMutation.mutateAsync({
          projectId,
          details: getPropertyDataForPayload(),
        });
      }
    }

    const projectPayload = {
      ...(scopeOfWork.isChanged && { scope_of_work: scopeOfWork.value }),
      ...(projectName.isChanged && { name: projectName.value }),
      ...(exitStrategy.isChanged && {
        exit_strategy: findIdInArrByKey({
          arr: exitStrategiesList,
          searchedVal: exitStrategy.value?.name_display,
        }),
      }),
      ...(projectType.isChanged && {
        type: findIdInArrByKey({
          arr: projectTypesList,
          searchedVal: projectType.value?.name_display,
        }),
      }),
      ...(existingPropertyType.isChanged && {
        property_existing_type: findIdInArrByKey({
          arr: propertyTypesList,
          searchedVal: existingPropertyType.value?.name_display,
        }),
      }),
      ...(proposedPropertyType.isChanged && {
        property_proposed_type: findIdInArrByKey({
          arr: propertyTypesList,
          searchedVal: proposedPropertyType.value?.name_display,
        }),
      }),
      ...(address_1.isChanged || city.isChanged || state.isChanged || zipCode.isChanged
        ? {
            address: {
              address_1: address_1.value,
              city: city.value,
              state: state.value?.name,
              zip_code: zipCode.value,
              id: project?.address?.id,
            },
          }
        : {}),
    };

    await projectMutation.mutateAsync({
      projectId,
      json: projectPayload,
    });
    return true;
  };

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  return {
    projectDetailsFields,
    exitStrategiesList,
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    isUpdated: isProjectDetailsUpdated,
    exitPath,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
  };
};
