import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  CSVPHBBudgetSectionsKeysEnum,
  DeleteAllListItemsParam,
  ErrorDual,
  IFilterOption,
  IMenuItem,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  IProjectMilestone,
  MutationKeyEnum,
  PatchProjectProdBuildGroupParam,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteProjectBuildingModels,
  deleteProjectMilestones,
  getProject,
  getProjectDrawRequestsList,
  getProjectMilestonesList,
  patchProjectProdBuildGroup,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  checkHasAmountChanges,
  getItemLocalHighlight,
  isCreatedProject,
  parsePathErrorDual,
  replaceMilestoneData,
} from '@utils';
import { useSafeSnackbar } from '@hooks';
import { DeleteIcon } from '@svgAsComponents';
import { Typography } from '@mui/material';
import { colors } from '@theme';
import { useExpandCollapseTable, useLoadingSkeleton, usePHBFilters } from '../hooks';

export type ControllerInterface = {
  initColumns: string[];
  milestones: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  groupByOptions: { ids: string; filterValue: string }[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  groupByValue: string;
  handleGroupByClick: (value: string) => void;
  isLoading: boolean;
  menuItems: IMenuItem[];
  isDeleteBudgetPopupOpen: boolean;
  setIsDeleteBudgetPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteBudget: (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => Promise<void>;
  isDeleting: boolean;
  totals: IMilestoneTotal;
  patchMsGroup: (params) => void;
};

export const useBudgetTable = (): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [listItems, setListItems] = useState([]);
  const [isDeleteBudgetPopupOpen, setIsDeleteBudgetPopupOpen] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { enqueueSnackbar } = useSafeSnackbar();

  const query = '{*}';
  const totalsQuery = '{totals}';
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
  ]);

  const project = requestedDataQueries[0].data;
  const drawRequests = requestedDataQueries[1].data?.results;

  const {
    groupIds,
    filterValue,
    handleFilterClick,
    groupByValue,
    handleGroupByClick,
    filterOptions,
    groupByOptions,
    filterKey,
  } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
  });

  const projectMilestonesTotalsQuery = useQuery<
    { results: IProjectMilestone[]; totals: IMilestoneTotal },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query: totalsQuery }],
    getProjectMilestonesList.bind(this, { projectId, query: totalsQuery }),
    { enabled: Boolean(projectId && groupIds) },
  );

  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: groupIds,
        filterKey,
      },
    ],
    getProjectMilestonesList.bind(this, {
      projectId,
      query,
      groupBy: groupIds,
      filterKey,
    }),
    { enabled: Boolean(projectId && groupIds) },
  );
  const projectMilestones = projectMilestonesQuery.data;

  const deleteBudgetMutation = useMutation<Response, ErrorDual, DeleteAllListItemsParam>(
    deleteProjectMilestones,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: () => {
        setIsDeleteBudgetPopupOpen(false);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
      },
      onError: (error) => {
        setIsDeleteBudgetPopupOpen(false);
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const deleteAllModelsMutation = useMutation<Response, ErrorDual, { project: string }>(
    deleteProjectBuildingModels,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  useEffect(() => {
    setListItems(null);
  }, [filterValue, groupByValue, projectMilestones?.count]);

  useEffect(() => {
    if (!projectMilestones?.results || listItems?.length) return;

    setListItems(
      projectMilestones.results.map((item, index) => createTableObject({ item, index })),
    );
  }, [projectMilestones?.results, listItems]);

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: false,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    isExpanded,
    isNested,
    index,
    project_milestone: { ...item.project_milestone, index },
  });

  const { onExpandClick, updateListItemsWithParentGroup } = useExpandCollapseTable({
    setListItems,
    createTableObject,
  });

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);

  const hasAmountChanges = useMemo(
    () => checkHasAmountChanges(projectMilestones?.results),
    [projectMilestones?.results],
  );

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'nameV2',
      'costType',
      'originalConstructionBudget',
      'prefundingCost',
      'originalEstimate',
      ...(retainageRate ? ['retainageRateBudget'] : []),
      'revisedConstructionBudget',
      ...(hasAmountChanges ? ['adjustmentsTotalApprovedBudget'] : []),
      'revisedMilestoneAmount',
      'balanceToFinish',
      'balanceToFinishRate',
      PermissionNamesEnums.PROJECT__SOV__INSPECTOR_ALLOWANCE,
      PermissionNamesEnums.PROJECT__SOV__INSPECTOR_ALLOWANCE_RATE,
      'approvedAmountCumulative',
      'lenderAllowanceRate',
      PermissionNamesEnums.PROJECT__SOV__GAP,
      'documentsPhotosUploaderMenu',
      'documentsPhotosGalleryMenu',
      'comments',
    ],
    [retainageRate, hasAmountChanges],
  );

  const menuItems = useMemo(
    () =>
      isCreatedProject(project?.status) && !drawRequests?.length
        ? [
            {
              text: (
                <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                  Delete budget section
                </Typography>
              ),
              icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
              action: () => setIsDeleteBudgetPopupOpen(true),
              dataTestName: 'project__budget__delete__button',
            },
          ]
        : [],
    [project, drawRequests],
  );

  const removeAllModels = async () =>
    deleteAllModelsMutation.mutateAsync({
      project: projectId,
    });

  const removeAllMilestones = async () =>
    deleteBudgetMutation.mutateAsync({
      projectId,
    });

  const removeBudgetSection = ({ isVerticalSelected, isHorizontalSelected }) => {
    deleteBudgetMutation.mutateAsync({
      projectId,
      isVertical: isVerticalSelected,
      isHorizontal: isHorizontalSelected,
    });
  };

  const deleteBudget = async (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => {
    if (!selectedKeys?.length) {
      setIsDeleteBudgetPopupOpen(false);
      return;
    }

    const isVerticalSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.IS_VERTICAL);
    const isHorizontalSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.IS_HORIZONTAL);
    const isAllSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.ALL);

    if (isAllSelected) {
      await removeAllMilestones();
      await removeAllModels();
    } else if (isVerticalSelected && isHorizontalSelected) {
      removeAllMilestones();
    } else {
      removeBudgetSection({ isVerticalSelected, isHorizontalSelected });
    }
  };

  const totals = useMemo(
    () => ({ ...projectMilestonesTotalsQuery.data?.totals, displayAll: true }),
    [projectMilestonesTotalsQuery.data?.totals],
  );

  const patchProjectProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchProjectProdBuildGroupParam
  >(patchProjectProdBuildGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_PROJECT_MILESTONES,
            {
              projectId,
              query,
              groupBy: groupIds,
              filterKey,
            },
          ],
        },
        (old) =>
          replaceMilestoneData({
            milestones: old,
            milestoneId: data.id,
            json: data,
          }),
      );
      updateListItemsWithParentGroup(data);
    },
  });

  const patchMsGroup = useCallback(
    (params) =>
      patchProjectProdBuildGroupMutation.mutate({
        project: projectId,
        group_by: groupIds,
        tags: params.milestoneTags,
        json: params.json,
        filterKey,
      }),
    [projectId, groupIds, filterKey],
  );

  return {
    initColumns,
    milestones: listItems,
    onExpandClick,
    groupByOptions,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      showTemporaryLoadingSkeleton();
      handleFilterClick(value);
    },
    groupByValue,
    handleGroupByClick: (value: string) => {
      showTemporaryLoadingSkeleton();
      handleGroupByClick(value);
    },
    menuItems,
    isDeleteBudgetPopupOpen,
    setIsDeleteBudgetPopupOpen,
    deleteBudget,
    isDeleting: deleteBudgetMutation.isLoading,
    isLoading:
      showLoadingSkeleton ||
      projectMilestonesQuery.isLoading ||
      projectMilestonesQuery.isIdle ||
      projectMilestonesTotalsQuery.isLoading,
    totals,
    patchMsGroup,
  };
};
