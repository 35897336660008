import React from 'react';
import { useProjectsStats } from './controller';
import { PieChartItem, StyledBox } from '../index';
import { Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { IPieChartData, PermissionNamesEnums } from '@interfaces';
import { WithPermission } from '@utils';

const ProjectsStats = () => {
  const { statuses, scores, states, borrowers, largeSize, borrowerLabels } = useProjectsStats();

  return (
    <Stack direction="row" sx={{ width: '100%', flexWrap: 'wrap', gap: 1, mb: 2 }}>
      <Grid container spacing={1}>
        <PieChartWrapper data={statuses} label="Project status" largeSize={largeSize} />
        <WithPermission permissionKey={PermissionNamesEnums.RISK_RADAR__SCORES__VIEW}>
          <PieChartWrapper data={scores} label="Health score" largeSize={largeSize} />
          <PieChartWrapper
            data={borrowers}
            label="Borrower"
            largeSize={largeSize}
            customLabels={borrowerLabels}
          />
        </WithPermission>

        <PieChartWrapper data={states} label="State" largeSize={largeSize} />
      </Grid>
    </Stack>
  );
};

const PieChartWrapper = ({
  data,
  label,
  largeSize,
  customLabels,
}: {
  data: IPieChartData[];
  label: string;
  largeSize: 6 | 3;
  customLabels?: { color: string; label: string }[];
}) => {
  const xlMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  return (
    <Grid item xs={12} sm={6} lg={largeSize}>
      <StyledBox sx={{ flex: 1, minWidth: '340px' }}>
        {Boolean(data?.length) && (
          <>
            <Typography variant="h4" sx={{ mb: 1 }}>
              {label}
            </Typography>
            <PieChartItem
              data={data}
              legend="label"
              padding={{ ...(xlMediaQuery && { right: 100, bottom: 0 }) }}
              height={xlMediaQuery ? 65 : 110}
              legendPosition={{ vertical: 'top', horizontal: 'left' }}
              margin={{ right: xlMediaQuery ? 30 : 55, top: 0 }}
              customLabels={customLabels}
              showTooltip
            />
          </>
        )}
      </StyledBox>
    </Grid>
  );
};

export default ProjectsStats;
