import React, {
  Dispatch,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import find from 'lodash/find';
import map from 'lodash/map';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DateValidationEnum,
  DeleteInspectionDocument,
  HookState,
  IInspection,
  IInspectionTotal,
  IMilestone,
  InspectionActionEnum,
  InspectionStatusEnum,
  IPhoto,
  IProject,
  IProjectDocument,
  IRightDrawerParams,
  IRightMenu,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
  TransloaditTemplateEnum,
  UpdateInspectionPayload,
} from '@interfaces';
import { DateFieldModel, useDateFieldModel } from '@models';
import {
  deleteInspectionDocument,
  getInspectionDocuments,
  getInspectionMilestones,
  getProject,
  getProjectInspectionById,
  patchInspectionToProject,
} from '@globalService';
import {
  checkIsCustomerSuccess,
  getDateValidationRule,
  getHookState,
  getInspectionNameByRole,
  getTeamRole,
} from '@utils';
import {
  IFileUploader,
  ImagePicker,
  useCommentsPreview,
  useFilesUploader,
  useImagePicker,
  useLineItemsFilter,
  useRightMenu,
  useSafeSnackbar,
} from '@hooks';
import { AuthContext, useLaunchDarklyFlags } from '@context';
import { ConfirmationModal, SuccessModal } from '@components';
import { LineItemFilterValues, onlyProgressPhotos } from '@constants';

enum PopupTypeEnum {
  CONFIRMATION = 'confirmation',
  APPROVAL = 'approval',
  DELETE_SUCCEED = 'deleteSucceed',
}

export interface ControllerInterface {
  inspectionScheduledAt: DateFieldModel;
  project: IProject;
  state: HookState;
  inspectionRefetch: () => void;
  startUploading: () => void;
  confirmCallBack: () => void;
  inspection: IInspection;
  showPreview: boolean;
  imagePicker: ImagePicker;
  imageContainer: React.MutableRefObject<HTMLElement>;
  setShowPreview: Dispatch<React.SetStateAction<boolean>>;
  fileUploader: IFileUploader;
  openFile: () => void;
  initColumns: Array<string>;
  isEditable: boolean;
  goBack: () => void;
  inspectionPhotos: IPhoto[] | IProjectDocument[];
  milestones: IMilestone[];
  drawRequestNumber: string;
  inspectionName: string;
  rightMenu: IRightMenu;
  rightDrawerParams: IRightDrawerParams;
  updateRightDrawer: () => () => void;
  showDeleteButton: boolean;
  onDeleteInspection: () => void;
  selectedPopup: ReactElement;
  totals: IInspectionTotal;
  filterValue: string;
  handleFiltersChange: (filterValue: string) => void;
  filterOptions: string[];
  isMilestoneMutatingOrFetching: boolean;
  isInspectionLinkedToDR: boolean;
  activeDocumentId: string;
}

export const useInspectionEnterResult = (): ControllerInterface => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { inspectionId, action, projectId } = useParams();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const imagePicker = useImagePicker();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const inspectionScheduledAt = useDateFieldModel({
    initValue: null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS_OR_EQUAL,
        maxDate: new Date(),
      }),
  });
  const fileUploader = useFilesUploader();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [popup, setPopup] = useState<PopupTypeEnum>(null);
  const imageContainer = useRef();
  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.INSPECTION_RESULTS,
  });
  const flags = useLaunchDarklyFlags();

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId,
    inspectionId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const rightDrawerParams = useMemo(
    () => ({
      projectId,
      inspectionId,
    }),
    [projectId, inspectionId],
  );

  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const startUploading = () => {
    const fields = {
      inspection_id: inspectionId,
    };
    fileUploader.uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.INSPECTION_DOCUMENTS,
    });
  };

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
      queryFn: getProjectInspectionById.bind(this, { projectId, inspectionId }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
        { projectId, inspectionId, query: onlyProgressPhotos },
      ],
      queryFn: getInspectionDocuments.bind(this, {
        projectId,
        inspectionId,
        query: onlyProgressPhotos,
      }),
    },
  ]);

  const inspectionMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId, filterKey }],
    getInspectionMilestones.bind(this, { projectId, inspectionId, filterKey }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const inspection = useMemo(() => requestedDataQueries[0].data, [requestedDataQueries[0].data]);
  const inspectionPhotos = useMemo(
    () =>
      flags?.['ENG_8203_migrate_proofpoints_to_documents']
        ? requestedDataQueries[2].data?.results || []
        : inspection?.proofpoints,
    [requestedDataQueries[2].data?.results, inspection?.proofpoints, flags],
  );
  const project = useMemo(() => requestedDataQueries[1].data, [requestedDataQueries[1].data]);
  const inspectionMilestones = useMemo(
    () => inspectionMilestonesQuery.data?.results,
    [inspectionMilestonesQuery.data?.results],
  );

  useEffect(() => {
    inspection?.scheduled_at && inspectionScheduledAt.setValue(new Date(inspection.scheduled_at));
  }, [inspection]);

  const updateQueries = () => {
    const params = { projectId, drawRequestId: inspection?.draw_request?.id };
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, params]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      { projectId, inspectionId },
    ]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      { projectId, inspectionId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST, params]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, params]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
      { projectId, inspectionId, query: onlyProgressPhotos },
    ]);
  };

  const confirmInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        updateQueries();
        setPopup(PopupTypeEnum.APPROVAL);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
      },
    },
  );

  const deleteInspectionDocumentMutation = useMutation<Response, Error, DeleteInspectionDocument>(
    deleteInspectionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
          { projectId, inspectionId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
        setPopup(PopupTypeEnum.DELETE_SUCCEED);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const confirmCallBack = useCallback(async () => {
    if (inspectionScheduledAt.validate()) {
      await confirmInspection.mutateAsync({
        projectId,
        inspectionData: {
          inspectionId,
          status: InspectionStatusEnum.COMPLETED,
          scheduled_at: inspectionScheduledAt.value,
        },
      });
    }
  }, [projectId, inspectionId, inspectionScheduledAt]);

  // TODO: use reports instead of report while deleting ENG_7773_upload_several_inspection_reports
  const openFile = useCallback(() => {
    if (inspection?.report) {
      imagePicker.open([inspection.report]);
    }
  }, [inspection?.report]);

  // update report after uploading
  useEffect(() => {
    imagePicker.close();
    openFile();
  }, [inspection?.report]);

  const isEditable = useMemo(() => action === InspectionActionEnum.EDIT, [action]);
  const isInspectionLinkedToDR = useMemo(() => Boolean(inspection?.draw_request?.id), [inspection]);

  const initColumns = useMemo(() => {
    return [
      'name',
      ...(isEditable ? ['previousInspectorAllowanceRate'] : []),
      PermissionNamesEnums.PROJECT__DRAW_REQUEST__INSPECTOR_ALLOWANCE_RATE,
      'revisedEstimate',
      ...(isInspectionLinkedToDR ? ['requestedAmount'] : []),
      'inspectionPhotos',
      'comments',
    ];
  }, [isEditable, isInspectionLinkedToDR]);

  const goBack = () => navigate(state || `/projects/${projectId}/inspections/all/`);

  const inspectionName = getInspectionNameByRole({
    teamRole,
    inspectionAgency: inspection?.inspection_agency,
  });

  const showDeleteButton = useMemo(
    () => checkIsCustomerSuccess(teamRole) && Boolean(inspection?.report?.file),
    [inspection, teamRole],
  );

  const onDeleteInspection = () => {
    setPopup(PopupTypeEnum.CONFIRMATION);
  };

  const selectedPopup = useMemo(() => {
    switch (popup) {
      case PopupTypeEnum.CONFIRMATION:
        return (
          <ConfirmationModal
            open
            title="Are you sure?"
            text="The inspection report will be deleted"
            maxWidth="md"
            fullWidth
            onClose={() => setPopup(null)}
            confirmCallback={() =>
              deleteInspectionDocumentMutation.mutateAsync({
                projectId,
                inspectionId,
                documentId: inspection?.report?.id,
              })
            }
            source="inspections__result__delete_report"
          />
        );
      case PopupTypeEnum.APPROVAL:
        return (
          <SuccessModal
            text="Inspection has been completed."
            open
            onClose={() => {
              setPopup(null);
              goBack();
            }}
          />
        );
      case PopupTypeEnum.DELETE_SUCCEED:
        return (
          <SuccessModal
            text="Inspection report has been deleted."
            open
            onClose={() => {
              setPopup(null);
            }}
          />
        );
      default:
        return null;
    }
  }, [popup, inspectionId, projectId]);

  const totals = useMemo(() => {
    const key = find(defaultOptions, { filterValue })?.totalKey;
    return {
      previous_inspector_allowance_rate:
        inspection?.totals?.[key]?.previous_inspector_allowance_rate,
      inspector_allowance_rate: inspection?.totals?.[key]?.inspector_allowance_rate,
      requested_amount: inspection?.totals?.[key]?.requested_amount,
      revised_estimate: inspection?.totals?.[key]?.revised_estimate,
    };
  }, [inspection, filterValue]);

  return {
    state: getHookState(requestedDataQueries),
    inspectionScheduledAt,
    inspection,
    inspectionRefetch: updateQueries,
    project,
    fileUploader,
    startUploading,
    showPreview,
    setShowPreview,
    confirmCallBack,
    imagePicker,
    imageContainer,
    openFile,
    initColumns,
    isEditable,
    goBack,
    milestones: inspectionMilestones || [],
    drawRequestNumber: inspection?.draw_request?.number,
    inspectionPhotos,
    inspectionName,
    rightDrawerParams,
    rightMenu,
    updateRightDrawer,
    showDeleteButton,
    onDeleteInspection,
    selectedPopup,
    totals,
    filterValue,
    handleFiltersChange: handleFilterClick,
    filterOptions: map(defaultOptions, 'filterValue'),
    isMilestoneMutatingOrFetching,
    isInspectionLinkedToDR,
    activeDocumentId: imagePicker.pdf?.[0]?.id || imagePicker.gallery?.[0]?.id,
  };
};
