import React from 'react';
import { Stack, Tabs, Tab, Box } from '@mui/material';
import { Routes, Route, Link } from 'react-router-dom';

import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';
import { InspectionTab, DrawChangeRequestTab } from './components';
import * as Controller from './controller';

const RequestsQueue = () => {
  const pathBase = `/requests`;
  const flags = useLaunchDarklyFlags();

  const { TABS, activeTab, showInspectionsTab } = Controller.useRequestsQueue();

  return (
    <Stack sx={{ height: '100%' }}>
      {!flags?.['ENG_8320_current_inspection_as_service'] && (
        <Box sx={{ position: 'relative', pt: 1.5 }}>
          <Tabs
            value={activeTab}
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
          >
            <Tab
              label={TABS.REQUESTS.label}
              value={TABS.REQUESTS.value}
              component={Link}
              to={`${pathBase}/${TABS.REQUESTS.value}`}
              sx={{ ml: { md: 3, xs: 2 } }}
            />
            {showInspectionsTab && (
              <Tab
                label={TABS.INSPECTIONS.label}
                value={TABS.INSPECTIONS.value}
                component={Link}
                to={`${pathBase}/${TABS.INSPECTIONS.value}`}
              />
            )}
          </Tabs>
        </Box>
      )}
      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flexGrow: 1,
          p: { md: 3, xs: 2 },
        }}
      >
        {flags?.['ENG_8320_current_inspection_as_service'] ? (
          <DrawChangeRequestTab />
        ) : (
          <Routes>
            <Route path={TABS.REQUESTS.value} element={<DrawChangeRequestTab />} />
            <Route path={TABS.INSPECTIONS.value} element={<InspectionTab />} />
          </Routes>
        )}
      </Stack>
    </Stack>
  );
};

export default RequestsQueue;
