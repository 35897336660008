import { useGetData, useGetListData } from '@context';
import {
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  QueryNamesEnums,
} from '@interfaces';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';
import { patchDrawRequestListItem } from '@globalService';
import _ from 'lodash';

export const useApproveCredit = ({ requestId, milestoneId, setOpen }) => {
  const queryClient = useQueryClient();
  const [updateData, setUpdateData] = useState<Record<string, number>>({});
  const { projectId } = useParams();
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'id'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: ['credit_reason', 'id', 'name', 'approved_credit_amount', 'requested_credit_amount'],
    args: { projectId, drawRequestId: requestId, milestoneId },
    options: {
      skip: true,
    },
  });

  const milestonesForCredit = useGetListData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
    keys: [
      'requested_credit_amount',
      'credit_reason',
      'id',
      'name',
      'approved_credit_amount',
      'index',
    ],
    args: { projectId, drawRequestId: requestId },
    options: {
      skip: milestoneId,
      strictSerialize: (data) =>
        _.sortBy(
          data.filter((item) => item.requested_credit_amount),
          ['index'],
        ),
    },
  });

  const milestones = useMemo(
    () => (milestoneId ? (milestone.data ? [milestone.data] : []) : milestonesForCredit.serialized),
    [milestone, milestoneId, milestonesForCredit],
  );

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      if (milestoneId) {
        milestone.manualUpdate({
          approved_credit_amount: data.approved_credit_amount,
          name: data.name,
          id: data.id,
        });
      } else {
        const updater = [...(milestonesForCredit.data?.results || [])];
        const index = updater.findIndex((item) => item.id === data.id);
        if (index > -1) {
          updater[index].approved_credit_amount = data.approved_credit_amount;
          milestonesForCredit.manualUpdate({ results: updater });
        }
      }
    },
  });

  const submit = useCallback(async () => {
    if (Object.keys(updateData).length === 0) return;
    await Promise.all(
      Object.keys(updateData).map((key) => {
        if (key) {
          patchMilestoneRequestMutation.mutateAsync({
            project: projectId,
            drawRequest: requestId,
            milestone: key,
            json: {
              approved_credit_amount: +updateData[key],
            },
          });
        }
      }),
    );
    queryClient.invalidateQueries([]);
    setOpen(false);
  }, [requestId, projectId, milestoneId, updateData]);

  const isMutating = useIsMutating();

  useEffect(() => {
    if (milestoneId) milestone.refetch();
  }, [milestoneId]);

  return {
    name: project.data.name,
    submit,
    isSubmiting: false,
    isLoading: milestonesForCredit.isLoading,
    milestones,
    setUpdateData,
    isMutating,
  };
};
