import { useEffect, useState } from 'react';
import { useSafeSnackbar } from '@hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getUserNotifications, updateUserNotifications } from '@globalService';
import { HookState, INotifications, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';

interface ControllerInterface {
  notifications: INotifications;
  handleChange: (type: string) => void;
  state: HookState;
}

const defaultProps = {
  email_enabled: true,
  push_enabled: true,
};

export const useSettingsEdit = (): ControllerInterface => {
  const [notifications, setNotifications] = useState(defaultProps);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();

  const userNotificationsQuery = useQuery<INotifications, Error>(
    [QueryNamesEnums.GET_USER_NOTIFICATIONS],
    getUserNotifications.bind(this),
  );

  useEffect(() => {
    setNotifications(userNotificationsQuery?.data);
  }, [userNotificationsQuery?.data]);

  const handleChange = (type: string) => {
    const newNotifications = { ...notifications, [type]: !notifications[type] };
    updateNotificationsMutation.mutateAsync(newNotifications);
    setNotifications(newNotifications);
  };

  const updateNotificationsMutation = useMutation<INotifications, Error, INotifications>(
    updateUserNotifications,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_USER_NOTIFICATIONS);
      },
      onError: (error) => {
        userNotificationsQuery.refetch();
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  return {
    notifications,
    handleChange,
    state: getHookState(userNotificationsQuery),
  };
};
