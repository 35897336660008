import { useCallback, useState, useEffect, ReactElement, useMemo, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import { DropdownFieldModel, useDropdownFieldModel } from '@models';
import { getRolesPermissionsList, getCompanyTeamById, updateCompanyTeam } from '@globalService';
import {
  QueryNamesEnums,
  EnumTypeForList,
  ITeam,
  IRolePermissions,
  HookState,
  IPermission,
  UpdateTeamPayload,
  PermissionNamesEnums,
} from '@interfaces';
import { getHookState, isRestricted, getDisplayRolesList } from '@utils';
import {
  useLeavePageBlocker,
  useConfirmationModal,
  ConfirmModalHookInterface,
  useSafeSnackbar,
} from '@hooks';
import { PermissionsContext } from '@context';

interface ControllerInterface {
  state: HookState;
  team: ITeam;
  goBack: () => void;
  permissionRole: DropdownFieldModel;
  rolesList: EnumTypeForList[];
  shownPermissions: { [key: string]: IPermission[] };
  getLeavePageConfirmModal: () => ReactElement<string, string>;
  updatePermission: (permission: IPermission) => void;
  handleUpdateTeam: () => void;
  isUpdated: boolean;
  isSaving: boolean;
  updateGroupPermissions: (group: string, enabled: boolean) => void;
  hasCompanyTeamsEditPermission: boolean;
  saveChangedPermissionsModal: ConfirmModalHookInterface;
}

export const useTeamPermissions = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { companyId, teamId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const saveChangedPermissionsModal = useConfirmationModal();

  const [shownPermissions, setShownPermissions] = useState<IPermission[]>([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const { getLeavePageConfirmModal, setTriggerExit } = useLeavePageBlocker({
    currentPagePathname: '/company/*/teams/*/permissions',
    confirmTitle: 'Exit permission management',
    isUpdated,
  });
  const permissionRole = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value?.id),
    validateOnChange: true,
  });

  const teamQuery = useQuery<ITeam, Error>(
    [QueryNamesEnums.GET_COMPANY_TEAM_BY_ID, { companyId, teamId }],
    getCompanyTeamById.bind(this, companyId, teamId),
  );

  const rolesPermissionsListQuery = useQuery<IRolePermissions[], Error>(
    [QueryNamesEnums.GET_ROLES_WITH_PERMISSIONS],
    getRolesPermissionsList.bind(this),
  );

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (permissionRole.value) {
      setShownPermissions(
        find(rolesPermissionsListQuery.data, { id: permissionRole.value?.id })?.permissions || [],
      );
    } else if (teamQuery.data) setShownPermissions(cloneDeep(teamQuery.data.permissions));
  }, [permissionRole.value, teamQuery.data, rolesPermissionsListQuery.data]);

  useEffect(() => {
    // Check if there are any changes in permissionRole or shownPermissions
    const hasRoleChanged =
      permissionRole.value &&
      !isEqual(permissionRole.value?.id, teamQuery.data?.permission_role?.id);
    const havePermissionsChanged = !isEqual(shownPermissions, teamQuery.data?.permissions);

    if (hasRoleChanged || havePermissionsChanged) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
  }, [permissionRole.value, shownPermissions, teamQuery.data]);

  const updateTeamMutation = useMutation<Response, Error, UpdateTeamPayload>(updateCompanyTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_COMPANY_TEAM_BY_ID,
        { companyId, teamId },
      ]);
      setTriggerExit(() => ({
        path: `/company/${companyId}/teams`,
        isNavigationConfirmed: true,
      }));
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
  const handleUpdateTeam = useCallback(() => {
    updateTeamMutation.mutateAsync({
      companyId,
      teamId,
      data: {
        permission_role: permissionRole.value?.id || teamQuery.data?.permission_role?.id,
        permissions: shownPermissions,
      },
    });
  }, [companyId, teamId, permissionRole, teamQuery.data, shownPermissions]);

  const groupPermissionsByGroup = (permissions) => {
    const groupedPermissions = {};

    permissions.forEach((permission) => {
      if (!permission.group) return;
      const group = permission.group;

      // If the group doesn't exist in the groupedPermissions object, create it as an empty array
      if (!groupedPermissions[group]) {
        groupedPermissions[group] = [];
      }

      // Push the permission into the corresponding group
      groupedPermissions[group].push(permission);
    });

    return groupedPermissions;
  };

  // update single permission
  const updatePermission = (permission: IPermission) => {
    setShownPermissions((prev) => {
      const newPermissions = [...prev];
      const index = newPermissions.findIndex((o) => o.name === permission.name);
      if (index !== -1) {
        newPermissions[index].enabled = !newPermissions[index].enabled;
      }
      return newPermissions;
    });
  };

  // update all permissions in a group
  const updateGroupPermissions = (group, enabled) => {
    const updatedPermissions = [...shownPermissions].map((permission) => {
      if (permission.group === group) {
        return { ...permission, enabled };
      }
      return permission;
    });

    setShownPermissions(updatedPermissions);
  };

  const rolesList = useMemo(() => {
    return getDisplayRolesList(rolesPermissionsListQuery.data);
  }, [rolesPermissionsListQuery.data]);

  const hasCompanyTeamsEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMPANY__TEAMS__EDIT, permissions),
    [permissions],
  );

  return {
    state: getHookState([teamQuery, rolesPermissionsListQuery]),
    team: teamQuery.data,
    goBack,
    permissionRole,
    rolesList,
    shownPermissions: groupPermissionsByGroup(shownPermissions),
    getLeavePageConfirmModal,
    updatePermission,
    handleUpdateTeam,
    isUpdated,
    isSaving: updateTeamMutation.isLoading,
    updateGroupPermissions,
    hasCompanyTeamsEditPermission,
    saveChangedPermissionsModal,
  };
};
