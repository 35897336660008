export const projectsPathList = (url) => (url ? `projects/?${url}` : `projects/?limit=9999`);
export const project = (projectId = 'project_pk', query?: string) =>
  `projects/${projectId}${query ? `/?query=${query}` : '/'}`;
export const projectFunds = (projectId = 'project_pk') => `projects/${projectId}/funds/`;

export const projectMilestones = (projectId = 'project_pk') => `projects/${projectId}/milestones/`;

export const projectBuildingModels = (projectId = 'project_pk') =>
  `projects/${projectId}/building_models/`;

export const projectMilestoneTags = (projectId = 'project_pk') =>
  `projects/${projectId}/milestone_tag_categories/`;

export const projectMilestoneTagById = (
  projectId = 'project_pk',
  milestoneTagId = 'milestone_tag_pk',
) => `projects/${projectId}/milestone_tag_categories/${milestoneTagId}/`;

export const projectBuildingModelsDelete = (projectId = 'project_pk') =>
  `projects/${projectId}/building_models/delete/`;

export const projectBuildingModelById = (projectId = 'project_pk', modelId = 'modelId') =>
  `projects/${projectId}/building_models/${modelId}/`;

export const projectBuildingById = (projectId = 'project_pk', buildingId = 'buildingId') =>
  `projects/${projectId}/buildings/${buildingId}/`;

export const projectMilestonesDelete = (projectId = 'project_pk', queryFiltersParams = '') =>
  `projects/${projectId}/milestones/delete/?${queryFiltersParams}`;

export const projectMilestoneColumns = (projectId = 'project_pk') =>
  `projects/${projectId}/milestones/columns/`;

export const projectProgress = (projectId = 'project_pk') => `projects/${projectId}/progress/`;

export const projectBuildings = (projectId = 'project_pk') =>
  `projects/${projectId}/buildings/?limit=9999`;

export const projectScoresNew = (projectId = 'project_pk') => `projects/${projectId}/scores_new/`;
export const projectSettings = (projectId = 'project_pk') => `projects/${projectId}/ui_settings/`;
export const projectDocumentTypes = () => `documents/project/document_types/`;
export const projectDocumentTypeByName = (documentType = 'document_type_name') =>
  `documents/project/document_types/${documentType}/`;

export const projectMilestonesList = ({
  projectId = 'project_pk',
  queryFiltersParams = '',
  filterParams = '',
}) => `projects/${projectId}/milestones/?${filterParams}${queryFiltersParams}`;

export const projectUserData = (projectId = 'project_pk') => `projects/${projectId}/me/`;
export const projectTypes = (query?: string) => `projects/types/?limit=9999&${query}`;

export const projectTypesWithQuery = ({
  restQlParams,
  query,
}: {
  restQlParams: string;
  query: string;
}) => `projects/types/?limit=9999&${query}${restQlParams ? `&query=${restQlParams}` : ''}`;

export const projectPropertyTypes = (query?: string) => `buildings/types/?limit=9999${query}`;

export const projectPropertyTypesWithQuery = ({
  restQlParams,
  query,
}: {
  restQlParams: string;
  query: string;
}) => `buildings/types/?limit=9999${restQlParams ? `&query=${restQlParams}` : ''}${query}`;

export const projectPropertyDetailsNames = () => `buildings/detail_names/`;
export const projectExitStrategies = () => `projects/exit_strategies/`;
export const projectTeams = (projectId = 'project_pk', companyId = '') =>
  `projects/${projectId}/teams/?${companyId ? `company=${companyId}` : ''}`;

export const projectTeamsWithQuery = (projectId = 'project_pk', query = '') =>
  `projects/${projectId}/teams/${query && `?${query}`}`;

export const milestoneById = (project: string, milestone: string, query = '') =>
  `projects/${project}/milestones/${milestone}/${query && `?query=${query}`}`;

export const companiesByRole = ({ role }: { role: string }) =>
  `projects/companies/?role=${role}&limit=999&sorting=name`;

export const projectsCompanies = ({ restQlParams, role }: { restQlParams: string; role: string }) =>
  `projects/companies/?limit=999&sorting=name${role ? `&role=${role}` : ''}${
    restQlParams ? `&query=${restQlParams}` : ''
  }`;

export const projectsTeamsWithQuery = ({ restQlParams }: { restQlParams: string }) =>
  `projects/teams/?limit=999&is_customer=true&sorting=name&exclude_approval_level=0${
    restQlParams ? `&query=${restQlParams}` : ''
  }`;

export const projectPermissions = (projectId = 'project_pk') =>
  `projects/${projectId}/permissions/`;

export const projectPhotos = (projectId = 'project_pk', query = '') =>
  `projects/${projectId}/photos/${query && `?${query}`}`;

export const projectsStates = () => 'projects/portfolio/states/';

export const projectsStatesWithQuery = ({ restQlParams }: { restQlParams: string }) =>
  `projects/portfolio/states/${restQlParams ? `?query=${restQlParams}` : ''}`;

export const projectsTeams = () =>
  'projects/teams/?limit=100&is_customer=true&sorting=name&exclude_approval_level=0';

export const projectCoordinators = () =>
  'projects/users/?is_draw_request_coordinator=true&sorting=first_name';

export const projectCoordinatorsWithQuery = ({ restQlParams }: { restQlParams: string }) =>
  `projects/users/?is_draw_request_coordinator=true&sorting=first_name${
    restQlParams ? `&query=${restQlParams}` : ''
  }`;

export const projectsUsers = (stringQueryParams) => `projects/users/${stringQueryParams || ''}`;

export const projectsStats = (stringQueryParams) =>
  `projects/portfolio/stats/?${stringQueryParams}`;

export const projects = () => `projects/`;

export const projectGroupStats = (stringQueryParams) =>
  `projects/stats/${stringQueryParams && `?q=${stringQueryParams}`}`;

export const workflowReasons = () => 'projects/workflow_reasons/';

export const projectThumb = (projectId = 'project_pk') => `projects/${projectId}/thumb/`;

export const projectMilestoneDocuments = (project: string, milestone: string, query: string) =>
  `projects/${project}/milestones/${milestone}/documents/${query}`;

export const projectMilestoneGroupDocuments = (project: string, milestone: string) =>
  `projects/${project}/milestone_groups/${milestone}/documents/`;

export const inviteProjectBorrowersPath = (projectId = 'project_pk') =>
  `projects/${projectId}/invite/`;

export const projectMilestonesBulk = ({ project, group_by, tags, filterParams = '' }) =>
  `projects/${project}/milestones/bulk/?group_by=${group_by}&tags=${tags}&${filterParams}`;
