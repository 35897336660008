import React, { FC, useState } from 'react';
import { Tooltip, ButtonProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ComponentWithTooltipProps } from './interface';

const ButtonWithTooltipOnClick: FC<ComponentWithTooltipProps & ButtonProps> = ({
  disabled = false, // truly disabled
  tooltipText,
  loading,
  dataTestName,
  onClick,
  conditionallyDisabled = false, // conditionally disabled with tooltip
  ...props
}) => {
  const [open, setOpen] = useState(false); // State to control tooltip visibility

  const handleClick = () => {
    if (conditionallyDisabled) {
      setOpen(true); // Show tooltip on click if button is conditionally disabled
      setTimeout(() => setOpen(false), 5000); // Hide tooltip after 5 seconds
    } else {
      onClick();
    }
  };

  return (
    <Tooltip
      title={tooltipText}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <span>
        {/* Wrap LoadingButton in a span to allow Tooltip work with disabled button */}
        <LoadingButton
          {...props}
          data-cy={dataTestName}
          onClick={handleClick}
          onMouseLeave={() => setOpen(false)}
          disabled={disabled} // Truly disabled state
          loading={loading}
        >
          {props.children}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

export default ButtonWithTooltipOnClick;
