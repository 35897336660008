import React, { FC } from 'react';
import { Button, MenuItem, Select, Stack, Typography } from '@mui/material';

import { CustomTextField, Popup, WysiwygEditor } from '@components';
import * as Controller from './controller';
import { ComponentProps } from './interface';
import { ChecklistIcon, EditIcon } from '@svgAsComponents';
import { isAttestationPolicyItem, isSubmissionPolicySource } from '@utils';
import { colors } from '@theme';

const EditPolicyItem: FC<ComponentProps> = ({ source, onClose, policyItem, saveItem, items }) => {
  const {
    itemName,
    description,
    handleSaveItem,
    policyItemTypesList,
    policyItemType,
    handlePolicyItemTypeChange,
  } = Controller.useEditPolicyItem({
    onClose,
    policyItem,
    saveItem,
    source,
    items,
  });

  return (
    <Popup
      open={Boolean(policyItem)}
      maxWidth="sm"
      title={`${policyItem?.label ? 'Edit' : 'Add'} requirement item`}
      icon={policyItem?.label ? EditIcon : ChecklistIcon}
    >
      <Stack sx={{ width: '100%', justifyContent: 'space-between', flex: 1 }}>
        <Stack spacing={2}>
          {isSubmissionPolicySource(source) && (
            <Stack>
              <Typography variant="label" sx={{ ml: 1, mb: 0.5 }}>
                Requirement type
              </Typography>
              <Select
                id="requirement-type-select"
                value={policyItemType}
                size="small"
                label="Requirement type"
                onChange={handlePolicyItemTypeChange}
                data-cy="policy__items__edit_item__item_type__select"
                disabled={Boolean(policyItem?.id && policyItem?.label)}
              >
                {policyItemTypesList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          )}
          <CustomTextField
            field={itemName}
            label="Requirement item name"
            placeholder="Write item name"
            inputProps={{
              'data-cy': 'policy__items__edit_item__item_name__input',
            }}
            required
            validationText={'Requirement item name must be unique'}
          />
          <Stack>
            <Typography variant="label" sx={{ ml: 1 }}>
              {isAttestationPolicyItem(policyItemType) ? 'Attestation text' : 'Description text'}
            </Typography>
            <WysiwygEditor
              editField={description}
              placeholder="Write item description"
              source="policy__items__edit_item__description__input"
            />
          </Stack>
          {isAttestationPolicyItem(policyItemType) && !description.isValid && (
            <Typography variant="label" sx={{ ml: 1 }} color={colors.status.error.medium}>
              Attestation text cannot be empty
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 4 }}
          spacing={2}
        >
          <Button
            variant="text"
            onClick={onClose}
            data-cy="policy__items__edit_item__cancel__button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveItem}
            disabled={!itemName.isValid}
            data-cy="policy__items__edit_item__add__button"
          >
            {policyItem?.label ? 'Save' : 'Add'}
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default EditPolicyItem;
