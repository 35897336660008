import React, { FC, useMemo } from 'react';
import { useMilestoneListPatch } from './controller';
import { MilestoneListWithPatchInterface } from './interface';
import { MilestoneList, MilestoneListV2 } from '@components';
import { useLocation } from 'react-router-dom';

const MilestoneListWithPatch: FC<MilestoneListWithPatchInterface> = ({
  requestId,
  refetch,
  containerRef,
  ...props
}) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { patchMilestone, resetMutation, deleteMilestone, updateListData } = useMilestoneListPatch({
    requestId,
    refetch,
  });

  return query.get('useV3') ? (
    <MilestoneListV2
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      patchMilestone={patchMilestone}
      resetMutation={resetMutation}
      updateData={updateListData}
      containerRef={containerRef}
      {...props}
    />
  ) : (
    <MilestoneList
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      patchMilestone={patchMilestone}
      resetMutation={resetMutation}
      updateData={updateListData}
      containerRef={containerRef}
      {...props}
    />
  );
};

export default MilestoneListWithPatch;
