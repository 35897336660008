import React from 'react';
import { Stack, Typography } from '@mui/material';

import { colors } from '@theme';
import { ComponentProps } from './interface';

const StatWidget = ({ label, mainText, secondaryText }: ComponentProps) => {
  return (
    <Stack
      sx={{
        borderRadius: 1,
        height: 94,
        padding: 2,
        backgroundColor: colors.background.white,
      }}
      justifyContent="center"
    >
      <Typography color={colors.text.medium} variant="body2SemiBold">
        {label}
      </Typography>
      <Stack direction="row" flexWrap="nowrap" alignItems="baseline">
        <Typography color={colors.status.information.medium} variant="h2" mr={1}>
          {mainText}
        </Typography>
        <Typography color={colors.text.medium} variant="label" noWrap>
          {secondaryText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StatWidget;
