import { TEAM_ROLES, isMobileDevice } from 'encirclelabs-shared';

const commonScreens = [
  '/profile',
  '/requests',
  '/projects',
  '/projects/:projectId',
  '/company',
  '/home',
];

const defaultSceenACL = {
  available: ['/profile', '/projects', '/projects/:projectId', '/company'],
  default: '/projects',
};
// map screens to team role
export const screensACL = isMobileDevice
  ? {
      [TEAM_ROLES.Lender]: defaultSceenACL,
      [TEAM_ROLES.Investor]: defaultSceenACL,
      [TEAM_ROLES.Owner]: defaultSceenACL,
      [TEAM_ROLES.GeneralContractor]: defaultSceenACL,
    }
  : {
      [TEAM_ROLES.Lender]: {
        available: [...commonScreens, '/risk-radar', '/analytics', '/services'],
        default: '/requests',
      },
      [TEAM_ROLES.Investor]: {
        available: [...commonScreens, '/risk-radar', '/analytics', '/services'],
        default: '/requests',
      },
      [TEAM_ROLES.Owner]: {
        available: [...commonScreens, '/risk-radar', '/analytics'],
        default: '/projects',
      },
      [TEAM_ROLES.GeneralContractor]: {
        available: commonScreens,
        default: '/projects',
      },
    };
