import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useGetData } from '@context';
import { IMilestone, QueryNamesEnums } from '@interfaces';

export const useInfoBreakdown = ({ requestId, milestoneId }) => {
  const { projectId } = useParams();

  const keys: (keyof IMilestone)[] = [
    'construction_holdback',
    'borrower_equity',
    'additional_borrower_equity',
    'requested_additional_borrower_equity',
    'revised_estimate',
    'cost_type',
    'project_milestone',
  ];

  const drLineItem = useGetData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys,
    args: { projectId, drawRequestId: requestId, milestoneId },
    options: {
      skip: !projectId || !requestId || !milestoneId,
    },
  });

  const projectLineItem = useGetData({
    type: QueryNamesEnums.GET_PROJECT_MILESTONE,
    keys,
    args: { projectId, milestoneId },
    options: {
      skip: !projectId || Boolean(requestId) || !milestoneId,
    },
  });

  const lineItem = useMemo(
    () => (requestId ? drLineItem.data : projectLineItem.data),
    [requestId, drLineItem.data, projectLineItem.data],
  );

  return {
    milestone: lineItem,
  };
};
