import { useMutation, useQueryClient } from 'react-query';
import { updateUiSettings } from '@globalService';
import { IUISettings, QueryNamesEnums } from '@interfaces';

export const useUpdateUiSettings = () => {
  const queryClient = useQueryClient();

  const updateSettingsMutation = useMutation<IUISettings, Error, IUISettings>(updateUiSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_UI_SETTINGS);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const updateSettings = async (settings) => await updateSettingsMutation.mutateAsync(settings);

  return { updateSettings, isColumnFilterUpdating: updateSettingsMutation.isLoading };
};
