import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import { useLaunchDarklyFlags } from '@context';
import { useStringFieldModel } from '@models';
import {
  DeleteDrawRequestListItem,
  ErrorDual,
  IDrawRequest,
  IMilestone,
  LineItemModalTypeEnums,
  LineItemPostPayload,
  LineItemPostResponse,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  QueryNamesEnums,
} from '@interfaces';
import { costTypeMap } from '@constants';
import { ControllerInterface, LineItemEditControllerProps } from './interface';
import { getBasicUrl, getInitFromMilestone, isRequestDraft, parsePathErrorDual } from '@utils';
import {
  deleteRequestMilestoneItem,
  getDrawRequest,
  patchDrawRequestListItem,
  postComment,
  postItemToRequest,
} from '@globalService';

export const useLineItemEdit = ({
  lineItem,
  setLineItemModal,
  requestId,
}: LineItemEditControllerProps): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: requestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId: requestId }),
    {
      enabled: Boolean(projectId && requestId),
    },
  );

  const isDrawRequestDraft = useMemo(
    () => isRequestDraft(drawRequestData.data?.status),
    [drawRequestData.data?.status],
  );

  const costTypeOptions = [
    { value: 'HARD', label: costTypeMap.HARD },
    { value: 'SOFT', label: costTypeMap.SOFT },
  ];

  const itemName = useStringFieldModel({
    initValue: lineItem?.name || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const comment = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
  });

  const requestedScheduledValue = useStringFieldModel({
    initValue: lineItem?.requested_revised_estimate?.toString() || '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  });

  const additionalBorrowerEquity = useStringFieldModel({
    initValue: lineItem?.additional_borrower_equity?.toString() || '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  });

  useEffect(() => {
    if (isDrawRequestDraft) {
      additionalBorrowerEquity.setValue(
        lineItem?.requested_additional_borrower_equity?.toString() || '',
      );
    }
  }, [isDrawRequestDraft, lineItem?.requested_additional_borrower_equity]);

  const [costType, setCostType] = useState(getInitFromMilestone(lineItem?.cost_type) || 'HARD');

  const onClose = () => {
    setLineItemModal({ open: false, type: LineItemModalTypeEnums.ADD, lineItem: null });
  };

  const handleCostTypeChange = (event) => {
    setCostType(event.target.value);
  };

  const postCommentMutation = useMutation<
    Response,
    Error,
    { url: string; value: { message: string; tags?: string[] } }
  >(postComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMMENTS);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addLineItemMutation = useMutation<LineItemPostResponse, Error, LineItemPostPayload>(
    postItemToRequest,
    {
      onSuccess: async (data, variables) => {
        if (variables?.comment && data?.id) {
          const url = getBasicUrl({
            requestType: 'post',
            projectId,
            requestId,
            milestoneId: data?.id,
          });

          await postCommentMutation.mutateAsync({
            url,
            value: { message: variables.comment },
          });
        }
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
          { projectId, requestId },
        ]);
        setLineItemModal({ open: false, type: LineItemModalTypeEnums.ADD, lineItem: null });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const patchMilestoneMutation = useMutation<IMilestone, ErrorDual, PatchDrawRequestListItemParam>(
    patchDrawRequestListItem,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onSuccess: () => {
        setLineItemModal({ open: false, type: LineItemModalTypeEnums.ADD, lineItem: null });
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_MILESTONES);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          { projectId, drawRequestId: requestId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSubmitClick = useCallback(() => {
    const isNameValid = itemName.validate();
    const isRequestedValueValid = requestedScheduledValue.validate();

    if (!isNameValid) return;

    if (lineItem?.id) {
      return patchMilestoneMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        milestone: lineItem?.id,
        json: {
          name: itemName.value,
          cost_type: costType,
        },
      });
    }

    if (!isRequestedValueValid) return;

    addLineItemMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      name: itemName.value,
      cost_type: costType,
      ...(isDrawRequestDraft
        ? {
            requested_revised_estimate: +requestedScheduledValue.value,
            ...(flags?.['ENG_7938_breakdown_of_line_item'] && {
              requested_additional_borrower_equity: +additionalBorrowerEquity.value,
            }),
          }
        : {
            approved_adjustments: +requestedScheduledValue.value,
            ...(flags?.['ENG_7938_breakdown_of_line_item'] && {
              requested_additional_borrower_equity: +additionalBorrowerEquity.value,
              additional_borrower_equity: +additionalBorrowerEquity.value,
            }),
          }),
      ...(comment.value && { comment: comment.value }),
    });
  }, [
    itemName.value,
    comment.value,
    requestedScheduledValue.value,
    additionalBorrowerEquity.value,
    costType,
    lineItem,
    isDrawRequestDraft,
    flags,
  ]);

  const deleteMilestoneMutation = useMutation<Response, ErrorDual, DeleteDrawRequestListItem>(
    deleteRequestMilestoneItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          { projectId, drawRequestId: requestId },
        ]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_MILESTONES);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  const handleDeleteClick = useCallback(() => {
    deleteMilestoneMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      milestone: lineItem?.id,
    });
    setLineItemModal({ open: false, type: LineItemModalTypeEnums.ADD, lineItem: null });
  }, [deleteMilestoneMutation, lineItem?.id, projectId]);

  return {
    itemName,
    comment,
    requestedScheduledValue,
    additionalBorrowerEquity,
    costType,
    costTypeOptions,
    onClose,
    handleCostTypeChange,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting: lineItem?.id ? patchMilestoneMutation.isLoading : addLineItemMutation.isLoading,
    isDeleting: deleteMilestoneMutation.isLoading,
    isDrawRequestDraft,
  };
};
