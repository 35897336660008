import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  IProjectStats,
  PermissionNamesEnums,
  QueryNamesEnums,
  ITablePagination,
} from '@interfaces';
import { getProjectGroupsStats } from '@globalService';
import { useTablePagination, useUrlParams } from '@hooks';
import { projectListFields } from '@constants';
import { useDebouncedEffect } from '@models';
import { useLocation } from 'react-router-dom';
import { AuthContext, PermissionsContext } from '@context';
import { getTeamRole, isRestricted } from '@utils';

export interface ControllerInterface {
  handleTabChange: (value: string) => void;
  tab: string;
  SwitcherTabs: { label: string; value: string; isActive: boolean }[];
  handleSearchSubmit: (value: string) => void;
  clearSearch: () => void;
  search: string;
  activeTabProjectsCount: number;
  tablePagination: ITablePagination;
  url: string;
  noProjectsText: string;
}

export const TABS = {
  ACTIVE: { label: 'Active', value: 'active', status: 'ACTIVE' },
  INACTIVE: { label: 'Inactive', value: 'inactive', status: 'CREATED' },
  ARCHIVED: {
    label: 'Archived',
    value: 'archived',
    status: 'INACTIVE_COMPLETE,INACTIVE_INCOMPLETE',
  },
  PAUSED: {
    label: 'Paused',
    value: 'paused',
    status: 'PAUSED',
  },
};

export const useProjects = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);

  const tablePagination = useTablePagination();
  const [tab, setTab] = useUrlParams(
    TABS.ACTIVE.value,
    'status',
    (s) => s.toString(),
    (s) => s.toString(),
  );

  const { search: existingSearch } = useLocation();
  const queryParams = new URLSearchParams(existingSearch);
  const existingQuerySearch = queryParams.get('query');
  const [querySearch, setQuerySearch] = useState(existingQuerySearch || '');
  const [search, setSearch] = useUrlParams(
    '',
    'query',
    (s) => s.toString(),
    (s) => s.toString(),
  );

  useDebouncedEffect(() => setSearch(querySearch), [querySearch], 500);

  const clearSearch = () => Boolean(search) && setQuerySearch('');
  const handleTabChange = (value: string) => setTab(value);

  const projectsStatsQuery = useQuery<IProjectStats, Error>(
    [QueryNamesEnums.GET_PROJECTS_STATS, { search }],
    getProjectGroupsStats.bind(this, search),
  );

  const SwitcherTabs = useMemo(
    () => [
      {
        label: `${TABS.ACTIVE.label} (${projectsStatsQuery.data?.statuses?.ACTIVE || 0})`,
        value: TABS.ACTIVE.value,
        isActive: tab === TABS.ACTIVE.value,
        projectCount: projectsStatsQuery.data?.statuses?.ACTIVE || 0,
      },
      ...(!isRestricted(PermissionNamesEnums.PROJECT__CREATE__BUTTON, permissions) ||
      !isRestricted(PermissionNamesEnums.PROJECT__BUDGET__EDIT, permissions)
        ? [
            {
              label: `${TABS.INACTIVE.label} (${projectsStatsQuery.data?.statuses?.CREATED || 0})`,
              value: TABS.INACTIVE.value,
              isActive: tab === TABS.INACTIVE.value,
              projectCount: projectsStatsQuery.data?.statuses?.CREATED || 0,
            },
          ]
        : []),
      {
        label: `${TABS.PAUSED.label} (${projectsStatsQuery.data?.statuses?.PAUSED || 0})`,
        value: TABS.PAUSED.value,
        isActive: tab === TABS.PAUSED.value,
        projectCount: projectsStatsQuery.data?.statuses?.PAUSED || 0,
      },
      {
        label: `${TABS.ARCHIVED.label} (${projectsStatsQuery.data?.statuses?.INACTIVE || 0})`,
        value: TABS.ARCHIVED.value,
        isActive: tab === TABS.ARCHIVED.value,
        projectCount: projectsStatsQuery.data?.statuses?.INACTIVE || 0,
      },
    ],
    [tab, projectsStatsQuery.data?.statuses, permissions, teamRole],
  );

  const activeTabProjectsCount = useMemo(
    () => SwitcherTabs.find((tab) => tab?.isActive)?.projectCount || 0,
    [SwitcherTabs],
  );

  const url = useMemo(() => {
    const paginationUrl = `pagination=true&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}`;
    const searchUrl = search ? `&q=${search}` : '';
    const status = `&status=${TABS[tab?.toUpperCase()]?.status}`;
    const requiredFieldsUrl = `&query=${projectListFields}`;

    return `${paginationUrl}${requiredFieldsUrl}${searchUrl}${status}`;
  }, [tablePagination.page, tablePagination.rowsPerPage, search, tab]);

  const noProjectsText = useMemo(
    () => (search !== '' ? 'Nothing was found' : `No ${TABS[tab?.toUpperCase()]?.value} projects`),
    [search, tab],
  );

  useEffect(() => {
    tablePagination.setPage(0);
  }, [tab, search]);

  return {
    handleTabChange,
    tab,
    SwitcherTabs,
    handleSearchSubmit: setQuerySearch,
    clearSearch,
    search: querySearch,
    activeTabProjectsCount,
    tablePagination,
    url,
    noProjectsText,
  };
};
