import React, { FC, useMemo } from 'react';
import { Button, OutlinedTextFieldProps, Stack, TextField } from '@mui/material';
import { InputAttributes, NumericFormatProps } from 'react-number-format';
import { CustomDatePickerInput, PercentsInput, Popup } from '@components';
import { useDateFieldModel, useStringFieldModel } from '@models';
import isInteger from 'lodash/isInteger';
import isNumber from 'lodash/isNumber';
import { getDateValidationRule, getRoundNumber, isPercentAllowed } from '@utils';
import { DateValidationEnum } from '@interfaces';
import { SummaryEditableDataEnum } from '../RequestSummary/controller';

interface TextInputElementProp extends OutlinedTextFieldProps {
  inputProps: NumericFormatProps<InputAttributes> & { 'data-cy': string };
  isRateChange: boolean;
}

const TextInputElement: FC<TextInputElementProp> = ({ inputProps, isRateChange, ...props }) => {
  return (
    <TextField
      InputProps={{
        ...(isRateChange ? { inputComponent: PercentsInput as any } : {}),
        inputProps,
      }}
      {...props}
    />
  );
};

const ChangeDRDataPopup: FC<{
  onClose: () => void;
  onSave: (value) => void;
  initData?: Date | number | string;
  title: string;
  type: SummaryEditableDataEnum;
}> = ({ onClose, onSave, initData, title, type }) => {
  const isNumberChange = useMemo(() => type === SummaryEditableDataEnum.NUMBER, [type]);
  const isRateChange = useMemo(() => type === SummaryEditableDataEnum.RATE, [type]);
  const isDisbursementDate = useMemo(
    () => type === SummaryEditableDataEnum.ESTIMATED_DISBURSEMENT_DATE,
    [type],
  );

  const date = useDateFieldModel({
    initValue: initData ? new Date(initData) : new Date(),
    validationRule: (value) =>
      getDateValidationRule(
        isDisbursementDate
          ? { value, rule: DateValidationEnum.MORE_OR_EQUAL, minDate: new Date() }
          : {
              value,
              rule: DateValidationEnum.LESS_OR_EQUAL,
              maxDate: new Date(),
            },
      ),
  });
  const number = useStringFieldModel(
    isRateChange
      ? {
          initValue: getRoundNumber(+initData, 2).toString() || '0',
          validationRule: (value) => isNumber(+value) && +value >= 0 && +value <= 100,
          validateOnChange: true,
        }
      : {
          initValue: (initData as string) || '1',
          validationRule: (value) => isInteger(+value),
          validateOnChange: true,
        },
  );
  const isNumberOrRateChange = isNumberChange || isRateChange;
  return (
    <Popup open maxWidth="md" title={title}>
      <Stack sx={{ width: '100%' }}>
        {isNumberOrRateChange ? (
          <TextInputElement
            size="small"
            fullWidth
            variant="outlined"
            value={number.value}
            onChange={number.handleChange}
            inputProps={
              isRateChange
                ? {
                    isAllowed: isPercentAllowed,
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    'data-cy': `draw_change_${type}_input`,
                  }
                : { 'data-cy': `draw_change_${type}_input` }
            }
            isRateChange={isRateChange}
          />
        ) : (
          <CustomDatePickerInput
            maxDate={isDisbursementDate ? undefined : new Date()}
            minDate={isDisbursementDate ? new Date() : undefined}
            field={date}
            label={title}
            inputProps={{
              'data-cy': `draw_change_${type}_date_picker`,
            }}
          />
        )}
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 1 }}
            disabled={(isNumberOrRateChange && !number.isValid) || !date.isValid}
            onClick={() => onSave(isNumberOrRateChange ? +number.value : date.value)}
            data-cy={`draw_change_${type}_save_button`}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ChangeDRDataPopup;
