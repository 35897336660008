import { useUpdateUiSettings, useUrlParams } from '@hooks';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ComponentProps, DEFAULT_SET_TO_SETTINGS, DEFAULT_SET_TO_URL } from './interface';
import * as FiltersItems from './filters';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import { isRestricted, toggleElementFromArray } from '@utils';
import { useDebouncedEffect } from '@models';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';
import { IMenuItem } from '@interfaces';

const serializeFilters = (filters: Record<string, Array<string>>): string => {
  const params = new URLSearchParams();

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (filters[key].length) params.set(key, filters[key].join(','));
    }
  }

  return params.toString();
};

const deserializeFilters = (queryString: string): Record<string, Array<string>> => {
  const params = new URLSearchParams(queryString);
  const filters: Record<string, Array<string>> = {};

  params.forEach((value, key) => {
    filters[key] = value.split(',');
  });

  return filters;
};

export const useFiltersV2 = ({
  filters,
  setFilterStringQuery,
  tableKey,
  onPageChange,
}: ComponentProps) => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { updateSettings } = useUpdateUiSettings();
  const { settings } = useContext(SettingsContext);
  const filterTabKey = useMemo(() => `filter-${tableKey}`, [tableKey]);
  const filterTabKeyVisibility = useMemo(() => `filter-visibility-${tableKey}`, [tableKey]);

  const [hiddenFilters, setHiddenFilters] = useState(
    settings?.personal_setting?.[filterTabKeyVisibility] || [...filters].slice(4),
  );

  const availableFilters = useMemo(
    () =>
      filters.filter((item) => {
        const filterConfig = FiltersItems[item];
        if (
          !filterConfig ||
          (filterConfig.permissionKey && isRestricted(filterConfig.permissionKey, permissions)) ||
          filterConfig.noNeedToRender
        )
          return false;
        if (filterConfig.userPermission) return filterConfig.userPermission(user);
        return true;
      }),
    [user, permissions, filters],
  );

  const toggleHiddenField = useCallback(
    (item) => {
      setHiddenFilters((data) => {
        const newFilters = toggleElementFromArray(data, item);
        updateSettings({
          personal_setting: {
            ...settings.personal_setting,
            [filterTabKeyVisibility]: newFilters,
          },
        });
        return newFilters;
      });
    },
    [filterTabKeyVisibility, updateSettings],
  );

  const availableFiltersMenuItems = useMemo<IMenuItem[]>(
    () =>
      [...availableFilters].slice(3).map((item) => {
        const filterConfig = FiltersItems[item];
        return {
          action: () => toggleHiddenField(item),
          textTypographyProps: {
            variant: 'body3',
          },
          text: filterConfig.title,
          icon: hiddenFilters.includes(item) ? <CheckboxIconDefault /> : <CheckboxIconChecked />,
          dataTestName: `available_filters__${filterConfig.title}`,
        };
      }),
    [hiddenFilters, availableFilters.length],
  );

  const initialData = useMemo(() => {
    const data: Record<string, Array<string>> = {};
    filters.map((item) => {
      const filterConfig = FiltersItems[item];
      if (filterConfig.defaultFilters?.length) {
        data[filterConfig.filterKey] = filterConfig.defaultFilters;
      }
    });
    return data;
  }, [filters]);

  const [filtersData, setData, onSetValue] = useUrlParams(
    initialData,
    'filters',
    serializeFilters,
    deserializeFilters,
  );

  const handleFiltersChange = useCallback(
    (key: string, value: string[], skipSettings?: boolean) => {
      const filterConfig = Object.values(FiltersItems).find((item) => item.filterKey === key);
      const needToUpdateUrl = filterConfig?.needToUpdateUrl || DEFAULT_SET_TO_URL;
      const needToUpdateSetting = filterConfig?.needToUpdateSetting || DEFAULT_SET_TO_SETTINGS;

      onSetValue({ [key]: value }, !needToUpdateUrl);
      if (needToUpdateSetting && !skipSettings)
        updateSettings({
          personal_setting: {
            ...settings.personal_setting,
            [filterTabKey]: {
              ...(settings.personal_setting?.[filterTabKey] || {}),
              [key]: value,
            },
          },
        });
    },
    [filtersData, settings, setHiddenFilters],
  );

  const setDataFromSettings = useCallback(
    (filters: Record<string, Array<string>>) => {
      const availableFiltersName = availableFilters.map((filter) => FiltersItems[filter].filterKey);
      Object.keys(filters).map((key) => {
        if (!filters[key] || !availableFiltersName.includes(key as string)) return;
        handleFiltersChange(key, filters[key], true);
      });
    },
    [availableFilters],
  );

  useEffect(() => {
    if (settings?.personal_setting?.[filterTabKey]) {
      setDataFromSettings(settings?.personal_setting?.[filterTabKey]);
    }
    if (settings?.personal_setting?.[filterTabKeyVisibility]) {
      setHiddenFilters(settings?.personal_setting?.[filterTabKeyVisibility]);
    }
  }, [settings?.personal_setting]);

  const updateFilters = () => {
    const string = serializeFilters(filtersData);
    setFilterStringQuery(string);
    onPageChange?.(null, 0);
  };

  useDebouncedEffect(
    () => {
      updateFilters();
    },
    [filtersData],
    500,
  );

  useEffect(() => {
    updateFilters();
  }, []);

  const resetFiltersToDefault = useCallback(() => {
    setData(initialData);
    setHiddenFilters([...filters].slice(4));
    updateSettings({
      personal_setting: {
        ...settings.personal_setting,
        [filterTabKeyVisibility]: [...filters].slice(4),
        [filterTabKey]: {
          ...initialData,
        },
      },
    });
  }, [settings, initialData]);

  return {
    filtersData,
    handleFiltersChange,
    availableFilters,
    resetFiltersToDefault,
    hiddenFilters,
    availableFiltersMenuItems,
  };
};
