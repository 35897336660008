import { useQueries } from 'react-query';
import first from 'lodash/first';
import {
  checkIsInvestor,
  checkIsLender,
  checkIsOwner,
  getBasicUrl,
  getBorrower,
  getHookState,
  getPoliciesByRole,
  getPreviousRequestsByID,
  getTeamRole,
} from '@utils';

import {
  HookState,
  IActivityLogItem,
  IDocument,
  IDrawRequest,
  IInspection,
  IMilestone,
  IMilestonePhotos,
  IProject,
  IProjectComment,
  IProjectProgress,
  ITeam,
  QueryNamesEnums,
  TableKeyEnum,
  TPolicies,
  TTeamRole,
} from '@interfaces';

import { useParams } from 'react-router-dom';
import { ACTIVE_COMMENTS_TYPES, LineItemFilterValues, TEAM_ROLES } from '@constants';
import {
  getComments,
  getDrawRequest,
  getDrawRequestAuditLog,
  getDrawRequestDocumentsList,
  getDrawRequestInspectionsList,
  getDrawRequestItemChecklist,
  getDrawRequestMilestones,
  getDrawRequestPhotos,
  getProject,
  getProjectDrawRequestsList,
  getProjectProgress,
  getProjectTeams,
} from '@globalService';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthContext, SettingsContext } from '@context';
import { usePHBFilters } from '../../../../sections/productionBuild/hooks';

export interface ControllerInterface {
  state: HookState;
  drawRequestData: IDrawRequest;
  previousDrawRequest: IDrawRequest[];
  project: IProject;
  customerLogo: string;
  borrower: ITeam;
  photos: IMilestonePhotos[];
  documents: IDocument[];
  auditLog: IActivityLogItem[];
  customerName: string;
  inspectionList: IInspection[];
  comments: IProjectComment[];
  policies: TPolicies;
  borrowerPolicies: TPolicies;
  printRef: React.MutableRefObject<HTMLElement>;
  progress: IProjectProgress;
  lastInspection: IInspection;
  renderedPage: React.MutableRefObject<number>;
  milestones: IMilestone[];
  setOtherModuleLoaded: React.Dispatch<React.SetStateAction<number>>;
  setImagesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useReport = (): ControllerInterface => {
  // TODO: rename after naming change
  const printRef = useRef<HTMLElement>(null);
  const { projectId, requestId } = useParams();
  const { user } = useContext(AuthContext);
  const renderedPage = useRef<number>(0);
  const [otherModulesLoaded, setOtherModuleLoaded] = useState<number>(0);
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
  const { isPHBProject } = useContext(SettingsContext);

  const { groupIds } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
  });

  const commentsUrl = useMemo(
    () =>
      getBasicUrl({
        requestType: 'get',
        projectId,
        requestId,
        milestoneId: null,
        inspectionId: null,
      }),
    [projectId, requestId],
  );

  const teamRole = getTeamRole(user);

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: requestId }],
      queryFn: getDrawRequest.bind(this, {
        projectId,
        drawRequestId: requestId,
        query: '',
      }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS, { projectId, drawRequestId: requestId }],
      queryFn: getDrawRequestPhotos.bind(this, projectId, requestId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestDocumentsList.bind(this, projectId, requestId),
    },
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_AUDIT_LOG, { drawRequestId: requestId }],
      queryFn: getDrawRequestAuditLog.bind(this, requestId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestInspectionsList.bind(this, { projectId, drawRequestId: requestId }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, companyId: null }],
      queryFn: getProjectTeams.bind(this, projectId, null),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_COMMENTS, { url: commentsUrl }],
      queryFn: getComments.bind(this, commentsUrl),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestItemChecklist.bind(this, projectId, requestId),
      enabled: Boolean(requestId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
      queryFn: getProjectProgress.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        {
          projectId,
          drawRequestId: requestId,
          filterKey: LineItemFilterValues.ALL.filterKey,
          groupBy: isPHBProject ? groupIds : null,
        },
      ],
      queryFn: getDrawRequestMilestones.bind(this, {
        projectId,
        drawRequestId: requestId,
        groupBy: isPHBProject ? groupIds : null,
        filterKey: LineItemFilterValues.ALL.filterKey,
      }),
    },
  ]);

  const comments = requestedDataQueries[8].data?.results?.filter(
    (comment: IProjectComment) =>
      (checkIsLender(comment.team?.role) ||
        checkIsOwner(comment.team?.role) ||
        checkIsInvestor(comment.team?.role) ||
        comment.is_external_comment) &&
      comment.message &&
      comment.tags?.includes('Report') &&
      ACTIVE_COMMENTS_TYPES.includes(comment.content_type),
  );

  const teams = requestedDataQueries[7].data?.results;
  const borrower = useMemo(() => getBorrower(teams), [teams]);

  const previousDrawRequest = useMemo(
    () => getPreviousRequestsByID(requestedDataQueries[5].data?.results, requestId),
    [requestedDataQueries[5].data?.results, requestId],
  );

  const setMetaForReady = useCallback(() => {
    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'report-ready';
    newMetaTag.content = 'true';
    document.head.appendChild(newMetaTag);
  }, []);

  useEffect(() => {
    if (
      getHookState(requestedDataQueries) === HookState.SUCCESS &&
      (requestedDataQueries[2].data.length == 0 || imagesLoaded) &&
      otherModulesLoaded == 2
    ) {
      setMetaForReady();
    }
  }, [requestedDataQueries, otherModulesLoaded, imagesLoaded]);

  const milestones = useMemo(
    () => requestedDataQueries[11].data?.results,
    [requestedDataQueries[11].data],
  );

  return {
    state: getHookState(requestedDataQueries),
    drawRequestData: requestedDataQueries[0].data,
    project: requestedDataQueries[1].data,
    photos: requestedDataQueries[2].data,
    documents: requestedDataQueries[3].data?.results,
    auditLog: requestedDataQueries[4].data?.results,
    inspectionList: requestedDataQueries[6].data?.results,
    previousDrawRequest,
    customerName: requestedDataQueries[1].data?.customer_name,
    customerLogo:
      requestedDataQueries[1].data?.lender_company_logo || user?.active_team?.company?.logo,
    borrower,
    comments,
    policies: getPoliciesByRole({ policies: requestedDataQueries[9].data, teamRole }),
    borrowerPolicies: getPoliciesByRole({
      policies: requestedDataQueries[9].data,
      teamRole: TEAM_ROLES.Owner as TTeamRole,
    }),
    printRef,
    progress: requestedDataQueries[10].data,
    lastInspection: first(requestedDataQueries[6].data?.results),
    renderedPage,
    milestones,
    setOtherModuleLoaded,
    setImagesLoaded,
  };
};
