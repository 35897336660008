import { FilterObject } from '../interface';
import { ProjectScoresDisplayValues } from '@constants';
import { PermissionNamesEnums, ScoreValuesEnum } from '@interfaces';

export const policyScoreFilter: FilterObject = {
  title: 'Policy risk',
  filterKey: ScoreValuesEnum.POLICY_HEALTH,
  permissionKey: PermissionNamesEnums.REQUEST_QUEUE__SCORES__VIEW,
  defaultFilters: [],
  getStaticValues: () =>
    ProjectScoresDisplayValues.map((item) => ({
      label: item.name,
      value: item.value,
    })),
  cypressSelector: 'filters__policy_score',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
};
