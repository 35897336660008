import React, { FC } from 'react';
import { InputLabel, Stack, TextField } from '@mui/material';
import { colors } from '@theme';
import { DigitInput, MilestoneListWithPatch } from '@components';
import { PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import { WithPermission } from '@utils';
import LoadingButton from '@mui/lab/LoadingButton';
import { useUnitsTable } from './controller';

const CreateByUnitsTable: FC<{ isEditable: boolean }> = ({ isEditable }) => {
  const {
    initColumns,
    projectUnits,
    addLineList,
    patchUnit,
    update,
    deleteItem,
    unitsNumber,
    handleInputChange,
    isLoading,
  } = useUnitsTable({
    isEditable,
  });

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneListWithPatch
        deleteMilestone={deleteItem}
        refetch={update}
        patchMilestone={patchUnit}
        withColumnIndication
        tableKey={TableKeyEnum.LINE_ITEMS}
        initColumns={initColumns}
        milestones={projectUnits}
        key="listWithEditModels"
        isLoading={isLoading}
        headerLeftPart={[
          {
            Component: (
              <WithPermission permissionKey={PermissionNamesEnums.PROJECT__CREATE__BUTTON}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <InputLabel>Number of properties</InputLabel>
                  <TextField
                    label="number"
                    variant="outlined"
                    value={unitsNumber.value}
                    onChange={handleInputChange}
                    inputProps={{
                      maxLength: 3,
                      'data-cy': 'project__budget__create__structure_table__add_units__input',
                    }}
                    InputProps={{ inputComponent: DigitInput as any }}
                    size="small"
                    disabled={!isEditable || isLoading}
                    error={!unitsNumber.isValid}
                  />
                </Stack>
              </WithPermission>
            ),
          },
        ]}
        headerRightPart={[
          {
            Component: (
              <WithPermission permissionKey={PermissionNamesEnums.PROJECT__CREATE__BUTTON}>
                <Stack spacing={1} direction="row">
                  <LoadingButton
                    loading={false}
                    size="small"
                    onClick={addLineList}
                    data-cy="project__budget__create__structure_table__add_item__button"
                    disabled={!isEditable || isLoading}
                  >
                    Add property
                  </LoadingButton>
                </Stack>
              </WithPermission>
            ),
          },
        ]}
        source="project__budget__create__structure_table"
      />
    </Stack>
  );
};
export default CreateByUnitsTable;
