import React, { FC } from 'react';
import { FormControlLabel, Checkbox, Stack, Typography, Tooltip, Box } from '@mui/material';
import { colors } from '@theme';

interface CheckboxWithMediaProps {
  imagePath: string;
  title: string;
  descriptions: string;
  checked?: boolean;
  onChange: () => void;
  dataTestName: string;
  disabled?: boolean;
}

const CheckboxWithMedia: FC<CheckboxWithMediaProps> = ({
  imagePath,
  title,
  descriptions,
  onChange,
  checked,
  dataTestName,
  disabled,
}) => (
  <FormControlLabel
    slotProps={{
      typography: {
        width: '100%',
      },
    }}
    sx={{ width: '100%' }}
    control={
      disabled ? (
        <Tooltip title="Your request cannot be combined with this option">
          <Box>
            <Checkbox
              disabled={true}
              checked={checked}
              data-cy={dataTestName}
              onChange={onChange}
            />
          </Box>
        </Tooltip>
      ) : (
        <Checkbox checked={checked} data-cy={dataTestName} onChange={onChange} />
      )
    }
    label={
      <Stack
        spacing={4}
        alignItems="center"
        sx={{
          flex: 1,
          width: '100%',
          border: `1px solid ${colors.neutral.lighter}`,
        }}
        px={4}
        direction="row"
      >
        <img src={imagePath} style={{ width: 120, height: 120 }} alt={title} />
        <Stack>
          <Typography variant="body1SemiBold">{title}</Typography>
          <Typography variant="body1">{descriptions}</Typography>
        </Stack>
      </Stack>
    }
  />
);

export default CheckboxWithMedia;
