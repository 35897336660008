import { getArrayFromObject, isCreatedInspection } from '@utils';
import { FilterObject } from '../interface';
import { inspectionStatusMap } from '@constants';
import { PermissionNamesEnums } from '@interfaces';

export const inspectionsListStatusFilter: FilterObject = {
  title: 'Inspection status',
  filterKey: 'status',
  permissionKey: PermissionNamesEnums.DRAW_REQUEST_INSPECTION_INFO,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  defaultFilters: [],
  getStaticValues: () =>
    (
      getArrayFromObject(inspectionStatusMap, 'value', 'label') as unknown as Array<{
        label: string;
        value: string;
      }>
    ).filter((o) => !isCreatedInspection(o.value)),
  cypressSelector: 'filters__inspections_list_status',
};
