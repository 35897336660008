import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
  Box,
  Typography,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@theme';

import { useCustomUppy, useSafeSnackbar } from '@hooks';
import { ComponentProps } from './interface';
import { ITransloaditError } from '@interfaces';
import { UploadIconNew } from '@svgAsComponents';
import { StyledDashboard } from '@components';

const FilesUploader = ({
  transloaditSignature: { params, signature },
  open,
  onClose,
  restrictions,
  refetchCallback,
  refetch,
  fields = [],
  metaFields = [],
  metaDataDefault,
  source,
}: ComponentProps) => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const { uppy } = useCustomUppy({ params, signature, restrictions, fields });
  const [showDragDrop, setShowDragDrop] = useState(true);

  useEffect(() => {
    uppy.on('complete', () => {
      onClose();
    });
    uppy.on('transloadit:complete', () => {
      if (refetch?.length) {
        refetch.forEach((query) => refetchCallback(query));
      }
    });
    uppy.on('error', (error: ITransloaditError) => {
      error.details && enqueueSnackbar(error.details, { variant: 'error' });
    });
    uppy.on('file-added', (file) => {
      setTimeout(() => {
        const uploadButton = document.querySelector(
          '.uppy-Dashboard .uppy-StatusBar-actions .uppy-u-reset.uppy-c-btn.uppy-StatusBar-actionBtn',
        );

        if (uploadButton) {
          uploadButton.setAttribute('data-cy', `${source}__upload__modal__upload__button`);
        }
      }, 300);

      if (!file) return;
      setShowDragDrop(false);
      if (Object.keys(metaDataDefault)) {
        uppy.setFileMeta(file.id, metaDataDefault);
      }
    });

    uppy.on('restriction-failed', (file) => {
      enqueueSnackbar(
        `${file.name} can't be uploaded as .${file.extension} extension is not allowed.`,
        {
          variant: 'error',
        },
      );
    });
  }, []);

  return (
    <Dialog
      aria-labelledby="files-uploader-dialog"
      open={open}
      sx={{ zIndex: 99999 }}
      data-cy={`${source}__media_uploader`}
    >
      <DialogContent sx={{ padding: 0, minWidth: 325 }}>
        <Box px={3} py={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Upload Media</Typography>
            <IconButton edge="end" onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Box>
        <Divider />
        <Box p={3} sx={{ whiteSpace: 'pre-wrap' }}>
          <StyledDashboard uppy={uppy} metaFields={metaFields} source={source} />
          {showDragDrop && (
            <Stack
              alignItems="center"
              sx={{
                position: 'absolute',
                bottom: '25%',
                left: '25%',
                backgroundColor: colors.uploaderBackground,
                pt: '25px',
                zIndex: 5999,
                pointerEvents: 'none',
              }}
            >
              <UploadIconNew />
              <Typography variant="h2">Drag and drop files here</Typography>
            </Stack>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FilesUploader;
