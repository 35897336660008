import React, { FC, useCallback, useEffect } from 'react';
import { Box, Stack } from '@mui/material';

import { CustomDatePickerInput, CustomSelect, WysiwygEditor } from '@components';
import {
  IButtonController,
  IChecklistController,
  IFieldsController,
  IValidationController,
} from '../../main/interfaces';
import SectionWrapper from '../sectionWrapper';
import { dateFormatter, debounceFunction, getDefaultChecklistItem, isValidDate } from '@utils';
import { PolicyItemTypesEnum } from '@interfaces';
import { SubmissionSectionKeyMap } from '@constants';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';

interface CompletionDateProps {
  checklistController: IChecklistController;
  fieldsController: IFieldsController;
  validationController: IValidationController;
  buttonsController: IButtonController;
}

const CompletionDate: FC<CompletionDateProps> = ({
  fieldsController,
  validationController,
  checklistController,
  buttonsController,
}) => {
  const {
    completionDate,
    completionDateComment,
    completionDateReasons,
    completionDateReasonsList,
    handleCompletionDateReasonChange: handleSelectChange,
    showCompletionDateReasons,
    isCompletionDateReasonsValid,
    startDate,
    isCompletionDateChanged,
    isInitMetadataUsed,
  } = fieldsController;
  const { checklistItems, handleDone } = checklistController;
  const { completionDateDone } = buttonsController;
  const { onSectionButtonsClick, validatedSection, isAdditionalRequirementResubmit } =
    validationController;
  const checklistItem = getDefaultChecklistItem(
    checklistItems,
    PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
  );
  const sectionKey = SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL];
  const isSectionSkipped = validatedSection?.[sectionKey]?.isSkipped;
  const isSectionDone = validatedSection?.[sectionKey]?.isDone;

  useEffect(() => {
    if (
      completionDate.value &&
      completionDate.validate() &&
      isCompletionDateChanged &&
      isCompletionDateReasonsValid &&
      !isSectionDone &&
      !isSectionSkipped
    ) {
      onSectionButtonsClick({ sectionKey, key: 'isDone' });
      completionDateDone();
    }
  }, [
    isCompletionDateReasonsValid,
    completionDate.value,
    isCompletionDateChanged,
    isSectionSkipped,
    isSectionDone,
  ]);

  const handleInputChange = useCallback(
    debounceFunction(async (payload) => {
      if (!checklistItem) return;

      handleDone({
        checklistItemId: checklistItem.id,
        shouldComplete: isSectionDone,
        metadata: {
          ...payload,
        },
      });
    }, 500),
    [checklistItem?.id, isSectionDone],
  );

  // automatically save data if it's changed
  useEffect(() => {
    const payload = {
      comment: completionDateComment.value,
      reasons: completionDateReasons,
      date: isValidDate(completionDate.value).value
        ? format(completionDate.value, 'yyyy-MM-dd')
        : null,
    };

    if (isInitMetadataUsed && !isEqual(payload, checklistItem?.metadata))
      handleInputChange(payload);
  }, [
    completionDateComment.value,
    completionDateReasons,
    completionDate.value,
    checklistItem?.metadata,
    isInitMetadataUsed,
  ]);

  return (
    <SectionWrapper
      title={checklistItem?.label || 'Update project completion date'}
      controller={validationController}
      sectionKey={sectionKey}
      collapsedSectionText={{
        value:
          completionDate.value && completionDate.validate()
            ? dateFormatter({ date: completionDate.value })
            : '',
      }}
      hideSkipButton
      handleDone={completionDateDone}
      innerRef={checklistItem?.itemRef}
      itemType={PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL}
    >
      <Box mx={8} mt={3}>
        <Stack sx={{ width: { xs: '100%', md: '50%' } }} spacing={3}>
          <Stack direction="row" spacing={1}>
            <CustomDatePickerInput
              label=""
              field={completionDate}
              minDate={startDate}
              inputStyles={{ width: '300px' }}
              disabled={isAdditionalRequirementResubmit}
            />
            {showCompletionDateReasons && (
              <CustomSelect
                label="Reason for update"
                optionsList={completionDateReasonsList}
                selectedOptions={completionDateReasons}
                handleSelectChange={(e) => handleSelectChange(e.target.value as string[])}
                required
                error={!isCompletionDateReasonsValid}
              />
            )}
          </Stack>
          {(showCompletionDateReasons || completionDateComment?.value) && (
            <WysiwygEditor
              editField={completionDateComment}
              source={'request__submission__completion_date__comments'}
            />
          )}
        </Stack>
      </Box>
    </SectionWrapper>
  );
};

export default CompletionDate;
