import React, { FC, useEffect } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useApproveCredit } from './controller';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextInputWithTooltip } from '@components';
import { useStringFieldModel } from '@models';
import { colors } from '@theme';

const ApproveLine: FC<{
  reason: string;
  requestedCredit: number;
  approvedAmount: number;
  title: string;
  setApproved: (approved: number) => void;
  source: string;
  index: number;
  isLoading?: boolean;
}> = ({
  requestedCredit,
  setApproved,
  reason,
  approvedAmount,
  title,
  source,
  index,
  isLoading,
}) => {
  const approved_amount = useStringFieldModel({
    initValue: approvedAmount?.toString(),
    withProgressCheck: true,
  });

  useEffect(() => {
    setApproved(+approved_amount.value);
  }, [approved_amount.value]);

  return (
    <Stack spacing={2} width="100%">
      <Box>
        <Typography variant="body1SemiBold">{title}</Typography>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <Box flex={1}>
            <TextInputWithTooltip
              fullWidth
              disabled
              label="Requested credit amount"
              onChange={null}
              value={requestedCredit}
              type="currency"
              dataTestName={`${source}__requested_credit_amount__input__index_${index}`}
            />
          </Box>
          <Box flex={1}>
            <TextInputWithTooltip
              fullWidth
              disabled={isLoading}
              label="Approved credit amount"
              value={approved_amount.value}
              onChange={approved_amount.handleChange}
              type="currency"
              dataTestName={`${source}__approved_credit_amount__input__index_${index}`}
            />
          </Box>
        </Stack>
        <Stack mt={1} direction="row">
          <Typography color={colors.text.medium} variant="body2" sx={{ mr: 0.5 }}>
            Reason:{' '}
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: reason || '' }} variant="body2" />
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};

const ApproveCreditPanel: FC<{
  milestoneId?: string;
  requestId?: string;
  source: string;
  setOpen?: CallableFunction;
  withProjectName?: boolean;
}> = ({ milestoneId, requestId, setOpen, source, withProjectName }) => {
  const { name, submit, isMutating, milestones, setUpdateData, isLoading } = useApproveCredit({
    requestId,
    milestoneId,
    setOpen,
  });
  return (
    <Stack flex={1}>
      {withProjectName && (
        <Typography variant="body2">
          Project:
          <Typography variant="body2SemiBold"> {name}</Typography>
        </Typography>
      )}
      <Stack mt={withProjectName ? 3 : 1} flex={1} spacing={4}>
        <Stack spacing={1}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography variant="h4">Сredit amount(s)</Typography>
          </Stack>
          <Typography variant="body2">
            You can return excess funds or correct a previous draw. The returned funds can also be
            used towards reallocation.
          </Typography>
        </Stack>
        <Stack flex={1} sx={{ overflowY: 'auto' }} spacing={4}>
          {milestones.map((item, index) => (
            <ApproveLine
              title={item.name}
              key={index}
              reason={item.credit_reason}
              setApproved={(data) => setUpdateData((d) => ({ ...d, [item.id]: data }))}
              approvedAmount={item.approved_credit_amount}
              requestedCredit={item.requested_credit_amount}
              source={source}
              index={index}
              isLoading={isLoading}
            />
          ))}
        </Stack>
      </Stack>
      <Stack spacing={2} justifyContent="flex-end" direction="row">
        <LoadingButton
          loading={!!isMutating}
          onClick={submit}
          data-cy={`${source}__confirm__button`}
        >
          Confirm
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ApproveCreditPanel;
