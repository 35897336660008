import { useQuery } from 'react-query';
import { IActivityLogItem, QueryNamesEnums } from '@interfaces';
import { getHookState } from '@utils';
import { getProjectAuditLog } from '@globalService';

export const useAuditLog = ({ projectId, sorting, filters }) => {
  let queryURL = `&limit=999&sorting=${sorting ? '-created_at' : 'created_at'}`;
  if (filters.length) {
    queryURL += `&tags=${filters.join(',')}`;
  }

  const projectAuditLogQuery = useQuery<{ results: IActivityLogItem[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_AUDIT_LOG, { projectId, queryURL }],
    getProjectAuditLog.bind(this, projectId, queryURL),
    {
      staleTime: 0,
    },
  );

  return {
    state: getHookState(projectAuditLogQuery),
    auditLogData: projectAuditLogQuery.data?.results,
  };
};
