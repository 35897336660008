import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { HookState, ScoreValuesEnum } from '@interfaces';
import { CollapsibleWidget } from '@components';
import { backgroundColorsMap, scoreColorMap } from '@constants';
import {
  CalendarIcon,
  DocumentsIconWithIndicator,
  DotIcon,
  PieIcon,
  ContractorIcon,
} from '@svgAsComponents';
import * as Controller from './controller';

const StatusIcon = styled(Box)({
  borderRadius: 2,
  width: 16,
  height: 16,
});

const IconMap = {
  [ScoreValuesEnum.SCHEDULE_HEALTH]: CalendarIcon,
  [ScoreValuesEnum.BUDGET_HEALTH]: PieIcon,
  [ScoreValuesEnum.POLICY_HEALTH]: DocumentsIconWithIndicator,
  [ScoreValuesEnum.BORROWER_SCORE]: ContractorIcon,
};

const ProjectScores = () => {
  const { projectId } = useParams();
  const controller = Controller.useProjectScore(projectId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return <Skeleton variant="rectangular" />;
    }

    case HookState.SUCCESS: {
      return <ScoreWidgetBody controller={controller} />;
    }

    default:
      return null;
  }
};

export default ProjectScores;

const ScoreWidgetBody: FC<{ controller: Controller.ControllerInterface }> = ({ controller }) => {
  const { scores, projectScore } = controller;

  return (
    <CollapsibleWidget
      title={<ScoresTitle controller={controller} />}
      titleBackgroundColor={backgroundColorsMap[projectScore.color]}
      containerStyles={{ minWidth: '300px', padding: '7px 8px' }}
      widgetType="projectScores"
    >
      <Stack spacing={2}>
        <BarWithIndicator value={projectScore.value} color={scoreColorMap[projectScore.color]} />

        {scores?.map((score) => {
          const Icon = IconMap[score.agent_id] || DocumentsIconWithIndicator;
          return (
            <Stack spacing={2} key={score.agent_id}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  backgroundColor: backgroundColorsMap[score.color],
                  px: 2.25,
                  py: 0.75,
                  borderRadius: '2px',
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Icon color={scoreColorMap[score.color]} size={24} />
                  <Typography
                    variant="body3SemiBold"
                    sx={{ color: scoreColorMap[score.color], pl: 2.25 }}
                  >
                    {score.label}
                  </Typography>
                </Stack>
                <Typography variant="body3SemiBold" sx={{ color: scoreColorMap[score.color] }}>
                  {score.flags?.length}
                </Typography>
              </Stack>

              {score.flags?.map((flag, index) => {
                return (
                  <Stack direction="row" alignItems="center" key={index}>
                    <Box sx={{ mx: 3.5, display: 'flex' }}>
                      <DotIcon color={scoreColorMap[score.color]} />
                    </Box>
                    <Typography variant="label">{flag}</Typography>
                  </Stack>
                );
              })}
            </Stack>
          );
        })}
      </Stack>
    </CollapsibleWidget>
  );
};

const ScoresTitle: FC<{ controller: Controller.ControllerInterface }> = ({ controller }) => {
  const { projectScore, scores } = controller;
  return (
    <Stack direction="row" alignItems="center">
      <StatusIcon
        sx={{
          backgroundColor: scoreColorMap[projectScore?.color],
        }}
      />
      <Typography
        variant="body3SemiBold"
        sx={{ color: scoreColorMap[projectScore?.color], ml: 0.5 }}
      >
        {projectScore.label}
      </Typography>
      <Stack direction="row" alignItems="center" sx={{ ml: 1 }}>
        {scores?.map((score) => {
          const Icon = IconMap[score.agent_id] || DocumentsIconWithIndicator;
          return (
            <Stack
              key={score.agent_id}
              direction="row"
              alignItems="center"
              sx={{ ml: 1, color: scoreColorMap[score.color] }}
            >
              <Icon color={scoreColorMap[score.color]} size={16} />
              <Typography
                variant="labelSemiBold"
                sx={{ color: scoreColorMap[score.color], ml: 0.5 }}
              >
                {score.flags?.length}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

const BarWithIndicator = ({ value, color }) => {
  let indicatorPosition;

  // not to overlap the bar with the indicator
  if (value < 4) {
    indicatorPosition = '0';
  } else if (value > 96) {
    indicatorPosition = 'calc(100% - 8px)';
  } else {
    indicatorPosition = `calc(${value}% - 4px)`;
  }

  const backgroundGradient = `linear-gradient(90deg, ${scoreColorMap.RED} 0%, ${scoreColorMap.YELLOW} 34.37%, ${scoreColorMap.YELLOW} 67.19%, ${scoreColorMap.GREEN} 100%)`;

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '12px' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background: backgroundGradient,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          borderRadius: '2px',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '8px',
          height: '24px',
          backgroundColor: color,
          top: '50%',
          transform: 'translateY(-50%)',
          left: indicatorPosition,
          borderRadius: '2px',
        }}
      />
    </Box>
  );
};
