import { ImageSizeEnum } from '@interfaces';
import { Box, Stack, Typography } from '@mui/material';
import { getAddress, getImageUrl } from '@utils';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { ControllerInterface } from '../controller';
import { ItemImage, BorderBox } from '../common';
import { LabelAndValue } from '@components';
import { colors } from '@theme';

export const SitePhotos: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const [loadedCount, setCount] = useState<number>(0);
  const { photos, project, customerName, setImagesLoaded, inspectionList } = controller;
  const thumbs = useMemo(
    () => [
      ...photos.flatMap(({ photos, milestone }) =>
        photos.map((item) => ({ ...item, name: milestone?.name })),
      ),
    ],
    [photos, inspectionList],
  );

  useEffect(() => {
    if (thumbs.length === loadedCount) {
      setImagesLoaded(true);
    }
  }, [thumbs.length, loadedCount]);

  return thumbs.length ? (
    <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
      <Typography variant="printH2" data-cy="report_photos_section_title">
        Site photos
      </Typography>
      <BorderBox>
        <Stack spacing={3} direction="row">
          <Stack spacing={1} flex={1}>
            <LabelAndValue size="printBody1" minHeight={0} label="Customer" text={customerName} />
            <LabelAndValue size="printBody1" minHeight={0} label="Project" text={project.name} />
          </Stack>
          <Stack spacing={1} flex={1}>
            <LabelAndValue
              minHeight={0}
              size="printBody1"
              label="Address"
              text={getAddress(project.address)}
            />
            <LabelAndValue
              minHeight={0}
              size="printBody1"
              label="Loan information"
              text={project.loan.external_id}
            />
          </Stack>
        </Stack>
      </BorderBox>

      <Stack flexWrap="wrap" direction="row" mt={1}>
        {thumbs?.map(({ file_representations, name }, index) => (
          <ItemImage key={index}>
            <img
              onLoad={() => setCount((data) => data + 1)}
              src={`${getImageUrl(ImageSizeEnum.THUMB, file_representations)}`}
            />
            {name && (
              <Stack justifyContent="center" direction="row">
                <Typography sx={{ color: colors.text.medium }}>Line item:&nbsp;</Typography>
                <Typography>{name}</Typography>
              </Stack>
            )}
          </ItemImage>
        ))}
      </Stack>
    </Box>
  ) : null;
};
