import React, { useMemo, useState } from 'react';
import { Box, Divider, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import {
  AdditionalRequirements,
  DocumentsIconWithIndicator,
  DownloadIcon,
  NoDataImage,
  NoTypeIcon,
} from '@svgAsComponents';

import { PoliciesListItemIconsComponentProps } from './interface';
import { colors, typography } from '@theme';
import { useImagePicker, useRightMenu } from '@hooks';
import { ENABLED_MIME_TYPES, fileTypesMap } from '@constants';
import {
  Gallery,
  InfoIconWithTooltip,
  LabelAndValue,
  PDFViewerNew,
  RightDrawer,
} from '@components';
import {
  dateFormatter,
  getUserFullName,
  isDocumentPolicyItem,
  isHistoryActionCreate,
  isPolicyItemCompleted,
  isPolicyItemSkipped,
} from '@utils';

const TABS = {
  documents: { label: 'Documents', value: 'documents' },
  comments: { label: 'Comments', value: 'comments' },
};

const PoliciesListItemIcons = ({
  item,
  index,
  source,
  showActionIcons,
}: PoliciesListItemIconsComponentProps) => {
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu();
  const [tab, setTab] = useState(TABS.documents.value);
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const { label, documents, description, created_by_team, historyEvents, type } = item;
  const title = label?.toString();
  const isAdditionalRequirement = created_by_team && showActionIcons;
  const addedBy = useMemo(() => {
    if (!created_by_team) return;
    const addedEvent = historyEvents?.find((event) => isHistoryActionCreate(event.action));
    if (!addedEvent?.updated_by_user) return;
    return `by ${getUserFullName(addedEvent?.updated_by_user)}`;
  }, [created_by_team, historyEvents]);

  const skippedBy = useMemo(() => {
    const skippedEvent = historyEvents?.find((event) => isPolicyItemSkipped(event.status));
    if (!skippedEvent?.updated_by_user) return;
    return `by ${getUserFullName(skippedEvent?.updated_by_user)}`;
  }, [historyEvents]);

  const checkedByUser = useMemo(() => {
    const checkedEvent = historyEvents?.find((event) => isPolicyItemCompleted(event.status));
    if (!checkedEvent?.updated_by_user) return;
    return `${getUserFullName(checkedEvent?.updated_by_user)}, ${dateFormatter({
      date: checkedEvent?.updated_at,
      withTime: true,
    })}`;
  }, [historyEvents]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        {isAdditionalRequirement && (
          <Tooltip
            title="Additional Borrower requirement"
            data-cy={`${source}_policy__items__list__additional_requirement__tooltip__index_${index}`}
          >
            <Stack>
              <AdditionalRequirements size={24} />
            </Stack>
          </Tooltip>
        )}

        {showActionIcons && (
          <>
            {isDocumentPolicyItem(type) && (
              <Stack>
                <IconButton
                  onClick={() => {
                    setTab(TABS.documents.value);
                    handleRightDrawerOpenerClick({ title });
                  }}
                  disabled={!documents?.length}
                  sx={{ p: 0 }}
                  data-cy={`${source}_policy__items__list__documents__icon__index_${index}`}
                >
                  <DocumentsIconWithIndicator size={22} />
                </IconButton>
              </Stack>
            )}
          </>
        )}

        <Stack>
          <InfoIconWithTooltip
            data-cy={`${source}_policy__items__list__info_tooltip__icon__index_${index}`}
            color={
              description || checkedByUser || addedBy || showActionIcons
                ? colors.status.information.medium
                : colors.neutral.medium
            }
            tooltipText={
              (description || checkedByUser || addedBy || showActionIcons) && (
                <Stack spacing={1}>
                  {description && (
                    <Stack>
                      <Typography variant="labelSemiBold">Description:</Typography>
                      <Typography
                        variant="label"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </Stack>
                  )}
                  {(checkedByUser || addedBy || showActionIcons) && (
                    <>
                      {description && <Divider />}
                      {(addedBy || showActionIcons) && (
                        <LabelAndValue
                          label="Added"
                          text={addedBy || 'From template'}
                          labelStyle={{ ...typography.label }}
                          textStyle={{ ...typography.labelSemiBold }}
                        />
                      )}
                      {checkedByUser && (
                        <LabelAndValue
                          label="Checked"
                          text={checkedByUser}
                          labelStyle={{ ...typography.label }}
                          textStyle={{ ...typography.labelSemiBold }}
                        />
                      )}
                      {skippedBy && (
                        <LabelAndValue
                          label="Skipped"
                          text={skippedBy}
                          labelStyle={{ ...typography.label }}
                          textStyle={{ ...typography.labelSemiBold }}
                        />
                      )}
                    </>
                  )}
                </Stack>
              )
            }
          />
        </Stack>
      </Stack>
      {showActionIcons && (
        <RightDrawer {...rightMenu}>
          <Tabs
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
            value={tab}
            onChange={handleChange}
            sx={{ mb: 4 }}
          >
            <Tab
              label={TABS.documents.label}
              value={TABS.documents.value}
              sx={{ textTransform: 'none' }}
            />
          </Tabs>
          {tab === TABS.documents.value && (
            <Stack>
              {!documents?.length && <EmptyState text="No documents yet" />}
              {documents?.map((item, index) => (
                <Stack key={item.id}>
                  <Stack direction="row" justifyContent="space-between">
                    <DocumentsName
                      mime_type={item.mime_type}
                      name={item.name || `Document #${++index}`}
                      file={item}
                    />
                    {item.link && (
                      <Tooltip title="Download">
                        <a href={item.link} download>
                          <IconButton>
                            <DownloadIcon size={20} />
                          </IconButton>
                        </a>
                      </Tooltip>
                    )}
                  </Stack>
                  <Divider sx={{ my: 2 }} />
                </Stack>
              ))}
            </Stack>
          )}
        </RightDrawer>
      )}
    </>
  );
};

const DocumentsName = ({ mime_type, name, file }) => {
  const { pdf, gallery, open, close, startIndex } = useImagePicker();
  const Icon = fileTypesMap[mime_type] || NoTypeIcon;

  const isCanBeOpen = ENABLED_MIME_TYPES.includes(mime_type);
  return (
    <>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          ...typography.body2SemiBold,
          ...(isCanBeOpen && {
            color: colors.text.link,
            cursor: 'pointer',
          }),
        }}
        onClick={() => (isCanBeOpen ? open([file]) : {})}
      >
        <Icon />
        <Box sx={{ pr: 1 }} />
        {name}
      </span>
      {gallery && <Gallery startIndex={startIndex} close={close} files={gallery} />}
      {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
    </>
  );
};

const EmptyState = ({ text }: { text: string }) => (
  <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
    <NoDataImage size={200} />
    <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
      {text}
    </Typography>
  </Stack>
);

export default PoliciesListItemIcons;
