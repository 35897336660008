import Uppy from '@uppy/core';
import { useUppy } from '@uppy/react';
import Webcam from '@uppy/webcam';
import Transloadit from '@uppy/transloadit';
import ThumbnailGenerator from '@uppy/thumbnail-generator';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/informer/dist/style.css';

export const useCustomUppy = ({ params, signature, fields = null, restrictions = {} }) => {
  const uppy = useUppy(() => {
    return new Uppy({
      restrictions,
    })
      .use(Webcam, { modes: ['picture'] })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        waitForThumbnailsBeforeUpload: true,
      })
      .use(Transloadit, {
        fields,
        params,
        signature,
        waitForEncoding: true,
      });
  });

  uppy.on('upload-error', (file, error) => {
    console.log('error with file>:', file.id);
    console.log('error message>:', error);
  });

  return {
    uppy,
  };
};
