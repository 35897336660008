import { IProjectDocument, ChangeProjectDocumentParam } from '@interfaces';

import { projectDocuments, projectDocument, projectDocumentRestore } from '../paths';

import { dataProvider } from '@api';

export const getProjectDocuments = async ({
  projectId,
  query,
}: {
  projectId: string;
  query?: string;
}): Promise<{ results: IProjectDocument[] }> =>
  dataProvider.get(projectDocuments(projectId, query)).json();

export const deleteProjectDocument = async ({
  projectId,
  documentId,
}: ChangeProjectDocumentParam): Promise<Response> =>
  dataProvider.delete(projectDocument(projectId, documentId));

export const updateProjectDocument = async ({
  projectId,
  documentId,
  scope,
}: ChangeProjectDocumentParam & { scope: string }): Promise<Response> =>
  dataProvider.patch(projectDocument(projectId, documentId), {
    json: { scope },
  });

export const restoreProjectDocument = async ({
  projectId,
  documentId,
}: ChangeProjectDocumentParam): Promise<Response> =>
  dataProvider.post(projectDocumentRestore(projectId, documentId));

export const getProjectDocumentById = async ({
  projectId,
  documentId,
  query = '',
}): Promise<IProjectDocument> =>
  dataProvider.get(projectDocument(projectId, documentId, query)).json();
