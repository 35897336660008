import React, { useContext } from 'react';
import { FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import { HookState } from '@interfaces';
import * as Controller from './controller';
import { AuthContext } from '@context';

const SettingsBlock = () => {
  const { notifications, handleChange, state } = Controller.useSettingsEdit();

  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="Notifications" />;
    }
    case HookState.SUCCESS: {
      return (
        <Stack sx={{ flexDirection: { sm: 'row', xs: 'column' }, mt: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(notifications?.email_enabled)}
                onChange={() => handleChange('email_enabled')}
                sx={{ width: 54 }}
              />
            }
            componentsProps={{ typography: { variant: 'body3' } }}
            label="Email notifications"
            sx={{ mr: 3 }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={Boolean(notifications?.push_enabled)}
                onChange={() => handleChange('push_enabled')}
                sx={{ width: 54 }}
              />
            }
            componentsProps={{ typography: { variant: 'body3' } }}
            label="Push notifications"
          />
        </Stack>
      );
    }

    default:
      return null;
  }
};

const SettingsTab = () => {
  const { user } = useContext(AuthContext);

  return (
    <StyledBox sx={{ flex: 1 }}>
      {user.time_zone && (
        <TextField
          id="time-zone"
          label="Time zone"
          defaultValue={user.time_zone}
          InputProps={{ readOnly: true }}
          sx={{
            mb: 4,
            width: '50%',
            '& .MuiInputBase-root': { height: '40px' },
          }}
        />
      )}
      <Typography variant="h3">Notifications</Typography>
      <SettingsBlock />
    </StyledBox>
  );
};

export default SettingsTab;
