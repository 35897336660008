import { useMemo } from 'react';
import { ICompany, ITeam, QueryNamesEnums } from '@interfaces';
import { getMyCompany, getProjectTeams } from '@globalService';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';
import last from 'lodash/last';

interface ControllerInterface {
  companyTeamsWithoutCurrentUserTeam: ITeam[];
  nextApprovalLevelTeam: ITeam;
  teamsList: ITeam[];
  teamsIsLoading: boolean;
}

export const useProjectTeams = ({
  currentReviewerTeam,
  prId,
}: {
  currentReviewerTeam?: ITeam;
  prId?: string;
}): ControllerInterface => {
  const { projectId } = useParams();
  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const { data: { results: teamsList = [] } = {}, isLoading } = useQuery<
    { results: ITeam[] },
    Error
  >(
    [
      QueryNamesEnums.GET_PROJECT_TEAMS,
      { companyId: companyQuery.data?.id, projectId: projectId || prId },
    ],
    getProjectTeams.bind(this, projectId || prId, companyQuery.data?.id),
    { enabled: Boolean((projectId || prId) && companyQuery.data?.id) },
  );

  const companyTeamsWithoutCurrentUserTeam = useMemo(
    () =>
      teamsList
        ?.filter(
          (o) => o.id !== currentReviewerTeam?.id && o?.project_team_enrollment?.approval_level > 0,
        )
        .sort(
          (a, b) =>
            a.project_team_enrollment?.approval_level - b.project_team_enrollment?.approval_level,
        ),
    [teamsList, currentReviewerTeam],
  );

  const currentTeamApprovalLevel = useMemo(
    () =>
      teamsList.find((team) => team.id === currentReviewerTeam?.id)?.project_team_enrollment
        ?.approval_level || 1,
    [teamsList],
  );

  const nextApprovalLevelTeam = useMemo(
    () =>
      find(
        companyTeamsWithoutCurrentUserTeam,
        (team) => team.project_team_enrollment.approval_level - currentTeamApprovalLevel === 1,
      ) || last(companyTeamsWithoutCurrentUserTeam),
    [companyTeamsWithoutCurrentUserTeam, currentTeamApprovalLevel],
  );

  const filteredTeamList = useMemo(
    () => teamsList.filter((team) => team?.project_team_enrollment?.approval_level > 0),
    [teamsList],
  );

  return {
    companyTeamsWithoutCurrentUserTeam,
    nextApprovalLevelTeam,
    teamsList: filteredTeamList,
    teamsIsLoading: isLoading,
  };
};
