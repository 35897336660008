import React, { useMemo, useContext } from 'react';
import { useQueries, useQuery } from 'react-query';

import { getBorrower, getHookState, isCreatedProject, isRestricted } from '@utils';
import { HookState, QueryNamesEnums, PermissionNamesEnums, IProject } from '@interfaces';
import {
  getProjectMilestonesList,
  getProjectTeams,
  getProjectUserData,
  getProject,
} from '@globalService';
import { PermissionsContext } from '@context';
import { Typography, Stack } from '@mui/material';
import { StyledLink } from '@components';
import { colors } from '@theme';

export const useProjectActivationWarning = ({ projectId }) => {
  const { permissions } = useContext(PermissionsContext);

  const query = '{}';
  const limit = '1';

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );
  const project = projectQuery.data;

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query, limit }],
      queryFn: getProjectMilestonesList.bind(this, { projectId, query, limit }),
      enabled: Boolean(project) && isCreatedProject(project?.status),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, companyId: null }],
      queryFn: getProjectTeams.bind(this, projectId, null),
      enabled: Boolean(projectId) && isCreatedProject(project?.status),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_USER_DATA, { projectId }],
      queryFn: getProjectUserData.bind(this, projectId),
      enabled: Boolean(projectId) && isCreatedProject(project?.status),
    },
  ]);

  const hookState = getHookState(requestedDataQueries);

  const budgetLength = requestedDataQueries[0].data?.count;
  const teams = requestedDataQueries[1].data?.results;

  const borrower = useMemo(() => getBorrower(teams), [teams]);

  const reasons = useMemo(() => {
    const reasonsList = [];

    if (
      !isCreatedProject(project?.status) ||
      hookState !== HookState.SUCCESS ||
      isRestricted(PermissionNamesEnums.PROJECT__OVERVIEW__PROJECT_STATUS_EDIT, permissions)
    ) {
      return null;
    }

    if (!budgetLength) reasonsList.push('budget');
    if (!borrower?.company) reasonsList.push('borrower’s company');

    return reasonsList;
  }, [project, hookState, permissions, budgetLength, borrower]);

  if (!reasons?.length) return null;

  return {
    warningWithLinks: (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '56px', color: colors.status.information.medium }}
        spacing={0.5}
      >
        <Typography variant="body2" sx={{ color: colors.status.information.medium }}>
          To activate the project, you need to
        </Typography>
        {!budgetLength && (
          <StyledLink
            data-cy="project_activation_warning__link_to_budget"
            to={`/projects/${projectId}/draws/all`}
          >
            add budget
          </StyledLink>
        )}
        {!borrower?.company && (
          <>
            {!budgetLength && (
              <Typography variant="body2" sx={{ color: colors.status.information.medium }}>
                and
              </Typography>
            )}
            <StyledLink
              data-cy="project_activation_warning__link_to_add_borrower"
              to={`/projects/${projectId}/add-borrower`}
            >
              add borrower company
            </StyledLink>
          </>
        )}
      </Stack>
    ),
  };
};
