import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';

import { colors } from '@theme';
import { CustomMultiAutocompleteWithTags, InputField, StyledDashboard } from '@components';
import { DeleteIcon, NoTypeIcon, UploadIcon, UploadIconNew } from '@svgAsComponents';
import { useCustomUppy, useSafeSnackbar } from '@hooks';
import model from './form-model/model';
import { ComponentProps } from './interface';
import {
  checkIsInvestor,
  checkIsLender,
  createTuple,
  getOptionLabel,
  getSortedByIndexMulestones,
  getTeamRole,
} from '@utils';
import {
  DocumentContentTypeEnum,
  DocumentVisibilityEnum,
  EnumTypeForList,
  IDocumentType,
  IMilestone,
  ITransloaditError,
  QueryNamesEnums,
} from '@interfaces';
import { AuthContext } from '@context';
import { fileTypesMap } from '@constants';

const { formId, formField } = model;

const DocumentUploaderWithForm = ({
  closeUploader,
  transloaditSignature: { params, signature },
  documentTypes,
  isOpen,
  refetchCallback,
  refetch,
  restrictions,
  milestones,
  source,
  drawRequestId,
  onUploadComplete,
  milestoneId,
}: ComponentProps) => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showDragDrop, setShowDragDrop] = useState(true);
  const showMilestones = useMemo(() => Boolean(milestones?.length), [milestones]);
  const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema | undefined>(null);
  const [showDashboard, setShowDashboard] = useState(true);
  const [generalScope, setGeneralScope] = useState(true);
  const [generalLineItems, setGeneralLineItems] = useState([]);
  const [generalDocumentType, setGeneralDocumentType] = useState<string>();

  // need these objects to avoid nested loops
  const documentTypesObj = documentTypes?.reduce((acc, obj) => {
    if (obj) acc[obj.name] = obj.id;
    return acc;
  }, {});
  const milestoneOptions = getSortedByIndexMulestones(milestones)?.map((milestone) => ({
    ...milestone,
    name_display: `${milestone.project_milestone?.index || milestone.index}. ${milestone.name}`,
  }));

  const mapLineItems = (items: IMilestone[], type: DocumentContentTypeEnum, key = 'id') =>
    items?.map((o) => createTuple(type, o[key])) || [];

  const getLinkedObjects = (lineItems: IMilestone[]) => {
    const projectTuple = createTuple(DocumentContentTypeEnum.PROJECT, params?.fields?.object_id);
    const drawRequestTuple = createTuple(DocumentContentTypeEnum.DRAW_REQUEST, drawRequestId);
    const milestoneTuple = mapLineItems(lineItems, DocumentContentTypeEnum.MILESTONE);
    const milestoneSubmitTuple = mapLineItems(
      lineItems,
      DocumentContentTypeEnum.MILESTONE_SUBMIT,
      'milestone_submit_id',
    );

    const linkedObjects = {
      [DocumentContentTypeEnum.MILESTONE]: [
        ...(showMilestones
          ? [projectTuple, ...milestoneTuple]
          : [createTuple(DocumentContentTypeEnum.MILESTONE, params?.fields?.object_id)]),
      ],
      [DocumentContentTypeEnum.MILESTONE_GROUP]: [
        ...(drawRequestId ? [drawRequestTuple] : []),
        createTuple(DocumentContentTypeEnum.MILESTONE_GROUP, params?.fields?.object_id),
      ],
      [DocumentContentTypeEnum.MILESTONE_SUBMIT]: [
        drawRequestTuple,
        ...(showMilestones
          ? milestoneSubmitTuple
          : [createTuple(DocumentContentTypeEnum.MILESTONE_SUBMIT, params?.fields?.object_id)]),
      ],
      [DocumentContentTypeEnum.DRAW_REQUEST]: [drawRequestTuple, ...milestoneSubmitTuple],
      [DocumentContentTypeEnum.PROJECT]: [projectTuple, ...milestoneTuple],
      [DocumentContentTypeEnum.CHECKLIST_ITEM]: [
        drawRequestTuple,
        createTuple(DocumentContentTypeEnum.CHECKLIST_ITEM, params?.fields?.object_id),
        ...milestoneSubmitTuple,
      ],
      [DocumentContentTypeEnum.INSPECTION_CHECKLIST_ITEM]: [
        createTuple(DocumentContentTypeEnum.INSPECTION_CHECKLIST_ITEM, params?.fields?.object_id),
      ],
      [DocumentContentTypeEnum.INSPECTION]: [
        createTuple(DocumentContentTypeEnum.INSPECTION, params?.fields?.object_id),
        ...milestoneTuple,
        ...(milestoneId ? [createTuple(DocumentContentTypeEnum.MILESTONE, milestoneId)] : []),
      ],
    };
    return linkedObjects[params?.fields?.content_type] || [];
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const files = await uppy.getFiles();
        files.map((file) => {
          const fileId = file.id.replaceAll('.', '-');
          uppy.setFileMeta(file.id, {
            name: values[`${fileId}-name`],
            document_type_id: documentTypesObj[values[`${fileId}-type`]],
            linked_objects: getLinkedObjects(values[`${fileId}-milestone`]),
            comment: values[`${fileId}-comment`] || '',
            thumb_disabled: true,
            scope: values[`${fileId}-scope`]
              ? DocumentVisibilityEnum.COMPANY
              : DocumentVisibilityEnum.EVERYONE,
          });
        });

        setShowDashboard(true);
        await uppy.upload();
      } catch (error) {
        formik.setSubmitting(false);
      }
    },
  });

  const { uppy } = useCustomUppy({
    params,
    signature,
    fields: ['name', 'comment', 'document_type_id', 'scope', 'linked_objects', 'thumb_disabled'],
    restrictions,
  });

  useEffect(() => {
    uppy.on('complete', () => {
      if (refetch?.length) {
        refetch.forEach((query) => refetchCallback(query));
      }
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS);
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DOCUMENTS);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS);
      if (onUploadComplete) onUploadComplete();
      resetFormAndClose();
    });

    uppy.on('files-added', (files) => {
      if (!files?.length) return;
      setShowDragDrop(false);
      setShowDashboard(false);
      setCurrentFiles(
        files.map((file) => ({ ...file, internal_id: file?.id.replaceAll('.', '-') })),
      );
    });

    uppy.on('error', (error: ITransloaditError) => {
      error.details && enqueueSnackbar(error.details, { variant: 'error' });
      resetFormAndClose();
    });

    uppy.on('restriction-failed', (file) => {
      enqueueSnackbar(
        `${file.name} can't be uploaded as .${file.extension} extension is not allowed.`,
        { variant: 'error' },
      );
    });
  }, []);

  useEffect(() => {
    if (currentFiles?.length) {
      const validationObject = {};
      const initialData = {};
      currentFiles.forEach((file) => {
        const name = `${file.internal_id}-name`;
        const type = `${file.internal_id}-type`;
        const scope = `${file.internal_id}-scope`;
        if (isEmpty(initialValues)) {
          //trim file extension from file name
          initialData[name] = file.data?.name?.split('.').slice(0, -1).join('.');
          initialData[type] = '';
          initialData[scope] = checkIsLender(teamRole) || checkIsInvestor(teamRole);
        }
        validationObject[name] = Yup.string().required('Required');
        validationObject[type] = Yup.string().required('Required');
      });
      setValidationSchema(Yup.object().shape(validationObject));
      if (isEmpty(initialValues)) setInitialValues(initialData);
    } else {
      setShowDragDrop(true);
      setShowDashboard(true);
    }
  }, [currentFiles]);

  const resetFormAndClose = useCallback(() => {
    setCurrentFiles([]);
    formik.resetForm();
    closeUploader();
  }, []);

  const valuesArray = ({
    value,
    id,
    name,
  }: {
    value: string | EnumTypeForList[];
    id: string;
    name: string;
  }) => {
    return currentFiles.map((file) =>
      file.internal_id === id
        ? value
        : formik.getFieldProps(`${file.internal_id}-${name}`)?.value || [],
    );
  };

  function setDocumentType(value: IDocumentType, id: string) {
    formik.setFieldValue(`${id}-type`, value?.name);
    if (!value?.name) setGeneralDocumentType(null);

    const docTypeArrays = valuesArray({ value: value?.name, id, name: 'type' });
    if (docTypeArrays.every((type) => type === value?.name)) {
      setGeneralDocumentType(value?.name);
    } else {
      setGeneralDocumentType(null);
    }
  }

  function setMilestone(value: EnumTypeForList[], id: string) {
    formik.setFieldValue(`${id}-milestone`, value);
    const lineItemArrays = valuesArray({ value, id, name: 'milestone' });
    const commonObjectIds = intersection(...lineItemArrays.map((arr) => arr.map((obj) => obj.id)));
    const commonLineItems = milestoneOptions.filter((milestone) =>
      commonObjectIds.includes(milestone.id),
    );

    setGeneralLineItems(commonLineItems);
  }

  function handleGeneralDocumentType(value) {
    setGeneralDocumentType(value);
    currentFiles.map((file) => formik.setFieldValue(`${file.internal_id}-type`, value?.name));
  }

  function handleGeneralLineItems(value) {
    setGeneralLineItems(value);
    currentFiles.map((file) => formik.setFieldValue(`${file.internal_id}-milestone`, value));
  }

  function updateGeneralScope(value) {
    setGeneralScope(value);
    currentFiles.map((file) => formik.setFieldValue(`${file.internal_id}-scope`, value));
  }

  function deleteDocument(id: string) {
    uppy.removeFile(id);
    setCurrentFiles((previous) => previous.filter((file) => file.internal_id !== id));
  }

  return (
    <Dialog
      aria-labelledby="responsive-dialog"
      open={isOpen}
      sx={{
        width: showMilestones ? '90%' : '60%',
        minWidth: 1200,
        margin: 'auto',
        ...(!showDashboard && showMilestones
          ? { '& .MuiPaper-root': { maxWidth: 'none', width: '100%' } }
          : { '& .MuiPaper-root': { maxWidth: 1000, width: '100%' } }),
      }}
    >
      <Stack sx={{ p: 2, whiteSpace: 'pre-wrap' }}>
        <Stack alignItems="center" sx={{ mb: 3 }}>
          <UploadIcon size={20} />
          <Typography variant="h4" sx={{ pt: '0.5rem' }} data-cy="upload_docs_modal_title">
            Upload document(s)
          </Typography>
        </Stack>
        {showDashboard && (
          <StyledDashboard uppy={uppy} hideUploadButton isDocumentUploader source={source} />
        )}
        {currentFiles?.length === 0 && showDragDrop && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              bottom: '65%',
              left: '47%',
              backgroundColor: colors.uploaderBackground,
              zIndex: 5999,
              pointerEvents: 'none',
            }}
          >
            <UploadIconNew />
          </Stack>
        )}
        <FormikProvider value={formik}>
          <Form id={formId}>
            {!showDashboard && currentFiles?.length > 0 && (
              <Paper sx={{ boxShadow: 'none', paddingY: 3, paddingX: 3 }}>
                <Stack justifyContent="space-between" alignItems="center" flexDirection="row">
                  <Stack sx={{ minWidth: 24 }} />
                  <Stack sx={{ minWidth: 280, pl: 2, width: '100%' }}>
                    <Typography variant="body1">
                      All documents
                      <Typography
                        variant="body1SemiBold"
                        data-cy={`${source}__upload__modal__documents_amount__value`}
                      >{` (${currentFiles?.length || 0})`}</Typography>
                    </Typography>
                  </Stack>
                  <Autocomplete
                    size="small"
                    options={documentTypes}
                    getOptionLabel={getOptionLabel((option: IDocumentType) => option.name)}
                    onChange={(_, value) => handleGeneralDocumentType(value)}
                    value={generalDocumentType || null}
                    renderInput={(params) => (
                      <InputField
                        {...params}
                        onChange={() => handleGeneralDocumentType('')}
                        name="general-type"
                        label="All documents type"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          'data-cy': `${source}__upload__modal__general_type__input`,
                        }}
                      />
                    )}
                    sx={{ minWidth: 200, width: '100%', pl: 2 }}
                  />
                  {showMilestones && (
                    <Stack sx={{ minWidth: 200, pl: 2, width: '100%', pt: 0.5 }}>
                      <CustomMultiAutocompleteWithTags
                        field={generalLineItems}
                        label="Line item(s) for all documents"
                        options={milestoneOptions}
                        setField={(value) => handleGeneralLineItems(value)}
                        inputProps={{
                          'data-cy': `${source}__upload__modal__general_line_item__input`,
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                      />
                    </Stack>
                  )}
                  <Stack sx={{ minWidth: 200, pl: 2, width: '100%' }} />
                  {(checkIsLender(teamRole) || checkIsInvestor(teamRole)) && (
                    <Stack sx={{ pl: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={generalScope}
                            onChange={(_, value) => updateGeneralScope(value)}
                            data-cy={`${source}__upload__modal__general_scope__switch`}
                          />
                        }
                        componentsProps={{ typography: { variant: 'body3' } }}
                        name="general-scope"
                        label={formField.scope.label}
                      />
                    </Stack>
                  )}
                  <Stack sx={{ minWidth: 48, pl: 2 }} />
                </Stack>
                <Divider sx={{ my: 2 }} />
                {currentFiles.map((currentFile, index) => {
                  const Icon =
                    fileTypesMap[currentFile?.type] ||
                    fileTypesMap[currentFile?.data?.type] ||
                    NoTypeIcon;
                  const fileId = currentFile?.internal_id;
                  return (
                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      flexDirection="row"
                      sx={{ mt: 2 }}
                      key={fileId}
                    >
                      <Stack sx={{ width: 24 }}>
                        <Icon />
                      </Stack>
                      <Stack sx={{ minWidth: 280, pl: 2, width: '100%' }}>
                        <InputField
                          size="small"
                          variant="outlined"
                          name={`${fileId}-name`}
                          label={formField.name.label}
                          fullWidth
                          inputProps={{
                            'data-cy': `${source}__upload__modal__document_name__input__index_${index}`,
                          }}
                        />
                      </Stack>
                      <Autocomplete
                        size="small"
                        options={documentTypes}
                        getOptionLabel={getOptionLabel((option: IDocumentType) => option.name)}
                        onChange={(_, value) => setDocumentType(value, fileId)}
                        value={formik.getFieldProps(`${fileId}-type`)?.value || null}
                        renderInput={(params) => (
                          <InputField
                            {...params}
                            onChange={() => setDocumentType(undefined, fileId)}
                            name={`${fileId}-type`}
                            label={formField.type.label}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              'data-cy': `${source}__upload__modal__document_type__input__index_${index}`,
                            }}
                          />
                        )}
                        sx={{ minWidth: 200, width: '100%', pl: 2 }}
                        isOptionEqualToValue={(option, value) => option.name === value}
                      />
                      {showMilestones && (
                        <Stack sx={{ minWidth: 200, pl: 2, width: '100%', pt: 0.5 }}>
                          <CustomMultiAutocompleteWithTags
                            label={formField.milestone.label}
                            options={milestoneOptions}
                            field={formik.getFieldProps(`${fileId}-milestone`)?.value || []}
                            setField={(value) => setMilestone(value, fileId)}
                            inputProps={{
                              'data-cy': `${source}__upload__modal__line_item__input__index_${index}`,
                            }}
                            isOptionEqualToValue={(option, value) => option.name === value.name}
                          />
                        </Stack>
                      )}
                      <Stack sx={{ minWidth: 200, pl: 2, width: '100%' }}>
                        <InputField
                          variant="outlined"
                          name={`${fileId}-comment`}
                          label={formField.comment.label}
                          size="small"
                          inputProps={{
                            'data-cy': `${source}__upload__modal__comment__input__index_${index}`,
                          }}
                        />
                      </Stack>
                      {(checkIsLender(teamRole) || checkIsInvestor(teamRole)) && (
                        <Stack sx={{ pl: 2 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Boolean(formik.getFieldProps(`${fileId}-scope`)?.value)}
                                onChange={() => {
                                  if (formik.getFieldProps(`${fileId}-scope`)?.value) {
                                    setGeneralScope(false);
                                  } else {
                                    const allScopes = currentFiles.every(
                                      (file) =>
                                        formik.getFieldProps(`${file.internal_id}-scope`)?.value ||
                                        file.internal_id === fileId,
                                    );
                                    if (allScopes) setGeneralScope(true);
                                  }
                                  formik.setFieldValue(
                                    `${fileId}-scope`,
                                    !formik.getFieldProps(`${fileId}-scope`)?.value,
                                  );
                                }}
                                data-cy={`${source}__upload__modal__scope__switch__index_${index}`}
                              />
                            }
                            componentsProps={{ typography: { variant: 'body3' } }}
                            name={`${fileId}-scope`}
                            label={formField.scope.label}
                          />
                        </Stack>
                      )}
                      <IconButton
                        sx={{ pl: 2 }}
                        onClick={() => deleteDocument(fileId)}
                        data-cy={`${source}__upload__modal__delete__button__index_${index}`}
                      >
                        <DeleteIcon size={24} />
                      </IconButton>
                    </Stack>
                  );
                })}
              </Paper>
            )}

            <Stack
              direction="row"
              flexWrap="wrap"
              spacing={2}
              justifyContent="flex-end"
              sx={{ mt: 6 }}
            >
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  resetFormAndClose();
                  uppy.cancelAll();
                  closeUploader();
                }}
                data-cy={`${source}__upload__modal__cancel__button`}
              >
                Cancel
              </Button>
              <Button
                disabled={formik.isSubmitting || currentFiles?.length === 0}
                type="submit"
                data-cy={`${source}__upload__modal__upload__button`}
              >
                {formik.isSubmitting
                  ? 'Uploading...'
                  : `Upload ${currentFiles?.length} ${
                      currentFiles?.length === 1 ? 'file' : 'files'
                    }`}
              </Button>
            </Stack>
          </Form>
        </FormikProvider>
      </Stack>
    </Dialog>
  );
};

export default DocumentUploaderWithForm;
