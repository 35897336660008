import React, { useContext, useMemo } from 'react';
import { Button, Grid } from '@mui/material';

import { ProjectStatusMap } from '@constants';
import {
  checkIsLender,
  checkIsOwner,
  getArrayFromObject,
  getTeamRole,
  WithPermission,
} from '@utils';
import { AuthContext } from '@context';
import { FilterButton, SearchBox } from '@components';
import { ComponentProps } from '../../interface';
import * as Controller from '../../controller';
import { PermissionNamesEnums, ScoreValuesEnum } from '@interfaces';

const ProjectsPortfolioFilters = ({
  handleFiltersChange,
  resetFiltersToDefault,
  filters,
  handleSearchSubmit,
  clearSearch,
  search,
}: ComponentProps) => {
  const {
    borrowerCompanies,
    customerCompanies,
    investorCompanies,
    lenderCompanies,
    projectPropertyTypes,
    projectTypes,
    states,
    scoresValues,
  } = Controller.useProjectFilters();

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isLender = useMemo(() => checkIsLender(teamRole), [teamRole]);
  const isOwner = useMemo(() => checkIsOwner(teamRole), [teamRole]);

  return (
    <Grid container justifyContent="space-between" wrap="nowrap" p={2}>
      <Grid item sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid container wrap="wrap" spacing={1}>
          <Grid item sx={{ width: '200px' }}>
            <SearchBox
              height="32px"
              search={search}
              onSubmit={handleSearchSubmit}
              clearSearch={clearSearch}
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={projectPropertyTypes}
              title="Existing property type"
              initialValue={filters.property_existing_type}
              valueName="property_existing_type"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.type === value?.type}
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={projectPropertyTypes}
              title="Proposed property type"
              initialValue={filters.property_proposed_type}
              valueName="property_proposed_type"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.type === value?.type}
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={projectTypes}
              title="Project type"
              initialValue={filters.project_type}
              valueName="project_type"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.type === value?.type}
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={getArrayFromObject(ProjectStatusMap, 'status', 'name')}
              title="Project status"
              initialValue={filters.status}
              valueName="status"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.status === value?.status}
            />
          </Grid>
          <Grid item>
            <FilterButton
              labels={states}
              title="State"
              initialValue={filters.state}
              label="state_display"
              valueName="state"
              updateFilters={handleFiltersChange}
              isOptionEqualToValue={(option, value) => option.state === value?.state}
            />
          </Grid>
          <WithPermission permissionKey={PermissionNamesEnums.RISK_RADAR__INVESTOR__VIEW}>
            <Grid item>
              <FilterButton
                labels={investorCompanies}
                initialValue={filters.investor}
                title="Investor"
                valueName="investor"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          </WithPermission>
          {!isLender && (
            <Grid item>
              <FilterButton
                labels={lenderCompanies}
                initialValue={filters.lender}
                title="Lender"
                valueName="lender"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          )}
          {!isOwner && (
            <Grid item>
              <FilterButton
                labels={borrowerCompanies}
                initialValue={filters.borrower}
                title="Borrower"
                valueName="borrower"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          )}
          <WithPermission permissionKey={PermissionNamesEnums.RISK_RADAR__CUSTOMER__VIEW}>
            <Grid item>
              <FilterButton
                labels={customerCompanies}
                initialValue={filters.customer}
                title="Customer"
                valueName="customer"
                updateFilters={handleFiltersChange}
              />
            </Grid>
          </WithPermission>
          <WithPermission permissionKey={PermissionNamesEnums.RISK_RADAR__SCORES__VIEW}>
            <Grid item>
              <FilterButton
                labels={scoresValues}
                initialValue={filters[ScoreValuesEnum.PROJECT_HEALTH]}
                title="Project health"
                valueName={ScoreValuesEnum.PROJECT_HEALTH}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
            <Grid item>
              <FilterButton
                labels={scoresValues}
                initialValue={filters[ScoreValuesEnum.BUDGET_HEALTH]}
                title="Budget health"
                valueName={ScoreValuesEnum.BUDGET_HEALTH}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
            <Grid item>
              <FilterButton
                labels={scoresValues}
                initialValue={filters[ScoreValuesEnum.POLICY_HEALTH]}
                title="Policy risk"
                valueName={ScoreValuesEnum.POLICY_HEALTH}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
            <Grid item>
              <FilterButton
                labels={scoresValues}
                initialValue={filters[ScoreValuesEnum.SCHEDULE_HEALTH]}
                title="Schedule health"
                valueName={ScoreValuesEnum.SCHEDULE_HEALTH}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
            <Grid item>
              <FilterButton
                labels={scoresValues}
                initialValue={filters[ScoreValuesEnum.BORROWER_SCORE]}
                title="Borrower health"
                valueName={ScoreValuesEnum.BORROWER_SCORE}
                updateFilters={handleFiltersChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
              />
            </Grid>
          </WithPermission>
          <Grid item>
            <Button size="small" variant="text" onClick={resetFiltersToDefault}>
              Reset filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectsPortfolioFilters;
