import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';

import { colors } from '@theme';
import { scoreColorMap } from '@constants';
import { ComponentProps } from './interface';
import { percentFormatter } from '@utils';

const HealthScore = ({ data, title }: ComponentProps) => {
  const scoreData = data.map((item) => ({
    value: Math.round(item.value),
    color: scoreColorMap[item.color],
  }));

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        width: '100%',
        borderRadius: 1,
        height: 94,
        padding: 2,
        backgroundColor: colors.background.white,
        alignItems: 'center',
      }}
    >
      {Boolean(scoreData.length) && (
        <Box sx={{ width: 62 }}>
          <PieChart data={scoreData} lineWidth={40} />
        </Box>
      )}

      <Stack>
        <Typography variant="body2SemiBold" color={colors.text.medium} sx={{ ml: 1 }}>
          {title}
        </Typography>
        <Stack direction="row" sx={{ mt: 1 }}>
          {data.map((item) => {
            if (item.value === 0) return null;
            return (
              <Stack direction="row" key={item.color} sx={{ mr: 1, ml: 1, alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    mr: 1,
                    borderRadius: '2px',
                    backgroundColor: scoreColorMap[item.color],
                  }}
                />
                <Typography variant="body3" color={scoreColorMap[item.color]}>
                  {percentFormatter({
                    value: item.value,
                    roundTo: 0,
                  })}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HealthScore;
