import React from 'react';
import { Box, Stack } from '@mui/material';
import { CustomCurrencyTextField, CustomPercentTextField } from '@components';
import { calculateAmount, calculateFraction, roundToTwoDigits } from '@utils';

const PaymentConfigurationEdit = ({
  postFundingConstructionBudget,
  constructionHoldback,
  constructionHoldbackFraction,
  borrowerEquity,
  borrowerEquityFraction,
  source,
  budgetEditDisabled = false,
  totalAmountLabel = 'Construction budget post-funding ($)',
  isEditable = true,
  clearOnTouchPostFundingConstructionBudget = false,
}) => {
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2}>
        <CustomCurrencyTextField
          label={totalAmountLabel}
          field={postFundingConstructionBudget}
          onChange={(e) => {
            postFundingConstructionBudget.setValue(e.target.value);

            constructionHoldback.setValue(
              calculateAmount(constructionHoldbackFraction.value, e.target.value).toString(),
            );
            borrowerEquity.setValue(
              calculateAmount(borrowerEquityFraction.value, e.target.value).toString(),
            );
          }}
          disabled={budgetEditDisabled || !isEditable}
          inputProps={{ 'data-cy': `${source}__construction_budget__input` }}
          required
          validationText="Construction budget post-funding is required"
          clearOnTouch={clearOnTouchPostFundingConstructionBudget}
        />
        <CustomCurrencyTextField
          label="Construction holdback ($)"
          field={constructionHoldback}
          onChange={(e) => {
            constructionHoldback.setValue(e.target.value);
            if (+postFundingConstructionBudget.value) {
              borrowerEquity.setValue(
                roundToTwoDigits(postFundingConstructionBudget.value - +e.target.value),
              );
              const contributionFraction = calculateFraction(
                e.target.value,
                postFundingConstructionBudget.value,
              );
              constructionHoldbackFraction.setValue(contributionFraction);
              borrowerEquityFraction.setValue(
                calculateFraction(
                  roundToTwoDigits(postFundingConstructionBudget.value - +e.target.value),
                  postFundingConstructionBudget.value,
                ),
              );
            }
          }}
          inputProps={{ 'data-cy': `${source}__construction_holdback__input` }}
          disabled={!+postFundingConstructionBudget.value || !isEditable}
          validationText={constructionHoldback.errorTip}
        />
        <CustomCurrencyTextField
          label="Borrower equity ($)"
          field={borrowerEquity}
          onChange={(e) => {
            borrowerEquity.setValue(e.target.value);
            if (+postFundingConstructionBudget.value) {
              constructionHoldback.setValue(
                roundToTwoDigits(postFundingConstructionBudget.value - +e.target.value),
              );
              const borrowerFraction = calculateFraction(
                e.target.value,
                postFundingConstructionBudget.value,
              );
              borrowerEquityFraction.setValue(borrowerFraction);
              constructionHoldbackFraction.setValue(
                calculateFraction(
                  roundToTwoDigits(postFundingConstructionBudget.value - +e.target.value),
                  postFundingConstructionBudget.value,
                ),
              );
            }
          }}
          inputProps={{ 'data-cy': `${source}__borrower_equity__input` }}
          disabled={!+postFundingConstructionBudget.value || !isEditable}
          validationText={borrowerEquity.errorTip}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box width="100%" />
        <CustomPercentTextField
          label="Construction holdback (%)"
          field={constructionHoldbackFraction}
          onChange={(e) => {
            constructionHoldbackFraction.setValue(e.target.value);
            borrowerEquityFraction.setValue(100 - +e.target.value);
            if (+postFundingConstructionBudget.value) {
              const constructionAmount = calculateAmount(
                e.target.value,
                postFundingConstructionBudget.value,
              );
              borrowerEquity.setValue(
                roundToTwoDigits(postFundingConstructionBudget.value - constructionAmount),
              );
              constructionHoldback.setValue(constructionAmount);
            }
          }}
          inputProps={{ 'data-cy': `${source}__construction_holdback_fraction__input` }}
          disabled={!+postFundingConstructionBudget.value || !isEditable}
          validationText={constructionHoldbackFraction.errorTip}
        />
        <CustomPercentTextField
          field={borrowerEquityFraction}
          label="Borrower equity (%)"
          onChange={(e) => {
            borrowerEquityFraction.setValue(e.target.value);
            constructionHoldbackFraction.setValue(100 - +e.target.value);
            if (+postFundingConstructionBudget.value) {
              const borrowerAmount = calculateAmount(
                e.target.value,
                postFundingConstructionBudget.value,
              );
              constructionHoldback.setValue(
                roundToTwoDigits(postFundingConstructionBudget.value - borrowerAmount),
              );
              borrowerEquity.setValue(borrowerAmount);
            }
          }}
          inputProps={{ 'data-cy': `${source}__borrower_equity_fraction__input` }}
          disabled={!+postFundingConstructionBudget.value || !isEditable}
          validationText={borrowerEquityFraction.errorTip}
        />
      </Stack>
    </Stack>
  );
};

export default PaymentConfigurationEdit;
