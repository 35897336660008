export const inspectionsDocumentItem = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  documentId = 'document_pk',
) => `projects/${projectId}/inspections/${inspectionId}/documents/${documentId}/`;

export const inspectionsDocuments = (
  projectId = 'project_pk',
  inspectionId = 'inspection_pk',
  query = '',
) => `projects/${projectId}/inspections/${inspectionId}/documents/${query}`;
